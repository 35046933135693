import { JobVarItemAttachmentService } from './../services/felixApi/job-var-item-attachment.service';
import { JobItemAttachmentService } from './../services/felixApi/job-item-attachment.service';
import { Component, OnInit, OnDestroy, ViewChild, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { ModalDismissReasons, NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ChangeTypeEnum } from '../dtos/change-type.enum';
import { ItemTypeEnum } from '../dtos/item-type.enum';
import { IJob } from '../dtos/job';
import { IJobItem } from '../dtos/job-item';
import { IJobVarItem } from '../dtos/job-var-item';
import { JobVarTypeEnum } from '../dtos/job-var-type.enum';
import { Variation } from '../dtos/variation';
import { ShowPdfComponent } from '../shared/show-pdf.component';

import { JobItemService } from '../services/felixApi/job-item.service';
import { JobVarItemService } from '../services/felixApi/job-var-item.service';
import { JobService } from '../services/felixApi/job.service';
import { VariationService } from '../services/felixApi/variation.service';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { ShowFullAddendaComponent } from './selection-modals/show-full-addenda.component';
import { SelectionReportModalComponent } from './selection-modals/selection-report-modal.component';
import { HeadingModalComponent } from './selection-modals/heading-modal.component';
import { AddEditDeleteEnum } from '../dtos/add-edit-delete.enum';
import { VariationTypeEnum } from '../dtos/variation-type.enum';
import { OptionListService } from '../services/felixApi/option-list.service';
import { SalesChoiceModalComponent } from './selection-modals/sales-choice-modal.component';
import { JobCopyModalComponent } from '../jobs/job-modals/job-copy-modal.component';
import { MoveItemsModalComponent } from './selection-modals/move-items-modal.component';
import { CreateSalesVariationModalComponent } from './selection-modals/create-sales-variation-modal.component';
import { NotificationService } from '../services/notification.service';
import { SelectionEditModalComponent } from './selection-modals/selection-edit-modal.component';
import { VariationAddModalComponent } from './selection-modals/variation-add-modal.component';
import { Subscription } from 'rxjs';
import { SubmitToEstimatingModalComponent } from './selection-modals/submit-to-estimating-modal.component';
import { MENU_HEIGHT } from '../../config/variables';
import { PHONE_SIZE } from '../../config/variables';
import { ClientChoiceModalComponent } from './selection-modals/client-choice-modal.component';
import { VariationCopyModalComponent } from './selection-modals/variation-copy/variation-copy-modal.component';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { AddSelectionBoardComponent } from './selection-modals/add-selection-board/add-selection-board.component';
import { CopyFromFinalJobComponent } from './selection-modals/copy-from-final-job/copy-from-final-job.component';
import { VariationHelperService } from '../services/felixApi/variation-helper.service';
import { JobItemCostComponent } from './selection-modals/job-item-cost/job-item-cost.component';
import { MarkAllCheckedComponent } from './selection-modals/mark-all-checked/mark-all-checked.component';
import { HouseOptionsGridComponent } from '../option-lists/house-options-grid/house-options-grid.component';
import { JobRolesComponent } from '../shared/job-roles/job-roles.component';
import { ApproveVariationComponent } from './selection-modals/approve-variation/approve-variation.component';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { ConstructiveSelectionsComponent } from './selection-modals/constructive-selections/constructive-selections.component';
import { LoadAddendaPdfComponent } from './selection-modals/load-addenda-pdf/load-addenda-pdf.component';
import { ChangeTypeComponent } from './selection-modals/change-type/change-type.component';
import { CheckShowRulesReportComponent } from './selection-modals/check-show-rules-report/check-show-rules-report';

@Component({
  selector: 'js-selection-job',
  templateUrl: './selection-job.component.html',
  styleUrls: ['./selection-job.component.scss']
})

export class SelectionJobComponent implements OnInit, OnDestroy, AfterViewInit {

  COMPONENT_NAME = 'selection-job';
  // PHONE_SIZE = 660;
  SHOW_LOCK_AT_SIZE = 800;
  SHOW_QTY_AT_SIZE = PHONE_SIZE;
  BUTTONS_WIDTH = 150; // we added 7 for the borders of the card - was 130
  BUTTONS_WIDTH_SMALL = 40;
  ISHIDDEN_MESSAGE_WIDTH = 40; // if we show hidden items we need room for the message
  PRICE_WIDTH = 90;
  IS_CHECKED_WIDTH = 20;
  CONTAINER_BORDER = 6; // the deduction required due to the accordian panels
  /* pixel height of header (Qty, Price) + footer (Total) in variation mode. for calc of list scroll div height: */
  VARIATION_MODE_HEIGHT = 40;
  WARNING_HEIGHT = 40; // if we have a warning we need this
  subscriptions: Subscription[] = [];
  configurationEnum = ConfigurationEnum;

  menuHeight = MENU_HEIGHT;
  innerWidth: number;
  // innerHeight: number;
  subHeaderHeight: number;
  footerHeight: number;
  buttonsWidth: number; // we need to calc this is phone size
  footerMarginRight: number; // the margin for the price to align correctly
  isRestrictedForm = false; // is this screen size small
  showQty = false; // show the qty and rate headings
  isAdminOrSuperUser = false; // if you are an admin you can turn the buttons back on after locked
  isClient = false; // indicates if this user is a client
  isAssociate = false; // indicates if this use is an associate
  // showTransLogo = true;
  errorMessage: any;
  currentJob: IJob;
  selectionsAdmin = false;
  variationsWrite = false;
  jobLineSetupAdmin = false;
  jobLineSetupWrite = false;
  // jobNumberForm: FormGroup;
  filterForm: UntypedFormGroup;
  jobs: IJob[] = [];
  // jobItemsRaw: IJobItem[] = [];
  // jobItems: IJobItem[] = [];
  updatedItems: IJobItem[] = [];
  jobNum = '';
  openPanel = '';
  updatedItem: any;
  editOption = '';
  originalItem: IJobItem; // the original item before being changed by VO.
  originalDesc = ''; // the original desc before being changed by VO.
  editIsSetUpLine = false;
  modalHeading = '';
  modalButton = '';
  modalButton2: string;
  closeResult = '';
  masterLockedMessage = 'Master';
  currentJobItemId: number;
  currentJobItem: IJobItem;
  andORSetupLinks = ''; // needed for when we set the setup tags in the modal to AND or OR
  addAboveOrAfter: string;
  canAddAbove = false;
  loading = false; // loading spinner
  loadingJob = false; // waitig for the job to load
  loadingOptions = true; // waiting for the option list to load
  closeOtherPanels = true; // we want to close other panels unless we are in a not-open VO

  jobItemsCheck: IJobItem[] = []; // check when deleting that we have no children

  // field to tell the system to show/hide setup lines
  testSelection: string; // used to test for Not Applicable items to show/hide them
  testItemDescription: string;

  // will need this to check what variations exist
  // variations: Variation[] = [];
  currentVariation: Variation;
  haveCheckedForVariations = false;
  anyVariations = false;
  anyPostContractVOs = false;
  openVariations = false; // any open variations? warn user
  arrayNum: number; // for iterating through an array
  stopGetLines = false; // this is used when a user clicks quickly on the goto variations while the initial master 'get' hasn't finished

  // check what mode the selections area is in
  showImages = false;
  variationNumber: number;
  showNumbering = false;
  // variationId: number; changed to use service variable
  variationType = 0; // 2/12 GH - added the variationType - took out isConstructionVO
  variationTypeEnum = VariationTypeEnum;

  // haveValidVariation = false;
  showAddEditButtons = false; // will update this flag when a user cannot use add/edit/del buttons
  showUpDownButtons = false; // will update depending on where we are - Master/Variations etc.

  // variables for job variation items
  currentJobVarItem: IJobVarItem;
  jobVarTypeEnum = JobVarTypeEnum;
  changeTypeEnum = ChangeTypeEnum;
  alreadyHaveVO = false;
  ItemType = ItemTypeEnum;
  isVariationOpen = false;
  variationStatusEnum = VariationStatusEnum;

  foundItems: boolean; // used to check a find command

  firstRecord: number; // used when we add a new var item
  lastRecord = 0;

  @ViewChild('card') cardDiv: ElementRef;
  @ViewChild('header') headerDiv: ElementRef;
  @ViewChild('ngbAccordion') ngbAccordion: NgbAccordion;
  // @ViewChild('footer') footerDiv: ElementRef;
  scrollHeight: number;
  navigationSubscription: Subscription;
  myWidth: number;
  adminOverrideButtons = false;
  openVariationToBeModifiedByClient: number; // the VO that a client can modify
  isSuperUser = false;
  defaultShowNotPrinted = false;
  canSeeAmountsPermission = false;
  headingTextWidth: number;
  addendumName: string;
  jobLinesSetupOnlyAdmin = false; // admin can add a template
  showWarning = false;
  itemsChangedFlag = false;
  variationsAdmin: boolean;
  isConstructiveEnabled: boolean;
  passedVariationNumber: string;
  useUploadedAddenda: boolean;

  // @HostListener('window:resize') onResize() {
  //   this.checkDOMSizes();
  // }
  @HostListener('window:click') onClickResize() {
    this.checkDOMSizes();
  }

  constructor(
    private _jobService: JobService,
    private _jobVarItemService: JobVarItemService,
    private _variationService: VariationService,
    private _varHelper: VariationHelperService,
    public _jobItemService: JobItemService,
    private _globalService: GlobalService,
    private _authService: AuthService,
    private modalService: NgbModal,
    private _optionService: OptionListService,
    private estimatingService: EstimatingService,
    private router: Router,
    private notiService: NotificationService,
    private jobItemAttachmentService: JobItemAttachmentService,
    private jobVarItemAttachmentService: JobVarItemAttachmentService) {

    this.subscriptions = this.subscriptions.concat(
      this.navigationSubscription = this.router.events.subscribe((e: any) => {
        // If it is a NavigationEnd event re-initalise the component
        if (e instanceof NavigationEnd) {
          if (this.currentJob) {
            this._jobItemService.filterByClientUpdatable = false;
            this.gotoMaster();
          }
        }
      })
    );
  }


  ngOnInit(): void {
    this._globalService.setAreaSelected('selections');

    const config = this._globalService.getCompanyConfigValue(ConfigurationEnum.ConstructiveIntegrationEnabled);
    if (config) {
      if (this._authService.isAdminOrSuperUser()) {
        this.isConstructiveEnabled = true;
      } else {
        const permission = this._authService.getSelectionsPermissions('Constructive');
        if (permission === 'Write' || permission === 'Admin') {
          this.isConstructiveEnabled = true;
        }
      }
    }

    this._jobItemService.showOnlyAddsChanges = false; // if in a variaton we can show just the entries in this VO or all the Addendum
    this._jobItemService.showHiddenLines = false; // hidden lines are not returned from the database unless true
    this._jobItemService.showSetupLines = true; // setup lines are only shown when the job is not locked but can be hidden
    this._jobItemService.isLocked = false; // is the current job locked?
    this._jobItemService.selectionsMode = 'Master'; // or will be set to 'Variations'
    this._jobItemService.showHistoryOfChanges = this._globalService.getShowHistoryOfChanges();
    this.showNumbering = this._globalService.getShowVariationNumbering();
    this._jobItemService.showNotPrintedItems = false;
    this._jobItemService.showNotApplicableItems = false;

    this.subscribeToWidthChanges();
    this.subscribeToVariationNumberChanges();
    this.subscribeToItemChanges();

    this.setPermissions();

    this.variationNumber = this._jobService.currentVariationId;
    this._jobItemService.currentVariation = null;
    this._jobService.currentVariationId = null;

    if (!this.variationNumber) {
      // we can check if we are passing the variation number from the login url
      const session = this._globalService.getSessionObject();
      if (session.passedVariationNumber && session.passedVariationNumber !== '') {
        // this.variationNumber = +session.passedVariationNumber;
        this.passedVariationNumber = session.passedVariationNumber;
        this._globalService.setSessionAtt('passedVariationNumber', ''); // clear
      }
    }

    this.jobNum = this._globalService.getCurrentJob();

    // if (this.jobNum) {
    //   console.log('init selections - job ' + this.jobNum);
    // }

    this.addendumName = this._globalService.getAddendumName();
    this._jobItemService.currentJobItems = []; // clear the list
    this._jobItemService.filterText = ''; // clear the filter

    // this.jobNumberForm = new FormGroup({
    //   jobNumber: new FormControl(this.jobNum)
    // });

    this.filterForm = new UntypedFormGroup({
      filterText: new UntypedFormControl('')
    });

    if (this.jobNum !== '') {
      // this.getJob(this.jobNum); global job number component will call this
    } else if (this.isClient) {
      // we load the first job for this client via the job-number component
    } else {
      this.loading = false;
      this.loadingJob = false;
      this.loadingOptions = false;
    }
  }

  setPermissions() {
    if (this._authService.isSuperUser()) {
      this.isSuperUser = true;
    }
    if (this._authService.isAdminOrSuperUser() || this.isSuperUser) {
      this.isAdminOrSuperUser = true;
      this.selectionsAdmin = true;
      this.variationsWrite = true;
      this.jobLineSetupAdmin = true;
      this.jobLineSetupWrite = true;
      this._jobItemService.showNotPrintedItems = true;
      this.defaultShowNotPrinted = true;
    } else if (this._authService.isClient()) {
      this.isClient = true;
      this.showImages = true;
    } else if (this._authService.isAssociate()) {
      this.isAssociate = true;
      this.showImages = true;
    } else {
      const permissionLevel = this._authService.getSelectionsPermissions('Selections');
      if (permissionLevel === 'Admin') {
        this.selectionsAdmin = true;
        this.variationsWrite = true;
        this._jobItemService.showNotPrintedItems = true;
        this.defaultShowNotPrinted = true;
      } else if (permissionLevel === 'Write') {
        this.variationsWrite = true;
        this._jobItemService.showNotPrintedItems = true;
        this.defaultShowNotPrinted = true;
      }
      const permissionJobLineSetup = this._authService.getSelectionsPermissions('JobLineSetup');
      if (permissionJobLineSetup === 'Admin') {
        this.jobLineSetupAdmin = true;
        this.jobLineSetupWrite = true;
        this._jobItemService.showNotPrintedItems = true;
        this.defaultShowNotPrinted = true;
      } else if (permissionJobLineSetup === 'Write') {
        this.jobLineSetupWrite = true;
        this._jobItemService.showNotPrintedItems = true;
        this.defaultShowNotPrinted = true;
      }
      const permissionSalesVariations = this._authService.getSelectionsPermissions('SalesVariations');
      if (permissionSalesVariations === 'Admin' || permissionSalesVariations === 'Write') {
        this._jobItemService.showNotPrintedItems = true;
        this.defaultShowNotPrinted = true;
        this.variationsWrite = true;
      }
      const permissionPreConVariations = this._authService.getSelectionsPermissions('PreContractVariations');
      if (permissionPreConVariations === 'Admin' || permissionPreConVariations === 'Write') {
        this._jobItemService.showNotPrintedItems = true;
        this.defaultShowNotPrinted = true;
        this.variationsWrite = true;
      }
    }
  }

  getOptionLists() {
    this.loadingOptions = true;
    // now need to load for everyone as we need the data - e.g. hideQuantity flag
    // if (this.jobLineSetupWrite || this.selectionsAdmin || this.variationsWrite || this.isClient) {
    this._optionService.resetCurrentOptionNodes(); // ensure we get a new list of options if we need them
    this.subscriptions = this.subscriptions.concat(
      this._optionService.getOptionListChildren(0, true, false)
        .subscribe({
          next: (treeOptionNodes) => {
            this._optionService.setCurrentOptionNodes(treeOptionNodes);
            this.getHouseOptionLists();
            this.getVariations();
          },
          error: (err) => {
            this.notiService.notify(err);
            // this.loadingOptions = false;
            alert('Error loading options, click Ok to retry');
            this.getOptionLists();
            // this.getVariations();
          }
        })
    );
  }

  getHouseOptionLists() {
    this._optionService.setCurrentHouseOptionNodes('', null);  // reset
    this.subscriptions = this.subscriptions.concat(
      this._optionService.getOptionListChildren(0, true, true, this._jobService.currentJob.houseTypeId)
        .subscribe({
          next: (treeHouseOptionNodes) => {
            this._optionService.setCurrentHouseOptionNodes(this._globalService.getCurrentJob(), treeHouseOptionNodes);
            this.loadingOptions = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            alert('Error loading house options, click Ok to retry');
            this.getHouseOptionLists();
            this.loadingOptions = false;
          }
        })
    );
  }

  ngAfterViewInit() {
    this.checkDOMSizes();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });

    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._globalService.selectonsInnerWidthChanged.subscribe(width => {
        setTimeout(() => {
          this.checkDOMSizes();
        }, 550); // wait for iPhone
      })
    );
    this.subscriptions = this.subscriptions.concat(
      this._globalService.innerHeightChanged.subscribe(height => {
        setTimeout(() => {
          this.checkDOMSizes();
        }, 550); // wait for iPhone
      })
    );
  }

  getJob(jobno: string) {
    this.loadingJob = true;
    this._jobItemService.selectionsMode = 'Master'; // reset

    this.currentJob = this._jobService.currentJob;
    this.jobNum = jobno;
    this._jobItemService.isLocked = this.currentJob.isLocked;
    this.useUploadedAddenda = this._jobService.currentJob?.useUploadedAddenda;

    if (this._jobItemService.isLocked) {
      this.showAddEditButtons = false;
      this._jobItemService.showSetupLines = false;
    } else {
      this.masterLockedMessage = 'Master';
      this.setShowAddEditButtons(); // check for permissions to add/change
      this._jobItemService.showSetupLines = true; // set default to show setup lines
    }
    this._globalService.setCurrentJobItemAbove(0);

    // we load here so we can hit GO to get the latest list
    this.getOptionLists();

  }

  initialGetItems() {
    // this.variationId = null;
    this._jobItemService.currentVariation = null;
    this._jobItemService.showOnlyAddsChanges = false;
    this._jobItemService.showHistoryOfChanges = this._globalService.getShowHistoryOfChanges();
    this.closeOtherPanels = true; // reset panel behaviour
    this._jobItemService.currentJobItems = []; // clear the list
    this.resetFilter();
    this.getJobItems(this.jobNum);
  }

  refreshJobNumber(jobNo: string) {
    if (jobNo && this._jobService.currentJob) {
      if (this._jobItemService.currentVariation && this._jobItemService.currentVariation.jobId !== this._jobService.currentJob.id) {
        this.router.navigate(['variations']);
      } else {
        if (jobNo.toUpperCase() !== this.jobNum?.toUpperCase() || this.variationNumber < 1) {
          this.variationNumber = null;
          this.openVariations = false;

          // clear image caches
          this.jobItemAttachmentService.cache = [];
          this.jobVarItemAttachmentService.cache = [];
        }
        this.showAddEditButtons = false; // if going back to master then must be read only
        this._jobItemService.currentVariation = null;
        this.closeOtherPanels = true; // reset panel behaviour
        this._jobItemService.currentJobItems = []; // clear the list
        this.resetFilter();
        this._jobItemService.showOnlyAddsChanges = false;
        this.stopGetLines = false;
        this.setPermissions(); // sometimes the permissions are not set right so we redo on change of job
        this.getJob(jobNo);
      }
    } else {
      this.changeJNum();
    }
  }

  setVariationNum(variationReturned: Variation) {
    this.variationNumber = variationReturned.variationNumber;
    this._jobItemService.currentVariation = variationReturned;
    this.variationType = variationReturned.variationType;
    this.stopGetLines = false;
    this._jobItemService.variationAdminFee = variationReturned.adminFee;
    this._jobItemService.showNotPrintedItems = true; // for variations we need to show all the hidden items
    this.currentVariation = variationReturned;
    this.getJobVarCostsForVariation();
  }

  getJobVarCostsForVariation() {
    if (this._jobItemService.currentVariation && this.selectionsAdmin) {
      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.getJobVarCostsForVariation(this._jobItemService.currentVariation.id)
          .subscribe({
            next: () => {
              // reset the filter
              this.resetFilter();
              this.getHideSections(this.currentVariation);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    } else {
      // reset the filter
      this.resetFilter();
      this.getHideSections(this.currentVariation);
    }
  }

  getJobVarCostsForJob() {
    if (this.selectionsAdmin) {
      this.subscriptions = this.subscriptions.concat(
        this.estimatingService.getJobVarCostsForJob(this._jobService.currentJob.id)
          .subscribe({
            next: () => {
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  getHideSections(variationReturned: Variation) {
    this._variationService.hiddenSectionsInVariation = [];

    this.subscriptions = this.subscriptions.concat(
      this._jobService.getHideSectionsAndCalcs(this._jobService.currentJob.id, variationReturned.id)
        .subscribe({
          next: (hideSections) => {
            // we want to reverse the selected type so we can show which ones we are showing
            const idsToHide = hideSections.filter(i => i.variationType === variationReturned.variationType);
            this._variationService.hiddenSectionsInVariation = idsToHide;

            this.getRemainingVariationInfo(variationReturned);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.getRemainingVariationInfo(variationReturned); // continue as minor error
          }
        })
    );
  }

  getRemainingVariationInfo(variationReturned: Variation) {
    if (variationReturned.statusId < this.variationStatusEnum.PendingApproval) {
      this.setShowAddEditButtons(); // check for permissions to add/change

      if (!this.isClient || variationReturned.statusId === this.variationStatusEnum.Open) {
        this.isVariationOpen = true;
      } else {
        this.isVariationOpen = false;
      }

      // ask if we want to show the full addenda or not?
      if (this.variationsWrite) { // GH 12/12/18 - if read only we just show the items in this VO
        if (variationReturned.hasItems) {
          this.showAllOrJustThisVO(variationReturned.variationType);
        } else {
          this._jobItemService.showOnlyAddsChanges = false;
          this.getJobItems(this.jobNum); // get items now we have a VO
        }
      } else if (this.isClient && variationReturned.canBeModifiedByClient && variationReturned.statusId === this.variationStatusEnum.Open) {
        this._jobItemService.showOnlyAddsChanges = false;
        this._jobItemService.filterByClientUpdatable = true;
        this.getJobItems(this.jobNum); // get items now we have a VO
      } else {
        this._jobItemService.showOnlyAddsChanges = true;
        this.closeOtherPanels = false; // we can allow other panels to be open at the same time
        this.getJobItems(this.jobNum); // get items now we have a VO
      }
    } else {
      this.isVariationOpen = false;
      this.showUpDownButtons = false;
      this.showAddEditButtons = false;
      this._jobItemService.showOnlyAddsChanges = true; // if not open only show items in this VO
      this.closeOtherPanels = false; // we can allow other panels to be open at the same time
      this.getJobItems(this.jobNum); // get items now we have a VO
    }

    // get the job variation item extra records for this variation
    this.getJobVarItemExtras(this.jobNum, variationReturned.variationNumber);
  }

  getJobVarItemExtras(jobNumber: string, variationNumber: number) {
    // we can get these syncronously
    this._jobItemService.currentJobVarItemExtras = []; // clear any previous

    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.getJobVarItemExtras(jobNumber, variationNumber)
        .subscribe({
          next: (jobVarItemExtras) => {
            this._jobItemService.currentJobVarItemExtras = jobVarItemExtras;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  getJobItems(jobno) {
    if (this.currentJob.masterJobId && this.currentJob.useMasterJobAddenda) {
      this.notiService.showInfo('Sub-Job - Uses the master job ' + this.addendumName);
    } else {
      this.loading = true;
      this._jobItemService.currentSectionHeadings = null;
      let initialMaster = true;
      if (this.anyVariations || this._jobItemService.isLocked) {
        initialMaster = false;
      }

      // return hidden lines then filter them out

      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.getAllJobItems(jobno, initialMaster, true,
          this._jobItemService.showHistoryOfChanges, this._jobItemService.showOnlyAddsChanges)
          .subscribe({
            next: (jobItemsRaw) => {
              // we hide set up lines for clients and trades
              if (!this.isAssociate && !this.isClient) {
                this._jobItemService.currentJobItemsUnfiltered = jobItemsRaw;
              } else {
                this._jobItemService.currentJobItemsUnfiltered = jobItemsRaw.filter(i => !i.isSetUpLine);
              }

              if (!this._jobItemService.currentVariation) {
                this.getJobVarCostsForJob();
              }

              if (this._jobItemService.currentJobItemsUnfiltered && this._jobItemService.currentJobItemsUnfiltered.length) {
                this.showSetupLinesFilter();
              } else {
                this._jobItemService.currentJobItems = [];
                if (this._jobItemService.selectionsMode === 'Master' && !this.anyVariations && !this.isClient && !this.isAssociate) {
                  this.copyJob(false);
                } else {
                  this.loading = false;
                }
              }
              this.checkDOMSizes();
            },
            error: (err) => {
              this.notiService.notify(err, '', true);
              this._jobItemService.currentJobItems = []; // clear any previous
              this.loading = false;
            }
          })
      );
    }
  }

  showSetupLinesFilter() {
    // filter the jobitmes depending on the boolean showSetupLines
    if (!this.stopGetLines) {
      // ok to continue - user hasn't clicked the goto variations mode.
      this._jobItemService.showSetupLinesFilter();
    }
    this.loading = false;
    this.stopGetLines = false;
  }

  isOpenPanel(acc, index: number): boolean {
    if (acc.isExpanded('acc-' + index)) {
      return true;
    } else {
      return false;
    }
  }

  // Patch change
  patchJobItem(updatedItem: any, resortItems: boolean, resortItemParentTypeId: number, resortItemParentId: number) {
    this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.updateJobItem(updatedItem).subscribe({
        next: (updatedItems) => {
          this._jobItemService.waitingSpinner.next(false);
          this.updatedItems = updatedItems;
          this._jobItemService.currentSectionHeadings = null;
          this._jobItemService.updateChangedItems(this.updatedItems, null, resortItems, resortItemParentTypeId, resortItemParentId);
        },
        error: (err) => {
          this.notiService.notify(err);
          this._jobItemService.setWaitingJobItemAPI(false); // notify  components that the call has completed
        }
      })
    );
  }

  // move an item up one spot in the list - have to take away from the orderNo!
  upItem(itm: IJobItem) {
    if (itm.orderNo > 1) {
      this.updatedItem = { id: itm.id, orderNo: itm.orderNo - 1 };
      this.patchJobItem(this.updatedItem, true, itm.jobItemAboveTypeId, itm.jobItemAboveId);
    }
  }

  // move an item down one spot in the list - have to add to the orderNo!
  downItem(itm: IJobItem) {
    this.updatedItem = { id: itm.id, orderNo: itm.orderNo + 1 };
    this.patchJobItem(this.updatedItem, true, itm.jobItemAboveTypeId, itm.jobItemAboveId);
  }

  editItem(contentSetupTags, itm: IJobItem) {
    this.currentJobItemId = itm.id;
    this.editOption = itm.itemDescription;
    this.originalDesc = itm.itemDescription;
    this.editIsSetUpLine = itm.isSetUpLine;
    this.modalHeading = 'Edit Section';
    this.modalButton = 'Update';
    this.modalButton2 = 'Revert to Original';

    const modalRef = this.modalService.open(HeadingModalComponent, { windowClass: 'modal-edit', backdrop: 'static' });
    modalRef.componentInstance.addEditDelete = AddEditDeleteEnum.Edit;
    modalRef.componentInstance.jobitem = itm;
    modalRef.componentInstance.jobItemAboveId = itm.jobItemAboveId;
    if (this._jobItemService.selectionsMode !== 'Master') {
      modalRef.componentInstance.variationNumber = this.variationNumber;
      modalRef.componentInstance.variationId = this._jobItemService.currentVariation?.id;
    }

    modalRef.result.then((updatedItem) => {
      if (!updatedItem.cancel) {
        this.getJobItems(this.jobNum);
      } else {
        if (updatedItem.gotoSetupTags) {
          this.currentJobItem = itm;
          if (!itm.setUpTags || itm.setUpTags === 'OR') {
            this.andORSetupLinks = 'OR';
          } else {
            this.andORSetupLinks = 'AND';
          }
          this.modalService.open(contentSetupTags, { size: 'lg' }).result.then((result3) => {
            this.closeResult = `Closed with: ${result3}`;
            if (itm.setUpTags !== this.andORSetupLinks) {
              this.updatedItem = { id: itm.id, setUpTags: this.andORSetupLinks };
              this.patchJobItem(this.updatedItem, false, null, null);
            } else {
              this.getJobItems(this.jobNum);
            }
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // add a section list to the end
  addItem(orderNo) {
    const modalRef = this.modalService.open(SelectionEditModalComponent,
      { windowClass: 'modal-edit', backdrop: 'static' });
    modalRef.componentInstance.addEdit = 'Add';
    modalRef.componentInstance.selectionItemAbove = 0;
    modalRef.componentInstance.jobItemAboveTypeId = this.jobVarTypeEnum.JobItem;
    modalRef.componentInstance.jobItemAboveId = null;
    modalRef.componentInstance.baseLevel = true;
    modalRef.componentInstance.orderNo = orderNo;

    modalRef.result.then((updatedItem) => {
      this.getJobItems(this.jobNum);
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addVarItem(itm, indexNo) {
    // add a new section by variation
    this.calcFirstLast(); // get the first and last records for this heading if any

    if (itm === null && this.lastRecord !== 0) {
      itm = this._jobItemService.currentJobItems[this.lastRecord];
      indexNo = itm.orderNo;
    }

    const modalRef = this.modalService.open(VariationAddModalComponent, { windowClass: 'modal-edit', backdrop: 'static' });
    if (itm) {
      modalRef.componentInstance.jobitem = itm;
    }
    modalRef.componentInstance.parentConnectedItemId = null;
    modalRef.componentInstance.variationNumber = this.variationNumber;
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
    modalRef.componentInstance.indexNo = indexNo;
    modalRef.componentInstance.firstRecord = this.firstRecord;
    modalRef.componentInstance.selectionsAdmin = this.selectionsAdmin;
    modalRef.componentInstance.variationType = this.variationType;


    modalRef.result.then(() => {
    }, () => {
    });
  }

  calcFirstLast() {
    // calc length of this list so we can add at the bottom
    this.firstRecord = 0;
    this.lastRecord = 0;
    for (let i = 0; i < this._jobItemService.currentJobItems.length; i++) {
      if (this._jobItemService.currentJobItems[i].jobItemAboveId === null) {
        if (this.firstRecord === 0) {
          this.firstRecord = i;
        }
        this.lastRecord = i;
      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  lockJob() {
    if (this._globalService.getCurrentCompany().salesVariationsActive) {
      this.createContract();
    } else {
      this.subscriptions = this.subscriptions.concat(
        this._jobService.updateJob({ jobNumber: this.jobNum, isLocked: true }).subscribe({
          next: () => {
            this._jobItemService.isLocked = true;
            this.masterLockedMessage = 'Master LOCKED';
            this.showAddEditButtons = false;
            this._jobItemService.showSetupLines = false; // hide setup lines
            this._jobItemService.showHiddenLines = false; // hide hidden lines
            this._jobItemService.currentJobItems = []; // reset
            this.getJobItems(this.jobNum);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  unlockJob() {
    if (this._globalService.isSalesVariationsActive() && this.currentJob.contractSignedDate) {
      this.notiService.showWarning('Cannot unlock a job with a contract signed date entered.');
    } else {
      this.subscriptions = this.subscriptions.concat(
        this._jobService.updateJob({ jobNumber: this.jobNum, isLocked: false }).subscribe({
          next: () => {
            this._jobItemService.isLocked = false;
            this.masterLockedMessage = 'Master';
            this.setShowAddEditButtons(); // check for permissions to add/change
            this._jobItemService.showSetupLines = true; // show setup lines
            this._jobItemService.showHiddenLines = false; // hide hidden lines
            this._jobItemService.currentJobItems = []; // reset
            this.getJobItems(this.jobNum);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  getVariations() {
    this.haveCheckedForVariations = false;
    this.openVariations = false;
    this.anyVariations = false;
    this.anyPostContractVOs = false;
    this.currentVariation = null;

    this.subscriptions = this.subscriptions.concat(
      this._variationService.getVariations()
        .subscribe({
          next: (variations) => {
            this._variationService.currentVariations = variations;
            this.loadingJob = false;
            if (!this._variationService.currentVariations.length) {
              // No variations found for given job
              this.errorMessage = '';
              this.haveCheckedForVariations = true;
              this.initialGetItems();
            } else {
              this.anyVariations = true;
              this.openVariationToBeModifiedByClient = null; // reset

              const variationStats = this._varHelper.checkVariations(this._variationService.currentVariations);
              this.openVariations = variationStats.openVariations;
              this.anyPostContractVOs = variationStats.postContractVariations;

              if (this.isClient) {
                this.openVariationToBeModifiedByClient = variationStats.openClientVariation;
              }
              if (!this.openVariations) {
                this.masterLockedMessage = '';
              }

              this.haveCheckedForVariations = true;

              // passedVariationNumber may have been passed from the login url
              if (this.passedVariationNumber && this.passedVariationNumber !== '') {
                this.variationNumber = variations
                  .find(v => v.displayedVariationNumber === this.passedVariationNumber)?.variationNumber;
                this.passedVariationNumber = null;
              }

              if (this.variationNumber) {
                // if coming from a variation
                // this.setVariationNum(this.currentVariation);
                this.gotoVariations();
              } else if (this.openVariationToBeModifiedByClient) {
                // we go to it
                this.getClientChoice();
              } else if (!this.currentJob.salesDate && !this.isAssociate && !this.isClient) {
                // call modal to ask what to do
                this.getSalesChoice();
              } else {
                this.initialGetItems();
              }
            }
          },
          error: (err) => {
            if (err.status === undefined) {
              this.errorMessage = 'No variations Exist for this job';
              this.haveCheckedForVariations = true;
              this.initialGetItems();
            } else {
              this.notiService.notify(err);
              this._jobItemService.currentJobItems = [];
            }
            this.loadingJob = false;
          }
        })
    );
  }

  getSalesChoice() {
    // for sales we go to the first open VO
    this.variationNumber = null;
    let voCount = 0;
    this._variationService.currentVariations.forEach(vo => {
      if (!this.variationNumber && vo.statusId < VariationStatusEnum.Cancelled) {
        this.variationNumber = vo.variationNumber;
      }
      voCount += 1;
    });

    // if (this.variationNumber && voCount === 1) { now always ask - GH 20-8-19
    //   this.gotoVariations();
    // } else {
    const modalRef = this.modalService.open(SalesChoiceModalComponent);

    modalRef.result.then((returnResult) => {
      this.closeResult = 'Closed';
      if (returnResult === 0) {
        this.initialGetItems();
      } else if (returnResult > 0) {
        this.variationNumber = returnResult;
        this.gotoVariations();
      } else {
        // sent -1 so user wants to enter a new VO
        this._jobService.currentVariationId = 0;
        this.router.navigate(['variationdetail']);
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.initialGetItems();
    });
    // }
  }

  getClientChoice() {
    // if we have detected that we have an open VO that a client can change we ask if the user wants to go to it
    this.variationNumber = null;

    const modalRef = this.modalService.open(ClientChoiceModalComponent, { windowClass: 'modal-600' });

    modalRef.result.then(() => {
      this.variationNumber = this.openVariationToBeModifiedByClient;
      this.gotoVariations();
    }, () => {
      this.initialGetItems();
    });
  }

  gotoVariations() {
    // go to the variation 'mode'
    // this.footerHeight = this.VARIATION_MODE_HEIGHT;
    this._jobItemService.selectionsMode = 'Variations';
    this.showAddEditButtons = false; // reset - will check for permissions to add/change once a valid VO read.
    this._jobItemService.currentJobItems = []; // clear the list until we have a variation number
    this.stopGetLines = true;
    this._jobItemService.showHistoryOfChanges = false; // we already show the history for THIS VO
    this._jobItemService.showHiddenLines = false;
    // this._jobItemService.showSetupLines = false; // hide setup lines
    this.loading = false; // stop any loading spinner that might be going
    if (this.variationNumber) {
      // find the current one
      for (this.arrayNum = 0; this.arrayNum < this._variationService.currentVariations.length; this.arrayNum += 1) {
        if (this._variationService.currentVariations[this.arrayNum].variationNumber === this.variationNumber) {
          this.currentVariation = this._variationService.currentVariations[this.arrayNum];
        }
      }
      if (this.currentVariation) {
        this.setVariationNum(this.currentVariation);
      }
    }
  }

  gotoMaster() {
    // go to the Master 'mode'
    window.scroll(0, 0); // after rotation of iPhone it needs this.
    this.resetFilter();
    this._jobItemService.selectionsMode = 'Master';
    if (this._jobItemService.isLocked) {
      this.showAddEditButtons = false; // if going back to master then must be read only
    } else {
      this.setShowAddEditButtons(); // check for permissions to add/change
      this._jobItemService.showSetupLines = true; // set default to show setup lines
    }
    // this.variationId = null;
    this.variationNumber = null;
    this._jobItemService.currentVariation = null;
    this._jobItemService.showOnlyAddsChanges = false;
    this.stopGetLines = false;
    this._jobItemService.showHistoryOfChanges = this._globalService.getShowHistoryOfChanges(); // = false; // default in master
    this._jobItemService.showNotPrintedItems = false; // reset for the master
    this.setPermissions(); // recheck and set showNotPrintedItems flag

    this.closeOtherPanels = true; // reset panel behaviour
    this._jobItemService.currentJobItems = []; // clear the list
    this.getJobItems(this.jobNum); // reread to get current master
  }

  setShowAddEditButtons() {
    // set the showAddEditButtons flag depending on permissions etc.
    this._authService.canSeeAmountsPermission = false;

    if (!this.adminOverrideButtons) {
      this.showAddEditButtons = false;
    }

    if (this.innerWidth > PHONE_SIZE) { // if the screen width is greater than this
      if (this._jobItemService.selectionsMode === 'Master') {
        this.showUpDownButtons = true;
        const permissionJobLineSetup = this._authService.getSelectionsPermissions('JobLineSetup');
        if (permissionJobLineSetup === 'Admin' || this.isAdminOrSuperUser) {
          this.jobLineSetupAdmin = true;
          this.jobLineSetupWrite = true;
        } else if (permissionJobLineSetup === 'Write') {
          this.jobLineSetupWrite = true;
        }
        if (this.jobLineSetupWrite && !this._jobItemService.isLocked) {
          this.showAddEditButtons = true;
          this._authService.canSeeAmountsPermission = true;
        } else {
          // if we are a read only user can we see the amounts?
          if (this.jobLineSetupWrite || this.isClient) {
            this._authService.canSeeAmountsPermission = true;
          } else if (!this.isAssociate) {
            const canSeeAmountsPerm = this._authService.getSelectionsPermissions('CanReadAmounts');
            if (canSeeAmountsPerm !== 'none') {
              this._authService.canSeeAmountsPermission = true;
            }
          }
        }

        const permissionJobLinesSetupOnly = this._authService.getSelectionsPermissions('JobItemSetupLinesOnly');
        if (permissionJobLinesSetupOnly === 'Admin') {
          this.jobLinesSetupOnlyAdmin = true;
        }
      } else {
        this.showUpDownButtons = false;
        let permissionLevel = '';
        this.variationsWrite = false; // reset the values
        this.variationsAdmin = false;

        if (this.variationType < 10) {
          permissionLevel = this._authService.getSelectionsPermissions('Variations');
        } else if (this.variationType < 20) {
          permissionLevel = this._authService.getSelectionsPermissions('SalesVariations');
        } else {
          permissionLevel = this._authService.getSelectionsPermissions('PreContractVariations');
        }
        if (permissionLevel === 'Admin' || this.isAdminOrSuperUser) {
          this.variationsAdmin = true;
          this.variationsWrite = true;
        } else if (permissionLevel === 'Write') {
          this.variationsWrite = true;
        }

        if ((this.selectionsAdmin && this.currentVariation && this.currentVariation.statusId <= this.variationStatusEnum.PendingApproval)
          || (this.variationsWrite && this.currentVariation && this.currentVariation.statusId === this.variationStatusEnum.Open)) {
          this.showAddEditButtons = true;
          this._authService.canSeeAmountsPermission = true;
        } else {

          // if we are a read only user can we see the amounts?
          if (this.variationsWrite || this.isClient) {
            this._authService.canSeeAmountsPermission = true;
          } else if (!this.isAssociate) {
            const canSeeAmountsPerm = this._authService.getSelectionsPermissions('CanReadAmounts');
            if (canSeeAmountsPerm !== 'none') {
              this._authService.canSeeAmountsPermission = true;
            }
          }
        }
      }
    }
    this.canSeeAmountsPermission = this._authService.canSeeAmountsPermission;
  }

  changeJNum() {
    // in the process of changing the Variation Number so clear out till the GO pressed
    this._jobItemService.selectionsMode = 'Master';
    this.showAddEditButtons = false; // if going back to master then must be read only
    this._jobItemService.showSetupLines = false; // and default to hiding setup lines
    this._jobItemService.currentVariation = null;
    // this.haveValidVariation = false;
    this._jobItemService.currentJobItems = []; // clear the list until we have a variation number
    this.resetFilter();
    this.currentJob = null;
    this.jobNum = '';
    this.loading = false;
    this.loadingJob = false;
    this.loadingOptions = false;

    // clear image caches
    this.jobItemAttachmentService.cache = [];
    this.jobVarItemAttachmentService.cache = [];
  }

  showHistoryClicked() {
    this._jobItemService.currentJobItems = [];
    this._globalService.setShowHistoryOfChanges(this._jobItemService.showHistoryOfChanges);
    // also show/hide not printed items with history
    this._jobItemService.showNotPrintedItems = this._jobItemService.showHistoryOfChanges;
    if (!this._jobItemService.showHistoryOfChanges && this.defaultShowNotPrinted) {
      this._jobItemService.showNotPrintedItems = true; // to set showNotPrintedItems correctly
    }
    this.getJobItems(this.jobNum); // reread to get current list
  }

  showOnlyThisVOClicked() {
    // don't allow the history
    if (this._jobItemService.showOnlyAddsChanges) {
      this._jobItemService.showHistoryOfChanges = false;
    }
    this._jobItemService.currentJobItems = [];
    this.showSetupLinesFilter();
  }

  addJobItemBoard() {
    // GH 16-6-20 - new add board component
    this.modalService.open(AddSelectionBoardComponent, { windowClass: 'modal-edit2' }).result.then((boardId) => {
      if (boardId) {
        this.loading = true;

        if (this._jobItemService.selectionsMode === 'Master') {
          this.subscriptions = this.subscriptions.concat(
            this._jobItemService.updateByBoard(boardId).subscribe({
              next: () => {
                this.resetFilter();
                this.getJobItems(this.jobNum);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.clearFilter();
                this.loading = false;
              }
            })
          );
        } else {
          this.subscriptions = this.subscriptions.concat(
            this._jobVarItemService.updateByBoard(this._jobItemService.currentVariation.id, boardId).subscribe({
              next: () => {
                this.resetFilter();
                this.getJobItems(this.jobNum);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.clearFilter();
                this.loading = false;
              }
            })
          );
        }
      }
    }, () => { });
  }

  copyJob(askToDelete: boolean) {
    const modalRef1 = this.modalService.open(JobCopyModalComponent, { windowClass: 'modal-edit' });
    modalRef1.componentInstance.askToDelete = askToDelete; // do we ask to delete existing records?

    modalRef1.result.then((copiedVariations) => {
      this._jobItemService.currentJobItems = [];
      this.resetFilter();
      if (this._globalService.isSalesVariationsActive()) {
        // we can ask to create the first sales variation
        if (copiedVariations) {
          this.loading = true;
          this.variationNumber = 1;
          this.getVariations();
        } else if (this.currentJob.salesDate) {
          this.loading = true;
          this.getJobItems(this.jobNum);
        } else {
          // do we skip this - default is we want it?
          const autoAsk = this._globalService.getCompanyConfigValue(this.configurationEnum.AutoAskForFirstSalesVariation);
          if (!autoAsk || autoAsk === 1) {
            this.createFirstSalesVO();
          } else {
            this.loading = true;
            this.getJobItems(this.jobNum);
          }
        }
      } else {
        this.loading = true;
        this.getJobItems(this.jobNum);
      }
    }, (reason) => {
      this.loading = false;
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  createFirstSalesVO() {
    // we have sales VO's active and we have just copied a template so we can ask to create VO
    const modalRef1 = this.modalService.open(CreateSalesVariationModalComponent, { windowClass: 'modal-edit' });

    modalRef1.result.then(() => {
      this.loading = true;
      this.variationNumber = 1;
      this.getVariations();
    }, () => {
      this.loading = true;
      this.getJobItems(this.jobNum);
    });
  }

  getPDF() {
    if (this._jobItemService.selectionsMode === 'Master' && this.useUploadedAddenda) {
      this.notiService.showInfo('Using the uploaded ' + this.addendumName);
      const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
      modalRef1.componentInstance.useUploadedAddenda = true;
      modalRef1.result.then(() => { }, () => { });
    } else {
      const modalRef = this.modalService.open(SelectionReportModalComponent);
      if (this._jobItemService.selectionsMode === 'Variations') {
        modalRef.componentInstance.variationNumber = this.variationNumber;
      }
      modalRef.componentInstance.exportToExcel = false;
      modalRef.componentInstance.isSalesQuote = false;
      modalRef.componentInstance.isContractQuote = false;

      modalRef.result.then((returnResult) => {
        this.closeResult = 'Closed';
        if (returnResult.runReport) {
          // show the attachment but read only so open full screen
          const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
          if (this._jobItemService.selectionsMode === 'Variations') {
            modalRef1.componentInstance.variationNumber = this.variationNumber;
          }
          modalRef1.componentInstance.printImages = returnResult.printImages;
          modalRef1.componentInstance.printPrices = returnResult.printPrices;
          modalRef1.componentInstance.printNotApplicable = returnResult.printNotApplicable;
          modalRef1.componentInstance.printNonPrintItems = returnResult.printNonPrintItems;
          modalRef1.componentInstance.printConnectedTogether = returnResult.printConnectedTogether;
          modalRef1.componentInstance.printVONumber = returnResult.printVONumber;
          modalRef1.componentInstance.exportToExcel = false;
          modalRef1.componentInstance.printProvisionalSums = returnResult.printProvisionalSums;
          modalRef1.componentInstance.printQuantities = returnResult.printQuantities;
          modalRef1.componentInstance.selectedCategories = returnResult.selectedCategories;
          modalRef1.componentInstance.printPdfAttachments = returnResult.printPdfAttachments;
          modalRef1.componentInstance.showSignatureSection = returnResult.showSignatureSection;
          modalRef1.componentInstance.showCheckBoxes = returnResult.showCheckBoxes;
          modalRef1.componentInstance.printWithHighlighting = returnResult.printWithHighlighting;
          modalRef1.componentInstance.printItemsNotTaken = returnResult.printItemsNotTaken;

          modalRef1.result.then(() => {
            this.closeResult = 'Closed';
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  getSalesQuotePDF(isSalesQuote: boolean) {
    const modalRef = this.modalService.open(SelectionReportModalComponent);
    modalRef.componentInstance.exportToExcel = false;
    if (isSalesQuote) {
      modalRef.componentInstance.isSalesQuote = true;
      modalRef.componentInstance.isContractQuote = false;
    } else {
      modalRef.componentInstance.isSalesQuote = false;
      modalRef.componentInstance.isContractQuote = true;
    }

    modalRef.result.then((returnResult) => {
      this.closeResult = 'Closed';
      if (returnResult.runReport) {
        // show the attachment but read only so open full screen
        const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
        modalRef1.componentInstance.printImages = returnResult.printImages;
        modalRef1.componentInstance.printPrices = returnResult.printPrices;
        modalRef1.componentInstance.printNotApplicable = returnResult.printNotApplicable;
        modalRef1.componentInstance.printNonPrintItems = returnResult.printNonPrintItems;
        modalRef1.componentInstance.printConnectedTogether = returnResult.printConnectedTogether;
        modalRef1.componentInstance.printVONumber = returnResult.printVONumber;
        modalRef1.componentInstance.exportToExcel = false;
        modalRef1.componentInstance.printProvisionalSums = returnResult.printProvisionalSums;
        modalRef1.componentInstance.isSalesQuote = true;
        modalRef1.componentInstance.printWholeAddendum = returnResult.printWholeAddendum;
        modalRef1.componentInstance.printItemsNotTaken = returnResult.printItemsNotTaken;
        modalRef1.componentInstance.printChangeHistory = returnResult.printChangeHistory;
        modalRef1.componentInstance.printIncludedAmounts = returnResult.printIncludedAmounts;
        modalRef1.componentInstance.printSalesQuoteOnly = returnResult.printSalesQuoteOnly;
        modalRef1.componentInstance.printPdfAttachments = returnResult.printPdfAttachments;
        modalRef1.componentInstance.printConfigAttachments = returnResult.printConfigAttachments;
        modalRef1.componentInstance.printFromSalesPriceOnwards = returnResult.printFromSalesPriceOnwards;
        modalRef1.componentInstance.quoteDate = returnResult.quoteDate;
        modalRef1.componentInstance.quoteExpiryDate = returnResult.quoteExpiryDate;
        modalRef1.componentInstance.exportToXML = returnResult.exportToXML;
        modalRef1.componentInstance.outputFileName = returnResult.outputFileName;
        modalRef1.componentInstance.isPriceGuide = returnResult.isPriceGuide;
        modalRef1.componentInstance.showSignatureSection = returnResult.showSignatureSection;

        modalRef1.result.then((returnResult1) => {
          this.closeResult = 'Closed';
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getExcelExport() {
    const modalRef = this.modalService.open(SelectionReportModalComponent);
    if (this._jobItemService.selectionsMode === 'Variations') {
      modalRef.componentInstance.variationNumber = this.variationNumber;
    }
    modalRef.componentInstance.isSalesQuote = false;
    modalRef.componentInstance.exportToExcel = true;
    modalRef.componentInstance.isContractQuote = false;

    modalRef.result.then((returnResult) => {
      this.closeResult = 'Closed';
      if (returnResult.runReport) {
        // show the attachment but read only so open full screen
        const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
        if (this._jobItemService.selectionsMode === 'Variations') {
          modalRef1.componentInstance.variationNumber = this.variationNumber;
        }
        modalRef1.componentInstance.printImages = returnResult.printImages;
        modalRef1.componentInstance.printPrices = returnResult.printPrices;
        modalRef1.componentInstance.printNotApplicable = returnResult.printNotApplicable;
        modalRef1.componentInstance.printNonPrintItems = returnResult.printNonPrintItems;
        modalRef1.componentInstance.printConnectedTogether = returnResult.printConnectedTogether;
        modalRef1.componentInstance.printVONumber = returnResult.printVONumber;
        modalRef1.componentInstance.exportToExcel = true;

        modalRef1.result.then((returnResult1) => {
          this.closeResult = 'Closed';
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  showAllOrJustThisVO(varType: number) {
    // show the attachment but read only so open full screen
    let askForAllItems = true;

    if (varType > 10 && varType < 20) {
      const autoAsk = this._globalService.getCompanyConfigValue(this.configurationEnum.AskSalesToShowOnlyItemsInThisVO);

      if (autoAsk !== 1) {
        askForAllItems = false;
      }
    }

    if (askForAllItems) {
      const modalRef = this.modalService.open(ShowFullAddendaComponent, { size: 'sm' });

      modalRef.result.then((returnResult) => {
        this.closeResult = 'Closed';
        if (returnResult) {
          this._jobItemService.showOnlyAddsChanges = false;
        } else {
          this._jobItemService.showOnlyAddsChanges = true;
        }

        this.getJobItems(this.jobNum); // get items now we have a VO
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this._jobItemService.showOnlyAddsChanges = false;
        this.getJobItems(this.jobNum); // get items now we have a VO
      });
    } else {
      this._jobItemService.showOnlyAddsChanges = false;
      this.getJobItems(this.jobNum); // get items now we have a VO
    }
  }

  resetFilter() {
    // clear the jobitems filter
    this.filterForm.patchValue({
      filterText: this._jobItemService.filterTextFromHistoryLink
    });
    this._jobItemService.filterText = this._jobItemService.filterTextFromHistoryLink;
    this._jobItemService.filterTextFromHistoryLink = '';
    this._jobItemService.filterByNotSelected = false;
    this._jobItemService.filterByUnChecked = false;
    this._jobItemService.filterByPSItems = false;

    if (!(this.isClient && this._jobItemService.currentVariation && this._jobItemService.currentVariation.canBeModifiedByClient
      && this._jobItemService.currentVariation.statusId === this.variationStatusEnum.Open)) {
      this._jobItemService.filterByClientUpdatable = false;
    }
    this._jobItemService.filterByNotSelectedByRep = false;

    this._jobItemService.currentSectionHeadings = null;
  }

  clearFilter() {
    // clear the jobitems filter
    this.resetFilter();
    this.showSetupLinesFilter();
  }

  runFilter() {
    // filter the jobitems but still need the headings
    this._jobItemService.filterText = this.filterForm.get('filterText').value.trim().toLowerCase();
    this._jobItemService.filterByNotSelected = false;
    this.stopGetLines = false;
    this.showSetupLinesFilter();
  }

  showNotPrintedClicked(resetShowHistory: boolean = false) {
    this.stopGetLines = false;
    if (resetShowHistory) {
      this._jobItemService.showNotPrintedItems = !this._jobItemService.showNotPrintedItems

      if (!this._jobItemService.showNotPrintedItems && this._jobItemService.showHistoryOfChanges) {
        this._jobItemService.showHistoryOfChanges = false;
        this._jobItemService.currentJobItems = [];
        this._globalService.setShowHistoryOfChanges(this._jobItemService.showHistoryOfChanges);
        this.getJobItems(this.jobNum); // reread to get current list
      } else {
        this.showSetupLinesFilter();
      }
    } else {
      this.showSetupLinesFilter();
    }
  }

  moveItemsToNewVariation() {
    const modalRef = this.modalService.open(MoveItemsModalComponent, { windowClass: 'modal-1000', backdrop: 'static' });
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;

    modalRef.result.then((returnResult) => {
      this.getJobItems(this.jobNum);
    }, (reason) => {
    });
  }

  createContract() {
    // route to the process to accept items into the contract and lock it
    // all variations need to be approved
    let foundUnApprovedVO = false;
    this._variationService.currentVariations.forEach(vo => {
      if (vo.statusId < this.variationStatusEnum.Cancelled) {
        foundUnApprovedVO = true;
      }
    });

    if (!this.currentJob.salesDate) {
      this.notiService.showWarning('Please enter a sales date before locking the job and creating the contract version.');
    } else if (foundUnApprovedVO) {
      // alert('There are unapproved variations. Please approve or cancel.');
      this.notiService.showWarning('There are unapproved variations. Please approve or cancel.');
    } else {
      this.router.navigate(['acceptIntoContract']);
    }
  }

  submitToEstimating() {
    // set the status of this variation to estiamting
    const modalRef = this.modalService.open(SubmitToEstimatingModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;

    modalRef.result.then(() => {
      this.currentVariation.statusId = this.variationStatusEnum.Estimating;
      this.setVariationNum(this.currentVariation);
    }, () => {
    });
  }

  approveVariation() {
    // set the status of this variation to estiamting
    const modalRef = this.modalService.open(ApproveVariationComponent, { backdrop: 'static' });
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;

    modalRef.result.then(() => {
      this.currentVariation.statusId = this.variationStatusEnum.Approved;
      this.setVariationNum(this.currentVariation);
    }, () => {
    });
  }

  doesHeaderHaveMissingClientSelections(jobitem: IJobItem) {
    if (!jobitem.jobItemAboveId) { // is header
      return this._jobItemService.doesHeaderHaveMissingClientSelections(jobitem);
    }
    return false;
  }

  filterByPSItems() {
    this.filterForm.patchValue({
      filterText: 'PS Items'
    });
    this._jobItemService.filterText = '';
    this._jobItemService.filterByPSItems = true;
    this._jobItemService.filterByNotSelected = false;
    this._jobItemService.filterByUnChecked = false;
    this._jobItemService.filterByClientUpdatable = false;
    this._jobItemService.filterByNotSelectedByRep = false;
    this.stopGetLines = false;
    this.showSetupLinesFilter();
  }

  filterByUnChecked() {
    this.filterForm.patchValue({
      filterText: 'Un-Checked'
    });
    this._jobItemService.filterText = '';
    this._jobItemService.filterByPSItems = false;
    this._jobItemService.filterByNotSelected = false;
    this._jobItemService.filterByUnChecked = true;
    this._jobItemService.filterByClientUpdatable = false;
    this._jobItemService.filterByNotSelectedByRep = false;
    this.stopGetLines = false;
    this.showSetupLinesFilter();
  }

  filterByUnSelected() {
    this.filterForm.patchValue({
      filterText: 'Un-Selected'
    });
    this._jobItemService.filterText = '';
    this._jobItemService.filterByPSItems = false;
    this._jobItemService.filterByNotSelected = true;
    this._jobItemService.filterByUnChecked = false;
    this._jobItemService.filterByClientUpdatable = false;
    this._jobItemService.filterByNotSelectedByRep = false;
    this.stopGetLines = false;
    this.showSetupLinesFilter();
  }

  filterByUnSelectedByConsultant() {
    this.filterForm.patchValue({
      filterText: 'Un-Selected'
    });
    this._jobItemService.filterText = '';
    this._jobItemService.filterByPSItems = false;
    this._jobItemService.filterByNotSelected = false;
    this._jobItemService.filterByUnChecked = false;
    this._jobItemService.filterByClientUpdatable = false;
    this._jobItemService.filterByNotSelectedByRep = true;
    this.stopGetLines = false;
    this.showSetupLinesFilter();
  }

  filterByClientUpdatable() {
    this.filterForm.patchValue({
      filterText: 'Client Update'
    });
    this._jobItemService.filterText = '';
    this._jobItemService.filterByPSItems = false;
    this._jobItemService.filterByNotSelected = false;
    this._jobItemService.filterByUnChecked = false;
    this._jobItemService.filterByClientUpdatable = true;
    this._jobItemService.filterByNotSelectedByRep = false;
    this.stopGetLines = false;
    this.showSetupLinesFilter();
  }

  checkDOMSizes() {
    // this.innerHeight = this._globalService.innerHeight;

    if (this.cardDiv && this._globalService.selectonsInnerWidth !== this.cardDiv.nativeElement.clientWidth) {
      this._globalService.setSelectionsInnerWidth(this.cardDiv.nativeElement.clientWidth);
    }

    this.myWidth = window.innerWidth;
    this.innerWidth = this._globalService.selectonsInnerWidth;

    if (!this.innerWidth) {
      this.innerWidth = this.headerDiv.nativeElement.clientWidth;
    }

    this.setShowAddEditButtons();

    this.subHeaderHeight = 86;
    if (this._jobItemService.selectionsMode === 'Variations') {
      if (this.canSeeAmountsPermission && this._jobItemService.currentVariation) {
        this.subHeaderHeight += 22;
      }
      if (this.innerWidth > PHONE_SIZE || this.canSeeAmountsPermission || (this.isClient
        && this.currentVariation.statusId === this.variationStatusEnum.Open && this.currentVariation.canBeModifiedByClient)) {
        this.footerHeight = 42;
      } else {
        this.footerHeight = 0;
      }
    } else {
      if (this.innerWidth > PHONE_SIZE) {
        this.footerHeight = this.VARIATION_MODE_HEIGHT;
      } else {
        this.footerHeight = 0;
      }
    }
    // console.log('innerWidth = ' + this.innerWidth);
    // console.log('footerHeight = ' + this.footerHeight + ' canSeeAmtsPerm = ' + this.canSeeAmountsPermission);
    // if (this.headerDiv) { // guard against resize before view is rendered
    //   this.subHeaderHeight = this.headerDiv.nativeElement.clientHeight;
    // }
    // console.log('innerHeight = ' + this._globalService.innerHeight);
    if (this.innerWidth < PHONE_SIZE) {
      this.isRestrictedForm = true;
    } else {
      this.isRestrictedForm = false;
    }

    this.setButtonsWidth();
  }

  setButtonsWidth() {
    if (this.innerWidth < PHONE_SIZE || !this.showAddEditButtons) {
      this.showQty = false;
      this.buttonsWidth = this.BUTTONS_WIDTH_SMALL - this.CONTAINER_BORDER + (this.myWidth - this.innerWidth);

      if (!this.isClient && !this.isAssociate) {
        this.buttonsWidth += 50; // for comments button & flag button
      }

      if (this.selectionsAdmin && this.innerWidth > PHONE_SIZE) {
        this.buttonsWidth += 25; // for estimating attachments indicator
      }

      if (this._jobItemService.showHiddenLines) {
        this.buttonsWidth += this.ISHIDDEN_MESSAGE_WIDTH;
      }

      this.footerMarginRight = this.buttonsWidth;
    } else {
      this.buttonsWidth = this.BUTTONS_WIDTH - this.CONTAINER_BORDER + (this.myWidth - this.innerWidth);

      this.showQty = true;
      if (this.selectionsAdmin && this.showAddEditButtons) {
        this.buttonsWidth += this.IS_CHECKED_WIDTH;
      }

      if (this.selectionsAdmin) {
        this.buttonsWidth += 25; // for estimating attachments indicator
      }

      if (this._jobItemService.showHiddenLines) {
        this.buttonsWidth += this.ISHIDDEN_MESSAGE_WIDTH;
      }

      this.footerMarginRight = this.buttonsWidth;

      if (this.currentJob && !this.currentJob.contractSignedDate) {
        this.footerMarginRight -= 90; // for quote button
      } else if (this.currentJob && this._jobItemService.selectionsMode === 'Variations' && this.currentJob.salesDate) {
        this.footerMarginRight -= 105; // for variation button
      }
    }

    // 12 is for margins and borders
    this.scrollHeight = this._globalService.innerHeight - (this.menuHeight + this.subHeaderHeight + this.footerHeight + 10);

    this.showWarning = false;
    if (window.innerHeight > 500) {
      if (this.currentJob && this.currentJob.warningNote && this.currentJob.warningNote !== '' && !this.isClient) {
        this.scrollHeight -= this.WARNING_HEIGHT;
        this.showWarning = true;
      }
    }
    // console.log('subHeaderHeight = ' + this.subHeaderHeight);
    // console.log('menuHeight = ' + this.menuHeight);

    // if (this.canSeeAmountsPermission && this._jobItemService.selectionsMode === 'Variations') {
    //   this.scrollHeight -= 22;
    // }
    // console.log('scrollHeight = ' + this.scrollHeight);

    this.headingTextWidth = this.innerWidth - 48;
    if (this.showAddEditButtons) {
      this.headingTextWidth -= 110;
    }
  }

  adminShowHideButtons() {
    this.adminOverrideButtons = true;
    this.showAddEditButtons = true;
    this.setButtonsWidth();
  }

  checkShowRulesReport() {
    const modalRef = this.modalService.open(CheckShowRulesReportComponent, { windowClass: 'modal-est' });
    modalRef.componentInstance.jobId = this._jobService.currentJob.id;
    modalRef.result.then(() => {
      this.getJobItems(this.jobNum);
    }, () => { });
  }

  copyFromAnotherVariation() {
    // set the status of this variation to estiamting
    const modalRef = this.modalService.open(VariationCopyModalComponent, { windowClass: 'modal-1000' });
    // modalRef.componentInstance.variationNumber = this._jobItemService.currentVariation.id;

    modalRef.result.then(() => {
      this.resetFilter();
      this.getJobItems(this.jobNum);
    }, () => {
    });
  }

  subscribeToVariationNumberChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.currentVariationChanged.subscribe(varNum => {
        this.variationNumber = varNum;
        this.gotoVariations();
      })
    );
  }

  subscribeToItemChanges() {
    // to get sub-components to fresh
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.waitingJobItemAPI$.subscribe(
        waitingAPI => {
          if (!waitingAPI) {
            this.itemsChangedFlag = !this.itemsChangedFlag;
          }
        }
      )
    );
  }

  copyFromFinalJob() {
    // copy from a final job into this master
    const modalRef1 = this.modalService.open(CopyFromFinalJobComponent, { windowClass: 'modal-edit' });

    modalRef1.result.then(() => {
      this.resetFilter();
      this.loading = true;
      this.getJobItems(this.jobNum);
    }, () => {
    });
  }

  createSalesTask() {
    // set the status of this variation to estiamting
    const modalRef = this.modalService.open(SubmitToEstimatingModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;

    modalRef.result.then(() => {
      this.currentVariation.statusId = this.variationStatusEnum.Estimating;
      this.setVariationNum(this.currentVariation);
    }, () => {
    });
  }

  collapsePanels() {
    this.ngbAccordion.collapseAll();
  }

  openPanels() {
    this.closeOtherPanels = false;
    setTimeout(() => {
      this.ngbAccordion.expandAll();
    }, 100); // wait for closePanels to be set
  }

  allowPanelsChanged() {
    this.closeOtherPanels = !this.closeOtherPanels;
  }

  clearHighlighting() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobVarItemService.clearVariationHighlighting(this.currentVariation.id).subscribe({
        next: () => {
          this._jobItemService.currentJobVarItemExtras.forEach(item => {
            item.colourId = null;
          });
          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  clearJobItemHighlighting() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.clearJobItemHighlighting(this.currentJob.id).subscribe({
        next: () => {
          this._jobItemService.currentJobItems.forEach(item => {
            item.highlightColourId = null;
          });
          this.loading = false;
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  costItemsCheck() {
    // here we use the modal to check all items have a cost so margins work
    const modalRef = this.modalService.open(JobItemCostComponent,
      { windowClass: 'modal-est2', backdrop: 'static', keyboard: false, scrollable: true });
    modalRef.componentInstance.jobVariationId = this._jobItemService.currentVariation.id;
    modalRef.componentInstance.variationNumber = this._jobItemService.currentVariation.variationNumber;
    modalRef.componentInstance.displayedVariationNumber = this._jobItemService.currentVariation.displayedVariationNumber;
    modalRef.componentInstance.adminFee = this._jobItemService.currentVariation.adminFee;
    modalRef.componentInstance.variationStatusId = this._jobItemService.currentVariation.statusId;

    modalRef.result.then(() => {
      this.gotoVariations();
    }, () => {
    });
  }

  checkAll() {
    // here we use the modal to check all items have a cost so margins work
    const modalRef = this.modalService.open(MarkAllCheckedComponent, { backdrop: 'static', keyboard: false });
    modalRef.componentInstance.jobVariationId = this._jobItemService.currentVariation.id;
    modalRef.result.then(() => {
      this.getJobItems(this.jobNum);
    }, () => {
    });
  }

  changeVariationType() {
    // here we use the modal to change the type
    if (this._jobItemService.currentVariation.variationType > 10 && this._jobItemService.currentVariation.variationType < 20) {
      this.notiService.showWarning('Cannot change the type of a Sales Variation.');
    } else {
      const modalRef = this.modalService.open(ChangeTypeComponent, { backdrop: 'static', keyboard: false });
      modalRef.result.then(() => {
        window.location.reload();
      }, () => {
      });
    }
  }

  gotoHouseOptions() {
    const modalRef = this.modalService.open(HouseOptionsGridComponent, { windowClass: 'modal-houseOptions' });
    modalRef.result.then(() => {
    }, () => {
    });
  }

  showNumberingClicked() {
    this.showNumbering = !this.showNumbering;
    this._globalService.setShowVariationNumbering(this.showNumbering);
  }

  showJobRoles() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(JobRolesComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobNumber = this.jobNum;

    modalRef.result.then(() => {
    }, () => {
    });
  }

  importConstructiveSelections() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(ConstructiveSelectionsComponent, { windowClass: 'modal-houseOptions' });
    modalRef.componentInstance.jobNumber = this.jobNum;
    modalRef.componentInstance.jobId = this.currentVariation.jobId;
    modalRef.componentInstance.jobVariationId = this.currentVariation.id;

    modalRef.result.then(() => {
      this.getJob(this.jobNum);
    }, () => {
    });
  }

  loadAddendaPdf() {
    // mainly for take-up we can get users to load a pdf instead of using Truth Engine for the addenda.
    const modalRef = this.modalService.open(LoadAddendaPdfComponent, { windowClass: 'modal-edit' });

    modalRef.result.then(() => {
      this.useUploadedAddenda = this._jobService.currentJob.useUploadedAddenda;
    }, () => {
      this.useUploadedAddenda = this._jobService.currentJob.useUploadedAddenda;
    });
  }
}
