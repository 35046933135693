import { ClaimsService } from './../services/felixApi/claims.service';
import { JobItemService } from './../services/felixApi/job-item.service';
import { TrackingFieldsService } from './../services/felixApi/tracking-fields.service';
import { Component, Input, OnChanges, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

import { IJob } from '../dtos/job';
import { IAddress } from '../dtos/address';
import { JobService } from '../services/felixApi/job.service';
import { GlobalService } from '../services/global.service';
import { AddressService } from '../services/felixApi/address.service';
import { UserService } from '../services/felixApi/user.service';
import { User } from '../dtos/user';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { AuthService } from '../services/auth.service';
import { JobCustomerService } from '../services/felixApi/job-customer.service';
import { IJobCustomer } from '../dtos/job-customer';
import { JobCustomerModalComponent } from './job-modals/job-customer-modal.component';
import { JobUsersModalComponent } from './job-modals/job-users-modal.component';
import { HouseType } from '../dtos/house-type';
import { NotificationService } from '../services/notification.service';
import { ConfigurationService } from '../services/felixApi/configuration.service';
import { JobTypeEnum } from '../dtos/job-type.enum';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { ChangeNumberModalComponent } from './job-modals/change-number-modal.component';
import { HouseModification } from '../dtos/house-modification';
import { JobEstimatingModalComponent } from './job-modals/job-estimating-modal/job-estimating-modal.component';
import { Lookup } from '../dtos/lookup';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { JobClaimsComponent } from './job-modals/job-claims/job-claims.component';
import { PHONE_SIZE, SML_PHONE_SIZE } from '../../config/variables';
import { JobEstimatingCheck } from '../dtos/job-estimating-check';
import { Division } from '../dtos/division';
import { TrackingColourEnum } from '../dtos/tracking-colour.enum';
import { TrackingFieldGroup } from '../dtos/tracking-field-group';
import { debounceTime } from 'rxjs/operators';
import { LeadManagementComponent } from './job-modals/lead-management/lead-management.component';
import { LeadManagementFieldsComponent } from './job-modals/lead-management-fields/lead-management-fields.component';
import { IntegrationService } from '../services/felixApi/integration.service';
import { LeadManagementField } from '../dtos/lead-management-field';
import { IntegrationTypeEnum } from '../dtos/integration-type.enum';
import { Bank } from '../dtos/bank';
import { JobBank } from '../dtos/job-bank';
import { LoanShortfallComponent } from '../reports/loan-shortfall/loan-shortfall.component';
import { EstateService } from '../services/felixApi/estate.service';
import { Estate } from '../dtos/estate';
import { JobExtra } from '../dtos/job-extra';
import { MaintenanceCompleteComponent } from './job-modals/maintenance-complete/maintenance-complete.component';
import { VariationService } from '../services/felixApi/variation.service';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { FormControl } from '@angular/forms';
import { AddClientUserComponent } from './job-modals/add-client-user/add-client-user.component';
import { LandMaster } from '../dtos/land-master';

@Component({
  selector: 'js-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss']
})
export class JobDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() jobNum: string;
  @Input() jobsWrite: boolean;
  @Input() jobsAdmin: boolean;

  // event to emit a 'refresh' command to the parent component
  @Output() refreshLines: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  COMPONENT_NAME = 'job-details';
  subscriptions: Subscription[] = [];

  jobTypes = [
    { id: 1, desc: 'Standard Job' },
    { id: 2, desc: 'Sales Template' },
    { id: 3, desc: 'Contract Template' },
    { id: 4, desc: 'House Type Template' },
    { id: 5, desc: 'Test Job' },
  ];

  jobForm: UntypedFormGroup;
  job: IJob = new IJob();
  jobAdded = false;
  jobAddress: IAddress;
  contractAddress: IAddress;
  users: User[] = [];
  customerLogins: User[] = [];
  jobCustomers: IJobCustomer[] = [];
  existingCustomerLoginId: number;
  showClientLastLoggedIn = false;
  updatedData: any;
  errorMessage: string;
  updateSuccessful: boolean;
  updateRun = false;
  deleteForm: UntypedFormGroup;
  closeResult = ''; // close result from delete modal
  userTypeEnum = UserTypeEnum;
  permissionLevel: string;
  jobsAdminPermission = false;
  loading = false;
  jobDeleted = true;
  tempSalesDate: string;
  isSalesVariationsActive: boolean;
  houseTypes: HouseType[] = [];
  originalHouseType: HouseType;
  jobTypeEnum = JobTypeEnum;
  selectedHouseTypeSalesPrice: number;
  configurationEnum = ConfigurationEnum;
  isAdmin: boolean;
  isClientNotificationOn: number;
  houseModifications: HouseModification[] = [];
  selectionsAdmin = false;
  variationPriceEstimatingEnabled = false;
  landTypes: Lookup[];
  landTitleTypes: Lookup[];
  landZones: Lookup[];
  isDepositPaidActive: boolean; // we have config to use these fields
  estimatingItemsExist = false; // do we have estimating items - used when we delete a job
  checkingIfEstimatingItemsExist: boolean;
  claimScheduleEnabled: boolean;
  clientLastLoggedIn: Date;
  lastLoggedInErr = false;
  phoneSize = false;
  xtraSmlSize = false;
  jobEstimatingCheck: JobEstimatingCheck;
  divisions: Division[] = [];
  defaultDivisionId: number; // used to created the division type as number and set it to null
  isSalesQuoteSignedDateShown: boolean;
  placeHolder: string;
  trackingFieldGroups: TrackingFieldGroup[];
  showJobData: boolean;
  saveJobData = false;
  isActiveCampaignActive: boolean;
  isHubSpotActive: boolean;
  allowCreateDeal: boolean;
  leadManagementId: any;
  leadManagementFields: LeadManagementField[];
  activeCampaignContactNameType: number;
  reloadJobData = 0;
  banks: Bank[];
  dropDownOptions: { width: number; minHeight: number; };
  bankId: number;
  dropdownWidth: number;
  loanReference: string;
  loanAmount: number;
  jobBank: JobBank;
  lenderInformationEnabled: boolean;
  jobs: IJob[];
  canReadAmountsPermission: boolean;
  claimsReadPermission: boolean;
  isSecondDepositVisible = false;
  estates: Estate[] = [];
  emailUpdatesToClientsActive: boolean;
  jobExtra: JobExtra;
  priceAsPerMasterHouseType: boolean;
  skipPriceCheckPopup: boolean;
  showPriceCheckPopup: boolean;
  showPriceAfterDate: boolean;
  addendumName: string;
  isNotRepOrIsRepForJob: boolean;
  permissionSalesRepsOthersData: string;
  cancelOpenVariationsPopupVisible: boolean;
  salesConsultant: string;
  truthEngineClientPortalActive: boolean;
  showContractsRequiredByDate: boolean;
  showContractsRequiredByField: boolean;
  isLandDatabaseActive: boolean;
  landMasters: LandMaster[];
  jobsWritePermission: boolean;

  constructor(
    private _jobService: JobService,
    private _variationService: VariationService,
    private _userService: UserService,
    private _addressService: AddressService,
    private _jobCustomerService: JobCustomerService,
    private _authService: AuthService,
    private _globalService: GlobalService,
    private _fb: UntypedFormBuilder,
    private router: Router,
    private notiService: NotificationService,
    private _configurationService: ConfigurationService,
    private estimatingService: EstimatingService,
    private modalService: NgbModal,
    private trackingFieldsService: TrackingFieldsService,
    private integrationService: IntegrationService,
    private jobItemService: JobItemService,
    private claimsService: ClaimsService,
    private estateService: EstateService) {
    this.onBankSelectionChanged = this.onBankSelectionChanged.bind(this);
    this.onLandMasterChanged = this.onLandMasterChanged.bind(this);
  }


  ngOnInit(): void {
    this.subscribeToWidthChanges();
    this.placeHolder = 'Printed on ' + this._globalService.getAddendumName();
    this.addendumName = this._globalService.getAddendumName();

    // set up form for delete modal
    this.deleteForm = this._fb.group({
      contractName: '',
      deleteAttachments: false,
      deleteEstimatingItems: false
    });

    if (this._authService.isAdminOrSuperUser()) {
      this.isAdmin = true;
    }

    this.isSecondDepositVisible = this._globalService.getCurrentCompany().id === 15; // only for Oakford atm

    // get config
    if (this._globalService.getCompanyConfigValue(this.configurationEnum.VariationPriceEstimatingEnabled) === 1) {
      this.variationPriceEstimatingEnabled = true;
    }

    if (this._globalService.getCompanyConfigValue(this.configurationEnum.ClaimSchedule) === 1) {
      this.claimScheduleEnabled = true;
    }

    if (this._globalService.getCompanyConfigValue(this.configurationEnum.LenderInformationEnabled) === 1) {
      this.lenderInformationEnabled = true;
      this.getBanks();
    }

    this.skipPriceCheckPopup = (this._globalService.getCompanyConfigValue(this.configurationEnum.SkipPriceCheckPopup) === 1);

    this.permissionLevel = this._authService.getSelectionsPermissions('Jobs');
    if (this.permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.jobsAdminPermission = true;
      this.jobsWritePermission = true;
      this.claimsReadPermission = true;
      this.canReadAmountsPermission = true;
    } else {
      if (this.permissionLevel === 'Write') {
        this.jobsWritePermission = true;
      }

      const invoicePermissionLevel = this._authService.getSelectionsPermissions('Invoices');
      if (invoicePermissionLevel === 'Read' || invoicePermissionLevel === 'Write' || invoicePermissionLevel === 'Admin') {
        this.claimsReadPermission = true;
      }

      const permissionAmounts = this._authService.getSelectionsPermissions('CanReadAmounts');
      if (permissionAmounts === 'Read' || permissionAmounts === 'Write' || permissionAmounts === 'Admin') {
        this.canReadAmountsPermission = true;
      }
    }

    const permissionLevel = this._authService.getSelectionsPermissions('Selections');
    if (permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.selectionsAdmin = true;
    }

    this.permissionSalesRepsOthersData = this._authService.getSelectionsPermissions('SalesRepsOthersData');

    this.isDepositPaidActive = this._globalService.getCompanyConfigValue(this.configurationEnum.BalanceOfDepositAmountsUsed) === 1;
    this.isSalesQuoteSignedDateShown = this._globalService.getCompanyConfigValue(this.configurationEnum.SalesQuoteSignedDateShown) === 1;
    this.emailUpdatesToClientsActive = this._globalService.getCompanyConfigValue(ConfigurationEnum.EmailUpdatesToClientsActive) === 1;
    this.truthEngineClientPortalActive = this._globalService.getCompanyConfigValue(ConfigurationEnum.TruthEngineClientPortalActive) === 1;
    this.showContractsRequiredByField = this._globalService.getCompanyConfigValue(ConfigurationEnum.ShowContractsRequiredByField) === 1;
    this.isLandDatabaseActive = this._globalService.getCompanyConfigValue(ConfigurationEnum.LandDatabase) === 1;

    this.getJobData(false);

    if (this.isLandDatabaseActive && this.jobsWritePermission) {
      this.getLandMasters();
    }

    this.isSalesVariationsActive = this._globalService.isSalesVariationsActive();
    this.setLeadManagementPerms(false);
  }

  ngOnChanges(): void {
    this.updateRun = false;
    this.reloadJobData = 0; // reset fields

    this.houseTypes = this._configurationService.houseTypes;
    this.landZones = this._configurationService.landZones;
    this.landTitleTypes = this._configurationService.landTitleTypes;
    this.landTypes = this._configurationService.landTypes;
    this.leadManagementFields = []; // reset after add or on new
    this.jobs = this._jobService.jobs;

    if (!this.jobForm) {
      // set up main form
      this.setupForm();
    }

    if (this.jobNum === '-1') { // clicked add job
      this.jobDeleted = false;
      this.updateRun = false;
      this.jobNum = '';
      this.job = null;
      this.jobExtra = null;
    }
    if (this.jobNum !== '0' && this.jobNum !== '') {
      // if passed a job number the look it up
      this.getJob();
    } else {
      this.setLeadManagementPerms(false);
      this.clearForm();
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setLeadManagementPerms(isJobFound: boolean) {
    // Active Campaign - 1 for normal and 2 for admins only - for testing
    // HubSpot - 3 for normal and 4 for admins only - for testing
    this.isActiveCampaignActive = false;
    this.isHubSpotActive = false;
    const config = this._globalService.getCompanyConfigValue(this.configurationEnum.ActiveCampaignOrHubSpotIntegrationEnabled);

    if (config) {
      if (this.isAdmin) {
        if (config === 1 || config === 2) {
          this.isActiveCampaignActive = true;
        } else if (config === 3 || config === 4) {
          this.isHubSpotActive = true;
        }
      } else {
        // users need jobs write and job cannot be marked sold
        if (!isJobFound || !this.job.salesDate) {
          const permissionLevel = this._authService.getSelectionsPermissions('Jobs');
          if (permissionLevel === 'Admin' || permissionLevel === 'Write') {
            if (config === 1) {
              this.isActiveCampaignActive = true;
            } else if (config === 3) {
              this.isHubSpotActive = true;
            }
          }
        }
      }
    }

    if (this.isActiveCampaignActive) {
      this.activeCampaignContactNameType = this._globalService.getCompanyConfigValue(this.configurationEnum.ActiveCampaignContactNameType);
    }

    if (this.isHubSpotActive) {
      this.allowCreateDeal = this._globalService.getCompanyConfigValue(this.configurationEnum.AllowCreateDeal) === 1;
    }
  }

  subscribeToWidthChanges() {
    this.setWidth(this._globalService.innerWidth);

    this.subscriptions = this.subscriptions.concat(
      this._globalService.innerWidthChanged.pipe(debounceTime(250)).subscribe((width: number) => {
        this.setWidth(width);
      })
    );
  }

  setWidth(innerWidth: number) {
    if (innerWidth <= SML_PHONE_SIZE) {
      this.xtraSmlSize = true;
    } else {
      this.xtraSmlSize = false;
    }

    if (innerWidth <= PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }

    if (window.innerHeight > 500) {
      this.showJobData = true;
    } else {
      this.showJobData = false;
    }

    this.showContractsRequiredByDate = innerWidth > 1230;

    this.dropdownWidth = window.innerWidth < 800 ? window.innerWidth : 800;
    this.dropDownOptions = { width: this.dropdownWidth, minHeight: 300 };
  }

  setupForm() {
    // create root form group for job data model
    // and create form controls for each field
    this.jobForm = this._fb.group({
      jobNumber: ['', Validators.compose([Validators.pattern('^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$'), Validators.required])],
      contractName: ['', Validators.required],
      salutation: '',
      isActive: new FormControl({ value: true, disabled: !this.jobsAdminPermission }),
      jobAddress: this.buildJobAddress(),
      contractAddress: this.buildContractAddress(),
      generalNotes: '',
      warningNote: '',
      contractPrice: 0,
      clientEmail: '',
      phoneNumber1: '',
      firstName1: '',
      lastName1: '',
      phoneNotes1: '',
      phoneNumber2: '',
      firstName2: '',
      lastName2: '',
      phoneNotes2: '',
      customerLoginEmail: '',
      sendInfoUpdateEmail: false,
      salesDate: this.dateOnly(null),
      contractSignedDate: this.dateOnly(null),
      houseTypeId: 0,
      depositAmount: 0,
      depositPaid: 0,
      depositBalanceDue: this.dateOnly(null),
      basePrice: 0,
      budgetAmount: 0,
      jobTypeId: [this.jobTypeEnum.StandardJob, Validators.required],
      isCustomerNotificationEnabled: false,
      houseModificationDescription: '',
      divisionId: [this.defaultDivisionId, Validators.required],
      salesQuoteSignedDate: this.dateOnly(null),
      masterJobId: null,
      depositAmount2: 0,
      isEmailClientUpdates: false,
      completionDate: this.dateOnly(null),
      handoverDate: this.dateOnly(null),
      maintenanceCompleteDate: this.dateOnly(null),
      useMasterJobAddenda: false,
      contractsRequiredByDate: this.dateOnly(null)
    });
    this.salesConsultant = '';
  }

  clearForm() {
    this.jobForm.patchValue({
      jobNumber: '',
      contractName: '',
      salutation: '',
      isActive: new FormControl({ value: true, disabled: !this.jobsAdminPermission }),
      jobAddress: {
        id: 0,
        lotNumber: '',
        streetNumber: '',
        streetName1: '',
        streetName2: '',
        suburbTown: '',
        state: '',
        postCode: '',
        planNumber: '',
        volume: '',
        folio: '',
        council: '',
        estate: '',
        landZoningId: 0,
        landTitleTypeId: 0,
        landTypeId: 0,
        titleDueDate: this.dateOnly(null),
        latitude: 0,
        longitude: 0,
        landPrice: 0,
        landId: null
      },
      contractAddress: {
        id: 0,
        lotNumber: '',
        streetNumber: '',
        streetName1: '',
        streetName2: '',
        suburbTown: '',
        state: '',
        postCode: ''
      },
      generalNotes: '',
      warningNote: '',
      contractPrice: 0,
      clientEmail: '',
      phoneNumber1: '',
      firstName1: '',
      lastName1: '',
      phoneNotes1: '',
      phoneNumber2: '',
      firstName2: '',
      lastName2: '',
      phoneNotes2: '',
      customerLoginEmail: '',
      sendInfoUpdateEmail: false,
      salesDate: this.dateOnly(null),
      contractSignedDate: this.dateOnly(null),
      houseTypeId: 0,
      depositAmount: this._globalService.getCompanyConfigValue(this.configurationEnum.DefaultJobDepositValue),
      depositPaid: 0,
      depositBalanceDue: '',
      basePrice: 0,
      budgetAmount: 0,
      jobTypeId: this.jobTypeEnum.StandardJob,
      isCustomerNotificationEnabled: false,
      houseModificationDescription: '',
      divisionId: this.defaultDivisionId,
      salesQuoteSignedDate: this.dateOnly(null),
      masterJobId: null,
      depositAmount2: this.isSecondDepositVisible ? this._globalService.getCompanyConfigValue(this.configurationEnum.DefaultJobDepositValue) : 0,
      isEmailClientUpdates: false,
      completionDate: this.dateOnly(null),
      handoverDate: this.dateOnly(null),
      maintenanceCompleteDate: this.dateOnly(null),
      useMasterJobAddenda: false,
      contractsRequiredByDate: this.dateOnly(null)
    });

    this.job = null;
    this.jobExtra = null;
    this.existingCustomerLoginId = null;
    this.originalHouseType = null;
    this.jobEstimatingCheck = null;
    this.leadManagementId = null;
    this.salesConsultant = '';
  }

  buildJobAddress(): UntypedFormGroup {
    return this._fb.group({
      id: 0,
      lotNumber: '',
      streetNumber: '',
      streetName1: '',
      streetName2: '',
      suburbTown: '',
      state: '',
      postCode: '',
      planNumber: '',
      volume: '',
      folio: '',
      council: '',
      estate: '',
      landZoningId: 0,
      landTitleTypeId: 0,
      landTypeId: 0,
      titleDueDate: this.dateOnly(null),
      latitude: 0,
      longitude: 0,
      landPrice: 0,
      landId: null
    });
  }

  buildContractAddress(): UntypedFormGroup {
    return this._fb.group({
      id: 0,
      lotNumber: '',
      streetNumber: '',
      streetName1: '',
      streetName2: '',
      suburbTown: '',
      state: '',
      postCode: ''
    });
  }

  getJobData(useCache: boolean) {
    this.users = [new User('', 0)];
    this.customerLogins = [new User('', 0)];

    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobData(useCache)
        .subscribe({
          next: () => {
            this.divisions = this.divisions.concat(this._userService.divisions);
            this.jobs = this._jobService.jobs;

            // copy house modifications in so we can add our own
            this.houseModifications = [];
            this._configurationService.houseModifications.forEach(houseModification => {
              this.houseModifications.push({ ...houseModification });
            });

            // copy estates in so we can add our own
            this.estates = [];
            this.estateService.estates.forEach(estate => {
              this.estates.push({ ...estate });
            });

            // does this user have a default division
            const currentUser = this._authService.getCurrentUser();
            if (currentUser.divisionId) {
              this.defaultDivisionId = currentUser.divisionId;
            } else if (this.divisions && this.divisions.length === 1) {
              this.defaultDivisionId = this.divisions.find(i => i.id !== 0)?.id;
            }
            if (this.jobNum === '0' || this.jobNum === '') {
              this.jobForm.patchValue({
                divisionId: this.defaultDivisionId
              });
            }
            this.filterUsers();
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  filterUsers() {
    this._userService.users.forEach(user => {
      if (user.isActive) {
        if (user.userTypeId === this.userTypeEnum.Admin || user.userTypeId === this.userTypeEnum.Office) {
          this.users = this.users.concat(user);
        } else if (user.userTypeId === this.userTypeEnum.Client) {
          this.customerLogins = this.customerLogins.concat(user);
        }
      }
    });
  }

  setJobHouseType() {
    this.selectedHouseTypeSalesPrice = 0;
    this.originalHouseType = null;
    this.priceAsPerMasterHouseType = true;

    const houseType = this.houseTypes.find(i => i.id === this.job?.houseTypeId);

    if (houseType && houseType.salesPrice) {
      this.selectedHouseTypeSalesPrice = houseType.salesPrice;
      this.originalHouseType = houseType;
      if (houseType.salesPrice !== this.job?.basePrice) {
        this.priceAsPerMasterHouseType = false;
      }
    }
  }

  getJob() {
    this.loading = true;
    this.clearForm();

    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJob(this.jobNum)
        .subscribe({
          next: (job) => {
            if (job) {
              this.job = job;
              this.jobExtra = this._jobService.currentJobExtra;

              // check if master job
              if (this.jobs.find(i => i.masterJobId === job.id)) {
                this.job.isMasterJob = true;
              }

              // if the current house modification description is not in the list we add it
              if (job.houseModificationDescription && job.houseModificationDescription != '') {
                const houseModificationFound = this.houseModifications.find(i => i.description === job.houseModificationDescription);
                if (!houseModificationFound) {
                  this.houseModifications.push(new HouseModification(job.houseModificationDescription));
                }
              }

              // if the current estate name is not in the list we add it
              if (job.estate && job.estate != '') {
                const estateFound = this.estates.find(i => i.name === job.estate);
                if (!estateFound) {
                  this.estates.push(new Estate(job.estate));
                }
              }

              this.jobDeleted = false;
              this.leadManagementId = null;
              this._jobService.currentJob = this.job;
              this._jobService.currentJobString = this._globalService.getJobString(this.job, true);
              this._jobService.currentJobNum = this.jobNum;
              this._globalService.setCurrentJob(this.jobNum);
              this.setLeadManagementPerms(true);

              this.setupForm();
              this.jobForm.patchValue({
                jobNumber: this.job.jobNumber,
                contractName: this.job.contractName,
                salutation: this.job.salutation,
                generalNotes: this.job.generalNotes,
                warningNote: this.job.warningNote,
                contractPrice: this.job.contractPrice,
                clientEmail: this.job.clientEmail,
                phoneNumber1: this.job.phoneNumber1,
                firstName1: this.job.firstName1,
                lastName1: this.job.lastName1,
                phoneNotes1: this.job.phoneNotes1,
                phoneNumber2: this.job.phoneNumber2,
                firstName2: this.job.firstName2,
                lastName2: this.job.lastName2,
                phoneNotes2: this.job.phoneNotes2,
                isActive: this.job.isActive,
                salesDate: this.dateOnly(this.job.salesDate),
                contractSignedDate: this.dateOnly(this.job.contractSignedDate),
                houseTypeId: this.job.houseTypeId,
                depositAmount: this.job.depositAmount,
                depositPaid: this.job.depositPaid,
                depositBalanceDue: this.dateOnly(this.job.depositBalanceDue),
                basePrice: this.job.basePrice,
                budgetAmount: this.job.budgetAmount,
                jobTypeId: this.job.jobTypeId,
                isCustomerNotificationEnabled: this.job.isCustomerNotificationEnabled,
                houseModificationDescription: this.job.houseModificationDescription,
                divisionId: this.job.divisionId ? this.job.divisionId : this.defaultDivisionId,
                salesQuoteSignedDate: this.dateOnly(this.job.salesQuoteSignedDate),
                masterJobId: this.job.masterJobId,
                depositAmount2: this.job.depositAmount2,
                isEmailClientUpdates: this.job.isEmailClientUpdates,
                completionDate: this.dateOnly(this.jobExtra?.completionDate),
                handoverDate: this.dateOnly(this.jobExtra?.handoverDate),
                maintenanceCompleteDate: this.dateOnly(this.jobExtra?.maintenanceCompleteDate),
                useMasterJobAddenda: this.job.useMasterJobAddenda,
                contractsRequiredByDate: this.dateOnly(this.job.contractsRequiredByDate)
              });

              this.jobForm.patchValue({
                jobAddress: {
                  id: this.job.jobAddress?.id,
                  lotNumber: this.job.jobAddress?.lotNumber,
                  streetNumber: this.job.jobAddress?.streetNumber,
                  streetName1: this.job.jobAddress?.streetName1,
                  streetName2: this.job.jobAddress?.streetName2,
                  suburbTown: this.job.jobAddress?.suburbTown,
                  state: this.job.jobAddress?.state,
                  postCode: this.job.jobAddress?.postCode,
                  planNumber: this.job.planNumber,
                  volume: this.job.volume,
                  folio: this.job.folio,
                  council: this.job.council,
                  estate: this.job.estate,
                  landZoningId: this.job.landZoningId,
                  landTitleTypeId: this.job.landTitleTypeId,
                  landTypeId: this.job.landTypeId,
                  titleDueDate: this.dateOnly(this.job.titleDueDate),
                  latitude: this.job.jobAddress?.latitude,
                  longitude: this.job.jobAddress?.longitude,
                  landPrice: this.job.landPrice,
                  landId: this.job.landId
                }
              });

              this.jobForm.patchValue({
                contractAddress: {
                  id: this.job.contractAddress?.id,
                  lotNumber: this.job.contractAddress?.lotNumber,
                  streetNumber: this.job.contractAddress?.streetNumber,
                  streetName1: this.job.contractAddress?.streetName1,
                  streetName2: this.job.contractAddress?.streetName2,
                  suburbTown: this.job.contractAddress?.suburbTown,
                  state: this.job.contractAddress?.state,
                  postCode: this.job.contractAddress?.postCode,
                }
              });

              this.getJobCustomers();
              this.checkUserIsNotRepOrRepForJob();

              // get any estimating lines so we can check these if needed
              this.getEstimatingItemsCheck();

              // set the house type etc
              this.setJobTypeValue();
              this.setJobHouseType();

              // get the lender info
              this.getJobBankDetails();

              // get the lead management data
              this.getLeadManagementData();

            } else {
              this.notiService.showError('Job not found');
              this.jobNotFound();
            }
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobNotFound();
          }
        })
    );
  }

  jobNotFound() {
    this.job = null;
    this.jobExtra = null;
    this.jobDeleted = true;
    this.jobCustomers = [];
    this.existingCustomerLoginId = null;
    this.setLeadManagementPerms(false);
    this.loading = false;
  }

  getJobCustomers() {
    this.showClientLastLoggedIn = false;
    this.subscriptions = this.subscriptions.concat(
      this._jobCustomerService.getJobCustomers(this.jobNum)
        .subscribe({
          next: (jobCustomers) => {
            this.jobCustomers = jobCustomers;
            this.jobForm.patchValue({
              customerLoginEmail: '',
              sendInfoUpdateEmail: false
            });
            this.existingCustomerLoginId = null;
            // set to first client as currently can only be one client job-customer
            if (this.jobCustomers.length > 0) {
              this.jobCustomers.forEach(element => {
                if (element.customer.userTypeId === this.userTypeEnum.Client) {
                  this.existingCustomerLoginId = element.id;
                  this.showClientLastLoggedIn = true;
                  this.getLastLoggedIn(this.existingCustomerLoginId);
                  this.jobForm.patchValue({
                    customerLoginEmail: element.customer.email,
                    sendInfoUpdateEmail: element.sendInfoUpdateEmail
                  });
                }
              });
            }

            this.getJobFieldsData();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  checkUserIsNotRepOrRepForJob() {
    this.isNotRepOrIsRepForJob = false;
    this.salesConsultant = '';
    this.subscriptions = this.subscriptions.concat(
      this._jobService.checkSalesRepAndHasJobRole(this.job.jobNumber)
        .subscribe({
          next: (jobSalesConsultant) => {
            if (this.permissionSalesRepsOthersData !== 'none' || this._authService.isAdminOrSuperUser()) {
              this.isNotRepOrIsRepForJob = true;
            } else {
              this.isNotRepOrIsRepForJob = jobSalesConsultant.isNotRepOrIsRepForJob;
            }
            this.salesConsultant = jobSalesConsultant.SalesConsultantName;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  getJobFieldsData() {
    this.subscriptions = this.subscriptions.concat(
      this.trackingFieldsService.getTrackingFieldGroups(true, true)
        .subscribe({
          next: () => {
            this.trackingFieldGroups = this.trackingFieldsService.trackingFieldGroups;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  customerLoginChanged(selectionChangeEvent) {
    if (selectionChangeEvent.value) {
      this.showClientLastLoggedIn = true;
    } else {
      this.showClientLastLoggedIn = false;
    }

    const user = this.jobCustomers
      .filter(c => c.customer.email === selectionChangeEvent.value)
      .pop();

    if (user) {
      this.getLastLoggedIn(user.id);
    } else {
      this.clientLastLoggedIn = null;
    }
  }

  getLastLoggedIn(userId: number) {
    this._jobCustomerService.getJobCustomerLastLoggedIn(userId).subscribe({
      next: (date) => {
        this.lastLoggedInErr = false;
        this.clientLastLoggedIn = date;
      }, error: () => {
        this.lastLoggedInErr = true;
      }
    });
  }

  saveDone() {
    // save done from job data
    this.saveGo();
  }

  save() {
    if (!this.skipPriceCheckPopup && !this.priceAsPerMasterHouseType && this.jobForm.get('salesDate').value
      && this.dateOnly(this.jobForm.get('salesDate').value) !== this.dateOnly(this.job?.salesDate)) {
      this.showPriceCheckPopup = true;
    } else if (this.job?.salesDate && this.jobForm.get('basePrice').value
      && this.jobForm.get('basePrice').value !== this.job?.basePrice) {
      this.showPriceAfterDate = true;
    } else {
      if (this.trackingFieldGroups && this.trackingFieldGroups.length && this.job && this.showJobData) {
        this.saveJobData = true;
      } else {
        this.saveGo();
      }
    }
  }

  saveGo() {
    this.saveJobData = false;
    // if not an admin we need house type
    if (!this.jobsAdminPermission && (!this.jobForm.get('houseTypeId').value || this.jobForm.get('houseTypeId').value === 0)) {
      this.notiService.showWarning('Please select a house type');
    } else {
      // set flags
      this.updateSuccessful = true;
      this.updateRun = false;

      if (this.jobNum !== '0' && this.jobNum !== '') {
        // update required
        this.updateJobCheck();
      } else {
        this.loading = true;
        const newJobNumber: string = this.jobForm.get('jobNumber').value;
        if (newJobNumber.trim().includes(' ') && this.jobForm.get('jobTypeId').value === JobTypeEnum.StandardJob) {
          this.notiService.showWarning('Standard jobs cannot contain a space in the job number');
          this.loading = false;
        } else {
          // add job details -----------------------------------
          this.updatedData = { jobNumber: this.jobForm.get('jobNumber').value, isActive: true };
          if (this.jobForm.get('contractName').value) {
            this.updatedData.contractName = this.jobForm.get('contractName').value;
          }
          if (this.jobForm.get('salutation').value) {
            this.updatedData.salutation = this.jobForm.get('salutation').value;
          }
          if (this.jobForm.get('contractPrice').value) {
            this.updatedData.contractPrice = this.jobForm.get('contractPrice').value;
          }
          if (this.jobForm.get('generalNotes').value) {
            this.updatedData.generalNotes = this.jobForm.get('generalNotes').value;
          }
          if (this.jobForm.get('warningNote').value) {
            this.updatedData.warningNote = this.jobForm.get('warningNote').value;
          }
          if (this.jobForm.get('clientEmail').value) {
            this.updatedData.clientEmail = this.jobForm.get('clientEmail').value;
          }
          if (this.jobForm.get('phoneNumber1').value) {
            this.updatedData.phoneNumber1 = this.jobForm.get('phoneNumber1').value;
          }
          if (this.jobForm.get('firstName1').value) {
            this.updatedData.firstName1 = this.jobForm.get('firstName1').value;
          }
          if (this.jobForm.get('lastName1').value) {
            this.updatedData.lastName1 = this.jobForm.get('lastName1').value;
          }
          if (this.jobForm.get('phoneNotes1').value) {
            this.updatedData.phoneNotes1 = this.jobForm.get('phoneNotes1').value;
          }
          if (this.jobForm.get('phoneNumber2').value) {
            this.updatedData.phoneNumber2 = this.jobForm.get('phoneNumber2').value;
          }
          if (this.jobForm.get('firstName2').value) {
            this.updatedData.firstName2 = this.jobForm.get('firstName2').value;
          }
          if (this.jobForm.get('lastName2').value) {
            this.updatedData.lastName2 = this.jobForm.get('lastName2').value;
          }
          if (this.jobForm.get('phoneNotes2').value) {
            this.updatedData.phoneNotes2 = this.jobForm.get('phoneNotes2').value;
          }
          if (this.jobForm.get('jobTypeId').value) {
            this.updatedData.jobTypeId = this.jobForm.get('jobTypeId').value;
          }
          if (this.jobForm.get('masterJobId').value) {
            this.updatedData.masterJobId = this.jobForm.get('masterJobId').value;
          }
          if (this.jobForm.get('depositAmount2').value && this.jobForm.get('depositAmount2').value != 0) {
            this.updatedData.depositAmount2 = this.jobForm.get('depositAmount2').value;
          }
          if (this.jobForm.get('jobAddress.landPrice').value) {
            this.updatedData.landPrice = this.jobForm.get('jobAddress.landPrice').value;
          }
          if (this.jobForm.get('jobAddress.landId').value) {
            this.updatedData.landId = this.jobForm.get('jobAddress.landId').value;
          }
          if (this.jobForm.get('divisionId').value) {
            this.updatedData.divisionId = this.jobForm.get('divisionId').value === 0 ? null : this.jobForm.get('divisionId').value;
          }
          if (this.jobForm.get('isCustomerNotificationEnabled').value) {
            this.updatedData.isCustomerNotificationEnabled = this.jobForm.get('isCustomerNotificationEnabled').value;
          }
          if (this.jobForm.get('salesDate').value) {
            this.updatedData.salesDate = this.jobForm.get('salesDate').value;
          }
          if (this.jobForm.get('contractSignedDate').value) {
            this.updatedData.contractSignedDate = this.jobForm.get('contractSignedDate').value;
          }
          if (this.jobForm.get('houseTypeId').value && this.jobForm.get('houseTypeId').value !== 0) {
            this.updatedData.houseTypeId = this.jobForm.get('houseTypeId').value;
          }
          if (this.jobForm.get('depositAmount').value && this.jobForm.get('depositAmount').value !== 0) {
            this.updatedData.depositAmount = this.jobForm.get('depositAmount').value;
          }
          if (this.jobForm.get('depositPaid').value && this.jobForm.get('depositPaid').value !== 0) {
            this.updatedData.depositPaid = this.jobForm.get('depositPaid').value;
          }
          if (this.jobForm.get('depositBalanceDue').value) {
            this.updatedData.depositBalanceDue = this.jobForm.get('depositBalanceDue').value;
          }
          if (this.jobForm.get('basePrice').value && this.jobForm.get('basePrice').value !== 0) {
            this.updatedData.basePrice = this.jobForm.get('basePrice').value;
          }
          if (this.jobForm.get('budgetAmount').value && this.jobForm.get('budgetAmount').value !== 0) {
            this.updatedData.budgetAmount = this.jobForm.get('budgetAmount').value;
          }
          if (this.jobForm.get('houseModificationDescription').value) {
            this.updatedData.houseModificationDescription = this.jobForm.get('houseModificationDescription').value;
          }
          if (this.jobForm.get('salesQuoteSignedDate').value) {
            this.updatedData.salesQuoteSignedDate = this.jobForm.get('salesQuoteSignedDate').value;
          }
          if (this.jobForm.get('contractsRequiredByDate').value) {
            this.updatedData.contractsRequiredByDate = this.jobForm.get('contractsRequiredByDate').value;
          }
          if (this.jobForm.get('isEmailClientUpdates').value) {
            this.updatedData.isEmailClientUpdates = this.jobForm.get('isEmailClientUpdates').value;
          }
          if (this.jobForm.get('useMasterJobAddenda').value) {
            this.updatedData.useMasterJobAddenda = this.jobForm.get('useMasterJobAddenda').value;
          }

          this.updatedData.planNumber = this.jobForm.get('jobAddress.planNumber').value;
          this.updatedData.volume = this.jobForm.get('jobAddress.volume').value;
          this.updatedData.folio = this.jobForm.get('jobAddress.folio').value;
          this.updatedData.council = this.jobForm.get('jobAddress.council').value;

          this.updatedData.estate = this.jobForm.get('jobAddress.estate').value;
          this.updatedData.landZoningId = this.jobForm.get('jobAddress.landZoningId').value === 0 ? null : this.jobForm.get('jobAddress.landZoningId').value;
          this.updatedData.landTitleTypeId = this.jobForm.get('jobAddress.landTitleTypeId').value === 0 ? null : this.jobForm.get('jobAddress.landTitleTypeId').value;
          this.updatedData.landTypeId = this.jobForm.get('jobAddress.landTypeId').value === 0 ? null : this.jobForm.get('jobAddress.landTypeId').value;

          if (this.jobForm.get('jobAddress.titleDueDate').value
            && this.jobForm.get('jobAddress.titleDueDate').value !== ''
            && this.jobForm.get('jobAddress.titleDueDate').value !== 'ull') {
            this.updatedData.titleDueDate = this.jobForm.get('jobAddress.titleDueDate').value;
          }

          this._globalService.setCurrentJob('');
          this.subscriptions = this.subscriptions.concat(
            this._jobService.addJob(this.updatedData).subscribe({
              next: (res) => {
                this.jobNum = res.jobNumber;
                this.jobAdded = true;
                this._globalService.setCurrentJob(this.jobNum);

                this.updateLeadManagementData(res.id);
                this.updateJobAddress(true);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.updateSuccessful = false;
                this.loading = false;
              }
            })
          );
        }
      }
    }
  }

  updateJobCheck() {
    if (this.job.isActive && !this.jobForm.get('isActive').value && this.jobsAdminPermission) {
      // check for open variations
      this.subscriptions = this.subscriptions.concat(
        this._variationService.getOpenVariations(this.job.id)
          .subscribe({
            next: (variations) => {
              const openVariations = variations.filter(i => i.statusId < VariationStatusEnum.Cancelled);
              if (openVariations && openVariations.length) {
                this.cancelOpenVariationsPopupVisible = true;
              } else {
                this.updateJobGo(false);
              }
            },
            error: () => {
              this.updateJobGo(false);
            }
          })
      );
    } else {
      this.updateJobGo(false);
    }
  }

  updateJobGo(cancelOpenVariations: boolean) {
    this.loading = true;
    this.cancelOpenVariationsPopupVisible = false;
    this.saveJobBankDetails();

    this.updatedData = { contractName: this.jobForm.get('contractName').value };

    if (this.job.salutation !== this.jobForm.get('salutation').value) {
      this.updatedData.salutation = this.jobForm.get('salutation').value;
    }
    if (this.job.isActive !== this.jobForm.get('isActive').value) {
      this.updatedData.isActive = this.jobForm.get('isActive').value;

      if (cancelOpenVariations) {
        this.updatedData.cancelVariations = true;
      }
    }
    if (this.job.contractPrice !== this.jobForm.get('contractPrice').value) {
      this.updatedData.contractPrice = this.jobForm.get('contractPrice').value;
    }
    if (this.job.generalNotes !== this.jobForm.get('generalNotes').value) {
      this.updatedData.generalNotes = this.jobForm.get('generalNotes').value;
    }
    if (this.job.warningNote !== this.jobForm.get('warningNote').value) {
      this.updatedData.warningNote = this.jobForm.get('warningNote').value;
    }
    if (this.job.clientEmail !== this.jobForm.get('clientEmail').value) {
      this.updatedData.clientEmail = this.jobForm.get('clientEmail').value;
    }
    if (this.job.phoneNumber1 !== this.jobForm.get('phoneNumber1').value) {
      this.updatedData.phoneNumber1 = this.jobForm.get('phoneNumber1').value;
    }
    if (this.job.firstName1 !== this.jobForm.get('firstName1').value) {
      this.updatedData.firstName1 = this.jobForm.get('firstName1').value;
    }
    if (this.job.lastName1 !== this.jobForm.get('lastName1').value) {
      this.updatedData.lastName1 = this.jobForm.get('lastName1').value;
    }
    if (this.job.phoneNotes1 !== this.jobForm.get('phoneNotes1').value) {
      this.updatedData.phoneNotes1 = this.jobForm.get('phoneNotes1').value;
    }
    if (this.job.phoneNumber2 !== this.jobForm.get('phoneNumber2').value) {
      this.updatedData.phoneNumber2 = this.jobForm.get('phoneNumber2').value;
    }
    if (this.job.firstName2 !== this.jobForm.get('firstName2').value) {
      this.updatedData.firstName2 = this.jobForm.get('firstName2').value;
    }
    if (this.job.lastName2 !== this.jobForm.get('lastName2').value) {
      this.updatedData.lastName2 = this.jobForm.get('lastName2').value;
    }
    if (this.job.phoneNotes2 !== this.jobForm.get('phoneNotes2').value) {
      this.updatedData.phoneNotes2 = this.jobForm.get('phoneNotes2').value;
    }
    if (this.job.jobTypeId !== this.jobForm.get('jobTypeId').value) {
      this.updatedData.jobTypeId = this.jobForm.get('jobTypeId').value;
    }
    if (this.job.masterJobId !== this.jobForm.get('masterJobId').value) {
      this.updatedData.masterJobId = this.jobForm.get('masterJobId').value;
    }
    if (this.job.depositAmount2 !== this.jobForm.get('depositAmount2').value) {
      this.updatedData.depositAmount2 = this.jobForm.get('depositAmount2').value;
    }
    if (this.job.landPrice !== this.jobForm.get('jobAddress.landPrice').value) {
      this.updatedData.landPrice = this.jobForm.get('jobAddress.landPrice').value;
    }
    if (this.job.landId !== this.jobForm.get('jobAddress.landId').value) {
      this.updatedData.landId = this.jobForm.get('jobAddress.landId').value;
    }
    if (this.job.divisionId !== this.jobForm.get('divisionId').value) {
      this.updatedData.divisionId = this.jobForm.get('divisionId').value === 0 ? null : this.jobForm.get('divisionId').value;
    }
    if (this.job.isCustomerNotificationEnabled !== this.jobForm.get('isCustomerNotificationEnabled').value) {
      this.updatedData.isCustomerNotificationEnabled = this.jobForm.get('isCustomerNotificationEnabled').value;
    }
    if (this.job.houseModificationDescription !== this.jobForm.get('houseModificationDescription').value) {
      this.updatedData.houseModificationDescription = this.jobForm.get('houseModificationDescription').value;
    }
    if (this.job.isEmailClientUpdates !== this.jobForm.get('isEmailClientUpdates').value) {
      this.updatedData.isEmailClientUpdates = this.jobForm.get('isEmailClientUpdates').value;
    }
    if (this.job.useMasterJobAddenda !== this.jobForm.get('useMasterJobAddenda').value) {
      this.updatedData.useMasterJobAddenda = this.jobForm.get('useMasterJobAddenda').value;
    }

    this.tempSalesDate = JSON.stringify(this.job.salesDate).substr(1, 10);
    if (this.tempSalesDate !== this.jobForm.get('salesDate').value) {
      this.updatedData.salesDate = this.jobForm.get('salesDate').value;
      this.checkDuplicateHouseOnStreet();
    }
    if (this.job.salesDate && (!this.jobForm.get('salesDate').value
      || this.jobForm.get('salesDate').value === '')) {
      this.updatedData.salesDate = null;
    }
    this.tempSalesDate = JSON.stringify(this.job.contractSignedDate).substr(1, 10);
    if (this.tempSalesDate !== this.jobForm.get('contractSignedDate').value) {
      this.updatedData.contractSignedDate = this.jobForm.get('contractSignedDate').value;
    }
    if (this.job.contractSignedDate && (!this.jobForm.get('contractSignedDate').value
      || this.jobForm.get('contractSignedDate').value === '')) {
      this.updatedData.contractSignedDate = null;
    }
    if (this.job.houseTypeId !== this.jobForm.get('houseTypeId').value) {
      this.updatedData.houseTypeId = this.jobForm.get('houseTypeId').value;
    }
    if (this.job.depositAmount !== this.jobForm.get('depositAmount').value) {
      this.updatedData.depositAmount = this.jobForm.get('depositAmount').value;
    }
    if (this.job.depositPaid !== this.jobForm.get('depositPaid').value) {
      this.updatedData.depositPaid = this.jobForm.get('depositPaid').value;
    }
    this.tempSalesDate = JSON.stringify(this.job.depositBalanceDue).substr(1, 10);
    if (this.tempSalesDate !== this.jobForm.get('depositBalanceDue').value) {
      this.updatedData.depositBalanceDue = this.jobForm.get('depositBalanceDue').value;
    }
    if (this.job.depositBalanceDue && (!this.jobForm.get('depositBalanceDue').value
      || this.jobForm.get('depositBalanceDue').value === '')) {
      this.updatedData.depositBalanceDue = null;
    }
    if (this.job.basePrice !== this.jobForm.get('basePrice').value) {
      this.updatedData.basePrice = this.jobForm.get('basePrice').value;
    }
    if (this.job.budgetAmount !== this.jobForm.get('budgetAmount').value) {
      this.updatedData.budgetAmount = this.jobForm.get('budgetAmount').value;
    }

    if (this.job.planNumber !== this.jobForm.get('jobAddress.planNumber').value) {
      this.updatedData.planNumber = this.jobForm.get('jobAddress.planNumber').value;
    }

    if (this.job.volume !== this.jobForm.get('jobAddress.volume').value) {
      this.updatedData.volume = this.jobForm.get('jobAddress.volume').value;
    }
    if (this.job.folio !== this.jobForm.get('jobAddress.folio').value) {
      this.updatedData.folio = this.jobForm.get('jobAddress.folio').value;
    }
    if (this.job.council !== this.jobForm.get('jobAddress.council').value) {
      this.updatedData.council = this.jobForm.get('jobAddress.council').value;
    }

    if (this.job.estate !== this.jobForm.get('jobAddress.estate').value) {
      this.updatedData.estate = this.jobForm.get('jobAddress.estate').value;
    }

    let id = this.jobForm.get('jobAddress.landZoningId').value === 0 ? null : this.jobForm.get('jobAddress.landZoningId').value;
    if (this.job.landZoningId !== id) {
      this.updatedData.landZoningId = id;
    }

    id = this.jobForm.get('jobAddress.landTitleTypeId').value === 0 ? null : this.jobForm.get('jobAddress.landTitleTypeId').value;
    if (this.job.landTitleTypeId !== id) {
      this.updatedData.landTitleTypeId = id;
    }

    id = this.jobForm.get('jobAddress.landTypeId').value === 0 ? null : this.jobForm.get('jobAddress.landTypeId').value;
    if (this.job.landTypeId !== id) {
      this.updatedData.landTypeId = id;
    }

    this.tempSalesDate = JSON.stringify(this.job.titleDueDate).substr(1, 10);
    if (this.tempSalesDate !== this.jobForm.get('jobAddress.titleDueDate').value) {
      this.updatedData.titleDueDate = this.jobForm.get('jobAddress.titleDueDate').value;
    }
    if (this.job.titleDueDate && (!this.jobForm.get('jobAddress.titleDueDate').value
      || this.jobForm.get('jobAddress.titleDueDate').value === '')) {
      this.updatedData.titleDueDate = null;
    }

    this.tempSalesDate = JSON.stringify(this.job.salesQuoteSignedDate).substr(1, 10);
    if (this.tempSalesDate !== this.jobForm.get('salesQuoteSignedDate').value) {
      this.updatedData.salesQuoteSignedDate = this.jobForm.get('salesQuoteSignedDate').value;
    }
    if (this.job.salesQuoteSignedDate && (!this.jobForm.get('salesQuoteSignedDate').value
      || this.jobForm.get('salesQuoteSignedDate').value === '')) {
      this.updatedData.salesQuoteSignedDate = null;
    }

    this.tempSalesDate = JSON.stringify(this.job.contractsRequiredByDate).substr(1, 10);
    if (this.tempSalesDate !== this.jobForm.get('contractsRequiredByDate').value) {
      this.updatedData.contractsRequiredByDate = this.jobForm.get('contractsRequiredByDate').value;
    }
    if (this.job.contractsRequiredByDate && (!this.jobForm.get('contractsRequiredByDate').value
      || this.jobForm.get('contractsRequiredByDate').value === '')) {
      this.updatedData.contractsRequiredByDate = null;
    }

    this.subscriptions = this.subscriptions.concat(
      this._jobService.updateJob(this.updatedData).subscribe({
        next: () => {
          // set these for the trade access messages
          this.job.isActive = this.jobForm.get('isActive').value;
          this.job.jobTypeId = this.jobForm.get('jobTypeId').value;
          this.job.divisionId = this.jobForm.get('divisionId').value;
          this.job.masterJobId = this.jobForm.get('masterJobId').value;
          this.updateJobAddress(true);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.updateSuccessful = false;
          this.loading = false;
        }
      })
    );
  }

  updateJobAddress(successFlag) {
    const ja = Object.assign({}, this.jobAddress, this.jobForm.get('jobAddress').value);
    if (!this.job || !this.job.jobAddressId) {
      // add address -----------------------------------
      // if (ja.streetName1 && ja.streetName1 !== '') {
      this.subscriptions = this.subscriptions.concat(
        this._addressService.addJobAddress(ja).subscribe({
          next: () => {
            this.updateContractAddress(successFlag);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updateSuccessful = false;
            this.updateContractAddress(false);
          }
        })
      );
      // } else {
      //   this.updateContractAddress(successFlag);
      // }
    } else {
      // update address -----------------------------------
      // if (ja.streetName1 && ja.streetName1 !== '') {
      this.subscriptions = this.subscriptions.concat(
        this._addressService.updateAddress(ja).subscribe({
          next: () => {
            this.updateContractAddress(successFlag);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updateSuccessful = false;
            this.updateContractAddress(false);
          }
        })
      );
      // } else {
      //   this.updateContractAddress(successFlag);
      // }
    }
  }

  updateContractAddress(successFlag) {
    const ca = Object.assign({}, this.contractAddress, this.jobForm.get('contractAddress').value);
    // Contract Address -----------------------------------
    if (!this.job || !this.job.contractAddressId) {
      // add contract address -----------------------------------
      // if (this.jobForm.get('contractAddress.streetName1').value && this.jobForm.get('contractAddress.streetName1').value !== '') {
      this.subscriptions = this.subscriptions.concat(
        this._addressService.addContractAddress(ca).subscribe({
          next: () => {
            this.updateCustomerLogin(successFlag);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updateSuccessful = false;
            this.updateCustomerLogin(false);
          }
        })
      );
      // } else {
      //   this.updateCustomerLogin(successFlag);
      // }
    } else {
      // update contract address -----------------------------------
      // if (this.jobForm.get('contractAddress.streetName1').value && this.jobForm.get('contractAddress.streetName1').value !== '') {
      this.subscriptions = this.subscriptions.concat(
        this._addressService.updateAddress(ca).subscribe({
          next: () => {
            this.updateCustomerLogin(successFlag);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updateSuccessful = false;
            this.updateCustomerLogin(false);
          }
        })
      );
      // } else {
      //   this.updateCustomerLogin(successFlag);
      // }
    }
  }

  updateCustomerLogin(successFlag) {
    const clientEmail = this.jobForm.get('customerLoginEmail').value;
    const sendInfoUpdateEmail = this.jobForm.get('sendInfoUpdateEmail').value;
    if (clientEmail === undefined || clientEmail === null || clientEmail === 'undefined' || clientEmail.trim() === '') {
      if (this.existingCustomerLoginId) {
        this.subscriptions = this.subscriptions.concat(
          this._jobCustomerService.deleteJobCustomer(this.existingCustomerLoginId).subscribe({
            next: () => {
              this.existingCustomerLoginId = null;
              this.finishUpdate(successFlag);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.updateSuccessful = false;
              this.finishUpdate(false);
            }
          })
        );
      } else {
        this.finishUpdate(successFlag);
      }
    } else {
      if (this.existingCustomerLoginId) {
        this.subscriptions = this.subscriptions.concat(
          this._jobCustomerService.patchJobCustomer(this.existingCustomerLoginId, clientEmail, sendInfoUpdateEmail).subscribe({
            next: () => {
              this.finishUpdate(successFlag);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.updateSuccessful = false;
              this.finishUpdate(false);
            }
          })
        );
      } else {
        this.subscriptions = this.subscriptions.concat(
          this._jobCustomerService.postJobCustomer(this.jobNum, clientEmail, sendInfoUpdateEmail).subscribe({
            next: (res) => {
              this.jobCustomers = [res as IJobCustomer];
              this.existingCustomerLoginId = this.jobCustomers[0].id;
              this.finishUpdate(successFlag);
            },
            error: (err) => {
              this.notiService.notify(err);
              this.updateSuccessful = false;
              this.finishUpdate(false);
            }
          })
        );
      }
    }
  }

  finishUpdate(emitSuccessFlag: boolean) {
    if (emitSuccessFlag && this.jobAdded) {
      this._globalService.setAreaSelected('selections');
      this.router.navigate(['selections/']);
    } else {
      this.updateRun = true;
      this.refreshLines.emit(emitSuccessFlag);
      this.getJob();
    }
  }

  deleteJob(content) {
    this.deleteForm.setValue({
      contractName: this.jobForm.get('contractName').value,
      deleteAttachments: false,
      deleteEstimatingItems: false
    });

    // check if we have estimating items
    this.checkingIfEstimatingItemsExist = true;
    this.estimatingItemsExist = false;

    this.getEstimatingItemsCheck();

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobService.deleteJob(this.jobNum).subscribe({
          next: () => {
            this.updateSuccessful = true;
            this.jobNum = '';
            this._globalService.setCurrentJob('');
            this.clearForm();
            this.jobDeleted = true;
            this.loading = false;
            this.updateRun = true;
            this._jobService.currentJobNum = '';
            this._jobService.currentJobString = '';
            this.refreshLines.emit(true);
          },
          error: (err) => {
            this.notiService.notify(err);
            this.updateSuccessful = false;
            this.finishUpdate(false);
          }
        })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getEstimatingItemsCheck() {
    this.estimatingItemsExist = false;

    this.subscriptions = this.subscriptions.concat(
      this.estimatingService.getJobEstimatingItemCheck(this._jobService.currentJob.id)
        .subscribe({
          next: (jobEstimatingCheck) => {
            this.jobEstimatingCheck = jobEstimatingCheck;

            if (jobEstimatingCheck && jobEstimatingCheck.numberRecords) {
              this.estimatingItemsExist = true;
            }
            this.checkingIfEstimatingItemsExist = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobEstimatingCheck = null;
            this.checkingIfEstimatingItemsExist = false;
          }
        })
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  tradeAccess() {
    // use modal to assign trades to this job
    const modalRef = this.modalService.open(JobCustomerModalComponent, { windowClass: 'modal-edit', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.users = this._userService.users;
    modalRef.componentInstance.jobNumber = this.jobNum;
    modalRef.componentInstance.job = this.job;

    modalRef.result.then((updatedItem) => {
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  assignUsers() {
    // use modal to set the users to the roles
    const modalRef = this.modalService.open(JobUsersModalComponent, { windowClass: 'modal-1000', backdrop: 'static', keyboard: false });
    modalRef.componentInstance.users = this.users;
    modalRef.componentInstance.jobNum = this.jobNum;

    modalRef.result.then(() => {
      this.checkUserIsNotRepOrRepForJob();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  setDivision(e: any) {
    const trackingColour = e.selectedItem?.trackingColour;

    if (!trackingColour || trackingColour === null || trackingColour === TrackingColourEnum.Clear.toString()) {
      e.element.style.backgroundColor = 'rgb(0, 0, 0, 0)';
    } else if (trackingColour === TrackingColourEnum.Orange.toString()) {
      e.element.style.backgroundColor = 'rgb(253, 209, 127, 0.6)';
    } else if (trackingColour === TrackingColourEnum.Yellow.toString()) {
      e.element.style.backgroundColor = 'rgb(253, 253, 139, 0.6)';
    } else if (trackingColour === TrackingColourEnum.Teal.toString()) {
      e.element.style.backgroundColor = 'rgb(132, 247, 237, 0.6)';
    } else if (trackingColour === TrackingColourEnum.Blue.toString()) {
      e.element.style.backgroundColor = 'rgb(147, 198, 247, 0.4)';
    } else if (trackingColour === TrackingColourEnum.Magenta.toString()) {
      e.element.style.backgroundColor = 'rgb(208, 157, 250, 0.6)';
    } else if (trackingColour === TrackingColourEnum.Grey.toString()) {
      e.element.style.backgroundColor = 'rgb(0, 0, 0, 0.2)';
    }
  }

  setHouseType(e: any) {
    if (!this.loading) {
      if (e.selectedItem?.id && (!this.job || !this.job.salesDate)
        && this._globalService.isSalesVariationsActive()) {
        // only change if no sales date - GH 10-8-19
        // a user has chosen a house type and we can set the basePrice
        const selectedHouseType = this.houseTypes.find(i => i.id === e.selectedItem.id);
        if (selectedHouseType) {
          const houseType = selectedHouseType;

          if (this.jobEstimatingCheck
            && (this.jobEstimatingCheck.numberRecords > 2
              || (this.jobEstimatingCheck.numberRecords === 1 && this.jobEstimatingCheck.recipeId !== this.originalHouseType?.recipeId))) {
            this.notiService.showWarning('Estimating lines exist so base price must be updated manually.');
          } else if (this.jobForm.get('basePrice').value !== houseType.salesPrice) {
            // if new house type has zero price we need to check if old price = old house type price - GH 14-8-19
            if (!houseType.salesPrice || houseType.salesPrice === 0) {
              if (this.jobForm.get('basePrice').value === this.selectedHouseTypeSalesPrice) {
                this.notiService.showInfo('The base house price has been updated');
                this.jobForm.patchValue({
                  basePrice: houseType.salesPrice
                });
                this.selectedHouseTypeSalesPrice = houseType.salesPrice;
                this.priceAsPerMasterHouseType = true;
              }
            } else {
              this.notiService.showInfo('The base house price has been updated');
              this.jobForm.patchValue({
                basePrice: houseType.salesPrice
              });
              this.selectedHouseTypeSalesPrice = houseType.salesPrice;
              this.priceAsPerMasterHouseType = true;
            }
          }

          if (houseType.defaultDeposit && this.jobForm.get('depositAmount').value !== houseType.defaultDeposit) {
            // if new house type we may need to update the deposit - GH 29-2-20
            this.notiService.showInfo('The deposit value has been updated');

            this.jobForm.patchValue({
              depositAmount: houseType.defaultDeposit
            });

            // second deposit will also use the same
            if (this.isSecondDepositVisible) {
              this.jobForm.patchValue({
                depositAmount2: houseType.defaultDeposit
              });
            }
          }
        }
      }
    }
  }

  getNextJobNumber() {
    // get the next available numeric job number
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getNextJobNumber(this.jobForm.get('divisionId').value)
        .subscribe({
          next: (jobNumber) => {
            this.jobForm.patchValue({
              jobNumber: jobNumber
            });
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  changeJobNumber() {
    // admins can change the job number
    const modalRef = this.modalService.open(ChangeNumberModalComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.jobId = this.job.id;

    modalRef.result.then((newJobNumber) => {
      this._globalService.setCurrentJob(newJobNumber);

      this._globalService.setAreaSelected('selections');
      this.router.navigate(['selections/']);
    }, () => {
    });
  }

  setHouseModificationDescription(description: string) {
    this.jobForm.patchValue({
      houseModificationDescription: description
    });
  }

  estimatePrice() {
    // here we use estimating to calc the price - EstimatingModalComponent
    if (this.jobNum !== '0' && this.jobNum !== '') {
      const modalRef = this.modalService.open(JobEstimatingModalComponent,
        { windowClass: 'modal-est2', backdrop: 'static', keyboard: false, scrollable: true });
      modalRef.componentInstance.fromFuelGauge = false;

      modalRef.result.then((result) => {
        if (+result !== this.jobForm.get('basePrice').value) {
          this.jobForm.patchValue({
            basePrice: +result
          });
        }

        this.getEstimatingItemsCheck();
      }, () => {
        this.getEstimatingItemsCheck();
      });
    } else {
      // we have to have a saved job
      this.notiService.showInfo('The job must be saved before using the estimating screen');
    }
  }

  claimLines() {
    const modalRef = this.modalService.open(JobClaimsComponent,
      { windowClass: 'modal-claims', backdrop: 'static', keyboard: false, scrollable: true });

    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  checkDuplicateHouseOnStreet() {
    // we give a warning if the same house type is on the same street so clients don't get side by side same house
    if (this.jobForm.get('houseTypeId').value && this.jobForm.get('jobAddress.streetName1').value) {
      let streetToSearchFor = this.jobForm.get('jobAddress.streetName1').value.toLowerCase().trim();

      // get rid of the street suffix - word at the end
      if (streetToSearchFor.lastIndexOf(' ') > 0) {
        streetToSearchFor = streetToSearchFor.slice(0, streetToSearchFor.lastIndexOf(' '));
      }

      this.subscriptions = this.subscriptions.concat(
        this._jobService.getJobsByAddress(streetToSearchFor)
          .subscribe({
            next: (jobs) => {
              const foundDuplicate = jobs.find(i => i.houseTypeId === this.jobForm.get('houseTypeId').value
                && i.id !== this.job.id
                && (i.masterJobId && i.masterJobId != this.job.id)
                && (this.job.masterJobId && this.job.masterJobId != i.id));

              if (foundDuplicate) {
                this.notiService.showWarningNoTimeOut('NOTE: Same house type on same street as job ' + foundDuplicate.jobNumber);
              }
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  checkSaleDate(event: any) {
    this.checkDate(event.component.option('value'), 'Sold');

    if (!this.priceAsPerMasterHouseType) {
      this.notiService.showWarning('Base house price is not as per master house type');
    }
  }

  checkContractSigned(event: any) {
    this.checkDate(event.component.option('value'), 'Contract Signed');
  }

  checkDate(newDate: Date, dateName: string) {
    if (newDate) {
      newDate = this.dateOnly(newDate);
      const todaysDate = this.dateOnly(new Date());
      if (newDate > todaysDate) {
        this.notiService.showError('Warning - ' + dateName + ' date in the future');
      } else {
        const lastMonthDate = this.dateOnly(new Date());
        lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
        if (newDate < lastMonthDate) {
          this.notiService.showWarning('Warning - ' + dateName + ' date more than a month old');
        }
      }
    }
  }

  dateOnly(date: Date): Date {
    if (date) {
      date = new Date(date);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    else {
      var newDate = null;
      return newDate;
    }
  }

  getLeadManagementData() {
    this.leadManagementId = null;
    if (this.isHubSpotActive || this.isActiveCampaignActive) {
      this.integrationService.getJobIntegrationData(this.job.id, this.isHubSpotActive ? IntegrationTypeEnum.HubSpot : IntegrationTypeEnum.ActiveCampaign)
        .subscribe({
          next: (res) => {
            this.leadManagementId = res?.integrationId;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    }
  }

  createLeadManagementDeal() {
    this.integrationService.createDeal(this.job.id)
      .subscribe({
        next: (res) => {
          this.leadManagementId = res?.integrationId;
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      });
  }

  getLeadManagementLink() {
    if (!this.jobForm.get('divisionId')?.value) {
      this.notiService.showWarning('Please select a division');
      return;
    }

    const modalRef = this.modalService.open(LeadManagementComponent,
      { windowClass: 'modal-est', backdrop: 'static', keyboard: false, scrollable: true });
    modalRef.componentInstance.jobId = this.job?.id;
    modalRef.componentInstance.isHubSpotActive = this.isHubSpotActive;
    modalRef.componentInstance.divisionId = this.jobForm.get('divisionId').value;

    modalRef.result.then(res => {
      this.leadManagementId = res.selectedRecord;

      if (res.selectedRecord && res.importData) {
        if (!this.job || !this.job.salesDate) {
          this.importLeadManagementData(res.selectedRecord);
        } else {
          this.updateJobIntegration(this.job.id, true);
        }
      }
    }, () => {
    });
  }

  getLeadManagementFields() {
    // admins can change the job number
    const modalRef = this.modalService.open(LeadManagementFieldsComponent,
      { windowClass: 'modal-1000', backdrop: 'static', keyboard: false, scrollable: true });
    modalRef.componentInstance.jobId = this.job?.id;
    modalRef.componentInstance.isHubSpotActive = this.isHubSpotActive;
    modalRef.componentInstance.divisionId = this.jobForm.get('divisionId').value;

    modalRef.result.then(leadManagementDealId => {
      if (leadManagementDealId) {
        if (leadManagementDealId === "Disconnected") {
          this.leadManagementId = null;
        } else {
          this.leadManagementFields = this.integrationService.leadManagementFields;
          this.importLeadManagementFields(this.integrationService.onlyJobData);
        }
      }
    });
  }

  importLeadManagementData(id: string) {
    const leadManagmentRecord = this.integrationService.leadManagementDeals.find(i => i.id === id);
    if (leadManagmentRecord) {

      this.jobItemService.setWaitingJobItemAPI(true);

      // get the fields we need to populate this job
      this.integrationService.getDealData(true, id, leadManagmentRecord.secondContactId, this.jobForm.get('divisionId').value)
        .subscribe({
          next: (res) => {
            this.jobItemService.setWaitingJobItemAPI(false);
            this.leadManagementFields = res;

            this.jobForm.patchValue({
              contractName: this.activeCampaignContactNameType
                ? leadManagmentRecord.firstName + ' ' + leadManagmentRecord.lastName
                + (this.integrationService.leadManagementSecondContact
                  ? ' ' + this.integrationService.leadManagementSecondContact.firstName + ' ' + this.integrationService.leadManagementSecondContact.lastName
                  : '')
                : leadManagmentRecord.title,
              firstName1: leadManagmentRecord.firstName,
              lastName1: leadManagmentRecord.lastName,
              phoneNumber1: leadManagmentRecord.phone,
              firstName2: this.integrationService.leadManagementSecondContact?.firstName,
              lastName2: this.integrationService.leadManagementSecondContact?.lastName,
              phoneNumber2: this.integrationService.leadManagementSecondContact?.phone,
              clientEmail: leadManagmentRecord.email
                ? leadManagmentRecord.email + (this.integrationService.leadManagementSecondContact?.email
                  ? '\n' + this.integrationService.leadManagementSecondContact?.email : '')
                : this.integrationService.leadManagementSecondContact?.email
            });

            this.importLeadManagementFields(false);
          }, error: (err) => {
            this.notiService.notify(err);
            this.jobItemService.setWaitingJobItemAPI(false);
          }
        });
    }
  }

  importLeadManagementFields(onlyJobData: boolean) {
    this.leadManagementFields.forEach(leadManagementField => {
      if (!onlyJobData) {
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'property lot number') {
          this.jobForm.patchValue({
            jobAddress: {
              lotNumber: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'property house number') {
          this.jobForm.patchValue({
            jobAddress: {
              streetNumber: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'property address') {
          this.jobForm.patchValue({
            jobAddress: {
              streetName1: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'property suburb') {
          this.jobForm.patchValue({
            jobAddress: {
              suburbTown: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'property state') {
          this.jobForm.patchValue({
            jobAddress: {
              state: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'property post code') {
          this.jobForm.patchValue({
            jobAddress: {
              postCode: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase().startsWith('land estate')) {
          this.jobForm.patchValue({
            jobAddress: {
              estate: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'land title date') {
          this.jobForm.patchValue({
            jobAddress: {
              titleDueDate: leadManagementField['fieldValue']
            }
          });
        }
        if (leadManagementField['fieldName'].trim().toLowerCase() === 'land type') {
          const landType = this.landTypes
            .find(i => i.description.trim().toLowerCase() === leadManagementField['fieldValue'].trim().toLowerCase());
          if (landType) {
            this.jobForm.patchValue({
              jobAddress: {
                landTypeId: landType.id
              }
            });
          }
        }
        if ((this.isActiveCampaignActive && leadManagementField['fieldName'].trim().toLowerCase() === 'home design')
          || (this.isHubSpotActive && leadManagementField['fieldName'].trim().toLowerCase() === 'new floorplan')) {
          const houseType = this.houseTypes
            .find(i => i.description.trim().toLowerCase() === leadManagementField['fieldValue'].trim().toLowerCase());

          if (houseType) {
            if (this.job) {
              if (houseType.id !== this.job.houseTypeId) {
                this.notiService.showWarning('House type updated');

                this.jobForm.patchValue({
                  houseTypeId: houseType.id
                });
                this.setHouseType(houseType.id);
              }
            } else {
              this.jobForm.patchValue({
                houseTypeId: houseType.id
              });
              this.setHouseType(houseType.id);
            }
          }
        }
      }

      if (this.job && this.trackingFieldsService.trackingFields && leadManagementField['fieldValue'] && leadManagementField['fieldValue'].trim() !== '') {
        // Arli (using HubSpot) only want Facade linked for Job Data fields
        if (this.isActiveCampaignActive || (this.isHubSpotActive && leadManagementField['fieldName'].trim().toLowerCase() === 'facade')) {
          // job exists so we can update the tracking fields
          const trackingField = this.trackingFieldsService.trackingFields
            .find(i => i.fieldName.trim().toLowerCase() === leadManagementField['fieldName'].trim().toLowerCase());

          if (trackingField) {
            const result = new Promise((resolve, reject) =>
              this.trackingFieldsService
                .updateJobField(this.job.id, trackingField.id.toString(), { textValue: leadManagementField['fieldValue'] })
                .subscribe({
                  next: (fieldRes) => {
                    this.reloadJobData++; // force component refresh
                    return resolve(fieldRes);
                  }, error: (fieldErr) => {
                    return reject(this._globalService.returnError(fieldErr));
                  }
                }));
          }
        }
      }
    });

    if (!onlyJobData) {
      this.notiService.showInfo('Job-Data updated and saved. Save to complete remaining import!');
      this.reloadJobData++; // force component refresh
    } else {
      this.notiService.showInfo('Job-Data updated');
    }
  }

  updateLeadManagementData(jobId: number) {
    if (this.leadManagementId) {
      // add the lead management data fields if any
      this.leadManagementFields.forEach(leadManagementField => {
        const trackingField = this.trackingFieldsService.trackingFields
          .find(i => i.fieldName.trim().toLowerCase() === leadManagementField['fieldName'].trim().toLowerCase());

        if (trackingField) {
          const result = new Promise((resolve, reject) =>
            this.trackingFieldsService
              .updateJobField(jobId, trackingField.id.toString(), { textValue: leadManagementField['fieldValue'] })
              .subscribe({
                next: (fieldRes) => {
                  return resolve(fieldRes);
                }, error: (fieldErr) => {
                  return reject(this._globalService.returnError(fieldErr));
                }
              })
          );
        }
      });

      // add the integration id
      this.updateJobIntegration(jobId, false);
    }
  }

  updateJobIntegration(jobId: number, showMessage: boolean) {
    var leadManagementIntegrationType: IntegrationTypeEnum = IntegrationTypeEnum.ActiveCampaign;
    if (this.isHubSpotActive) {
      leadManagementIntegrationType = IntegrationTypeEnum.HubSpot;
    }
    const integrationResult = new Promise((resolve, reject) =>
      this.integrationService.updateJobIntegration(jobId, leadManagementIntegrationType,
        this.leadManagementId)
        .subscribe({
          next: (fieldRes) => {
            if (showMessage) {
              this.notiService.showSuccess('Connection stored');
            }
            return resolve(fieldRes);
          }, error: (fieldErr) => {
            return reject(this._globalService.returnError(fieldErr));
          }
        })
    );
  }

  getBanks() {
    this.subscriptions = this.subscriptions.concat(
      this.claimsService.getBanks()
        .subscribe({
          next: (banks) => {
            this.banks = banks;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  getJobBankDetails() {
    if (this.lenderInformationEnabled) {
      this.jobBank = null;
      this.bankId = null;
      this.loanReference = '';
      this.loanAmount = null;

      this.subscriptions = this.subscriptions.concat(
        this.claimsService.getJobBankDetails(this.job.id)
          .subscribe({
            next:
              (jobBank) => {
                this.jobBank = jobBank;
                this.bankId = jobBank?.bankId;
                this.loanReference = jobBank?.loanReference;
                this.loanAmount = jobBank?.loanAmount;
              },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  saveJobBankDetails() {
    if (this.lenderInformationEnabled) {
      if (!this.jobBank) {
        this.subscriptions = this.subscriptions.concat(
          this.claimsService.postJobBank(
            {
              jobId: this.job.id,
              bankId: this.bankId,
              loanReference: this.loanReference,
              loanAmount: this.loanAmount
            }
          )
            .subscribe({
              next:
                (jobBank) => {
                  this.jobBank = jobBank;
                },
              error: (err) => {
                this.notiService.notify(err);
              }
            })
        );
      } else {
        if (this.jobBank.bankId !== this.bankId
          || this.jobBank.loanReference !== this.loanReference
          || this.jobBank.loanAmount !== this.loanAmount) {
          this.subscriptions = this.subscriptions.concat(
            this.claimsService.patchJobBank(this.jobBank.id,
              {
                bankId: this.bankId,
                loanReference: this.loanReference,
                loanAmount: this.loanAmount
              }
            )
              .subscribe({
                next:
                  () => {
                  },
                error: (err) => {
                  this.notiService.notify(err);
                }
              })
          );
        }
      }
    }
  }

  onBankSelectionChanged(dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.bankId = event.selectedRowsData[0].id;
      dropDownBox.close();
    }
  }

  onLandMasterChanged(dropDownBox, event) {
    if (event.selectedRowKeys.length > 0) {
      this.jobForm.patchValue({
        jobAddress: {
          streetNumber: event.selectedRowsData[0].address?.streetNumber,
          lotNumber: event.selectedRowsData[0].address?.lotNumber,
          streetName1: event.selectedRowsData[0].address?.streetName1,
          streetName2: event.selectedRowsData[0].address?.streetName2,
          suburbTown: event.selectedRowsData[0].address?.suburbTown,
          postCode: event.selectedRowsData[0].address?.postCode,
          state: event.selectedRowsData[0].address?.state,
          landId: event.selectedRowsData[0].id,
          estate: this.estates.find(i => i.id === event.selectedRowsData[0].estateId)?.name ?? ''
        }
      });
      dropDownBox.close();
    }
  }

  runLoanShortfall() {
    const modalRef = this.modalService.open(LoanShortfallComponent,
      { windowClass: 'modal-splits', scrollable: true });

    modalRef.result.then(() => {
    }, () => {
    });
  }

  addClientUser() {
    const modalRef = this.modalService.open(AddClientUserComponent);
    modalRef.componentInstance.firstName = this.jobForm.get('firstName1').value;
    modalRef.componentInstance.lastName = this.jobForm.get('lastName1').value;

    modalRef.result.then((newUser: User) => {
      this.customerLogins = this.customerLogins.concat(newUser);
      this.jobForm.patchValue({
        customerLoginEmail: newUser.email
      });
    }, () => { });
  }

  onMasterJobSelected(e: any) {
    // if the contract name is blank we copy the details
    if (e) {
      if ((!this.jobForm.get('contractName').value || this.jobForm.get('contractName').value.trim() === '')) {
        const masterJob = this.jobs.find(i => i.id === e.source.value);

        this.jobForm.patchValue({
          contractName: masterJob.contractName,
          salutation: masterJob.salutation,
          clientEmail: masterJob.clientEmail,
          phoneNumber1: masterJob.phoneNumber1,
          firstName1: masterJob.firstName1,
          lastName1: masterJob.lastName1,
          phoneNotes1: masterJob.phoneNotes1,
          phoneNumber2: masterJob.phoneNumber2,
          firstName2: masterJob.firstName2,
          lastName2: masterJob.lastName2,
          phoneNotes2: masterJob.phoneNotes2
        });

        if (masterJob.jobAddress) {
          this.jobForm.patchValue({
            jobAddress: {
              lotNumber: masterJob.jobAddress.lotNumber,
              streetName1: masterJob.jobAddress.streetName1,
              streetName2: masterJob.jobAddress.streetName2,
              suburbTown: masterJob.jobAddress.suburbTown,
              state: masterJob.jobAddress.state,
              postCode: masterJob.jobAddress.postCode,
              planNumber: masterJob.planNumber,
              volume: masterJob.volume,
              folio: masterJob.folio,
              council: masterJob.council,
              estate: masterJob.estate,
              landZoningId: masterJob.landZoningId,
              landTitleTypeId: masterJob.landTitleTypeId,
              landTypeId: masterJob.landTypeId,
              titleDueDate: this.dateOnly(masterJob.titleDueDate),
              latitude: masterJob.jobAddress.latitude,
              longitude: masterJob.jobAddress.longitude
            }
          });
        }

        if (masterJob.contractAddress) {
          this.jobForm.patchValue({
            contractAddress: {
              lotNumber: masterJob.contractAddress.lotNumber,
              streetNumber: masterJob.contractAddress.streetNumber,
              streetName1: masterJob.contractAddress.streetName1,
              streetName2: masterJob.contractAddress.streetName2,
              suburbTown: masterJob.contractAddress.suburbTown,
              state: masterJob.contractAddress.state,
              postCode: masterJob.contractAddress.postCode,
            }
          });
        }
      }
    }
  }

  addCustomItem(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    const newItem = {
      id: null,
      name: data.text
    };

    if (data.text && data.text !== '' && !this.estates.find(i => i.name === data.text)) {
      this.estates.push(new Estate(data.text))
    }

    data.customItem = newItem;
  }

  addCustomHouseModificationDescription(data) {
    if (!data.text) {
      data.customItem = null;
      return;
    }

    const newItem = {
      id: null,
      description: data.text
    };

    if (data.text && data.text !== '' && !this.houseModifications.find(i => i.description === data.text)) {
      this.houseModifications.push(new HouseModification(data.text));
    }

    data.customItem = newItem;
  }

  maintenanceComplete() {
    // mark job maintenance complete
    const modalRef = this.modalService.open(MaintenanceCompleteComponent, { windowClass: 'modal-edit' });

    modalRef.result.then((newDate: Date) => {
      this.jobExtra.maintenanceCompleteDate = newDate;
      this.jobForm.patchValue({
        maintenanceCompleteDate: newDate,
        isActive: newDate ? false : true
      });
    }, () => {
    });
  }

  checkDuplicateAddress() {
    if (!this.loading
      && (this.jobForm.get('jobAddress.streetName1').value ?? '') !== ''
      && (this.jobForm.get('jobAddress.suburbTown').value ?? '') !== ''
      && ((this.jobForm.get('jobAddress.lotNumber').value ?? '') !== ''
        || (this.jobForm.get('jobAddress.streetNumber').value ?? '') !== '')) {
      this.subscriptions = this.subscriptions.concat(
        this._jobService.getDuplicateJobs(this.jobForm.get('jobAddress').value)
          .subscribe({
            next: (duplicateJobs) => {
              if (duplicateJobs && duplicateJobs.length) {
                if (duplicateJobs.length == 1) {
                  const duplicateJob = duplicateJobs.find(i => i.id !== this.job.id);
                  if (duplicateJob) {
                    this.notiService.showWarningNoTimeOut('Duplicate address found - job ' + duplicateJob.jobNumber);
                  }
                } else {
                  this.notiService.showWarningNoTimeOut('Duplicate addresses found. Use Search to find them');
                }
              }
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  setJobType(e: any) {
    if (e.selectedItem) {
      this.setHouseTypeIdValidator(e.selectedItem.id === JobTypeEnum.StandardJob);
    }
  }

  setJobTypeValue() {
    this.setHouseTypeIdValidator(this.jobForm.get('jobTypeId').value === JobTypeEnum.StandardJob);
  }

  setHouseTypeIdValidator(isRequired: boolean): void {
    const houseTypeIdControl = this.jobForm.get('houseTypeId');

    if (isRequired) {
      houseTypeIdControl.setValidators([Validators.required]);
    } else {
      houseTypeIdControl.clearValidators();
    }

    houseTypeIdControl.updateValueAndValidity();
  }

  isRequired(control: AbstractControl): boolean {
    const validator = control.validator ? control.validator({} as AbstractControl) : null;
    return validator && validator.required;
  }

  // Function to display lender details
  displayExpr(bank: Bank): string {
    const parts = [bank.description, bank.contactName, bank.contactPhone, bank.contactEmail];
    return parts.filter(part => part).join(' | ');
  }

  getLandMasters() {
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getLandMasters()
        .subscribe({
          next: (landMasters) => {
            this.landMasters = landMasters ?? [];
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }
}
