<!-- Template for asking what job number -->
<ng-template #content let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h2 class="modal-title">{{reportHeading}}</h2>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>{{reportDescription}}</p>
    <br>

    <!-- askForJobNumber -->
    <div *ngIf="askForJobNumber">
      <form novalidate [formGroup]="jobNumberForm">
        <fieldset>

          <js-job-number-small [jobNumber]="jobNumber" [saveJobNumber]="true"
            (refreshJobNumber)="refreshJobNumber($event)"></js-job-number-small>

          <div *ngIf="reportHeading === 'Total Contract Summary'" class="row">
            <label class="control-label-sm">Print Variation Numbers:&nbsp;&nbsp;
              <mat-checkbox formControlName="printVONumbers"></mat-checkbox>
            </label>
            <label *ngIf="currentJob?.isLocked" class="control-label-sm">Print From Contract:&nbsp;&nbsp;
              <mat-checkbox formControlName="printFromContract"></mat-checkbox>
            </label>
          </div>

          <div *ngIf="useAssignedTo && (!jobNumber || jobNumber === '')" class="">
            <span class='me-2'>Assigned To:</span>
            <dx-select-box id="assignedUser" [dataSource]="users" displayExpr="fullName" valueExpr="id" [width]="300"
              [(value)]="officerId">
            </dx-select-box>

            <button type="button" class="btn btn-sm btn-primary ms-1" (click)="setToMe()">Me</button>
          </div>

          <div *ngIf="selectUser" class="">
            <span class='me-2'>Select User:</span>
            <dx-select-box id="selectUser" [dataSource]="users" displayExpr="fullName" valueExpr="id" [width]="300"
              [(value)]="officerId">
            </dx-select-box>
          </div>
        </fieldset>
      </form>


      <label *ngIf="showIncludeTestJobsCheckBox && (!jobNumber || jobNumber === '')" class="control-label-sm ps-0 mt-2">
        Include Test Jobs&nbsp;&nbsp;
        <mat-checkbox [(ngModel)]="includeTestJobs"></mat-checkbox>
      </label>
    </div>

    <div *ngIf="reportHeading === 'Variations Report'">
      <!-- <label class="col-sm-5 control-label-sm" for="selectedIDs">Status (blank for all):</label>
      <select name="selectedIDs" id="selectedIDs" [(ngModel)]="selectedStatusId" class="col-sm-6">
        <option *ngFor="let voStatus of variationStatus" [value]="voStatus.id">{{voStatus.name}}</option>
      </select> -->
      <div class="widget-container">
        <dx-list *ngIf="salesVariationsActive" #list [dataSource]='variationTypesStore' [height]="150"
          [selectionMode]="selectionModeValue" [selectAllMode]="selectAllModeVlaue" [showSelectionControls]="true"
          [(selectedItemKeys)]="selectedVariationTypes"></dx-list>

        <dx-list #list [dataSource]='variationStatusStore' [height]="300" [selectionMode]="selectionModeValue"
          [selectAllMode]="selectAllModeVlaue" [showSelectionControls]="true" [(selectedItemKeys)]="selectedIDs">
        </dx-list>
      </div>

      <label class="control-label-sm">Include Test Jobs&nbsp;&nbsp;
        <mat-checkbox [(ngModel)]="includeTestJobs"></mat-checkbox>
      </label>

      <label class="control-label-sm ms-3">Include Zero Value Variations&nbsp;&nbsp;
        <mat-checkbox [(ngModel)]="includeZeroValueVOs"></mat-checkbox>
      </label>
    </div>

    <div *ngIf="reportHeading === 'Job List with Roles'" class="row">
      <label class="control-label-sm">Include In-Active Jobs&nbsp;&nbsp;
        <mat-checkbox [(ngModel)]="includeInActiveJobs"></mat-checkbox>
      </label>

      <label class="control-label-sm">Include Test Jobs&nbsp;&nbsp;
        <mat-checkbox [(ngModel)]="includeTestJobs"></mat-checkbox>
      </label>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="c('Close click')" [disabled]="!okToRunReport">
      Run Report
    </button>
  </div>
</ng-template>

<div class="card">

  <div class="card-header">
    <span>Reports</span>
  </div>

  <div class="card-body">
    <div *ngIf="!loading">
      <button class="btn-sm btn-outline-primary btnClass" (click)="outstandingVOsReport(content)">
        Variations Report
      </button>
      <button *ngIf="jobsWritePermission" class="btn-sm btn-outline-primary btnClass"
        (click)="contractSummaryReport(content)">
        Total Contract Summary
      </button>
      <button class="btn-sm btn-outline-primary btnClass" (click)="itemsWaitingSelection(content)">
        Items Waiting Selection
      </button>
      <button class="btn-sm btn-outline-primary btnClass" (click)="outstandingPSReport(content)">
        Outstanding Provisional Sums
      </button>
      <button *ngIf="jobLineSetupAdmin" class="btn-sm btn-outline-primary btnClass"
        (click)="jobShowHideSetupReport(content)">
        Job Show/Hide Setup
      </button>
      <button *ngIf="jobLineSetupAdmin" class="btn-sm btn-outline-primary btnClass" (click)="jobItemsChanged(content)">
        Job Items Changed
      </button>
      <button class="btn-sm btn-outline-primary btnClass" (click)="findItem()">
        Find an item across jobs
      </button>
      <button class="btn-sm btn-outline-primary btnClass" (click)="jobRolesReport(content)">
        Job Listing of Roles
      </button>
      <button *ngIf="isActiveCampaignActive" class="btn-sm btn-outline-primary btnClass"
        (click)="jobsIntegratedToActiveCampaign()">
        Jobs Integrated with Active Campaign
      </button>
      <button *ngIf="isHubSpotActive" class="btn-sm btn-outline-primary btnClass"
      (click)="jobsIntegratedToHubSpot()">
      Jobs Integrated with HubSpot
    </button>
      <button  class="btn-sm btn-outline-primary btnClass"
        (click)="jobDocumentsChanged()">
        Info Attachments Changed
      </button>
      <button *ngIf="claimScheduleEnabled" class="btn-sm btn-outline-primary btnClass"
        (click)="loanShortfall()">
        Loan Shortfall
      </button>
      <button *ngIf="estimatingRead" class="btn-sm btn-outline-primary btnClass" (click)="jobMarginReport()">
        Margin Fuel Guage
      </button>
    </div>

    <!-- Image Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

    <br>
    <div *ngIf="error" class="error">{{errorMessage}}</div>
    <!-- <div *ngIf="success" class="success">{{successMessage}}</div> -->
  </div>
</div>
