import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import DataSource from 'devextreme/data/data_source';
import { GlobalService } from '../../../services/global.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'js-check-show-rules-report',
  templateUrl: './check-show-rules-report.html',
  styleUrls: ['./check-show-rules-report.css']
})
export class CheckShowRulesReportComponent implements OnInit, OnDestroy {
  @Input() jobId: number;

  subscriptions: Subscription[] = [];
  dataSource: DataSource<any, any>;
  maxGridHeight: number;
  updateRecords: number[] = [];
  fixRun: boolean;

  constructor(private _activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private _jobItemService: JobItemService) { }


  ngOnInit(): void {
    this.maxGridHeight = window.innerHeight - 200;
    this.setupDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setupDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this._jobItemService.getJobItemCheckRuleItems(this.jobId, this.updateRecords).subscribe({
            next: (res) => {
              return resolve(res);
            }, error: (err) => {
              return reject(this.globalService.returnError(err));
            }
          }));
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      locateInMenu: 'auto',
      widget: 'dxButton',
      options: {
        text: 'Run Fix',
        onClick: this.runFix.bind(this),
        matTooltip: 'Collapse All Rows'
      }
    });
  }

  runFix() {
    if (this.updateRecords.length === 0) {
      this.notiService.showWarning('Please select at least one row');
      return;
    }
    this.fixRun = true;
    this.setupDataSource();
  }

  cancel() {
    if (this.fixRun) {
      this._activeModal.close();
    } else {
      this._activeModal.dismiss();
    }
  }
}
