import { AuthService } from './../services/auth.service';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { JobItemService } from '../services/felixApi/job-item.service';
import { GlobalService } from '../services/global.service';

import { IJobItem } from '../dtos/job-item';
import { IOptionListHeader } from '../dtos/option-list-header';
import { ItemTypeEnum } from '../dtos/item-type.enum';
import { SelectionTypeEnum } from '../dtos/selection-type.enum';
import { JobVarTypeEnum } from '../dtos/job-var-type.enum';
import { ChangeTypeEnum } from '../dtos/change-type.enum';
import { IJobVarItem } from '../dtos/job-var-item';
import { SelectionEditModalComponent } from './selection-modals/selection-edit-modal.component';
import { HeadingModalComponent } from './selection-modals/heading-modal.component';
import { AddEditDeleteEnum } from '../dtos/add-edit-delete.enum';
import { VariationAddModalComponent } from './selection-modals/variation-add-modal.component';
import { SelectionAttachmentModalComponent } from './selection-modals/selection-attachment-modal.component';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { PHONE_SIZE } from '../../config/variables';
import { EstimatingModalComponent } from './selection-modals/estimating-modal/estimating-modal.component';
import { JobVarItemService } from '../services/felixApi/job-var-item.service';
import { UtilsService } from '../services/utils.service';
import { VariationService } from '../services/felixApi/variation.service';


@Component({
  selector: 'js-selection-heading',
  templateUrl: './selection-heading.component.html',
  // take the next bit out - tried to get a wider modal but mucked up indenting (padding)
  // encapsulation: ViewEncapsulation.None,
  styleUrls: ['./selection-heading.component.scss']
})
export class SelectionHeadingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showAddEditButtons: boolean; // to show/hide edit.
  @Input() iJobItem: IJobItem;
  @Input() variationNumber: number;
  @Input() headingLevel: number;
  @Input() showImages: boolean;
  @Input() isParentHiddenFromMaster: boolean;
  @Input() selectionsAdmin: boolean;
  @Input() variationType: number;
  @Input() jobLineSetupAdmin: boolean;
  @Input() isVariationOpen: boolean;
  @Input() headingIsSetUpLine: boolean;
  @Input() showNumbering: boolean;
  @Input() itemsChangedFlag: number; // used to refresh the list


  // event to emit a 'refresh' command to the parent component
  @Output() refreshLines: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  COMPONENT_NAME = 'selection-heading';
  // PHONE_SIZE = 660;
  subscriptions: Subscription[] = [];

  colours = [
    { name: 'Standard', id: 0 },
    { name: 'Red', id: 1 },
    { name: 'Green', id: 2 }
  ];

  containerMargin: number;
  errorMessage: any;
  jobItems: IJobItem[] = [];
  jobItemsRaw: IJobItem[] = [];
  updatedItems: IJobItem[] = [];
  selectedValue: string;
  optionList: IOptionListHeader[] = [];
  optionListTmp: IOptionListHeader[] = [];
  updatedItem: any;
  testUpdatedItem: boolean;
  optDescription = '';
  optItemTypeId: number;
  optionListId = 0;
  editIsSetupLine = false;
  selectionTypeId: number;
  modalHeading = '';
  modalButton = '';
  modalButton2: string;
  closeResult = '';
  optSelection = '';
  optPrice = 0;
  optisNoCharge = false;
  optisPriceNote = false;
  optisChecked = false;
  optnoteColour = 0;
  optisBoldNote = false;
  optisItalicNote = false;
  optSelectionCheckbox: boolean;
  // loading = false; // loading spinner - now uses jobitemservice.loadingJobItems
  isDoNotPrint: boolean;
  isEOStandard: boolean;

  treeOptionNodes: any[] = [];
  treeOptionNodesSetup = false;
  treeOptionOptions = {};
  treeJobItemNodes: any[] = [];
  treeJobItemNodesSetup = false;
  treeJobItemOptions = {};

  // when adding or editing a detail line we need to ask if we are going to link to another line
  detailOptionType: string;
  linkedJobItemId: number;
  ItemType = ItemTypeEnum;
  SelectionType = SelectionTypeEnum;
  setupLinkedJobItemId: number; // for linking for setup to show/hide
  setupValue: string;
  setupValueOrig: string; // holding the original value to check on update

  // form for entering a manual selection in a dropdown
  manualSelection = '';

  currentJobItem: IJobItem;
  currentJobItemId: number;
  andORSetupLinks: string; // tells us if we ALL or only one condition need to be true to show the line

  // for variations
  originalItem: IJobItem; // the original item before being changed by VO.
  originalDesc = ''; // the original desc before being changed by VO.
  alreadyHaveVO: boolean; // when changing a variation line - have we already changed it?
  editOption = '';
  jobItemsCheck: IJobItem[] = [];
  jobVarTypeEnum = JobVarTypeEnum;
  changeTypeEnum = ChangeTypeEnum;
  currentJobVarItem: IJobVarItem;
  addAboveOrAfter: string;
  canAddAbove = false;
  isHiddenFromMaster = false;
  changeTypeId: number;
  firstRecord: number;
  lastRecord = 0;
  testSelection: string; // used to test for Not Applicable items

  newItem = new IJobItem; // for adding a new item

  innerWidth: number; // the actual width of the users window
  phoneSize = false; // we calculate if the user is on a small device and show the images differently
  attachmentTypeEnum = AttachmentTypeEnum;
  isQtyRequired: any;
  addendumName: string;
  isClientOrAssociate: boolean;
  thisHeadingItems: IJobItem[];

  constructor(public _jobItemService: JobItemService,
    private _authService: AuthService,
    private _globalService: GlobalService,
    private _jobVarItemService: JobVarItemService,
    private notiService: NotificationService,
    private variationService: VariationService,
    private modalService: NgbModal,
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    // console.log(JSON.stringify(this._jobItemService.currentJobItems.filter(i => i.jobItemAboveId === this.iJobItem.id)));
    this.containerMargin = (this.headingLevel * 10) + 7;
    this.addendumName = this._globalService.getAddendumName();
    this.isClientOrAssociate = this._authService.isClient() || this._authService.isAssociate();

    this.subscribeToWidthChanges();
    this.calcIsRestrictedForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calcFirstLast();

    this.thisHeadingItems = this._jobItemService.currentJobItems
      .filter(i => i.jobItemAboveId === this.iJobItem.id
        && (!this.isClientOrAssociate || (i.itemTypeId === this.ItemType.Heading || !i.isDoNotPrint)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this._jobItemService.setWaitingJobItemAPI(false);
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._globalService.innerWidthChanged.subscribe(width => {
        this.calcIsRestrictedForm();
      })
    );
  }

  calcIsRestrictedForm() {
    this.innerWidth = this._globalService.innerWidth;
    if (this.innerWidth < PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }
  }

  calcFirstLast() {
    // calc length of this list so we can add at the bottom
    this.firstRecord = 0;
    this.lastRecord = 0;
    for (let i = 0; i < this._jobItemService.currentJobItems.length; i++) {
      if (this._jobItemService.currentJobItems[i].jobItemAboveId === this.iJobItem.id) {
        if (this.firstRecord === 0) {
          this.firstRecord = i;
        }
        this.lastRecord = i;
      }
    }
  }

  getJobItemList() {
    this._jobItemService.readJobItems();
  }

  showSetupLinesFilter() {
    // filter the jobitmes depending on the boolean showSetupLines
    this._jobItemService.variationTotal = 0;

    this._jobItemService.showSetupLinesFilter();

    this.calcFirstLast();
  }

  // add a new option list to the end
  addItem() {
    // call modal
    const modalRef = this.modalService.open(SelectionEditModalComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.addEdit = 'Add';
    modalRef.componentInstance.baseLevel = false;
    modalRef.componentInstance.headingIsSetUpLine = this.headingIsSetUpLine;

    // if we have an item above then send this so we can link to it but must be another dropdown
    if (this._jobItemService.currentJobItems && this._jobItemService.currentJobItems.length) {
      if (this._jobItemService.currentJobItems[this.lastRecord].itemTypeId === this.ItemType.Detail &&
        this._jobItemService.currentJobItems[this.lastRecord].selectionTypeId === this.SelectionType.Dropdown) {
        modalRef.componentInstance.selectionItemAbove = this._jobItemService.currentJobItems[this.lastRecord].id;
      } else {
        modalRef.componentInstance.selectionItemAbove = 0;
      }
    } else {
      modalRef.componentInstance.selectionItemAbove = 0;
    }

    modalRef.componentInstance.jobItemAboveTypeId = this.jobVarTypeEnum.JobItem;
    modalRef.componentInstance.jobItemAboveId = this.iJobItem.id;

    modalRef.result.then((updatedForm) => {
      this.getJobItemList();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  // Patch change
  patchJobItem(updatedItem: any, rereadList: boolean, resortItems: boolean, jobItemAboveTypeId: number,
    jobItemAboveId: number, fromDrop: boolean, prevIndex: number, currIndex: number) {
    this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.updateJobItem(updatedItem).subscribe({
        next: (updatedItems) => {
          this._jobItemService.waitingSpinner.next(false);
          this.updatedItems = updatedItems;
          if (rereadList) {
            this.getJobItemList();
          } else {
            this._jobItemService.updateChangedItems(this.updatedItems, null, resortItems, jobItemAboveTypeId, jobItemAboveId);
          }
        },
        error: (err) => {
          this._jobItemService.setWaitingJobItemAPI(false);
          this._jobItemService.waitingSpinner.next(false);
          this.notiService.notify(err);
          if (fromDrop) {
            this.getJobItemList();
          }
        }
      })
    );
  }

  // move an item up one spot in the list - have to take away from the orderNo!
  upItem(itm: IJobItem) {
    if (itm.orderNo > 1) {
      this.updatedItem = { id: itm.id, orderNo: itm.orderNo - 1 };
      this.patchJobItem(this.updatedItem, false, true, itm.jobItemAboveTypeId, itm.jobItemAboveId, false, 0, 0);
    }
  }

  // move an item down one spot in the list - have to add to the orderNo!
  downItem(itm: IJobItem) {
    this.updatedItem = { id: itm.id, orderNo: itm.orderNo + 1 };
    this.patchJobItem(this.updatedItem, false, true, itm.jobItemAboveTypeId, itm.jobItemAboveId, false, 0, 0);
  }

  editItem(contentSetupTags, itm: IJobItem) {
    this.currentJobItemId = itm.id;
    this.optDescription = itm.itemDescription;
    this.editIsSetupLine = itm.isSetUpLine;

    this.modalHeading = 'Edit Line';
    this.modalButton = 'Update';
    this.modalButton2 = 'Revert to Original';

    const modalRef = this.modalService.open(HeadingModalComponent, { windowClass: 'modal-edit', backdrop: 'static' });
    modalRef.componentInstance.addEditDelete = AddEditDeleteEnum.Edit;
    modalRef.componentInstance.jobitem = itm;
    modalRef.componentInstance.jobItemAboveId = itm.jobItemAboveId;
    if (this._jobItemService.selectionsMode !== 'Master') {
      modalRef.componentInstance.variationNumber = this.variationNumber;
      if (this._jobItemService.currentVariation) {
        modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
      }
    }

    modalRef.result.then((updatedItem) => {
      if (!updatedItem.cancel) {
        this.getJobItemList();
      } else {
        if (updatedItem.gotoSetupTags) {
          this.currentJobItem = itm;
          // sometimes we have null or not set
          if (!itm.setUpTags || itm.setUpTags === 'OR') {
            this.andORSetupLinks = 'OR';
          } else {
            this.andORSetupLinks = 'AND';
          }
          this.modalService.open(contentSetupTags, { size: 'lg' }).result.then((result3) => {
            this.closeResult = `Closed with: ${result3}`;
            if (itm.setUpTags !== this.andORSetupLinks) {
              this.updatedItem = { id: itm.id, setUpTags: this.andORSetupLinks };
              this.patchJobItem(this.updatedItem, false, false, null, null, false, 0, 0);
            } else {
              this.getJobItemList();
            }
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onRefreshLines(message: boolean) {
    // if (this.headingLevel !== 0 && message) {
    //   this.refreshLines.emit(true);
    // }
    this.getJobItemList();
  }

  onRefreshFromVO(message: boolean) {
    // if (message) {
    //   this.refreshLines.emit(true);
    // }
    this.getJobItemList();
  }

  addVarItem(itm, indexNo) {
    let clickedAddToEnd = false;
    if (indexNo === -1) {
      clickedAddToEnd = true;
    }
    this.calcFirstLast(); // get the first and last records for this heading if any
    if (indexNo === -1 && this.lastRecord > 0) {
      indexNo = this.lastRecord;
      itm = this._jobItemService.currentJobItems[this.lastRecord];
    }

    const modalRef = this.modalService.open(VariationAddModalComponent, { windowClass: 'modal-edit', backdrop: 'static' });
    modalRef.componentInstance.jobitem = itm;
    modalRef.componentInstance.jobItemAboveId = itm.id;
    if (indexNo === -1) {
      // modalRef.componentInstance.jobItemParentTypeId = itm.originalItemTypeId;
      // modalRef.componentInstance.jobItemAboveId = itm.id;
      if (itm.changedByVO === this.variationNumber) {
        modalRef.componentInstance.parentConnectedItemId = itm.connectedItemId;
      }
    } else {
      // modalRef.componentInstance.jobItemParentTypeId = itm.jobItemAboveTypeId;
      // modalRef.componentInstance.jobItemAboveId = itm.jobItemAboveId;
      if (this.iJobItem.changedByVO === this.variationNumber) {
        modalRef.componentInstance.parentConnectedItemId = this.iJobItem.connectedItemId;
      }
    }
    modalRef.componentInstance.variationNumber = this.variationNumber;
    if (this._jobItemService.currentVariation) {
      modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
    }
    modalRef.componentInstance.indexNo = indexNo;
    modalRef.componentInstance.firstRecord = this.firstRecord;
    modalRef.componentInstance.selectionsAdmin = this.selectionsAdmin;
    modalRef.componentInstance.variationType = this.variationType;
    modalRef.componentInstance.clickedAddToEnd = clickedAddToEnd;
    modalRef.componentInstance.headingTypeId = this.iJobItem.originalItemTypeId ? this.iJobItem.originalItemTypeId : 1;
    modalRef.componentInstance.headingItemId = this.iJobItem.id;

    modalRef.result.then((updatedItem) => {
      if (!updatedItem.cancel) {
        if (updatedItem.addAttachment || updatedItem.addAndEstimate) {
          // go straight to adding an attachment
          this.newItem = new IJobItem;
          this.newItem.id = updatedItem.addedItemId;
          this.newItem.changedByJobVarId = updatedItem.addedItemId;
          this.newItem.changedByVOId = this._jobItemService.currentVariation.id;
          this.newItem.itemTypeId = updatedItem.itemTypeId;
          this.newItem.itemDescription = updatedItem.itemDescription;
          this.newItem.selection = updatedItem.selection;
          this.newItem.price = updatedItem.price;
          this.newItem.quantity = updatedItem.quantity;
          this.newItem.costTypeId = updatedItem.costTypeId;
          this.isQtyRequired = updatedItem.isQtyRequired;
          if (updatedItem.addAttachment) {
            this.showAttachment(this.newItem);
          } else {
            this.estimatePrice();
          }
        }
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  showAttachment(jobitem) {
    // show the attachment
    if (jobitem.ItemType === this.ItemType.Note) {
      const modalRef = this.modalService.open(SelectionAttachmentModalComponent, { windowClass: 'modal-pdf' });
      modalRef.componentInstance.jobitem = jobitem;
      if (this._jobItemService.currentVariation) {
        modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
      }

      modalRef.result.then((updatedForm) => {
        this.getJobItemList();
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.getJobItemList();
      });
    } else {
      // must be an image
      const modalRef = this.modalService.open(SelectionAttachmentModalComponent, { size: 'lg' });
      modalRef.componentInstance.jobitem = jobitem;
      if (this._jobItemService.currentVariation) {
        modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;
      }

      modalRef.result.then((updatedForm) => {
        this.getJobItemList();
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        this.getJobItemList();
      });
    }
  }

  estimatePrice() {
    // here we use estimating to calc the price - EstimatingModalComponent
    const modalRef = this.modalService.open(EstimatingModalComponent,
      { windowClass: 'modal-est3', backdrop: 'static', keyboard: false, scrollable: true });
    modalRef.componentInstance.jobVarItemId = this.newItem.changedByJobVarId;
    modalRef.componentInstance.itemDescription = this.newItem.itemDescription;
    modalRef.componentInstance.selection = this.newItem.selection;
    modalRef.componentInstance.variationId = this._jobItemService.currentVariation.id;

    modalRef.result.then((result) => {
      let price = 0;
      let isChecked = this.newItem.isChecked;

      if (result && (+result)) {
        price = +result;
      } else {
        // returned a zero or null price so must be TBA so mark not checked
        isChecked = false;
      }

      if (this.isQtyRequired) {
        if (this.newItem.quantity) {
          price = this.utilsService.roundEven(price * this.newItem.quantity);
        } else {
          price = 0;
        }
      }

      if (price === 0) {
        price = null;
      }
      if (price !== this.newItem.price || isChecked !== this.newItem.isChecked) {
        this.updatedItem = {
          id: this.newItem.id,
          price: price,
          isChecked: isChecked
        };

        if (this.isQtyRequired) {
          // we need to update the JobVarCalc record
          this.updatedItem.UpdateJobVarCalcs = true;
          // backend to look up and just for new calc record so we don't pass the id's
          this.updatedItem.NewOptionListId = this.updatedItem.selectedOptionId;
          this.updatedItem.NewQuantity = this.newItem.quantity;
          this.updatedItem.NewCostTypeId = this.newItem.costTypeId;
          this.updatedItem.NewItemRate = +result;
        }

        this.subscriptions = this.subscriptions.concat(
          this._jobVarItemService.updateJobVarItem(this.updatedItem).subscribe({
            next: () => {
              this.getJobItemList();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.getJobItemList();
            }
          })
        );
      } else {
        this.getJobItemList();
      }
    }, () => {
      this.getJobItemList();
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== event.currentIndex) {
      const currentItem = this.thisHeadingItems[event.previousIndex];
      if (currentItem.changedByVOId) {
        this.notiService.showInfo('Cannot move an item that has been changed in a variation');
        // this._jobItemService.sortJobItems();
      } else {
        const element = this.thisHeadingItems[event.currentIndex];
        moveItemInArray(this.thisHeadingItems, event.previousIndex, event.currentIndex);

        this.updatedItem = { id: currentItem.id, orderNo: element.orderNo };
        this.patchJobItem(this.updatedItem, true, true, 1, this.iJobItem.id, true, event.previousIndex, event.currentIndex);
      }
    }
  }

  calcDisplayedVariationNumber(variationNumber: number) {
    return this.variationService.currentVariations.find(v => v.variationNumber === variationNumber)?.displayedVariationNumber;
  }
}
