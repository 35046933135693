<!-- modal for delete -->
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Delete Variation</h4>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form (ngSubmit)="c('Close click')" novalidate [formGroup]="deleteForm">
      <div class="row">
        <label class="form-label col-sm-2" for="varnTitleId">Title:</label>
        <input type="text" class="form-control-sm col-sm-10" id="varnTitleId" formControlName="varnTitle" readonly>
      </div>

      <dx-check-box *ngIf="isAdmin" text="Delete items (Admin only)" [rtlEnabled]="true" [(value)]="deleteLines"
        class="mt-2">
      </dx-check-box>

      <br>
      <div class="mt-4">
        <button type="submit" class="btn btn-danger">Delete</button>
      </div>
    </form>
  </div>
</ng-template>

<!-- Main HTML -->
<div class="card">
  <div class="card-header">
    <div>Variation Header</div>
    <div>{{pageTitle}}: {{currentJobNum}} - {{jobString}}</div>
  </div>

  <div class="card-body">
    <form *ngIf="!loading" class="form-horizontal dataForm" novalidate [formGroup]="varnForm">
      <fieldset>

        <div class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="varnTitleId">Variation Title<span class="required"> *</span></label>
          <dx-text-box id="varnTitleId" class="col-sm-8 px-0" formControlName="varnTitle"
            placeholder="Title (required)" required></dx-text-box>
        </div>

        <!-- if sales date null we are sales stage -->
        <div class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="variationType">Variation Type<span class="required"> *</span></label>

          <div class="form-control-sm col-sm-8">
            <dx-radio-group [items]="variationTypesFiltered" [(value)]="variationTypeId" valueExpr="id"
              displayExpr="description" layout="horizontal" class="px-0" (onValueChanged)="onTypeValueChanged($event)">
            </dx-radio-group>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="extraDescriptionId">Extra Description</label>
          <dx-text-area id="extraDescriptionId" class="col-sm-8 px-0" formControlName="extraDescription"></dx-text-area>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="authorId">Raised By</label>
          <dx-text-box id="authorId" class="col-sm-4 px-0" formControlName="author"></dx-text-box>
          <label class="col-sm-3 form-label righttext" for="additionalDaysId">Additional Days</label>
          <dx-number-box id="additionalDaysId" class="col-sm-1 px-0" formControlName="additionalDays"
            format="#" autocomplete="off"></dx-number-box>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="variationDateId">Date of Variation<span class="required"> *</span></label>
          <div class="col-sm-3 px-0 pt-0 pb-0">
            <dx-date-box id="variationDateId" formControlName="variationDate" type="date" 
            [calendarOptions]="{ showTodayButton: true }" required></dx-date-box>
          </div>
          
          <!-- Activity -->
          <label *ngIf="isConstructionActivitiesUsed" class="col-sm-2 form-label righttext"
            for="companyActivityId">Activity</label>
          <dx-select-box *ngIf="isConstructionActivitiesUsed" id="companyActivityId" class="col-sm-3 px-0" formControlName="companyActivityId"
            [dataSource]="activities" valueExpr="id" displayExpr="displayDescription" searchEnabled="true"
            [showClearButton]="true"></dx-select-box>
          <!-- <select *ngIf="isConstructionActivitiesUsed" name="companyActivityId" id="companyActivityId"
            formControlName="companyActivityId" class="form-control-sm col-sm-3">
            <option value=0 selected></option>
            <option *ngFor="let activity of activities" [value]="activity.id">
              {{activity.activityCode}} - {{activity.description}}
            </option>
          </select> -->
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="dueDateId">Due Date</label>
          <dx-date-box id="dueDateId" class="col-sm-3 px-0 pt-0 pb-0" formControlName="dueDate" type="date" 
            [showClearButton]="true" [calendarOptions]="{ showTodayButton: true }"></dx-date-box>
        </div>

        <div *ngIf="variationTypeId && variationTypeId < 10 && variationTypeId !== variationTypeEnum.Office"
          class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="adminFeeId">Admin Fee</label>
          <dx-number-box id="adminFeeId" class="col-sm-2 px-0" formControlName="adminFee"
            format="$ #,###" autocomplete="off"></dx-number-box>
        </div>

        <!-- if not adding a new variation we can change the status and status comment. -->
        <div *ngIf="variationNum !== 0" class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="statusId">Status</label>
          <div ngbDropdown class="form-control-sm statusDropdownClass col-sm-3">
            <button class="btn btn-sm btn-outline-primary" id="statusId" ngbDropdownToggle>{{selectedStatus}}</button>
            <div *ngIf="permissionLevel === 'Admin'" ngbDropdownMenu aria-labelledby="statusId">
              <button class="dropdown-item" (click)='setStatus("Open")'>Open</button>
              <button class="dropdown-item" (click)='setStatus("Estimating")'>Estimating</button>
              <button class="dropdown-item" (click)='setStatus("Pending Approval")'>Pending Approval</button>
              <button class="dropdown-item" (click)='setStatus("Cancelled")'>Cancelled</button>
              <button class="dropdown-item" (click)='setStatus("Approved")'>Approved</button>
              <button *ngIf="variations[0].variationType < 10" class="dropdown-item"
                (click)='setStatus("Actioned")'>Actioned</button>
              <button *ngIf="variations[0].variationType < 10" class="dropdown-item"
                (click)='setStatus("Invoiced")'>Invoiced</button>
            </div>
          </div>

          <!-- Assigned User -->
          <label class="col-sm-2 form-label righttext" for="assignedUserId">Estimator</label>
          <dx-select-box id="assignedUserId" class="col-sm-3 px-0" formControlName="assignedUserId"
            [dataSource]="estimators" valueExpr="userId" displayExpr="user.fullName" searchEnabled="true"
            [showClearButton]="true"></dx-select-box>
          <!-- <select name="assignedUserId" id="assignedUserId" formControlName="assignedUserId"
            class=" form-control-sm col-sm-3">
            <option value=0 selected></option>
            <option *ngFor="let user of estimators" [value]="user.userId">
              {{user.user.firstName}}
              {{user.user.lastName}}
            </option>
          </select> -->
        </div>

        <div class="form-group row">
          <label class="col-sm-3 form-label lefttext" for="statusCommentId">Comment</label>
          <dx-text-area class="col-sm-4" id="statusCommentId" type="text" formControlName="statusComment"></dx-text-area>

          <!-- is this VO to be updated by a client? -->
          <label *ngIf="customerUpdateAllowed" class="col-sm-4 form-label righttext"
            for="canBeModifiedByClient">
            <span class="lefttext">Can be modified by the client?</span>
            <dx-check-box *ngIf="customerUpdateAllowed" class="ms-2 pe-0" id="canBeModifiedByClient"
              formControlName="canBeModifiedByClient"></dx-check-box>
          </label>
        </div>

        <div class="form-group row saveRow">
          <button *ngIf="permissionWrite && variationNum === 0" class="btn btn-sm btn-primary saveButtonClass"
            [disabled]='!varnForm.valid' (click)='save(true)'>Save & Start Variation</button>

          <button *ngIf="permissionWrite" class="btn btn-sm btn-primary saveButtonClass" [disabled]='!varnForm.valid'
            (click)='save()'>Save</button>

          <button class="btn btn-sm btn-secondary saveButtonClass" (click)='back()'>Back</button>

          <button
            *ngIf="permissionWrite && variationNum !== 0 && variations && variations[0]?.statusId < variationStatus.PendingApproval"
            class="btn btn-sm btn-danger saveButtonClass" (click)='delete(content)'>Delete</button>

          <button
            *ngIf="variationSplitsEnabled && permissionLevel === 'Admin' && variations && variations[0]?.variationType < 10"
            class="btn btn-sm btn-secondary saveButtonClass" (click)='invoiceSplits()'>Invoice Splits</button>

          <button *ngIf="variationNum !== 0 && variations" class="btn btn-sm btn-secondary saveButtonClass"
            (click)='gotoSelections()'>Go to details</button>

          <div *ngIf='updateSuccessful && updateRun' class="updatemessage">Updated Successfully</div>
        </div>
      </fieldset>
    </form>

    <!-- Loading Spinner -->
    <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  </div>

</div>
