import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { TreeModule } from '@circlon/angular-tree-component';
import { DxDropDownButtonModule } from 'devextreme-angular';

import {
  BrowserCacheLocation, InteractionType,
  IPublicClientApplication, LogLevel, PublicClientApplication
} from '@azure/msal-browser';
import {
  MsalModule, MsalInterceptor,
  MsalInterceptorConfiguration, MsalGuardConfiguration,
  MSAL_INSTANCE, MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG, MsalService,
  MsalBroadcastService, MsalRedirectComponent, MsalGuard
} from '@azure/msal-angular';

import { environment } from '../environments/environment';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { OptionListsModule } from './option-lists/option-lists.module';
import { SelectionsModule } from './selections/selections.module';
import { JobsModule } from './jobs/jobs.module';
import { VariationsModule } from './variations/variations.module';
import { LoginModule } from './login/login.module';
import { JobDocumentsModule } from './job-documents/job-documents.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SalesTasksModule } from './sales-tasks/sales-tasks.module';
import { InvoicesModule } from './invoices/invoices.module';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ReportsComponent } from './reports/reports.component';

import { AcceptIntoContractComponent } from './accept-into-contract/accept-into-contract.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { JobMarginComponent } from './reports/job-margin/job-margin.component';
import { ReportActiveCampaignComponent } from './reports/report-active-campaign/report-active-campaign.component';
import { ReportHubSpotComponent } from './reports/report-hubspot/report-hubspot.component';
import { LoanShortfallComponent } from './reports/loan-shortfall/loan-shortfall.component';
import { JobDocumentsChangedComponent } from './reports/job-documents-changed/job-documents-changed.component';
import { AllEstimateItemsComponent } from './reports/all-estimate-items/all-estimate-items.component';
import { CostCentreSummaryComponent } from './reports/cost-centre-summary/cost-centre-summary.component';
import { SalesUpsDownsComponent } from './reports/sales-ups-downs/sales-ups-downs.component';
import { VariationReportComponent } from './reports/variation-report/variation-report.component';
import { MarginSaveToRecipeComponent } from './reports/job-margin/margin-save-to-recipe/margin-save-to-recipe.component';
import { CallUpsComponent } from './call-ups/call-ups.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  if (logLevel === LogLevel.Info) return;
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: environment.applicationUrl + 'auth',
      postLogoutRedirectUri: '/',
      knownAuthorities: [environment.authoryDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning, // environment.production ? LogLevel.Warning : LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apiUrl, environment.consentScopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.consentScopes],
    },
    loginFailedRoute: '/login'
  };
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    ReportsComponent,
    AcceptIntoContractComponent,
    JobMarginComponent,
    ReportActiveCampaignComponent,
    ReportHubSpotComponent,
    LoanShortfallComponent,
    JobDocumentsChangedComponent,
    AllEstimateItemsComponent,
    CostCentreSummaryComponent,
    SalesUpsDownsComponent,
    VariationReportComponent,
    MarginSaveToRecipeComponent,
    CallUpsComponent
  ],
  imports: [
    // ng
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgbCollapseModule,
    NgbModule,
    DxDropDownButtonModule,
    // app
    AppRoutingModule,
    SelectionsModule,
    SharedModule,
    OptionListsModule,
    JobDocumentsModule,
    JobsModule,
    VariationsModule,
    LoginModule,
    InvoicesModule,
    DashboardModule,
    SalesTasksModule,
    // package
    TreeModule,
    ToastrModule.forRoot(),
    MsalModule
  ],
  providers: [
    DeviceDetectorService,
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
