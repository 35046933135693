import { Component, EventEmitter, Input, OnInit, Output, OnChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { ChangeTypeEnum } from '../dtos/change-type.enum';
import { ItemTypeEnum } from '../dtos/item-type.enum';
import { SelectionTypeEnum } from '../dtos/selection-type.enum';
import { JobVarTypeEnum } from '../dtos/job-var-type.enum';
import { IJobItem } from '../dtos/job-item';
import { IOptionListHeader } from '../dtos/option-list-header';
import { ShowPdfComponent } from '../shared/show-pdf.component';
import { SelectionAttachmentModalComponent } from './selection-modals/selection-attachment-modal.component';

import { JobItemService } from '../services/felixApi/job-item.service';
import { OptionListService } from '../services/felixApi/option-list.service';
import { GlobalService } from '../services/global.service';
import { OptionColourEnum } from '../dtos/option-colour.enum';
import { AttachmentTypeEnum } from '../dtos/attachment-type.enum';
import { CostTypeEnum } from '../dtos/cost-type.enum';
import { VariationTypeEnum } from '../dtos/variation-type.enum';
import { AuthService } from '../services/auth.service';
import { CommentModalComponent } from './selection-modals/comment-modal.component';
import { JobItemCommentTypeEnum } from '../dtos/comment-type.enum';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { PHONE_SIZE } from '../../config/variables';
import { VariationService } from '../services/felixApi/variation.service';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { CloseDropdownService } from '../services/close-dropdown.service';
import { ItemSetupModalComponent } from './selection-modals/item-setup-modal/item-setup-modal.component';
import { BackgroundColourEnum } from '../dtos/background-colour.enum';


@Component({
  selector: 'js-selections',
  templateUrl: './selections.component.html',
  styleUrls: ['./selections.component.scss']
})
export class SelectionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showAddEditButtons: boolean; // to show/hide edit.
  @Input() iJobItem: IJobItem;
  @Input() variationNumber: number;
  @Input() headingLevel: number;
  @Input() showImages: boolean;
  @Input() jobLineSetupAdmin: boolean;
  @Input() selectionsAdmin: boolean;
  @Input() headingIsSetUpLine: boolean;
  @Input() dragOn: boolean;

  // event to emit a 'refresh' command to the parent component
  @Output() refreshLines: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('selectionDrop') selectionDrop: NgbDropdown;

  COMPONENT_NAME = 'selections';
  // PHONE_SIZE = 660;
  // SHOW_QTY_AT_SIZE = 700;
  ITEM_DESC_PERC = 0.35; // the % of what is left after deducting the buttons and price etc fields
  PRICE_WIDTH = 90;
  QTY_WIDTH = 60;
  BUTTONS_WIDTH = 150; // 170
  BUTTONS_WIDTH_SMALL = 4;
  ISHIDDEN_MESSAGE_WIDTH = 40; // if we show hidden items we need room for the message
  IS_CHECKED_WIDTH = 20;
  CONTAINER_BORDER = 19; // the deduction required due to the accordian panels
  SCROLL_ADJUSTMENT = 17;
  subscriptions: Subscription[] = [];

  colours = [
    { name: 'Standard', id: 0 },
    { name: 'Red', id: 1 },
    { name: 'Green', id: 2 }
  ];

  costTypeDropdown = [
    { name: 'Standard', id: 0 },
    { name: 'Note', id: 1 },
    { name: 'Provisional Sum', id: 4 },
    { name: 'By Owner', id: 6 },
    { name: 'Included', id: 7 },
    { name: 'Excluded', id: 10 }
  ];

  descWidthDeduct: number; // to help style the description
  optionColourEnum = OptionColourEnum; // import the colour enum for the HTML
  updatedItems: IJobItem[] = [];

  innerWidth: number; // the actual width of the users window
  phoneSize: boolean; // we calculate if the user is on a small device and show the images differently
  errorMessage: any;
  jobitem: IJobItem;
  selectedValue: string;
  optionList: IOptionListHeader[] = [];
  optionListTmp: IOptionListHeader[] = [];
  updatedItem: any;
  testUpdatedItem: boolean;
  optDescription = '';
  optDescription2 = '';
  optItemTypeId: number;
  optionListId = 0;
  editIsSetupLine = false;
  selectionTypeId: number;
  modalHeading = '';
  modalButton = '';
  closeResult = '';
  optSelection = '';
  optionColour: number;
  isBoldText: boolean;
  optNoteColour: number;
  optNoteColourName: string;
  optIsBoldNote: boolean;
  isDoNotPrint = false;
  optIsItalicNote: boolean;
  optSelectionCheckbox: boolean;
  canEnterManualSelection = true;
  loading = false; // loading spinner
  changeTypeEnum = ChangeTypeEnum;

  treeOptionNodes: any[] = [];
  treeOptionNodesSetup = false;
  treeOptionOptions = {};
  treeJobItemNodes: any[] = [];
  treeJobItemNodesSetup = false;
  treeJobItemOptions = {};

  // when adding or editing a detail line we need to ask if we are going to link to another line
  detailOptionType: string;
  linkedJobItemId: number;
  ItemType = ItemTypeEnum;
  SelectionType = SelectionTypeEnum;
  setupLinkedJobItemId: number; // for linking for setup to show/hide
  setupValue: string;
  setupValueOrig: string; // holding the original value to check on update
  warningNote = ''; // modal will use this to display any warning notes from an option selected
  isOriginalSelectionInOptionList = true;

  // form for entering a manual selection in a dropdown
  manualSelection = '';
  jobVarTypeEnum = JobVarTypeEnum;

  attachmentTypeEnum = AttachmentTypeEnum;

  // set up tags
  currentJobItemId: number;
  currentJobItem: IJobItem;
  andORSetupLinks: string; // tells us if we ALL or only one condition need to be true to show the line

  selectionWidth: number; // calculated width of the selection area - adjusted if a PS amount exists
  itemDescWidth: number; // calculate the width of the itemDescription field. - depends if buttons are showing
  buttonsWidth: number; // width of the buttons.
  quantityWidth: number; // the % width of the qty field if the item has one.
  provisionalSum: number;
  quantity: number; // the quantity as per IsQuantity required on the option.
  isQtyRequired = false; // is a qty required - set by the selected option parent
  costTypeEnum = CostTypeEnum;
  variationTypeEnum = VariationTypeEnum;
  variationTypeChar: string;
  jobItemSetupLinesOnlyPerm: string; // permission to update job set up lines
  jobLineSetupWrite = false; // write perm for job items

  waitingAPI = true; // the service will tell us if a previous joitem API is waiting to complete

  isAssociate = false; // is this user an associate or trade
  isClient = false; // is this user a client

  jobItemCommentTypeEnum = JobItemCommentTypeEnum;
  displayQuantity: number; // if null we display 0
  itemDescriptionAndSeletionWidth: number;
  costTypeId: number;
  costTypeDesc: string;
  showPriceSpace: boolean;
  isPrintedDescDifferent = false; // option list items may have a different printed desc
  canSeeAmounts: boolean;
  showHistoryAsUnDeletedItem: boolean;
  isEstimatingAttachmentsExist: boolean;
  refreshImage = false; // used to force refresh of images on change
  backgroundColourEnum = BackgroundColourEnum;
  isJobLocked: boolean;

  constructor(public _jobItemService: JobItemService,
    private _optionListService: OptionListService,
    private _globalService: GlobalService,
    private modalService: NgbModal,
    private notiService: NotificationService,
    private variationService: VariationService,
    private _estimatingService: EstimatingService,
    private closeDropdownService: CloseDropdownService,
    public _authService: AuthService) { }

  ngOnInit(): void {
    this.subscribeToWidthChanges();
    this.subscribeToCloseDropdown();

    this._globalService.setCurrentJobItemAbove(this.iJobItem.id);
    if (this.iJobItem.quantity && this.iJobItem.quantity !== 0) {
      this.iJobItem.isQtyRequired = true;
    }
    this.jobitem = this.iJobItem;

    this.isAssociate = this._authService.isAssociate();
    this.isClient = this._authService.isClient();
    this.canSeeAmounts = this._authService.canSeeAmountsPermission;

    const permissionJobLineSetup = this._authService.getSelectionsPermissions('JobLineSetup');
    if (permissionJobLineSetup === 'Admin' || permissionJobLineSetup === 'Write'
      || this._authService.isAdminOrSuperUser()) {
      this.jobLineSetupWrite = true;
    }

    if (!this.jobLineSetupWrite && this.showAddEditButtons && this.variationNumber) {
      this.showAddEditButtons = false;
    }

    this.jobItemSetupLinesOnlyPerm = this._authService.getSelectionsPermissions('JobItemSetupLinesOnly');

    // subscribe to the service to see if we are waiting on another API call to complete
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.waitingJobItemAPI$.subscribe(
        waitingAPI => this.waitingAPI = waitingAPI
      )
    );

    this.setColour(this.iJobItem.noteColour);
    this.optIsBoldNote = this.iJobItem.isBoldNote;
    this.optIsItalicNote = this.iJobItem.isItalicNote;
    this.isDoNotPrint = this.iJobItem.isDoNotPrint;

    if (this.iJobItem.changedByVOId && this._jobItemService.showHistoryOfChanges) {
      // for items that were previously hidden we need to find the details
      if (!this.iJobItem.isHistoryRecord
        && this.iJobItem.changeTypeId === this.changeTypeEnum.Change
        && this.iJobItem.originalItemTypeId === this.jobVarTypeEnum.JobItem) {
        // find if we have a history record that is marked isDeleted as we need to show this item as added
        this.showHistoryAsUnDeletedItem =
          this._jobItemService.isDeletedJobItemFromCurrentList(this.iJobItem.id, this.iJobItem.changedByVOId);
      }
      // get the displayed variation number
      this.subscriptions = this.subscriptions.concat(
        this.variationService.getVariationFromCurrentList(this.iJobItem.changedByVOId)
          .subscribe(
            variation => {
              this.variationTypeChar = variation.displayedVariationNumber;
            })
      );
    }
    this.calcIsRestrictedForm(); // recalc in case PS value changed - when init we still need this
  }

  ngOnChanges() {
    // console.log('on-changes fired');
    if (this.iJobItem.quantity && this.iJobItem.quantity !== 0) {
      this.iJobItem.isQtyRequired = true;
    }
    this.jobitem = this.iJobItem;
    this.isJobLocked = this._jobItemService.isLocked;

    if (this.jobitem.printedDescription && this.jobitem.printedDescription !== ''
      && this.jobitem.printedDescription !== this.jobitem.selection) {
      if ((this._authService.isAssociate() || this._authService.isClient())) {
        this.jobitem.selection = this.jobitem.printedDescription;
        this.isPrintedDescDifferent = false;
      } else {
        this.isPrintedDescDifferent = true;
      }
    }

    this.calcIsRestrictedForm(); // recalc in case PS value changed

    this.doWeHaveEstimatingAttachments();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  subscribeToWidthChanges() {
    this.subscriptions = this.subscriptions.concat(
      this._globalService.selectonsInnerWidthChanged.subscribe(width => {
        this.calcIsRestrictedForm();
      })
    );
  }

  subscribeToCloseDropdown() {
    this.subscriptions = this.subscriptions.concat(
      this.closeDropdownService.closeDropdown.subscribe((itemId) => {
        if (itemId !== this.jobitem.id && this.selectionDrop) {
          this.selectionDrop.close();
        }
      })
    );
  }

  calcSelectionWidth() {
    if (this.headingLevel > 6) {
      this.descWidthDeduct = 60;
    } else {
      this.descWidthDeduct = (this.headingLevel * 10) - this.headingLevel;
    }

    // calc the buttons width
    if ((this.showAddEditButtons || !this._jobItemService.isLocked) && !this.phoneSize) {
      this.buttonsWidth = this.BUTTONS_WIDTH;
    } else {
      this.buttonsWidth = this.BUTTONS_WIDTH_SMALL;

      if (!this.isClient && !this.isAssociate) {
        this.buttonsWidth += 25; // for comments button & flag button
      }

      if (!this.phoneSize && this.selectionsAdmin) {
        this.buttonsWidth += 27; // for estimating attachments indicator button
      }
    }

    if (this._jobItemService.showHistoryOfChanges) {
      this.buttonsWidth += 55; // for history notes
    }

    if (this._jobItemService.showHiddenLines) {
      this.buttonsWidth += this.ISHIDDEN_MESSAGE_WIDTH;
    }

    if (!this.showAddEditButtons && this.iJobItem.attachmentId && this.iJobItem.attachmentTypeId === this.attachmentTypeEnum.PDF) {
      this.buttonsWidth += 22; // room for large paperclip
    }

    this.itemDescriptionAndSeletionWidth = this.innerWidth - this.CONTAINER_BORDER;

    if (this.dragOn) {
      this.itemDescriptionAndSeletionWidth -= 8;

      if (this.showImages) {
        this.itemDescriptionAndSeletionWidth -= 12; // in case we a scroll appears after the widths are calculated
      }
    }

    if (!this.iJobItem.itemDescription || this.iJobItem.itemDescription === '') {
      this.itemDescWidth = 0;
      this.selectionWidth = this.itemDescriptionAndSeletionWidth - this.descWidthDeduct - this.buttonsWidth;
    } else {
      this.itemDescWidth = (this.itemDescriptionAndSeletionWidth * this.ITEM_DESC_PERC) - this.descWidthDeduct;
      this.selectionWidth = this.itemDescriptionAndSeletionWidth - this.itemDescWidth - this.buttonsWidth - this.descWidthDeduct;
    }

    if (this.iJobItem.isQtyRequired && !this.phoneSize) {    // room for the qty but hide if width too small
      if (!this.iJobItem.quantity) {
        this.displayQuantity = 0;
      } else {
        this.displayQuantity = this.iJobItem.quantity;
      }

      // we need to check if we are hiding the qty
      if (this.iJobItem.optionListId && (this.isClient || this.isAssociate)) {
        const optionList = this._jobItemService.getOptionListItemById(this.iJobItem.optionListId);
        if (optionList && optionList.hideQuantity) {
          this.quantityWidth = 0;
        } else {
          this.quantityWidth = this.QTY_WIDTH;
        }
      } else {
        this.quantityWidth = this.QTY_WIDTH;
      }
      this.selectionWidth -= this.quantityWidth; // also take away space for PS amount so they line up

      // do we need the price section
      if (!this.isAssociate
        && (this.iJobItem.provisionalSum || this.iJobItem.costTypeId === this.costTypeEnum.PSFinalised)) {
        this.selectionWidth -= this.PRICE_WIDTH;
        this.showPriceSpace = false;
      } else if (this.iJobItem.costTypeId === this.costTypeEnum.ByOwner
        || this.iJobItem.costTypeId === this.costTypeEnum.Excluded
        || this.iJobItem.costTypeId === this.costTypeEnum.Note) {
        this.showPriceSpace = false;
      } else {
        this.selectionWidth -= this.PRICE_WIDTH;
        this.showPriceSpace = true;
      }
    } else {
      this.quantityWidth = 0;

      // do we need the price section
      if (!this.phoneSize && !this.isAssociate
        && (this.iJobItem.provisionalSum || this.iJobItem.costTypeId === this.costTypeEnum.PSFinalised)) {
        this.selectionWidth -= this.PRICE_WIDTH;
        this.showPriceSpace = false;
      } else if (this.iJobItem.costTypeId === this.costTypeEnum.ByOwner
        || this.iJobItem.costTypeId === this.costTypeEnum.Excluded) {
        this.selectionWidth -= this.PRICE_WIDTH;
        this.showPriceSpace = false;
      } else if (this.iJobItem.isQtyRequired) {
        this.selectionWidth -= this.PRICE_WIDTH;
        this.showPriceSpace = true;
      } else {
        this.showPriceSpace = false;
      }
    }

    if (this.iJobItem.itemDescription === null || this.iJobItem.itemDescription === '') {
      this.selectionWidth += this.itemDescWidth;
    }
  }

  calcIsRestrictedForm() {
    // store whether we are readOnly or not
    this.innerWidth = this._globalService.selectonsInnerWidth;

    // if this item has been changed in a VO then we have to make read only here.
    // - this happens when we have sales or pre-cont VO's and the job is not locked
    if ((this.iJobItem.changedByJobVarId && this.iJobItem.changedByJobVarId !== 0)) {
      this.showAddEditButtons = false;
    } else {
      // we need to check if any linked 'child' items are changed to also make this read only
      if (this.iJobItem.hasLinkedItems) {
        this._jobItemService.currentJobItems.forEach(item => {
          if (item.linkedJobItemId === this.iJobItem.id && item.changedByJobVarId && item.changedByJobVarId !== 0) {
            this.showAddEditButtons = false;
          }
        });
      }
    }

    if (this.innerWidth < PHONE_SIZE) {
      this.phoneSize = true;
    } else {
      this.phoneSize = false;
    }
    // calc width of selection area - if a PS amount exists then we need room for it
    this.calcSelectionWidth();
  }

  getJobItemList() {
    this.refreshLines.next(true);
  }

  // if dropdown opened then get the data
  toggled(event, jitem) {
    if (event) {
      this.closeDropdownService.setCloseDropdown(this.jobitem.id);
      this.getOptionListForDropdown(jitem);
    }
  }

  getOptionListForDropdown(jitem) {
    this.treeOptionNodes = this._optionListService.getCurrentOptionNodes();
    if (!this.treeOptionNodes || !this.treeOptionNodes.length) {
      this._optionListService.resetCurrentOptionNodes(); // ensure we get a new list of options if we need them
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, false)
          .subscribe({
            next: (treeOptionNodes) => {
              this.treeOptionNodes = treeOptionNodes;
              this._optionListService.setCurrentOptionNodes(treeOptionNodes);
              this.getOptionListForDropdown2(jitem);
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    } else {
      this.getOptionListForDropdown2(jitem);
    }
  }

  getOptionListForDropdown2(jitem) {
    this.isQtyRequired = false;
    if (jitem.optionListId === null) {
      this.optionList = [new IOptionListHeader('Please select previous option first...', 0, null)];
      if (jitem.canEnterManualSelection) {
        this.optionList = this.optionList.concat(new IOptionListHeader('Other - Click here', 999, null));
        if (jitem.selection === '') {
          this.isOriginalSelectionInOptionList = true;
          this.manualSelection = '';
        } else {
          this.manualSelection = jitem.selection; // GH was changed to '' 16-4-19 but changed back 8-9-19
          this.isOriginalSelectionInOptionList = false;
        }
      }
    } else {
      this.optionList = [new IOptionListHeader('Please Select...', 0, null)];
      this.optionListTmp = this._jobItemService.getOptionListItems(jitem.optionListId);
      this.optionList = this.optionList.concat(this.optionListTmp);

      // we have also set the optionListParentNode in the service
      this.isQtyRequired = this._jobItemService.optionListParentNode.isQtyRequired;
      if (this._jobItemService.optionListParentNode
        && this._jobItemService.optionListParentNode.childSelectionWarning
        && this._jobItemService.optionListParentNode.childSelectionWarning.trim() !== '') {
        this.notiService.showInfo(this._jobItemService.optionListParentNode.childSelectionWarning);
      }

      if (jitem.canEnterManualSelection) {
        this.optionList = this.optionList.concat(new IOptionListHeader('Other - Click here', 999, null));
        if (jitem.selection === '') {
          this.isOriginalSelectionInOptionList = true;
          this.manualSelection = '';
        } else {
          this.manualSelection = jitem.selection; // GH was changed to '' 16-4-19 but changed back 8-9-19
          this.optSelection = 'Other - Click here';
          this.isOriginalSelectionInOptionList = false;
          for (let i = 0; i < this.optionListTmp.length; i++) {
            if (this.iJobItem.selection === this.optionListTmp[i].description) {
              this.isOriginalSelectionInOptionList = true;
              this.optSelection = jitem.selection;
            }
          }
        }
      }
    }
  }

  // Patch change
  patchJobItem(updatedItem: any, rereadList: boolean, resortItems: boolean,
    resortItemParentTypeId: number, resortItemParentId: number, showError: boolean = true) {
    this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending

    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.updateJobItem(updatedItem).subscribe({
        next: (updatedItems) => {
          this.updatedItems = updatedItems;
          if (rereadList) {
            this.getJobItemList();
          } else {
            // we are now returning the changed items so we just update list
            // - comment out the above and uncomment out the one below when ready to test
            this._jobItemService.updateChangedItems(this.updatedItems, null, resortItems, resortItemParentTypeId, resortItemParentId);
            if (updatedItem.quantity) {
              this.iJobItem.quantity = updatedItem.quantity;
            }
            if (updatedItem.provisionalSum) {
              this.iJobItem.provisionalSum = updatedItem.provisionalSum;
            }
            this.calcIsRestrictedForm(); // recalc in case PS value changed
          }
        },
        error: (err) => {
          if (showError) {
            this.notiService.notify(err, null, true);
          }
          this.getJobItemList();
        }
      })
    );
  }

  // move an item up one spot in the list - have to take away from the orderNo!
  upItem(itm: IJobItem) {
    if (itm.orderNo > 1) {
      this.updatedItem = { id: itm.id, orderNo: itm.orderNo - 1 };
      this.patchJobItem(this.updatedItem, false, true, itm.jobItemAboveTypeId, itm.jobItemAboveId, false);
    }
  }

  // move an item down one spot in the list - have to add to the orderNo!
  downItem(itm: IJobItem) {
    this.updatedItem = { id: itm.id, orderNo: itm.orderNo + 1 };
    this.patchJobItem(this.updatedItem, false, true, itm.jobItemAboveTypeId, itm.jobItemAboveId, false);
  }

  editItem(content, contentSetupTags, itm: IJobItem, fromDropDown: boolean) {
    if (!this.waitingAPI) {
      if (itm.itemTypeId === ItemTypeEnum.Detail && itm.selectionTypeId === SelectionTypeEnum.Dropdown) {
        this.emitCloseDropdown();
      }
      this.currentJobItemId = itm.id;
      this.optDescription = itm.itemDescription;
      this.selectionTypeId = itm.selectionTypeId;
      this.optionListId = itm.optionListId;

      if (fromDropDown) {
        this.optionColour = this.optionColourEnum.Standard;
        this.optSelection = 'Other - Click here';
      } else if (itm.selectionTypeId === this.SelectionType.Dropdown && (itm.selection === null || itm.selection === '') && !fromDropDown) {
        this.optSelection = 'Please Select...';
        this.optionColour = this.optionColourEnum.Red;
      } else {
        this.optSelection = itm.selection;
        this.optionColour = itm.optionColour;
      }

      this.optItemTypeId = itm.itemTypeId;
      this.linkedJobItemId = itm.linkedJobItemId;
      this.editIsSetupLine = itm.isSetUpLine;
      this.canEnterManualSelection = itm.canEnterManualSelection;
      this.isBoldText = itm.isBoldText;
      this.detailOptionType = '';
      this.andORSetupLinks = itm.setUpTags;

      this.optNoteColour = itm.noteColour;
      this.optIsBoldNote = itm.isBoldNote;
      this.optIsItalicNote = itm.isItalicNote;
      this.isDoNotPrint = itm.isDoNotPrint;
      this.provisionalSum = itm.provisionalSum;
      this.quantity = itm.quantity;
      if (this.quantity) {
        if (this.provisionalSum) {
          this.provisionalSum = this.provisionalSum / this.quantity;
        }
      }
      this.costTypeId = !itm.costTypeId ? 0 : itm.costTypeId;
      this.setCostType(this.costTypeId);

      if (this.optItemTypeId === this.ItemType.Detail) {
        if (this.selectionTypeId === this.SelectionType.Dropdown) {
          if (this.linkedJobItemId) {
            this.detailOptionType = 'Link to another line';
          } else {
            this.detailOptionType = 'Use an Option List';
          }
          this.getOptionListForDropdown(itm);
        } else {
          this.detailOptionType = 'Checkbox';
          if (itm.selection === 'Yes') {
            this.optSelectionCheckbox = true;
          } else {
            this.optSelectionCheckbox = false;
          }
        }
      }

      this.modalHeading = 'Edit Line';
      this.modalButton = 'Update';

      if (fromDropDown) {
        this.optSelection = 'Other - Click here';
        this.isOriginalSelectionInOptionList = false;
        this.manualSelection = this.jobitem.selection;
      }

      this.modalService.open(content, { size: 'lg', backdrop: 'static' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        if (this.closeResult.substr(0, 18) === 'Closed with: Setup') {

          const modalRef = this.modalService.open(ItemSetupModalComponent,
            { windowClass: 'modal-edit2', backdrop: 'static' });
          modalRef.componentInstance.jobItem = itm;
          modalRef.componentInstance.jobLineSetupAdmin = this.jobLineSetupAdmin;

          modalRef.result.then((returnData) => {
            this.editIsSetupLine = returnData.isSetupLine;
            this.canEnterManualSelection = returnData.canEnterManualSelection;
            this.detailOptionType = returnData.detailOptionType;
            if (this.optionListId !== returnData.optionListId) {
              this.optionListId = returnData.optionListId;
              this.optSelection = '';
            }
            this.linkedJobItemId = returnData.linkedJobItemId;

            if (returnData.setupTags) {
              this.currentJobItem = itm;

              if (!itm.setUpTags || itm.setUpTags === 'OR') {
                this.andORSetupLinks = 'OR';
              } else {
                this.andORSetupLinks = 'AND';
              }
              this.modalService.open(contentSetupTags, { windowClass: 'modal-edit2', backdrop: 'static' }).result.then((result3) => {
                this.closeResult = `Closed with: ${result3}`;
                this.updateAfterModal(itm, true, false);
              }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              });
            } else {
              this.updateAfterModal(itm, false, false);
            }
          }, () => {
          });
        } else {
          if (this.closeResult.substr(0, 23) === 'Closed with: AttachFile') {
            // add a file clicked
            this.updateAfterModal(itm, false, true);
            this.showAttachment(itm);
          } else {
            this.updateAfterModal(itm, false, false);
          }
        }
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  emitCloseDropdown() {
    this.selectionDrop.close();
    this.closeDropdownService.setCloseDropdown(this.jobitem.id);
  }

  updateAfterModal(itm: IJobItem, rereadList: boolean, gotoAttachment: boolean) {
    if (this.detailOptionType === 'Use an Option List') {
      this.linkedJobItemId = null;
      this.selectionTypeId = this.SelectionType.Dropdown;
    } else if (this.detailOptionType === 'Link to another line') {
      this.selectionTypeId = this.SelectionType.Dropdown;
    } else if (this.detailOptionType === 'Checkbox') {
      this.optionListId = null;
      this.linkedJobItemId = null;
      this.selectionTypeId = this.SelectionType.Checkbox;
      if (this.optSelectionCheckbox) {
        this.optSelection = 'Yes';
      } else {
        this.optSelection = '';
      }
    }

    // set to null if zero to keep database consistant.
    let provSum = this.provisionalSum;
    if (provSum === 0) {
      provSum = null;
    }
    if (this.quantity === 0) {
      this.quantity = null;
    }

    // round qty to max 4 decimal places
    if (this.quantity) {
      this.quantity = +(this.quantity.toFixed(4));
      if (provSum) {
        provSum = provSum * this.quantity;
      }
    }

    this.updatedItem = { id: itm.id };
    this.testUpdatedItem = false;
    if (itm.itemDescription !== this.optDescription) {
      this.updatedItem.itemDescription = this.optDescription;
      this.testUpdatedItem = true;
      rereadList = true;
      this.iJobItem.itemDescription = this.optDescription;
    }
    if (itm.selectionTypeId !== this.selectionTypeId) {
      this.updatedItem.selectionTypeId = this.selectionTypeId;
      rereadList = true;
      this.testUpdatedItem = true;
    }
    if (itm.selectionTypeId === this.SelectionType.Dropdown
      && (this.optSelection === 'Please Select...' || this.optSelection === 'Please select previous option first...')) {
      this.optSelection = '';
    } else if (itm.selectionTypeId === this.SelectionType.Dropdown && this.optSelection === 'Other - Click here') {
      this.optSelection = this.manualSelection;
    }

    if (itm.selection !== this.optSelection) {
      this.updatedItem.selection = this.optSelection;
      rereadList = true;
      this.testUpdatedItem = true;
    }
    if (itm.optionListId !== this.optionListId) {
      this.updatedItem.optionListId = this.optionListId;
      this.testUpdatedItem = true;
    }
    if (itm.linkedJobItemId !== this.linkedJobItemId) {
      this.updatedItem.linkedJobItemId = this.linkedJobItemId;
      this.testUpdatedItem = true;
    }
    if (itm.isSetUpLine !== this.editIsSetupLine) {
      this.updatedItem.isSetUpLine = this.editIsSetupLine;
      this.jobitem.isSetUpLine = this.editIsSetupLine;
      this.testUpdatedItem = true;
    }
    if (itm.canEnterManualSelection !== this.canEnterManualSelection) {
      this.updatedItem.canEnterManualSelection = this.canEnterManualSelection;
      this.jobitem.canEnterManualSelection = this.canEnterManualSelection;
      this.testUpdatedItem = true;
    }
    if (itm.setUpTags !== this.andORSetupLinks) {
      this.updatedItem.setUpTags = this.andORSetupLinks;
      rereadList = true;
      this.testUpdatedItem = true;
    }
    if (itm.noteColour !== this.optNoteColour) {
      this.updatedItem.noteColour = this.optNoteColour;
      this.jobitem.noteColour = this.optNoteColour;
      this.testUpdatedItem = true;
    }
    if (itm.isBoldNote !== this.optIsBoldNote) {
      this.updatedItem.isBoldNote = this.optIsBoldNote;
      this.jobitem.isBoldNote = this.optIsBoldNote;
      this.testUpdatedItem = true;
    }
    if (itm.isItalicNote !== this.optIsItalicNote) {
      this.updatedItem.isItalicNote = this.optIsItalicNote;
      this.jobitem.isItalicNote = this.optIsItalicNote;
      this.testUpdatedItem = true;
    }
    if (itm.provisionalSum !== provSum) {
      this.updatedItem.provisionalSum = provSum;
      this.jobitem.provisionalSum = provSum;
      rereadList = true;
      this.testUpdatedItem = true;
    }
    if (itm.isDoNotPrint !== this.isDoNotPrint) {
      this.updatedItem.isDoNotPrint = this.isDoNotPrint;
      this.jobitem.isDoNotPrint = this.isDoNotPrint;
      this.testUpdatedItem = true;
    }
    if (itm.quantity !== this.quantity) {
      this.updatedItem.quantity = this.quantity;
      this.jobitem.quantity = this.quantity;
      this.testUpdatedItem = true;
    }
    if (itm.costTypeId !== this.costTypeId) {
      this.updatedItem.costTypeId = this.costTypeId;
      this.testUpdatedItem = true;
    }

    this.calcIsRestrictedForm(); // recalc the width of the selection area
    if (this.testUpdatedItem) {
      if (gotoAttachment) {
        this.patchJobItem(this.updatedItem, false, false, null, null); // we always refresh when coming back from the attachment (for now)
      } else {
        this.patchJobItem(this.updatedItem, rereadList, false, null, null);
      }
    } else if (!gotoAttachment) {
      this.getJobItemList(); // reread list in case we have changed set up links
    }
  }

  deleteItem(content, itm: IJobItem) {
    if (!this.waitingAPI) {
      if (itm.attachmentId) {
        this.notiService.showWarning('Cannot delete an item with an attachment');
      } else {
        this.optDescription = itm.itemDescription;
        this.modalHeading = 'Delete Line';
        this.modalButton = 'Delete';
        this.optItemTypeId = itm.itemTypeId;
        this.optSelection = itm.selection;

        this.modalService.open(content).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;

          this._jobItemService.setWaitingJobItemAPI(true); // tell the service to tell all other components that an API is pending

          this.subscriptions = this.subscriptions.concat(
            this._jobItemService.deleteJobItem(itm.id).subscribe({
              next: () => {
                this._jobItemService.setWaitingJobItemAPI(false);
                this.getJobItemList();
              },
              error: (err) => {
                this.notiService.notify(err);
                this._jobItemService.setWaitingJobItemAPI(false);
              }
            })
          );
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    }
  }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes() {
    this.treeJobItemNodesSetup = false;
    this.treeJobItemOptions = {
      idField: 'id',
      displayField: 'itemDescription',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22
    };

    // just read the items from the in-memory list
    // - we want only the items under the same heading otherwise the variation options code wont work.
    this.treeJobItemNodes = [];
    this._jobItemService.currentJobItems.forEach(element => {
      if (element.jobItemAboveTypeId === this.iJobItem.jobItemAboveTypeId &&
        element.jobItemAboveId === this.iJobItem.jobItemAboveId &&
        (element.originalItemTypeId !== this.iJobItem.originalItemTypeId ||
          element.id !== this.iJobItem.id) &&
        element.orderNo <= this.iJobItem.orderNo) {
        this.treeJobItemNodes = this.treeJobItemNodes.concat(element);
      }
    });
    this.treeJobItemNodesSetup = true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  setSelection(itm: IJobItem, opt) {
    this.emitCloseDropdown();
    if (itm.attachmentId) {
      this.notiService.showWarning('There is a manual image/attachment for this item that will have to be changed manually');
    }
    if (opt.description === 'Please Select...' || opt.description === 'Please select previous option first...') {
      this.optDescription2 = '';
    } else {
      this.optDescription2 = opt.description;
    }

    if (itm.selection !== this.optDescription2) {
      // show warning message if there is one
      if (opt.warningNote && opt.warningNote !== '') {
        this.notiService.showWarningNoTimeOut(opt.warningNote);
      }
      this.updatedItem = { id: itm.id, selection: this.optDescription2, selectedOptionId: opt.id };

      let refreshAll = false;
      if (itm.isSetUpLine || this.headingIsSetUpLine) {
        refreshAll = true;
      }
      this.patchJobItem(this.updatedItem, refreshAll, false, null, null);
    }
  }

  setSelectionModal(opt) {
    if (this.jobitem.attachmentId) {
      this.notiService.showWarning('There is a manual image/attachment for this item that will have to be changed manually');
    }
    if (opt.description === 'Please Select...') {
      this.optSelection = 'Please Select...';
      this.optionColour = this.optionColourEnum.Red;
      this.isBoldText = false;
      this.isOriginalSelectionInOptionList = true;
    } else if (opt.description === 'Other - Click here') {
      this.optSelection = 'Other - Click here';
      this.isOriginalSelectionInOptionList = false;
      this.manualSelection = this.jobitem.selection;
    } else {
      this.optSelection = opt.description;
      this.warningNote = opt.warningNote;
      this.optionColour = opt.optionColour;
      this.isBoldText = opt.isBoldText;
      this.isOriginalSelectionInOptionList = true;
      this.manualSelection = opt.description;
    }
  }

  setCheckBox(itm, sel) {
    // a job item with a checkbox has been ticked so update it
    if (sel === false) {
      this.optDescription2 = '';
    } else {
      this.optDescription2 = 'Yes';
    }
    this.updatedItem = { id: itm.id, selection: this.optDescription2 };
    this.patchJobItem(this.updatedItem, true, false, null, null);
  }

  showAttachment(jobitem) {
    // show the attachment
    const modalRef = this.modalService.open(SelectionAttachmentModalComponent,
      { windowClass: 'modal-pdf', backdrop: 'static' });
    modalRef.componentInstance.jobitem = jobitem;

    modalRef.result.then((attachmentId) => {
      this.getJobItemList();

      if (attachmentId) {
        this.refreshImage = !this.refreshImage;
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.getJobItemList();
    });
  }

  showAttachmentReadOnly(jobitem) {
    // show the attachment but read only so open full screen
    const modalRef = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf', backdrop: 'static' });
    modalRef.componentInstance.jobitem = jobitem;

    modalRef.result.then((returnResult) => {
      this.closeResult = 'Closed';
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  setColour(colourId) {
    this.optNoteColour = colourId;
    if (colourId === null || colourId === 0) {
      this.optNoteColourName = 'Standard';
    } else if (colourId === 1) {
      this.optNoteColourName = 'Red';
    } else {
      this.optNoteColourName = 'Green';
    }
  }

  showComment() {
    // open the appropriate comment
    // show the attachment
    const modalRef = this.modalService.open(CommentModalComponent, { windowClass: 'modal-edit', backdrop: 'static' });
    modalRef.componentInstance.jobitem = this.jobitem;
    modalRef.componentInstance.isLocked = this._jobItemService.isLocked;

    modalRef.result.then((returnRecord) => {
      if (returnRecord) {
        this.jobitem.commentTypeId = returnRecord.commentTypeId;
        this.jobitem.jobItemCommentId = returnRecord.commentId;
        this._jobItemService.updateComment(this.jobitem.originalItemTypeId, this.jobitem.id,
          returnRecord.commentTypeId, returnRecord.commentId);
      } else {
        this.getJobItemList();
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  selectionDropDownOpen() {
    if (this.selectionDrop) {
      this.selectionDrop.open();
    }
  }

  setCostType(costTypeId: number) {
    if (costTypeId === 0) {
      this.costTypeId = null;
    } else {
      this.costTypeId = costTypeId;
    }

    this.costTypeDropdown.forEach(element => {
      if (element.id === costTypeId) {
        this.costTypeDesc = element.name;
      }
    });

    if (costTypeId !== this.costTypeEnum.ProvisionalSum) {
      this.provisionalSum = 0;
    }
    if (costTypeId !== this.costTypeEnum.Note) {
      this.isDoNotPrint = false;
    }
  }

  clickToVariation(variationNumber: number, itemDescription: string, selection: string) {
    this.variationNumber = variationNumber;
    let searchString = selection && selection !== '' && selection !== 'Please Select...' ? selection : itemDescription.trim();

    // if the searchString contains brackets
    const indexNum = searchString.indexOf('(');
    if (indexNum > 0) {
      searchString = searchString.substring(0, indexNum - 1).trim();
    }
    this._jobItemService.setVariationNumber(variationNumber, searchString);
  }

  doWeHaveEstimatingAttachments() {
    // do we have estimating attachments - if so show the icon
    this.isEstimatingAttachmentsExist = false;

    if (this.selectionsAdmin && !this.jobitem.isHistoryRecord && this.jobitem.changedByVOId) {
      const jobVarCost = this._estimatingService.jobVarCosts.find(i => i.jobVarItemId === this.jobitem.changedByJobVarId && i.hasBlob);
      if (jobVarCost && jobVarCost.hasBlob) {
        this.isEstimatingAttachmentsExist = true;
      }
    }
  }

  changeBackground() {
    // change the background colour of this line
      const highlightColourId = this.iJobItem.highlightColourId === 3 ? 0 : (this.iJobItem.highlightColourId ?? 0) + 1;

      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.updateJobItem({id: this.iJobItem.id, highlightColourId: highlightColourId}).subscribe({
          next: () => {
            this.iJobItem.highlightColourId = highlightColourId;
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
}
