import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { TreeComponent } from '@circlon/angular-tree-component';
import { SelectionTypeEnum } from '../../dtos/selection-type.enum';
import { JobItemService } from '../../services/felixApi/job-item.service';
import { OptionListService } from '../../services/felixApi/option-list.service';
import { GlobalService } from '../../services/global.service';
import { IJobItem } from '../../dtos/job-item';
import { IJob } from '../../dtos/job';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { JobService } from '../../services/felixApi/job.service';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { JobVarTypeEnum } from '../../dtos/job-var-type.enum';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { NotificationService } from '../../services/notification.service';
import { Subscription } from 'rxjs';
import { IOptionListHeader } from '../../dtos/option-list-header';
import { CostTypeEnum } from '../../dtos/cost-type.enum';

@Component({
  selector: 'js-selection-edit-modal',
  templateUrl: './selection-edit-modal.component.html',
  styleUrls: ['./selection-edit-modal.component.scss']
})
export class SelectionEditModalComponent implements OnInit, OnDestroy {
  @Input() addEdit: string;
  @Input() selectionItemAbove: number;
  @Input() jobItemAboveTypeId: number;
  @Input() jobItemAboveId: number;
  @Input() baseLevel: boolean;
  @Input() orderNo: number;
  @Input() headingIsSetUpLine: boolean;

  @ViewChild('tree3') tree: TreeComponent;

  COMPONENT_NAME = 'selection-edit-modal';
  subscriptions: Subscription[] = [];

  colours = [
    { name: 'Standard', id: 0 },
    { name: 'Red', id: 1 },
    { name: 'Green', id: 2 }
  ];

  costTypeDropdown = [
    { name: 'Standard', id: 0 },
    { name: 'Note', id: 1 },
    { name: 'Provisional Sum', id: 4 },
    { name: 'By Owner', id: 6 },
    { name: 'Included', id: 7 },
    { name: 'Excluded', id: 10 }
  ];

  updatedItem: any;
  errorMessage: any;
  ItemType = ItemTypeEnum;
  loading = true;
  copyComplete = true;
  optDescription = '';
  optItemTypeId: number;
  optionListId = null;
  optSelection = '';
  optNoteColour: number;
  optNoteColourName = 'Standard';
  optIsBoldNote = false;
  optIsItalicNote = false;
  linkedJobItemId = null;
  modalHeading = 'Add Item';
  modalButton = 'Add';
  detailOptionType = 'Use an Option List';
  editIsSetupLine = false;
  isDoNotPrint = false;
  canEnterManualSelection = true;
  andORSetupLinks = 'AND'; // default to ANDing all setup tags together
  selectionTypeId: number;
  SelectionType = SelectionTypeEnum;
  addedJobItem: IJobItem;
  provisionalSum: number; // used for provisional sum amounts in the initial job item
  costTypeId: number; // the cost type if defined for this item

  treeOptionNodes: IOptionListHeader[] = [];
  treeOptionNodesSetup = false;
  treeOptionOptions = {};
  treeJobItemNodes: any[] = [];
  treeJobItemNodesTmp: any[] = [];
  treeJobItemNodesSetup = false;
  treeJobItemOptions = {};

  // copy job variables
  jobToCopy: IJob;
  jobNumberToCopyForm: UntypedFormGroup;
  itemsToCopy: any[] = [];
  itemsToCopyDto: SelectedJobItems;
  selectedNodes: any[] = [];
  copyTreeOptions = {};
  jobVarTypeEnum = JobVarTypeEnum;
  jobSearchTypeEnum = JobSearchTypeEnum;
  addedAnother = false; // have we added another? used to close instead of cancel the modal
  isQtyRequired = false; // if we select and option that needs it we set to true
  quantity: number; // the number is we need it
  allItemsSelected: boolean; // for copy we check we are selecting all the items
  costTypeDesc: string;
  costTypeEnum = CostTypeEnum;
  addendumName: string;
  jobNumber: string;

  constructor(private _jobItemService: JobItemService,
    private _optionListService: OptionListService,
    private _jobService: JobService,
    private _formBuilder: UntypedFormBuilder,
    private _globalService: GlobalService,
    private notiService: NotificationService,
    private _activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.addEdit === 'Add') {
      this.setUpForAdd();
      this.setCostType(0);
    }
    this.addendumName = this._globalService.getAddendumName();
    this.copyJobSetup();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setUpForAdd() {
    // note that set up of tree nodes is slow so we try to do once for this module
    this.setupOptionNodes();
  }

  // set up the tree nodes of job items to allow linking
  setupOptionNodes() {
    this.treeOptionOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };

    this.treeOptionNodes = this._optionListService.getCurrentOptionNodes();
    if (!this.treeOptionNodes) {
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, false)
          .subscribe({
            next: (treeOptionNodes) => {
              this.treeOptionNodes = treeOptionNodes;
              this.treeOptionNodesSetup = true;
              this._optionListService.setCurrentOptionNodes(this.treeOptionNodes);
              this.loading = false;
            },
            error: (err) => {
              this.loading = false;
              this.treeOptionNodesSetup = true;
              this.notiService.notify(err);
            }
          })
      );
    } else {
      this.treeOptionNodesSetup = true;
      this.loading = false;
    }
  }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes(JobNumber) {
    this.treeJobItemOptions = {
      idField: 'id',
      displayField: 'itemDescription',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22
    };
    // start at item 0 to get all lines with children = true and get hidden lines = true
    // only used in setup so don't send to get any variations - i.e. don't send isLocked
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.getJobItems(JobNumber, 1, 0, true, true, false, 0, false, false)
        .subscribe({
          next: (treeJobItemNodes) => {
            this.treeJobItemNodes = treeJobItemNodes;
            this.treeJobItemNodesSetup = true;
          },
          error: (err) => {
            this.treeJobItemNodesSetup = true;
            this.notiService.notify(err);
          }
        })
    );
  }

  // set the linked option list when clicked on from tree
  setOptionList(node) {
    this.optionListId = node.data.id;
    this.isQtyRequired = node.data.isQtyRequired;
    if (node.data.defaultDescription && node.data.defaultDescription !== '') {
      this.optDescription = node.data.defaultDescription;
    }
  }

  // when the pption list tree is initialised set the focused node to the one already selected.
  onOptionTreeInit(tree: TreeComponent) {
    if (this.optionListId !== null && this.optionListId !== undefined && this.optionListId !== 0 && this.treeOptionNodesSetup) {
      tree.treeModel.getNodeById(this.optionListId)?.setActiveAndVisible();
    }
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
    this.loading = false;
  }

  // when the job item tree is initialised set the focused node to the one already selected.
  onItemTreeInit(tree: TreeComponent) {
    this.loading = false;
  }

  // set the linked job item when clicked on from tree
  setLinkedItemId(id) {
    if (id !== this.linkedJobItemId) {
      this.linkedJobItemId = id;
      this.optSelection = '';
    }
  }

  // add a new option list to the end and stay in Modal to add another
  addAnother() {
    // don't close the form
    this.addAfterModal(false);
    this.addedAnother = true;
  }

  addAfterModal(closeform) {
    if (this.optItemTypeId === this.ItemType.Detail && this.detailOptionType === 'Use an Option List' && !this.optionListId) {
      this.notiService.showInfo('Please select an option list');
      return;
    }

    this.loading = true;
    if (this.detailOptionType === 'Use an Option List') {
      this.linkedJobItemId = null;
      this.selectionTypeId = this.SelectionType.Dropdown;
    } else if (this.detailOptionType === 'Link to item above') {
      this.linkedJobItemId = this.selectionItemAbove; // passed to this modal
      this.optionListId = null;
      this.selectionTypeId = this.SelectionType.Dropdown;
      this.optSelection = '';
    } else if (this.detailOptionType === 'Link to another line') {
      this.optionListId = null;
      this.selectionTypeId = this.SelectionType.Dropdown;
    } else if (this.detailOptionType === 'Checkbox') {
      this.optionListId = null;
      this.linkedJobItemId = null;
      this.selectionTypeId = this.SelectionType.Checkbox;
      this.canEnterManualSelection = true;
    }

    if (this.optItemTypeId === this.ItemType.Heading) {
      this.costTypeId = null;
    }

    // set to null if zero to keep database consistant and ensure no PS for detail and heading items
    let provSum = this.provisionalSum;
    if (this.optItemTypeId === this.ItemType.Heading || provSum === 0) {
      provSum = null;
    }
    if (this.optItemTypeId !== this.ItemType.Detail || this.detailOptionType !== 'Use an Option List' || this.quantity === 0) {
      this.quantity = null;
    }

    // round qty to max 4 decimal places
    if (this.quantity) {
      this.quantity = +(this.quantity.toFixed(4));
      if (provSum) {
        provSum = provSum * this.quantity;
      }
    }

    this.updatedItem = {
      itemTypeId: this.optItemTypeId,
      itemDescription: this.optDescription,
      selectionTypeId: this.selectionTypeId,
      canEnterManualSelection: this.canEnterManualSelection,
      optionListId: this.optionListId,
      selection: this.optSelection,
      jobItemAboveTypeId: this.jobItemAboveTypeId,
      jobItemAboveId: this.jobItemAboveId,
      linkedJobItemId: this.linkedJobItemId,
      isSetUpLine: this.editIsSetupLine,
      SetupTags: this.andORSetupLinks,
      noteColour: this.optNoteColour,
      isBoldNote: this.optIsBoldNote,
      isItalicNote: this.optIsItalicNote,
      isDoNotPrint: this.isDoNotPrint,
      provisionalSum: provSum,
      orderNo: this.orderNo,
      quantity: this.quantity,
      costTypeId: this.costTypeId
    };
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.addJobItem(this.updatedItem).subscribe({
        next: (res) => {
          this.addedJobItem = res as IJobItem;
          if (closeform) {
            this.close();
          } else {
            if (this.optItemTypeId === this.ItemType.Detail && this.selectionTypeId === this.SelectionType.Dropdown) {
              this.selectionItemAbove = this.addedJobItem.id;
            } else {
              this.selectionItemAbove = 0;
            }
            this.optDescription = '';
            this.optItemTypeId = null; // reset to ask again
            this.optionListId = null;
            this.optSelection = '';
            this.provisionalSum = null;
            this.quantity = null;
            this.linkedJobItemId = null;
            this.modalHeading = 'Add Line';
            this.modalButton = 'Add';
            this.detailOptionType = 'Checkbox';
            this.editIsSetupLine = false;
            this.canEnterManualSelection = true;
            this.optNoteColour = 0;
            this.optIsBoldNote = false;
            this.optIsItalicNote = false;
            this.isDoNotPrint = false;
            this.setCostType(0);

            this.loading = false;
            // this.input.nativeElement.focus();
          }
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  setColour(colourId) {
    this.optNoteColour = colourId;
    if (colourId === 0) {
      this.optNoteColourName = 'Standard';
    } else if (colourId === 1) {
      this.optNoteColourName = 'Red';
    } else {
      this.optNoteColourName = 'Green';
    }
  }

  update() {
    // close the form
    this.addAfterModal(true);
  }

  close() {
    this._activeModal.close();
  }

  cancel() {
    if (this.addedAnother) {
      this._activeModal.close(); // close so we refresh items
    } else {
      this._activeModal.dismiss();
    }
  }

  copyJobSetup() {
    this.jobNumberToCopyForm = this._formBuilder.group({
      contractName: this._globalService.jobstring
    });

    this.jobNumber = this._globalService.getCurrentJob();

    this.copyTreeOptions = {
      idField: 'id',
      displayField: 'itemDescription',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };
  }

  copyTreeNodeClicked(node, $event) {
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, $event.target.checked));
      this.tree.treeModel.update();
    }
    node.data.checked = !node.data.checked;
  }

  checkAllChildren(node, checkValue) {
    node.data.checked = checkValue;
    if (node.children) {
      node.children.forEach((child) => this.checkAllChildren(child, checkValue));
    }
  }

  onCopyTreeInit(tree: TreeComponent) {
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
    this.loading = false;
  }

  runCopy() {
    // get the items in the right order
    this.loading = true;
    this.itemsToCopy = [];
    this.allItemsSelected = true;
    for (let i = 0; i < this.tree.treeModel.nodes.length; i++) {
      if (this.tree.treeModel.getNodeById(this.tree.treeModel.nodes[i].id).data.checked === true) {
        this.itemsToCopy = this.itemsToCopy.concat(+this.tree.treeModel.nodes[i].id);
      } else {
        this.allItemsSelected = false;
      }
      if (this.tree.treeModel.nodes[i].children) {
        this.tree.treeModel.nodes[i].children.forEach((child) => this.checkAddItemsToCopy(child));
      }
    }

    // if we have selected all and we are adding to the bottom we call the SQL USP route
    if (this.allItemsSelected && this.orderNo === 0) {
      this.updatedItem = {
        copyFromJobNo: this.jobToCopy.jobNumber,
        createMissingToJob: false,
        copyTypeId: 2 // append to the end
      };

      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.copyJobItems(this.updatedItem).subscribe({
          next: () => {
            this._activeModal.close();
          },
          error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    } else {

      this.itemsToCopyDto = { selectedJobItemIds: this.itemsToCopy };

      // console.log(this.COMPONENT_NAME + '-Copying:' + JSON.stringify(this.itemsToCopyDto));
      this.copyComplete = false;
      if (!this.orderNo) {
        this.orderNo = 0;
      }
      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.copyJobItemSet(this.jobVarTypeEnum.JobItem, this.jobItemAboveId, this.orderNo,
          this.itemsToCopyDto, null, 0, 0).subscribe({
            next: () => {
              this.close();
            },
            error: (err) => {
              this.loading = false;
              this.copyComplete = true;
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  checkAddItemsToCopy(node) {
    if (this.tree.treeModel.getNodeById(node.id).data.checked === true) {
      this.itemsToCopy = this.itemsToCopy.concat(+node.id);
    } else {
      this.allItemsSelected = false;
    }
    if (node.children) {
      node.children.forEach((child) => this.checkAddItemsToCopy(child));
    }
  }

  refreshJobNumber(jobNumber: string) {
    this.jobNumber = jobNumber;
    this.treeJobItemNodes = [];
    this.treeJobItemNodesSetup = false;

    if (this.jobNumber && this.jobNumber !== '') {
      const currentJob = this._jobService.jobs?.find(i => i.jobNumber === jobNumber);
      if (currentJob) {
        this.jobToCopy = currentJob;
        this.jobNumberToCopyForm.patchValue({
          contractName: currentJob.contractName
        });
        this.setupJobItemNodes(this.jobNumber);
      }
    }
  }

  resetTreeForLinkingItems() {
    // reset as we can be going to checkbox
    this.setCostType(0);

    // set up the tree for linking this item to the items above if chosen by the user
    this.treeJobItemNodesSetup = false;
    this.treeJobItemOptions = {
      idField: 'id',
      displayField: 'itemDescription',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22
    };
    this.treeJobItemNodes = [];
    // start at item 0 to get all lines with children = true and get hidden lines = true
    // only used in setup so don't send to get any variations - i.e. don't send isLocked
    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.getJobItems(this._globalService.getCurrentJob(), 1, this.jobItemAboveId, true, true, false, 0, false, false)
        .subscribe({
          next: (treeJobItemNodesTmp) => {
            this.treeJobItemNodesTmp = treeJobItemNodesTmp;
            this.treeJobItemNodesTmp.forEach(element => {
              if (element.jobItemAboveId === this.jobItemAboveId &&
                element.itemTypeId === this.ItemType.Detail &&
                element.selectionTypeId === this.SelectionType.Dropdown) {
                this.treeJobItemNodes = this.treeJobItemNodes.concat(element);
              }
            });
            this.treeJobItemNodesSetup = true;
          },
          error: (err) => {
            this.treeJobItemNodesSetup = true;
            this.notiService.notify(err);
          }
        })
    );
  }

  resetTreeForCopy() {
    this.treeJobItemNodes = [];
  }

  selectAllNodesForCopy() {
    this.tree.treeModel.roots.forEach((child) => this.checkAllChildren(child, true));
    this.tree.treeModel.update();
  }

  setCostType(costTypeId: number) {
    if (costTypeId === 0) {
      this.costTypeId = null;
    } else {
      this.costTypeId = costTypeId;
    }

    this.costTypeDropdown.forEach(element => {
      if (element.id === costTypeId) {
        this.costTypeDesc = element.name;
      }
    });

    if (costTypeId !== this.costTypeEnum.ProvisionalSum) {
      this.provisionalSum = 0;
    }
  }

  refreshList() {
    this._optionListService.setCurrentOptionNodes(null);
    this.treeOptionNodesSetup = false;
    this.setupOptionNodes();
  }

  resetMasterType() {
    // if a checkbox we can't have provisional sums
    this.setCostType(0);
    this.isQtyRequired = false;
  }
}
