export class CompanyActivity {
    id: number;
    activityCode: string;
    description: string;
    isActive: boolean;
    longDescription: string;
    activityTypeId: number;
    isDefaultForVariations: boolean;
    displayDescription: string;

    constructor(data?: Partial<CompanyActivity>) {
        this.id = data?.id || 0;
        this.activityCode = data?.activityCode || '';
        this.description = data?.description || '';
        this.isActive = data?.isActive || false;
        this.longDescription = data?.longDescription || '';
        this.activityTypeId = data?.activityTypeId || 0;
        this.isDefaultForVariations = data?.isDefaultForVariations || false;
        this.displayDescription = this.setDisplayDescription();
      }

    private setDisplayDescription(): string {
        if (this.description.startsWith(this.activityCode)) {
            return this.description;
        } else {
            return `${this.activityCode} - ${this.description}`;
        }
    }
}

