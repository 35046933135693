<div class="modal-header">
  <h4 class="modal-title">Copy the FINAL items from another job to this one</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">

  <js-job-number-small [jobNumber]="" [saveJobNumber]="false" [defaultToTemplatesOnSearch]="false"
    (refreshJobNumber)="refreshJobNumber($event)"></js-job-number-small>

  <!-- Image Loading Spinner -->
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

</div>

<div *ngIf="!loading" class="modal-footer">
  <button type="button" class="btn btn-sm btn-primary" (click)="copyJob()" [disabled]="!jobNumber || jobNumber === ''">
    Run Copy
  </button>
</div>
