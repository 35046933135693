<div class="modal-header">
  <h2 class="modal-title">{{modalHeading}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body contentAddVar">
  <form *ngIf="!updating" [formGroup]="addForm">

    <!-- if in a variation first use a radio button to select where this line goes -->
    <div *ngIf="canAddAbove" class="radioclass mb-3">
      <label class="marginleft15">
        <input type="radio" formControlName="addAboveOrAfter" value="After" name="addAboveOrAfter"> Add after this line
      </label>
      <label class="ms-3">
        <input type="radio" formControlName="addAboveOrAfter" value="Above" name="addAboveOrAfter"> Add above this line
      </label>
    </div>

    <!-- first use a radio button to select the type of line -->
    <div class="row">
      <div class="btn-group btn-group-toggle alignCenter" ngbRadioGroup name="radioBasic">
        <label ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Heading" (click)="clearWhenHeading()"
            formControlName="itemTypeId">
          Heading
        </label>

        <label *ngIf="jobItemAboveId" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Detail" formControlName="itemTypeId">
          Lookup Item
        </label>

        <label *ngIf="jobItemAboveId" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Note" (click)="clearWhenNote()"
            formControlName="itemTypeId">
          Note
        </label>

        <label *ngIf="userCanChangePrices" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Copy" formControlName="itemTypeId">
          Copy
        </label>

        <label *ngIf="jobItemAboveId" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.HouseOption" formControlName="itemTypeId">
          House Option
        </label>

        <label *ngIf="isEstimatingWrite && clickedAddToEnd" ngbButtonLabel class="btn btn-sm btn-primary">
          <input ngbButton type="radio" [value]="itemTypeEnum.Load" formControlName="itemTypeId">
          Load Items
        </label>
      </div>
    </div>

    <div
      *ngIf="addForm && addForm.get('itemTypeId').value && addForm.get('itemTypeId').value !== itemTypeEnum.Copy && addForm.get('itemTypeId').value !== itemTypeEnum.Load">
      <!-- description is always set-->
      <div *ngIf="addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption" class="row mt-3">
        <label
          *ngIf="addForm.get('itemTypeId').value === itemTypeEnum.Detail || addForm.get('itemTypeId').value === itemTypeEnum.Heading"
          class="col-sm-2 form-label" for="itemDescription">Description:*</label>
        <label
          *ngIf="addForm.get('itemTypeId').value !== itemTypeEnum.Detail && addForm.get('itemTypeId').value !== itemTypeEnum.Heading"
          class="col-sm-2 form-label" for="itemDescription">Description:</label>
        <input
          *ngIf="addForm.get('itemTypeId').value === itemTypeEnum.Detail || addForm.get('itemTypeId').value === itemTypeEnum.Heading"
          class="form-control-sm col-sm-9" id="itemDescription" type="text" formControlName="itemDescription"
          placeholder="(required)" />
        <input
          *ngIf="addForm.get('itemTypeId').value !== itemTypeEnum.Detail && addForm.get('itemTypeId').value !== itemTypeEnum.Heading"
          class="form-control-sm col-sm-9" id="itemDescription" type="text" formControlName="itemDescription" />
      </div>

      <div *ngIf="isInDoNotPrintSection" class="isInDoNotPrintSection">
        BEWARE: This item is "Not Printed on the {{addendumName}}". Ensure colours & selections can be printed.
      </div>

      <div *ngIf="(addForm.get('itemTypeId').value === itemTypeEnum.Heading) && !isInDoNotPrintSection"
        class="row mt-3">
        <label class="col-sm-11 form-label righttext" for="isDoNotPrint">Do NOT print on {{addendumName}}?
          <input class="marginTop5 ms-2" type="checkbox" id="isDoNotPrint" formControlName="isDoNotPrint">
        </label>
      </div>

      <div *ngIf="(addForm.get('itemTypeId').value === itemTypeEnum.Detail)" class="row mt-3">
        <!-- we need to select an option so use a tree -->
        <div>
          <b>Select Option:</b>
        </div>

        <!-- Loading Spinner -->
        <js-loading-spinner *ngIf="loading"></js-loading-spinner>

        <div *ngIf="!loading && treeOptionNodes && treeOptionNodes.length">
          <input id="filter" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="filter nodes"
            class="ms-2p" />
          <button
            (click)="$event.preventDefault(); $event.stopPropagation(); tree.treeModel.clearFilter(); tree.treeModel.collapseAll(); filter.value=''">Clear
            Filter</button>

          <div class="globalTreeclass">
            <tree-root #tree [focused]="true" [nodes]="treeOptionNodes" [options]="treeOptionOptions"
              (focus)="setOptionList(tree.treeModel.getFocusedNode())" (initialized)="onOptionTreeInit(tree)">
            </tree-root>
          </div>
        </div>

        <div *ngIf="!loading && (!treeOptionNodes || !treeOptionNodes.length)">
          ** No options exist for this section **
        </div>
      </div>

      <div *ngIf="(addForm.get('itemTypeId').value === itemTypeEnum.HouseOption)" class="mt-3">
        <!-- we need to select an option so use a tree -->
        <div>
          <b>Select Option:</b>
        </div>

        <!-- Loading Spinner -->
        <js-loading-spinner *ngIf="loadingHouseOptions"></js-loading-spinner>

        <div *ngIf="!loadingHouseOptions && treeHouseOptionNodes && treeHouseOptionNodes.length">
          <input id="filter" #filter (keyup)="tree.treeModel.filterNodes(filter.value)" placeholder="filter nodes"
            class="ms-2p" />
          <button
            (click)="$event.preventDefault(); $event.stopPropagation(); tree.treeModel.clearFilter(); tree.treeModel.collapseAll(); filter.value=''">Reset
          </button>

          <div class="globalTreeclass">
            <tree-root #tree3 [focused]="true" [nodes]="treeHouseOptionNodes" [options]="treeOptionOptions"
              (initialized)="onHouseOptionTreeInit(tree3)">
              <ng-template #treeNodeTemplate let-node="node" let-index="index">
                <input *ngIf="!node.data.notSelectable && !node.hasChildren" type="checkbox" id="check-{{node.data.id}}"
                  [checked]="node.data.checked" (change)="countHouseOptionsSelected(node)">
                <span class='buttonClass' (click)="expandNode(node)">
                  <span *ngIf="node.data.defaultDescription && node.data.defaultDescription !== ''">
                    <b>{{node.data.defaultDescription}}:</b>
                  </span>
                  {{node.data.description}}
                  <span
                    *ngIf="!node.data.salesPriceTypeIfAddedId && node.data.salesPriceIfAdded && node.data.salesPriceIfAdded !== 0"
                    class="buttonPriceClass">
                    : ${{node.data.salesPriceIfAdded | number}}
                  </span>
                  <span *ngIf="node.data.salesPriceTypeIfAddedId === priceTypeEnum.Note" class="buttonPriceClass">
                    : NOTE
                  </span>
                  <span *ngIf="node.data.salesPriceTypeIfAddedId === priceTypeEnum.NoCharge" class="buttonPriceClass">
                    : No Charge
                  </span>
                  <span *ngIf="node.data.salesPriceTypeIfAddedId === priceTypeEnum.ProvisionalSum"
                    class="buttonPriceClass">
                    : PS ${{node.data.salesPriceIfAdded | number}}
                  </span>
                </span>
              </ng-template>
            </tree-root>
          </div>
        </div>

        <div *ngIf="!loadingHouseOptions && (!treeHouseOptionNodes || !treeHouseOptionNodes.length)">
          ** No options exist for this section **
        </div>
      </div>

      <!-- if Note -->
      <div *ngIf="(addForm.get('itemTypeId').value === itemTypeEnum.Note)" class="mt-3">
        <label class="form-label" for="selection">Note:</label>
        <textarea class="form-control" [style.height.px]="noteHeight" id="selection"
          formControlName="selection"></textarea>
      </div>

      <!-- if variation text shown -->
      <div
        *ngIf="isVariationOnlyTextShown && addForm.get('itemTypeId').value !== itemTypeEnum.Heading && addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption"
        class="mt-2">
        <label class="form-label" for="variationOnlyComment">Variation ONLY text:</label>
        <textarea class="form-control" id="variationOnlyComment" formControlName="variationOnlyComment"></textarea>
      </div>

      <!-- if note select colour and is bold -->
      <div *ngIf="(addForm.get('itemTypeId').value === itemTypeEnum.Note)" class="row mt-3">
        <label class="form-label col-sm-2" for="noteColour">Colour:</label>
        <!-- <select name="noteColour" id="noteColour" formControlName="noteColour" class="col-sm-2">
          <option *ngFor="let colour of colours" [value]="colour.id">
            {{colour.name}}
          </option>
        </select> -->
        <div ngbDropdown class="col-sm-2 p-0">
          <button class="btn-sm btn-outline-primary dropdownclass" id="dropdownBasicS" ngbDropdownToggle
            (click)="$event.preventDefault()">{{currentColour}}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasicS" class="dropdownmenuclass">
            <!-- until service returns only the ones required test for Ids equal -->
            <div *ngFor="let colour of colours">
              <button class="dropdown-item"
                (click)='$event.preventDefault(); setColour(colour.id)'>{{colour.name}}</button>
            </div>
          </div>
        </div>

        <div class="priceFieldClass80">
          <label class="form-label labelclass" for="isBoldNoteId">Bold:</label>
          <input type="checkbox" name="isBoldNoteId" id="isBoldNoteId" formControlName="isBoldNote"
            class="ms-2 marginTop5">
        </div>

        <div class="priceFieldClass80">
          <label class="form-label labelclass" for="isItalicNoteId">Italic:</label>
          <input type="checkbox" name="isItalicNoteId" id="isItalicNoteId" formControlName="isItalicNote"
            class="ms-2 marginTop5">
        </div>
      </div>

      <!-- hide pricing section if user cannot change -->
      <div *ngIf="userCanChangePrices">
        <!-- Qty heading if required -->
        <div *ngIf="isQtyRequired" class="row mt-3">
          <div class="col-sm-4 righttext">Quantity</div>
          <div *ngIf="showPrice" class="col-sm-4 lineTotalClass">Price</div>
        </div>

        <div *ngIf="addForm.get('itemTypeId').value !== itemTypeEnum.Heading
        && variationType !== variationTypeEnum.Office
        && variationType !== variationTypeEnum.PreContractExFromContract
        && ((addForm.get('itemTypeId').value !== itemTypeEnum.Detail) || (treeOptionNodes && treeOptionNodes.length))
        && ((addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption))" class="row mt-3">
          <label class="form-label col-sm-2" for="price">Price:</label>

          <!-- Qty -->
          <!-- <div *ngIf="isQtyRequired" class="col-sm-2"> -->
          <input *ngIf="isQtyRequired" class="form-control-sm col-sm-2 righttext me-1" name="quantity" id="quantity"
            formControlName="quantity" type="number" step="1" autocomplete="off" />
          <!-- </div> -->

          <!-- Price Type -->
          <div ngbDropdown class="col-sm-2 p-0">
            <button class="btn-sm btn-outline-primary dropdownclass" id="dropdownBasicS2" ngbDropdownToggle
              (click)="$event.preventDefault()">{{costTypeDesc}}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasicS2" class="dropdownmenuclass">
              <!-- until service returns only the ones required test for Ids equal -->
              <div *ngFor="let c of costTypeDropdown">
                <button class="dropdown-item" (click)='$event.preventDefault(); setCostType(+c.id)'>{{c.name}}</button>
              </div>
            </div>
          </div>

          <!-- Price -->
          <input *ngIf="showPrice" class="form-control-sm col-sm-2 righttext me-1" name="price" id="price"
            formControlName="price" type="number" step="1" autocomplete="off" (keyup)="checkNoCents()" />

          <!-- If Qty we show a line total -->
          <div *ngIf="isQtyRequired && showPrice" class="col-sm-3 marginTop5 lineTotalClass">
            <b class="me-3">Total:</b>{{getRoundedPrice(addForm.get('quantity').value * addForm.get('price').value) |
            number:'1.0-0'}}
          </div>

        </div>

        <div
          *ngIf="addForm.get('itemTypeId').value !== itemTypeEnum.Heading && addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption"
          class="row mt-3">
          <div *ngIf="selectionsAdmin" class="col-sm-11 checkclass">
            <label class="form-label labelclass" for="isCheckedInAdd">Checked:</label>
            <input type="checkbox" name="isCheckedInAdd" id="isCheckedInAdd" formControlName="isChecked"
              class="ms-2 marginTop5" (click)="isCheckedClicked()">
          </div>
        </div>
      </div>

      <div class="mt-3">
        <button type="button" class="btn btn-sm btn-primary" [disabled]="(addForm.get('itemDescription').value === '' && addForm.get('selection').value === '' && addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption)
          || ((addForm.get('itemTypeId').value === itemTypeEnum.Detail || addForm.get('itemTypeId').value === itemTypeEnum.Heading) && addForm.get('itemDescription').value === '')
          || (addForm.get('itemTypeId').value === itemTypeEnum.HouseOption && houseOptionsSelected === 0)"
          (click)="addVarItem()">Add</button>
        <button
          *ngIf="addForm.get('itemTypeId').value !== itemTypeEnum.Heading && addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption"
          type="button" class="btn btn-sm btn-primary ms-2" [disabled]="(addForm.get('itemDescription').value === '' && addForm.get('selection').value === '')
          || (addForm.get('itemTypeId').value === itemTypeEnum.Detail && addForm.get('itemDescription').value === '')"
          (click)="addPlusAttachment()">Add + Add Attachment</button>

        <!-- if we have permission we can go to the estimating form -->
        <button
          *ngIf="selectionsAdmin && addForm.get('itemTypeId').value !== itemTypeEnum.Heading && addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption"
          type="button" class="btn btn-sm btn-primary ms-2" [disabled]="(addForm.get('itemDescription').value === '' && addForm.get('selection').value === '')
        || (addForm.get('itemTypeId').value === itemTypeEnum.Detail && addForm.get('itemDescription').value === '')"
          (click)="estimatePrice()">Add + Estimate</button>

        <!-- show the variation only text box? -->
        <button *ngIf="!isVariationOnlyTextShown && addForm.get('itemTypeId').value !== itemTypeEnum.Heading
          && addForm.get('itemTypeId').value !== itemTypeEnum.HouseOption" type="button"
          class="btn btn-sm btn-secondary ms-2" (click)="isVariationOnlyTextShown = !isVariationOnlyTextShown">
          Add variation text
        </button>
      </div>
    </div>

    <!-- copy job items -->
    <div *ngIf="addForm && addForm.get('itemTypeId').value && addForm.get('itemTypeId').value === itemTypeEnum.Copy"
      class="mt-3">
      <js-job-number-small [jobNumber]="jobNumber" [saveJobNumber]="false"
        (refreshJobNumber)="refreshJobNumber($event)">
      </js-job-number-small>

      <!-- select items -->
      <form *ngIf="jobToCopy">
        <div class="mt-2">
          <button type="button" class="btn btn-sm btn-primary mb-1" (click)="selectAllNodesForCopy()">
            Select All
          </button>
          <div *ngIf="treeJobItemNodesSetup">
            <input id="filter" #filter (keyup)="tree3.treeModel.filterNodes(filter.value)" placeholder="filter nodes"
              class="ms-2p" />
            <button
              (click)="$event.preventDefault(); $event.stopPropagation(); tree3.treeModel.clearFilter(); tree3.treeModel.collapseAll(); filter.value=''">
              Clear Filter
            </button>

            <div class="globalTreeclass">
              <tree-root #tree3 [focused]="true" [nodes]="treeNodes" [options]="copyTreeOptions"
                (initialized)="onItemTreeInit(tree3)">
                <ng-template #treeNodeTemplate let-node="node" let-index="index">
                  <input (change)="copyTreeNodeClicked(tree3, node)" type="checkbox" id="check-{{node.data.id}}"
                    [indeterminate]="node.data.indeterminate" [checked]="node.data.checked">
                  <span class="ms-2" (click)="expandNode(node)">{{ node.data.description }}</span>
                </ng-template>
              </tree-root>
            </div>
          </div>
        </div>

        <div class="">
          <button type="button" class="btn btn-sm btn-primary" (click)="runCopy()">Go</button>
        </div>

        <!-- Loading Spinner -->
        <js-loading-spinner *ngIf="!copyComplete"></js-loading-spinner>

      </form>
    </div>

    <div *ngIf="addForm && addForm.get('itemTypeId').value && addForm.get('itemTypeId').value === itemTypeEnum.Load">

      <div id="fileuploader-container">
        <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
          [(value)]="value" uploadMode="useForm" [allowedFileExtensions]="['.xls', '.xlsx']">
        </dx-file-uploader>

        <div>5 columns with header row: Item Number, Item Prefix, Note, PS or Blank, Price</div>
        <br>
        <button type="button" class="btn btn-sm btn-primary" (click)="runUpload()"
          [disabled]='value === null || !value.length'>Go</button>
      </div>

    </div>
  </form>

  <!-- Loading Spinner -->
  <js-loading-spinner *ngIf="updating"></js-loading-spinner>
</div>
