import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { BoardMasterService } from '../services/felixApi/board-master.service';
import { BoardItemService } from '../services/felixApi/board-item.service';
import { JobItemLinkService } from '../services/felixApi/job-item-link.service';
import { JobItemService } from '../services/felixApi/job-item.service';
import { JobVarItemService } from '../services/felixApi/job-var-item.service';
import { JobService } from '../services/felixApi/job.service';
import { OptionListService } from '../services/felixApi/option-list.service';
import { EstimatingService } from '../services/felixApi/estimating.service';

import { DetailVariationComponent } from './detail-variation.component';
import { JobItemLinkComponent } from './job-item-link.component';
import { SelectVariationComponent } from './select-variation.component';
import { SelectionHeadingComponent } from './selection-heading.component';
import { SelectionJobComponent } from './selection-job.component';
import { SelectionsRoutingModule } from './selections-routing.module';
import { SelectionsComponent } from './selections.component';
import { SelectionAttachmentModalComponent } from './selection-modals/selection-attachment-modal.component';
import { VariationEditModalComponent } from './selection-modals/variation-edit-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SelectionEditModalComponent } from './selection-modals/selection-edit-modal.component';
import { ShowFullAddendaComponent } from './selection-modals/show-full-addenda.component';
import { SelectionReportModalComponent } from './selection-modals/selection-report-modal.component';
import { HeadingModalComponent } from './selection-modals/heading-modal.component';
import { VariationAddModalComponent } from './selection-modals/variation-add-modal.component';
import { JobsModule } from '../jobs/jobs.module';
import { ConnectItemsModalComponent } from './selection-modals/connect-items-modal.component';
import { ConfirmDeleteModalComponent } from './selection-modals/confirm-delete-modal.component';
import { SalesChoiceModalComponent } from './selection-modals/sales-choice-modal.component';
import { CommentModalComponent } from './selection-modals/comment-modal.component';
import { MoveItemsModalComponent } from './selection-modals/move-items-modal.component';
import { CreateSalesVariationModalComponent } from './selection-modals/create-sales-variation-modal.component';
import { CategoryService } from '../services/felixApi/category.service';
import { CategoryModalComponent } from './selection-modals/category-modal/category-modal.component';
import { SubmitToEstimatingModalComponent } from './selection-modals/submit-to-estimating-modal.component';
import { ClientChoiceModalComponent } from './selection-modals/client-choice-modal.component';
import { ImageSelectModalComponent } from './selection-modals/image-select-modal/image-select-modal.component';
import { VariationCopyModalComponent } from './selection-modals/variation-copy/variation-copy-modal.component';
import { EstimatingModalComponent } from './selection-modals/estimating-modal/estimating-modal.component';
import { EstimatingAttachmentsModalComponent } from './selection-modals/estimating-attachments-modal/estimating-attachments-modal.component';
import { ImageSelectPriceComponent } from './selection-modals/image-select-price/image-select-price.component';
import { MoveItemComponent } from './selection-modals/move-item/move-item.component';
import { HideSectionComponent } from './selection-modals/hide-section/hide-section.component';
import { AddSelectionBoardComponent } from './selection-modals/add-selection-board/add-selection-board.component';
import { EstimatingSaveToRecipeModalComponent } from './selection-modals/estimating-save-to-recipe-modal/estimating-save-to-recipe-modal.component';
import { CopyFromFinalJobComponent } from './selection-modals/copy-from-final-job/copy-from-final-job.component';
import { ItemSetupModalComponent } from './selection-modals/item-setup-modal/item-setup-modal.component';
import { JobItemExtraService } from '../services/felixApi/job-item-extra.service';
import { OptionHeaderComponent } from './selection-modals/image-select-modal/option-header/option-header.component';
import { JobItemCostComponent } from './selection-modals/job-item-cost/job-item-cost.component';
import { SelectCategoriesComponent } from './selection-modals/select-categories/select-categories.component';
import { MarkAllCheckedComponent } from './selection-modals/mark-all-checked/mark-all-checked.component';
import { ApproveVariationComponent } from './selection-modals/approve-variation/approve-variation.component';
import { ConstructiveSelectionsComponent } from './selection-modals/constructive-selections/constructive-selections.component';
import { LoadAddendaPdfComponent } from './selection-modals/load-addenda-pdf/load-addenda-pdf.component';
import { ChangeTypeComponent } from './selection-modals/change-type/change-type.component';
import { CheckShowRulesReportComponent } from './selection-modals/check-show-rules-report/check-show-rules-report';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        SelectionsRoutingModule,
        NgbModule,
        JobsModule,
        DragDropModule,
        NgxExtendedPdfViewerModule
    ],
    declarations: [
        SelectionsComponent,
        SelectionJobComponent,
        SelectionHeadingComponent,
        JobItemLinkComponent,
        DetailVariationComponent,
        SelectVariationComponent,
        SelectionAttachmentModalComponent,
        VariationEditModalComponent,
        SelectionEditModalComponent,
        ShowFullAddendaComponent,
        SelectionReportModalComponent,
        HeadingModalComponent,
        VariationAddModalComponent,
        ConnectItemsModalComponent,
        ConfirmDeleteModalComponent,
        SalesChoiceModalComponent,
        CommentModalComponent,
        MoveItemsModalComponent,
        CreateSalesVariationModalComponent,
        CategoryModalComponent,
        SubmitToEstimatingModalComponent,
        ClientChoiceModalComponent,
        ImageSelectModalComponent,
        VariationCopyModalComponent,
        EstimatingModalComponent,
        EstimatingAttachmentsModalComponent,
        ImageSelectPriceComponent,
        MoveItemComponent,
        HideSectionComponent,
        AddSelectionBoardComponent,
        EstimatingSaveToRecipeModalComponent,
        CopyFromFinalJobComponent,
        ItemSetupModalComponent,
        OptionHeaderComponent,
        JobItemCostComponent,
        SelectCategoriesComponent,
        MarkAllCheckedComponent,
        ApproveVariationComponent,
        ConstructiveSelectionsComponent,
        LoadAddendaPdfComponent,
        ChangeTypeComponent,
        CheckShowRulesReportComponent
    ],
    providers: [
        JobItemService,
        JobService,
        JobVarItemService,
        OptionListService,
        JobItemLinkService,
        BoardMasterService,
        BoardItemService,
        CategoryService,
        EstimatingService,
        JobItemExtraService
    ]
})
export class SelectionsModule { }
