import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { IJobItem } from '../../dtos/job-item';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { PDFReports } from '../../dtos/pdf-report';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { IOptionListHeader } from '../../dtos/option-list-header';
import { ItemTypeEnum } from '../../dtos/item-type.enum';
import { ChangeTypeEnum } from '../../dtos/change-type.enum';
import { SelectionTypeEnum } from '../../dtos/selection-type.enum';
import { OptionListService } from './option-list.service';
import { IJobVarItem } from '../../dtos/job-var-item';
import { PatchReturnTypeEnum } from '../../dtos/patchReturnType.enum';
import { CostTypeEnum } from '../../dtos/cost-type.enum';
import { OptionTypeEnum } from '../../dtos/option-type.enum';
import { AuthService } from '../auth.service';
import { Variation } from '../../dtos/variation';
import { NotificationService } from '../notification.service';
import { JobService } from './job.service';
import { IJobItemWithChildren } from '../../dtos/job-item-with-children';
import { JobVarItemExtra } from '../../dtos/job-var-item-extra';
import { JobVarTypeEnum } from '../../dtos/job-var-type.enum';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';
import { VariationService } from './variation.service';
import { HttpService } from '../http.service';
import { UtilsService } from '../utils.service';
import { SearchResult } from '../../dtos/search-result';
import { ShowHideCheck } from '../../dtos/show-hide-check';

@Injectable()
export class JobItemService {
  private _jobItemUrl = '';

  COMPONENT_NAME = 'JobItemService';

  // storing the jobitems for the current job
  currentJobItemsJobNo = '';
  currentJobItems: IJobItem[] = [];
  currentJobItemsUnfiltered: IJobItem[] = [];
  currentJobItemsThisVO: IJobItem[] = [];
  currentJobVarItemExtras: JobVarItemExtra[]; // we store these for the current variation
  jobVarTypeEnum = JobVarTypeEnum;

  nextLevelItems: IJobItem[] = [];
  addedItems: IJobItem[] = [];
  addedItem: IJobItem;
  filterText = '';
  showOnlyAddsChanges = false; // if in a variaton we can show just the entries in this VO or all the Addendum
  showHiddenLines = false; // hidden lines are not returned from the database unless true
  showSetupLines = true; // setup lines are only shown when the job is not locked but can be hidden
  isLocked = false; // is the current job locked?
  selectionsMode = 'Master'; // or will be set to 'Variations'
  showHistoryOfChanges = false;
  itemTypeEnum = ItemTypeEnum;

  filterByNotSelected = false; // addendum filter test
  filterByUnChecked = false;
  filterByPSItems = false;
  filterByClientUpdatable = false; // filter to show only those items that a customer can change
  showNotPrintedItems = false; // show/hide items that are flagged as Not Printed on Addendum
  showNotApplicableItems = false; // show/hide items that are flagged as Not Applicable on Addendum
  filterByNotSelectedByRep: boolean; // filter for when a quote item is still to be selected by a sales rep
  treeOptionNodes: IOptionListHeader[] = [];
  treeHouseOptionNodes: IOptionListHeader[] = [];
  singleOptionNodes: IOptionListHeader[] = [];
  optionListParentNode: IOptionListHeader;
  optionsListFound: boolean;
  ItemType = ItemTypeEnum;
  changeTypeEnum = ChangeTypeEnum;
  selectionTypeEnum = SelectionTypeEnum;

  // to store the total of a variation that is in the selections area
  currentVariation: Variation; // the current selected variation id
  currentVariationNumber: number; // the current variation - we can emit if linked to from the history text
  currentVariationChanged = new EventEmitter<number>();

  variationTotal = 0;
  variationAdminFee = 0;
  provisionalSum = 0;
  price = 0;
  patchReturnTypeEnum = PatchReturnTypeEnum;
  optionTypeEnum = OptionTypeEnum;
  costTypeEnum = CostTypeEnum;

  // this will tell components that it or another component is waiting an API call to complete so can disable input
  private waitingJobItemAPI = new BehaviorSubject<boolean>(false); // init to false
  waitingJobItemAPI$ = this.waitingJobItemAPI.asObservable();

  waitingSpinner = new BehaviorSubject<boolean>(false); // init to false
  waitingSpinner$ = this.waitingSpinner.asObservable();

  id: number;
  clientItemsToSelectTotal = 0;
  clientItemsSelected = 0;
  isTBARecords: boolean;
  currentSectionHeadings: IJobItem[];
  tempSections: IJobItem[];
  filterTextFromHistoryLink = '';
  variationItemNumber: number;

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private _authService: AuthService,
    private _jobService: JobService,
    private utils: UtilsService,
    private variationService: VariationService,
    private notiService: NotificationService,
    private optionService: OptionListService) { }

  // set the observable value
  setWaitingJobItemAPI(newValue: boolean) {
    this.waitingJobItemAPI.next(newValue);
      this.waitingSpinner.next(newValue);
  }

  getAllJobItems(jobNo, initialMaster, showHiddenLines,
    showHistory, getOnlyThisVO, variationId: number = null): Observable<IJobItem[]> {
    this._jobItemUrl = this.globalService.getApiUrl() + '/jobs/' + jobNo + '/items-all';

    if (initialMaster) {
      this._jobItemUrl += '?initialMaster=true';
    } else {
      this._jobItemUrl += '?initialMaster=false';
    }

    if (variationId) {
      // we use this when we are doing a variation copy otherwise we are NOT passing a variationId
      this._jobItemUrl += '&variationId=' + variationId + '&newmethodwithoptions=true';
      this._jobItemUrl += '&showHiddenLines=false';
    } else {
      this._jobItemUrl += '&showHiddenLines=true';
      if (this.currentVariation && this.currentVariation.id && this.currentVariation.id !== 0) {
        this._jobItemUrl += '&variationId=' + this.currentVariation.id + '&newmethodwithoptions=true';
      }
    }

    if (showHistory) {
      this._jobItemUrl += '&showHistory=true';
    }
    if (getOnlyThisVO) {
      this._jobItemUrl += '&getOnlyThisVO=true';
    }

    return this._http.get<IJobItem[]>(this._jobItemUrl,
      this.httpService.getHttpOptions()).pipe(
        tap((res) => {
          // check for connected items
          res.forEach(element => {
            const connectedItems = res.find(i => i.connectedItemId === element.changedByJobVarId);
            if (connectedItems) {
              element.hasConnectedItems = true;
            }
          });
        }),
        catchError(this.handleError));
  }

  getJobItems(jobNo, idType, id, getChildren, showHiddenLines, isLocked, variationId,
    showHistory, getOnlyThisVO): Observable<IJobItem[]> {

    this._jobItemUrl = this.globalService.getApiUrl() + '/jobs/' + jobNo + '/items';

    this.globalService.setIsGetChildren(getChildren);

    if (idType && idType !== undefined && idType !== 0) {
      this._jobItemUrl += '?jobItemAboveTypeId=' + idType;
    } else {
      this._jobItemUrl += '?jobItemAboveTypeId=1';
    }
    if (id !== 0) {
      this._jobItemUrl += '&jobItemAboveId=' + id;
    }
    if (getChildren) {
      this._jobItemUrl += '&includeChildren=true';
    }
    if (showHiddenLines) {
      this._jobItemUrl += '&showHiddenLines=true';
    }
    if (isLocked) {
      this._jobItemUrl += '&initialMaster=false';
    } else {
      this._jobItemUrl += '&initialMaster=true';
    }
    if (variationId && variationId !== undefined && variationId !== 0) {
      this._jobItemUrl += '&variationId=' + variationId;
    }
    if (showHistory) {
      this._jobItemUrl += '&showHistory=true';
    }
    if (getOnlyThisVO) {
      this._jobItemUrl += '&getOnlyThisVO=true';
    }

    return this._http.get<IJobItem[]>(this._jobItemUrl,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getOneJobItem(jobNo, id, getChildren): Observable<IJobItem[]> {
    this.globalService.setIsGetChildren(getChildren);
    if (getChildren) {
      return this._http.get<IJobItem[]>(this.globalService.getApiUrl() + '/jobs/' + jobNo +
        '/items?includeChildren=true&jobItemId=' + id,
        this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
    } else {
      return this._http.get<IJobItem[]>(this.globalService.getApiUrl() + '/jobs/' + jobNo +
        '/items?jobItemId=' + id,
        this.httpService.getHttpOptions()).pipe(
          catchError(this.handleError));
    }
  }

  isDeletedJobItemFromCurrentList(id: number, variationId: number): boolean {
    let answer = false;
    this.currentJobItemsUnfiltered.forEach(element => {
      if (element.originalItemTypeId === this.jobVarTypeEnum.JobItem && element.id === id
        && element.changedByVOId === variationId
        && element.isHistoryRecord && element.isDeleted) {
        answer = true;
      }
    });

    return answer;
  }

  addJobItem(itm: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/items';
    return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  updateJobItem(itm: any): Observable<IJobItem[]> {
    const url = this.globalService.getApiUrl() + '/job-items/' + itm.id;
    return this._http.patch<IJobItem[]>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteJobItem(itmId: number, confirmDeleteChildren: boolean = false) {
    const url = this.globalService.getApiUrl() + '/job-items/' + itmId + '?adminDeleteChildren=' + confirmDeleteChildren;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  updateByBoard(boardId: number) {
    // update job items by calling the board update patch
    const url = this.globalService.getApiUrl() + '/job-items/' + this.globalService.getCurrentJob() + '/board-items/' + boardId;
    const itm = {};
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  copyJobItems(itm: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/copied-items';
    return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  copyJobItemsFromFinalJob(jobFrom: string) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/copy-final-to-master/' + jobFrom;
    const itm = {};
    return this._http.post(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  copyJobItemSet(jobItemAboveTypeId, jobItemAboveId, orderNo, items: SelectedJobItems, variationId, jobVarTypeId, itemChangedId) {
    if (variationId) {
      this._jobItemUrl = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() +
        '/copy-selected-items-to-vo?orderNo=' + orderNo + '&variationId=' + variationId;
    } else {
      this._jobItemUrl = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() +
        '/copy-selected-items?orderNo=' + orderNo;
    }

    if (jobItemAboveTypeId) {
      this._jobItemUrl += '&jobItemAboveTypeId=' + jobItemAboveTypeId;
    }
    if (jobItemAboveId) {
      this._jobItemUrl += '&jobItemAboveId=' + jobItemAboveId;
    }
    if (jobVarTypeId) {
      this._jobItemUrl += '&jobVarTypeId=' + jobVarTypeId;
    }
    if (itemChangedId) {
      this._jobItemUrl += '&itemChangedId=' + itemChangedId;
    }
    return this._http.post(this._jobItemUrl, JSON.stringify(items), this.httpService.getHttpOptions());
  }

  getOptionListItems(id: number): IOptionListHeader[] {
    this.treeOptionNodes = this.optionService.getCurrentOptionNodes();
    this.singleOptionNodes = [];
    this.optionListParentNode = null;
    this.optionsListFound = false;
    if (this.treeOptionNodes && this.treeOptionNodes.length) {
      this.treeOptionNodes.forEach(element => {
        if (element.id === id) {
          this.optionListParentNode = element;
          this.getChildrenNodes(element);
        } else if (!this.optionsListFound) {
          this.checkChildrenNodes(element, id);
        }
      });
    }

    if (!this.optionsListFound) {
      this.getHouseOptionListItems(id);
    }

    if (this.optionListParentNode === null) {
      this.optionListParentNode = new IOptionListHeader('', 0, null);
      this.optionListParentNode.isQtyRequired = false;
    }

    // use new raw data
    return this.optionService.optionListsRaw.filter(i => i.optionListIdAbove === id && (!i.houseTypeId || i.houseTypeId === this._jobService.currentJob.houseTypeId));
  }

  getHouseOptionListItems(id: number) {
    // console.log('getHouseOptionListItems');
    this.treeHouseOptionNodes = this.optionService.getCurrentHouseOptionNodes(this.globalService.getCurrentJob());

    if (this.treeHouseOptionNodes && this.treeHouseOptionNodes.length) {
      this.treeHouseOptionNodes.forEach(element => {
        if (element.id === id) {
          this.optionListParentNode = element;
          this.getChildrenNodes(element);
        } else if (!this.optionsListFound) {
          this.checkChildrenNodes(element, id);
        }
      });
    }
  }

  checkChildrenNodes(node: IOptionListHeader, id: number): void {
    node.children.forEach(element => {
      if (element.id === id) {
        this.optionListParentNode = element;
        this.getChildrenNodes(element);
      } else if (!this.optionsListFound) {
        this.checkChildrenNodes(element, id);
      }
    });
  }

  getOptionListItemById(id: number): IOptionListHeader {
    this.treeOptionNodes = this.optionService.getCurrentOptionNodes();
    let foundItem: IOptionListHeader;

    if (id && this.treeOptionNodes && this.treeOptionNodes.length) {
      this.treeOptionNodes.forEach(element => {
        if (element.id === id) {
          foundItem = element;
        } else if (!foundItem) {
          foundItem = this.checkChildrenNodesforItem(element, id);
        }
      });
    }

    // set type to priced for enums to work if price not null
    if (foundItem && !foundItem.salesPriceTypeIfChangedInSameListId) {
      foundItem.salesPriceTypeIfChangedInSameListId = this.costTypeEnum.Priced;
    }

    if (!foundItem || !foundItem.id) {
      foundItem = this.getHouseOptionListItemById(id);
    }
    return foundItem;
  }

  getHouseOptionListItemById(id: number): IOptionListHeader {
    this.treeHouseOptionNodes = this.optionService.getCurrentHouseOptionNodes(this.globalService.getCurrentJob());
    let foundItem: IOptionListHeader;

    if (id && this.treeHouseOptionNodes) {
      this.treeHouseOptionNodes.forEach(element => {
        if (element.id === id) {
          foundItem = element;
        } else if (!foundItem) {
          foundItem = this.checkChildrenNodesforItem(element, id);
        }
      });
    }

    // set type to priced for enums to work if price not null
    if (foundItem && !foundItem.salesPriceTypeIfChangedInSameListId) {
      foundItem.salesPriceTypeIfChangedInSameListId = this.costTypeEnum.Priced;
    }

    return foundItem;
  }

  checkChildrenNodesforItem(node: IOptionListHeader, id: number): IOptionListHeader {
    let foundItem: IOptionListHeader;
    node.children.forEach(element => {
      if (element.id === id) {
        foundItem = element;
      } else if (!foundItem) {
        foundItem = this.checkChildrenNodesforItem(element, id);
      }
    });

    return foundItem;
  }

  getChildrenNodes(node: IOptionListHeader): void {
    // console.log('jobitem service - getChildrenNodes - found node: ' + node.id + ' desc:' + node.description);
    if (node.children) {
      node.children.forEach((child) => {
        if (child.isActive) {
          // this.singleOptionNodes.push(child);
          this.singleOptionNodes = this.singleOptionNodes.concat(child);
        }
      });
    }
    this.optionsListFound = true;
  }

  getItemsNotSelectedReport(jobNumber, userId, includeTestJobs: boolean): Observable<PDFReports> {
    let url = this.globalService.getApiUrl() + '/job-items/notselected-report?includeTestJobs=' + includeTestJobs;
    if (jobNumber) {
      url += '&jobNumber=' + jobNumber;
    }
    if (userId) {
      url += '&userId=' + userId;
    }
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getOutstandingPSReport(jobNumber, includeTestJobs: boolean, userId: number): Observable<PDFReports> {
    let url = this.globalService.getApiUrl() + '/job-items/outstandingPS-report?includeTestJobs=' + includeTestJobs;
    if (jobNumber) {
      url += '&jobNumber=' + jobNumber;
    }
    if (userId) {
      url += '&userId=' + userId;
    }
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getjobItemsChangedReport(jobNumber: string, userId: number): Observable<PDFReports> {
    let url = this.globalService.getApiUrl() + '/reports/jobs/' + jobNumber + '/modified-items';
    if (jobNumber) {
      url += '?jobNumber=' + jobNumber;
      if (userId) {
        url += '&modifiedUserId=' + userId;
      }
    } else if (userId) {
      url += '?modifiedUserId=' + userId;
    }
    return this._http.get<PDFReports>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getJobItemCheckRuleItems(jobId: number, updateRecords: number[]): Observable<ShowHideCheck[]> {
    const url = this.globalService.getApiUrl() + '/job-items-checkshowhide?jobId=' + jobId + '&updateRecords=' + updateRecords;
    return this._http.patch<ShowHideCheck[]>(url, JSON.stringify(updateRecords), this.httpService.getHttpOptions());
  }

  searchJobItems(selectedOptionListId: number, searchText: string,
    activeJobsOnly: boolean, includeChildOptions: boolean, includeChildOptionsOnly: boolean,
    includeChangedItems: boolean, jobId: number): Observable<SearchResult[]> {
    const url = this.globalService.getApiUrl() + '/job-items/search?activeJobsOnly=' + activeJobsOnly;

    return this._http.post<SearchResult[]>(url,
      JSON.stringify({
        optionId: selectedOptionListId,
        searchString: searchText,
        includeChildOptions: includeChildOptions,
        includeChildOptionsOnly: includeChildOptionsOnly,
        includeChangedItems: includeChangedItems,
        jobId: jobId
      }),
      this.httpService.getHttpOptions());
  }

  clearJobItemHighlighting(jobId: number) {
    const url = this.globalService.getApiUrl() + '/job-items/clear-highlighting?jobId=' + jobId;
    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  readJobItems() {
    // return hidden lines then filter them out
    this.getAllJobItems(this._jobService.currentJob.jobNumber, false, true,
      this.showHistoryOfChanges, this.showOnlyAddsChanges)
      .subscribe(
        jobItemsRaw => {
          this.currentJobItemsUnfiltered = jobItemsRaw;
          if (this.currentJobItemsUnfiltered && this.currentJobItemsUnfiltered.length) {
            this.showSetupLinesFilter();
          } else {
            this.currentJobItems = [];
            this.currentSectionHeadings = [];
          }
        },
        err => {
          this.notiService.notify(err);
        });
  }

  showSetupLinesFilter() {
    // filter the jobitmes
    // ok to continue - user hasn't clicked the goto variations mode.
    // To filter out Not Applicable items we need to check if the job is locked,
    // and the item is not in the current variation if we have one open
    // 12-6-20 GH Added the ability to hide sections in a variation by type

    // 6-3-22 - add variation item numbers - reset first
    this.currentJobItemsUnfiltered.forEach(element => {
      element.variationItemNumber = null;
    });
    this.variationItemNumber = 1;

    // 16-3-21 clear section headings
    if (!this.currentJobItems || !this.currentJobItems.length) {
      this.currentSectionHeadings = null;
    }

    const originalLength = this.currentJobItems.length;
    let iRaw = 0;
    let testSelection = '';
    let testItemDescription = '';

    this.clientItemsToSelectTotal = 0;
    this.clientItemsSelected = 0;

    let variationId = null;
    if (this.currentVariation) {
      variationId = this.currentVariation.id;

      if (this.currentVariation.statusId < VariationStatusEnum.PendingApproval) {
        this.variationTotal = this.variationAdminFee;
      } else {
        this.variationTotal = this.currentVariation.variationTotal;
      }
      this.isTBARecords = false;
    }

    // set variation numbering
    if (variationId) {
      const topLevelItems = this.currentJobItemsUnfiltered.filter(i => !i.jobItemAboveId);
      topLevelItems.forEach(element => {
        if (element.itemTypeId === ItemTypeEnum.Heading && !element.isHistoryRecord) {
          this.setVariationNumbering(variationId, element.originalItemTypeId, element.id);
        }
      });
    }

    if (!variationId || !this.showOnlyAddsChanges) {
      this.currentJobItemsThisVO = this.currentJobItemsUnfiltered;
    } else {
      // filter for this VO only
      this.currentJobItemsThisVO = [];
      this.currentJobItemsUnfiltered.forEach(element => {
        if (!element.jobItemAboveId) {
          if (element.itemTypeId === ItemTypeEnum.Heading && !element.isHistoryRecord) {
            this.nextLevelItems = this.checkNextLevel(variationId, element.originalItemTypeId, element.id);
            if (this.nextLevelItems.length) {
              this.currentJobItemsThisVO = this.currentJobItemsThisVO.concat(element);
              this.currentJobItemsThisVO = this.currentJobItemsThisVO.concat(this.nextLevelItems);
            } else if (element.changedByVOId === variationId) {
              this.currentJobItemsThisVO = this.currentJobItemsThisVO.concat(element);
            }
          } else if (element.changedByVOId === variationId) {
            this.currentJobItemsThisVO = this.currentJobItemsThisVO.concat(element);
          }
        }
      });
    }

    // console.log(this.currentJobItemsUnfiltered.filter(i => i.jobItemAboveId === 2));
    this.currentJobItemsThisVO.forEach(item => {

      if ((this.showSetupLines || !item.isSetUpLine)
        && (this.showHiddenLines || !item.isDeleted)
        && (this.showNotPrintedItems || !item.isDoNotPrint)) {

        if (!item.selection
          || (item.itemTypeId === this.itemTypeEnum.Detail && item.selectionTypeId === this.selectionTypeEnum.Checkbox)) {
          testSelection = '';
        } else {
          testSelection = item.selection.toLowerCase().trim();
        }

        if (!this.isLocked
          || (this.selectionsMode === 'Master'
            // we want to hide that items are Not Applicable but show Not Applicable for history
            && ((this.showHistoryOfChanges && item.changedByVOId
              && ((!this._authService.isClient() && !this._authService.isAssociate()) || item.isHistoryRecord))
              || (this.showNotApplicableItems || testSelection !== 'not applicable')))
          || (this.selectionsMode === 'Variations'
            && (item.changedByVOId === variationId || (this.showNotApplicableItems || testSelection !== 'not applicable')))) {

          // we hide not selected history items for clients and trades
          if (!(this._authService.isClient() || this._authService.isAssociate())
            || !(item.itemTypeId === this.ItemType.Detail
              && item.selectionTypeId === this.selectionTypeEnum.Dropdown
              && item.isHistoryRecord
              && (!item.selection || item.selection === ''))) {

            if (this.selectionsMode === 'Variations' && item.changedByVOId === variationId && !item.itemNotTaken) {
              if (item.price) {
                this.price = item.price;
              } else {
                this.price = 0;
              }
              if (item.provisionalSum) {
                this.provisionalSum = item.provisionalSum;
              } else {
                this.provisionalSum = 0;
              }

              if (!(item.costTypeId > CostTypeEnum.Priced && item.costTypeId !== CostTypeEnum.EOStandard
                && item.costTypeId !== this.costTypeEnum.Included)
                && !item.provisionalSum && (item.price === null || item.price === 0)
                && (!item.isHistoryRecord || item.changeTypeId === ChangeTypeEnum.Delete)) {
                this.isTBARecords = true;
              }

              if (this.price
                && this.currentVariation.statusId < VariationStatusEnum.PendingApproval
                && (!this._authService.isClient() || item.costTypeId !== this.costTypeEnum.Included)) {
                // if we are a client and item is included in base price we don't add
                if (this.headingNotHidden(item.jobItemAboveTypeId, item.jobItemAboveId)) {
                  if (item.isHistoryRecord) {
                    // this.variationTotal -= this.provisionalSum;
                    if (item.changeTypeId === this.changeTypeEnum.Delete) {
                      this.variationTotal += this.price;
                    }
                  } else {
                    if (!this._authService.isClient() || item.isChecked) {
                      this.variationTotal += this.price;
                    } else {
                      this.isTBARecords = true;
                    }
                  }
                }
              }
            }

            if (!item.itemDescription) {
              testItemDescription = '';
            } else {
              testItemDescription = item.itemDescription.toLowerCase();
            }

            if ((this.filterByNotSelected
              && (item.itemTypeId === this.ItemType.Detail
                && item.selectionTypeId === this.selectionTypeEnum.Dropdown
                && !item.isHistoryRecord
                && (!item.selection || item.selection === '' || this.checkNotSelected(item))))
              || (!this.filterByNotSelected && !this.filterByPSItems
                && !this.filterByUnChecked && !this.filterByClientUpdatable
                && !this.filterByNotSelectedByRep
                && (this.filterText === ''
                  || testItemDescription.search(this.filterText) >= 0
                  || testSelection.search(this.filterText) >= 0))
              || (this.filterByPSItems && ((item.provisionalSum && item.provisionalSum !== 0)
                || item.costTypeId === this.costTypeEnum.ProvisionalSum
                || item.costTypeId === this.costTypeEnum.PSFinalised))
              || (this.filterByClientUpdatable
                && (this.isItemClientUpdatable(item, variationId)))
              || (this.filterByUnChecked && !item.isChecked && !item.hasLinkedItems
                && !(item.itemTypeId === this.ItemType.Heading)
                && item.changedByVOId === variationId && !item.itemNotTaken
                && (!item.isHistoryRecord || item.changeTypeId === ChangeTypeEnum.Delete))
              || (this.filterByNotSelectedByRep
                && (item.itemTypeId === this.ItemType.Detail
                  && item.selectionTypeId === this.selectionTypeEnum.Dropdown
                  && !item.isHistoryRecord
                  && (!item.selection || item.selection === '')
                  && this.checkNotSelectedByRep(item.optionListId)))
              || (item.itemTypeId === this.ItemType.Heading
                && this.filterHeading(variationId, item.originalItemTypeId, item.id))) {

              // check we have a hidden section in a variation
              const idToHide = this.variationService.hiddenSectionsInVariation.filter(
                i => item.jobItemAboveTypeId !== this.jobVarTypeEnum.JobVarItem && i.jobItemId === item.id);
              if (!variationId || !idToHide[0]) {
                if (this.currentJobItems.length !== this.currentJobItemsThisVO.length ||
                  this.currentJobItems[iRaw].id !== item.id) {
                  this.currentJobItems[iRaw] = item;
                } else {
                  // need to update each field so that the DOM doesn't kill and
                  // recreate the element which causes the screen to 'jump'
                  this.updateSingleItem(iRaw, item, originalLength);
                }
                iRaw++;
              }
            }
          }
        }
      }
    });
    // now if we have more items in jobItems then delete them
    if (iRaw < this.currentJobItems.length) {
      // this.currentJobItems.pop();
      this.currentJobItems = this.currentJobItems.slice(0, iRaw);
    }

    // console.log('finished setupfilter');

    if (this.filterByClientUpdatable) {
      // count items for cleint entry
      this.currentJobItems.forEach(item => {
        if (!item.isHistoryRecord && item.optionListId && !item.hasLinkedItems) {
          this.clientItemsToSelectTotal += 1;
          if (item.selection && item.selection !== '' && !this.checkNotSelected(item)) {
            this.clientItemsSelected += 1;
          }
        }
      });
    }

    this.currentSectionHeadings = this.currentJobItems.filter(i => i.jobItemAboveId === null);

    this.setWaitingJobItemAPI(false); // notify  components that the call has completed
  }

  doesHeaderHaveMissingClientSelections(itemHeader: IJobItem) {
    return this.doesHeaderHaveMissingClientSelectionsRecurse(this.currentJobItems, [itemHeader.id]);
  }

  doesHeaderHaveMissingClientSelectionsRecurse(items: IJobItem[], parentsToCheck: number[]): boolean {
    const nextParentsToCheck = [];
    for (let i = 0; i < items.length; i++) {
      if (parentsToCheck.includes(items[i].jobItemAboveId)) {
        if (this.doesItemHaveMissingClientSelection(items[i])) {
          return true;
        } else {
          nextParentsToCheck.push(items[i].id);
        }
      }
    }

    if (nextParentsToCheck.length === 0) {
      return false;
    }

    // there are multiple items with the same id (different selections) so return distinct set
    return this.doesHeaderHaveMissingClientSelectionsRecurse(items, [... new Set(nextParentsToCheck)]);
  }

  doesItemHaveMissingClientSelection(item: IJobItem): boolean {
    if (!item.isHistoryRecord && item.optionListId) {
      if (!item.selection || item.selection === '' || this.checkNotSelected(item)) {
        return true;
      }
    }
    return false;
  }

  headingNotHidden(jobItemAboveTypeId: number, jobItemAboveId: number): boolean {
    const aboveTypeID = jobItemAboveTypeId ? jobItemAboveTypeId : this.jobVarTypeEnum.JobItem;

    let result = true;
    if (jobItemAboveId !== null) {
      const jobVarItem = this.currentJobItemsUnfiltered
        .filter(i => i.originalItemTypeId === aboveTypeID && i.id === jobItemAboveId);
      if (jobVarItem[0]) {
        if (jobVarItem[0].isDeleted) {
          result = false;
        } else {
          result = this.headingNotHidden(jobVarItem[0].jobItemAboveTypeId, jobVarItem[0].jobItemAboveId);
        }
      } else {
        result = false;
      }
    }
    return result;
  }


  isItemClientUpdatable(item: IJobItem, variationId: number): boolean {
    if (!item.isHistoryRecord
      && (item.changedByVOId === variationId
        || (item.optionListId && this.getOptionListItemById(item.optionListId)?.childItemSelectableByClient
          && this.currentVariation?.canBeModifiedByClient))) {
      return true;
    } else {
      return false;
    }
  }

  // checkNextLevelForClientUpdatable(headingTypeId: number, headingId: number): boolean {
  //     // does this next level have children in this VO?
  //     let answer = false;
  //     this.currentJobItemsUnfiltered.forEach(element => {
  //         if (element.jobItemAboveTypeId === headingTypeId && element.jobItemAboveId === headingId) {
  //             if (element.optionListId && this.getOptionListItemById(element.optionListId).childItemSelectableByClient
  //                 && this.currentVariation && this.currentVariation.canBeModifiedByClient) {
  //                 answer = true;
  //             } else if (element.itemTypeId === this.ItemType.Heading) {
  //                 return this.checkNextLevelForClientUpdatable(element.originalItemTypeId, element.id);
  //             }
  //         }
  //     });
  //     return answer;
  // }

  checkNotSelected(item: IJobItem): boolean {
    let resultValue = false;
    if (item.selectedOptionId) {
      const option = this.getOptionListItemById(item.selectedOptionId);
      if (option && option.optionTypeId && option.optionTypeId === this.optionTypeEnum.ToBeSelectedAtPreStart) {
        resultValue = true;
      }
    }
    return resultValue;
  }

  checkNotSelectedByRep(optionListId: number): boolean {
    let resultValue = false;
    if (optionListId) {
      const optionRecord = this.getOptionListItemById(optionListId);
      if (optionRecord && optionRecord.optionTypeId
        && optionRecord.optionTypeId === this.optionTypeEnum.NotValidTillSelected) {
        resultValue = true;
      }
    }
    return resultValue;
  }

  checkNextLevel(variationId, headingTypeId, headingId): IJobItem[] {
    // does this next level have children in this VO?
    let thisLevelItems = [];
    this.currentJobItemsUnfiltered.forEach(element => {
      if (((element.jobItemAboveTypeId === null && headingTypeId === 1) || element.jobItemAboveTypeId === headingTypeId)
        && element.jobItemAboveId === headingId) {
        if (element.itemTypeId === ItemTypeEnum.Heading && !element.isHistoryRecord) {
          this.nextLevelItems = this.checkNextLevel(variationId, element.originalItemTypeId, element.id);
          if (this.nextLevelItems.length) {
            thisLevelItems = thisLevelItems.concat(element);
            thisLevelItems = thisLevelItems.concat(this.nextLevelItems);
          } else if (element.changedByVOId === variationId) {
            thisLevelItems = thisLevelItems.concat(element);
          }
        } else if (element.changedByVOId === variationId) {
          thisLevelItems = thisLevelItems.concat(element);
        }
      }
    });
    return thisLevelItems;
  }

  setVariationNumbering(variationId, headingTypeId, headingId) {
    const thisLevelItems = this.currentJobItemsUnfiltered
      .filter(i => ((i.jobItemAboveTypeId === null && headingTypeId === 1) || i.jobItemAboveTypeId === headingTypeId)
        && i.jobItemAboveId === headingId);

    thisLevelItems.forEach(element => {
      if (element.itemTypeId === ItemTypeEnum.Heading) {
        if (!element.isHistoryRecord) {
          this.setVariationNumbering(variationId, element.originalItemTypeId, element.id);
        }
      } else if (element.changedByVOId === variationId) {
        if (!element.connectedItemId && this.isLinkedItemInVO(element.linkedJobItemId, variationId)
          && (element.isHistoryRecord || element.changeTypeId === ChangeTypeEnum.Add)
          && (!element.optionNumber || element.optionNumber === 1)) {
          element.variationItemNumber = this.variationItemNumber;
          this.variationItemNumber++;
        }
      }
    });
  }

  isLinkedItemInVO(linkedJobItemId: number, variationId: number): boolean {
    if (linkedJobItemId) {
      const existingItem = this.currentJobItemsUnfiltered.find(i => i.id === linkedJobItemId && i.changedByVOId === variationId);
      if (existingItem) {
        return false;
      }
    }
    return true;
  }

  private updateSingleItem(iRaw: number, item: IJobItem, originalLength: number) {
    // console.log('updateSingleItem');
    // console.log(this.currentJobItems[iRaw].itemDescription);
    // console.log(item.itemDescription);
    if (iRaw > originalLength) {
      // this.currentJobItems.push(item);
      this.currentJobItems = this.currentJobItems.concat(item);
    } else {
      this.currentJobItems[iRaw].id = item.id;
      this.currentJobItems[iRaw].jobId = item.jobId;
      this.currentJobItems[iRaw].jobVariationId = item.jobVariationId;
      this.currentJobItems[iRaw].jobItemAboveId = item.jobItemAboveId;
      this.currentJobItems[iRaw].orderNo = item.orderNo;
      this.currentJobItems[iRaw].itemTypeId = item.itemTypeId;
      this.currentJobItems[iRaw].itemDescription = item.itemDescription;
      this.currentJobItems[iRaw].selectionTypeId = item.selectionTypeId;
      this.currentJobItems[iRaw].canEnterManualSelection = item.canEnterManualSelection;
      this.currentJobItems[iRaw].linkedJobItemId = item.linkedJobItemId;
      this.currentJobItems[iRaw].connectedJobItemId = item.connectedJobItemId;
      this.currentJobItems[iRaw].selectedOptionId = item.selectedOptionId;
      this.currentJobItems[iRaw].selection = item.selection;
      this.currentJobItems[iRaw].costTypeId = item.costTypeId;
      this.currentJobItems[iRaw].provisionalSum = item.provisionalSum;
      this.currentJobItems[iRaw].price = item.price;
      this.currentJobItems[iRaw].isSetUpLine = item.isSetUpLine;
      this.currentJobItems[iRaw].setUpTags = item.setUpTags;
      // this.currentJobItems[iRaw].imageUrl = item.imageUrl;
      this.currentJobItems[iRaw].isDeleted = item.isDeleted;
      this.currentJobItems[iRaw].changedByVO = item.changedByVO;
      this.currentJobItems[iRaw].changedByVOId = item.changedByVOId;
      this.currentJobItems[iRaw].changedByJobVarId = item.changedByJobVarId;
      this.currentJobItems[iRaw].changeTypeId = item.changeTypeId;
      this.currentJobItems[iRaw].jobItemAboveTypeId = item.jobItemAboveTypeId;
      this.currentJobItems[iRaw].jobVarTypeId = item.jobVarTypeId;
      this.currentJobItems[iRaw].itemChangedId = item.itemChangedId;
      this.currentJobItems[iRaw].originalItemTypeId = item.originalItemTypeId;
      this.currentJobItems[iRaw].isHistoryRecord = item.isHistoryRecord;
      this.currentJobItems[iRaw].itemNotTaken = item.itemNotTaken;
      this.currentJobItems[iRaw].deleteComment = item.deleteComment;
      this.currentJobItems[iRaw].attachmentName = item.attachmentName;
      this.currentJobItems[iRaw].attachmentId = item.attachmentId;
      this.currentJobItems[iRaw].attachmentTypeId = item.attachmentTypeId;
      this.currentJobItems[iRaw].isBoldNote = item.isBoldNote;
      this.currentJobItems[iRaw].isItalicNote = item.isItalicNote;
      this.currentJobItems[iRaw].noteColour = item.noteColour;
      this.currentJobItems[iRaw].attachmentVariationId = item.attachmentVariationId;
      this.currentJobItems[iRaw].optionListAttachmentId = item.optionListAttachmentId;
      this.currentJobItems[iRaw].isBoldText = item.isBoldText;
      this.currentJobItems[iRaw].optionColour = item.optionColour;
      this.currentJobItems[iRaw].isHiddenFromMaster = item.isHiddenFromMaster;
      this.currentJobItems[iRaw].isChecked = item.isChecked;
      this.currentJobItems[iRaw].isConfirmedbyCustomer = item.isConfirmedbyCustomer;
      this.currentJobItems[iRaw].isDoNotPrint = item.isDoNotPrint;
      this.currentJobItems[iRaw].connectedItemId = item.connectedItemId;
      this.currentJobItems[iRaw].previousItemDescription = item.previousItemDescription;
      this.currentJobItems[iRaw].previousSelection = item.previousSelection;
      this.currentJobItems[iRaw].previousSelectedOptionId = item.previousSelectedOptionId;
      this.currentJobItems[iRaw].previousIsDeleted = item.previousIsDeleted;
      this.currentJobItems[iRaw].previousQuantity = item.previousQuantity;
      this.currentJobItems[iRaw].previousOptionListId = item.previousOptionListId;
      this.currentJobItems[iRaw].hasLinkedItems = item.hasLinkedItems;
      this.currentJobItems[iRaw].masterItemId = item.masterItemId;
      this.currentJobItems[iRaw].optionNumber = item.optionNumber;
      this.currentJobItems[iRaw].varOptionId = item.varOptionId;
      this.currentJobItems[iRaw].hideFixedPSum = item.hideFixedPSum;
      this.currentJobItems[iRaw].optionImageId = item.optionImageId;
      this.currentJobItems[iRaw].commentTypeId = item.commentTypeId;
      this.currentJobItems[iRaw].jobItemCommentId = item.jobItemCommentId;
      this.currentJobItems[iRaw].quantity = item.quantity;

      if (item.optionListId === null) {
        this.currentJobItems[iRaw].optionListId = null;
        this.currentJobItems[iRaw].isQtyRequired = false;
      } else if (this.currentJobItems[iRaw].optionListId !== item.optionListId) {
        this.currentJobItems[iRaw].optionListId = item.optionListId;
        this.currentJobItems[iRaw].isQtyRequired = this.checkIsQtyReuired(item.optionListId);
      }

      if (item.selectedOptionId) {
        const option = this.getOptionListItemById(item.selectedOptionId);
        if (option && option.printedDescription) {
          this.currentJobItems[iRaw].printedDescription = option.printedDescription;
        } else {
          this.currentJobItems[iRaw].printedDescription = null;
        }
      } else {
        this.currentJobItems[iRaw].printedDescription = null;
      }
    }
  }

  updateChangedItems(updatedItems: IJobItem[], variationId: number, resortItems: boolean,
    resortItemParentTypeId: number, resortItemParentId: number) {
    this.currentJobItemsUnfiltered.forEach(item => {
      updatedItems.forEach(changedElement => {
        if (item.id === changedElement.id) {
          item.jobItemAboveId = changedElement.jobItemAboveId;
          item.orderNo = changedElement.orderNo;
          item.itemTypeId = changedElement.itemTypeId;
          item.itemDescription = changedElement.itemDescription;
          item.selectionTypeId = changedElement.selectionTypeId;
          item.canEnterManualSelection = changedElement.canEnterManualSelection;
          item.linkedJobItemId = changedElement.linkedJobItemId;
          item.connectedJobItemId = changedElement.connectedJobItemId;
          item.selectedOptionId = changedElement.selectedOptionId;
          item.selection = changedElement.selection;
          item.costTypeId = changedElement.costTypeId;
          item.provisionalSum = changedElement.provisionalSum;
          item.price = changedElement.price;
          item.isSetUpLine = changedElement.isSetUpLine;
          item.setUpTags = changedElement.setUpTags;
          // item.imageUrl = changedElement.imageUrl;
          item.isDeleted = changedElement.isDeleted;
          item.changedByVO = changedElement.changedByVO;
          item.changedByVOId = changedElement.changedByVOId;
          item.changedByJobVarId = changedElement.changedByJobVarId;
          item.changeTypeId = changedElement.changeTypeId;
          item.jobVarTypeId = changedElement.jobVarTypeId;
          item.itemChangedId = changedElement.itemChangedId;
          item.originalItemTypeId = changedElement.originalItemTypeId;
          item.isHistoryRecord = changedElement.isHistoryRecord;
          item.itemNotTaken = changedElement.itemNotTaken;
          item.deleteComment = changedElement.deleteComment;
          item.attachmentName = changedElement.attachmentName;
          item.attachmentId = changedElement.attachmentId;
          item.attachmentTypeId = changedElement.attachmentTypeId;
          item.isBoldNote = changedElement.isBoldNote;
          item.isItalicNote = changedElement.isItalicNote;
          item.noteColour = changedElement.noteColour;
          item.attachmentVariationId = changedElement.attachmentVariationId;
          item.optionListAttachmentId = changedElement.optionListAttachmentId;
          item.isBoldText = changedElement.isBoldText;
          item.optionColour = changedElement.optionColour;
          item.isHiddenFromMaster = changedElement.isHiddenFromMaster;
          item.isChecked = changedElement.isChecked;
          item.isConfirmedbyCustomer = changedElement.isConfirmedbyCustomer;
          item.isDoNotPrint = changedElement.isDoNotPrint;
          item.connectedItemId = changedElement.connectedItemId;
          item.previousItemDescription = changedElement.previousItemDescription;
          item.previousSelection = changedElement.previousSelection;
          item.previousSelectedOptionId = changedElement.previousSelectedOptionId;
          item.previousIsDeleted = changedElement.previousIsDeleted;
          item.previousOptionListId = changedElement.previousOptionListId;
          item.previousQuantity = changedElement.previousQuantity;
          item.hasLinkedItems = changedElement.hasLinkedItems;
          item.masterItemId = changedElement.masterItemId;
          item.optionNumber = changedElement.optionNumber;
          item.varOptionId = changedElement.varOptionId;
          item.hideFixedPSum = changedElement.hideFixedPSum;
          item.optionImageId = changedElement.optionImageId;
          item.commentTypeId = changedElement.commentTypeId;
          item.jobItemCommentId = changedElement.jobItemCommentId;
          item.quantity = changedElement.quantity;

          if (changedElement.optionListId === null) {
            item.optionListId = null;
            item.isQtyRequired = false;
          } else if (item.optionListId !== changedElement.optionListId) {
            item.optionListId = changedElement.optionListId;
            item.isQtyRequired = this.checkIsQtyReuired(changedElement.optionListId);
          }

          if (changedElement.selectedOptionId) {
            const option = this.getOptionListItemById(changedElement.selectedOptionId);
            if (option && option.printedDescription) {
              item.printedDescription = option.printedDescription;
            } else {
              item.printedDescription = null;
            }
          } else {
            item.printedDescription = null;
          }
        }
      });
    });

    if (resortItems) {
      this.sortJobItems();
    }

    this.showSetupLinesFilter();
  }

  updateChangedVarItems(updatedItems: IJobVarItem[], variationId: number,
    variationNumber: number) {

    // updatedItems.forEach(element => {
    //     console.log('returned: ' + JSON.stringify(element));
    // });

    let isAddedItems = false;
    this.addedItems = []; // reset

    this.currentJobItemsUnfiltered.forEach(item => {
      updatedItems.forEach(changedElement => {
        if (item.changedByJobVarId === changedElement.id) {
          if (item.isHistoryRecord) {
            if (changedElement.patchReturnTypeId === this.patchReturnTypeEnum.Deleted) {
              // the record has been deleted so the history is now the valid one
              item.isHistoryRecord = false;
            } else {
              item.itemNotTaken = changedElement.itemNotTaken;
            }

            if (changedElement.patchReturnTypeId === this.patchReturnTypeEnum.Deleted
              || item.changeTypeId === this.changeTypeEnum.Delete) {
              item.costTypeId = changedElement.costTypeId;
              item.price = changedElement.price;
              item.isChecked = changedElement.isChecked;
              item.isConfirmedbyCustomer = changedElement.isConfirmedbyCustomer;
              item.itemNotTaken = changedElement.itemNotTaken;
              item.deleteComment = changedElement.deleteComment;
              item.quantity = changedElement.quantity;
              item.linkedJobItemId = changedElement.linkedJobItemId;
              item.hasLinkedItems = changedElement.hasLinkedItems;
            }
          } else if (changedElement.patchReturnTypeId === this.patchReturnTypeEnum.Changed) {
            item.itemDescription = changedElement.itemDescription;
            item.selectedOptionId = changedElement.selectedOptionId;
            item.selection = changedElement.selection;
            item.costTypeId = changedElement.costTypeId;
            item.price = changedElement.price;
            item.isDeleted = changedElement.isDeleted;
            item.itemNotTaken = changedElement.itemNotTaken;
            item.deleteComment = changedElement.deleteComment;
            item.attachmentName = changedElement.attachmentName;
            item.attachmentId = changedElement.attachmentId;
            item.attachmentTypeId = changedElement.attachmentTypeId;
            item.isBoldNote = changedElement.isBoldNote;
            item.isItalicNote = changedElement.isItalicNote;
            item.noteColour = changedElement.noteColour;
            item.isBoldText = changedElement.isBoldNote;
            item.optionColour = changedElement.noteColour;
            item.isHiddenFromMaster = changedElement.isHiddenFromMaster;
            item.isChecked = changedElement.isChecked;
            item.isConfirmedbyCustomer = changedElement.isConfirmedbyCustomer;
            item.isDoNotPrint = changedElement.isDoNotPrint;
            item.connectedItemId = changedElement.connectedItemId;
            item.hideFixedPSum = changedElement.hideFixedPSum;
            item.quantity = changedElement.quantity;
            item.optionImageId = changedElement.optionImageId;
            item.commentTypeId = changedElement.commentTypeId;
            item.jobItemCommentId = changedElement.jobItemCommentId;
            item.optionListAttachmentId = changedElement.optionListAttachmentId;
            item.linkedJobItemId = changedElement.linkedJobItemId;
            item.hasLinkedItems = changedElement.hasLinkedItems;

            if (item.optionListId !== changedElement.optionListId) {
              if (changedElement.optionListId === null) {
                item.optionListId = null;
                item.isQtyRequired = false;
              } else {
                item.optionListId = changedElement.optionListId;
                item.isQtyRequired = this.checkIsQtyReuired(item.optionListId);
              }
            }

            // get the printed description
            if (changedElement.selectedOptionId) {
              const option = this.getOptionListItemById(changedElement.selectedOptionId);
              if (option && option.printedDescription) {
                item.printedDescription = option.printedDescription;
              } else {
                item.printedDescription = null;
              }
            } else {
              item.printedDescription = null;
            }

          } else if (changedElement.patchReturnTypeId === this.patchReturnTypeEnum.Added) {
            this.createAddedItem(item, changedElement, variationNumber, variationId);
            isAddedItems = true;
          }
        } else if (changedElement.patchReturnTypeId === this.patchReturnTypeEnum.Added
          && item.originalItemTypeId === changedElement.originalItemTypeId && item.id === changedElement.originalItemId) {

          // console.log('found matching item to added one - item: ' + JSON.stringify(item));
          // console.log('found matching item to added one - changedElement: ' + JSON.stringify(changedElement));

          this.createAddedItem(item, changedElement, variationNumber, variationId);
          item.isHistoryRecord = true; // must be setting this to true for added records
          item.changedByVO = variationNumber;
          item.changedByVOId = variationId;
          item.changedByJobVarId = changedElement.id;
          item.optionListId = changedElement.optionListId;
          isAddedItems = true;
        }
      });
    });

    // now add any new records - these are new records that change or delete an existing item
    if (isAddedItems) {
      this.addedItems.forEach(addItem => {

        // console.log('job-item-service - adding item: ' + JSON.stringify(addItem));

        // find where it needs to go
        let i = 1;
        let foundItem = 0;
        let foundLinkedItem = false;
        let foundLinkedHistoryItem = false; // if we are changing a set we need to find the bottom
        let linkedItemId = 0;

        this.currentJobItemsUnfiltered.forEach(existingItem => {
          if (foundLinkedHistoryItem) {
            // we are now finding the linked item to place it after that
            if (existingItem.id === linkedItemId) {
              foundItem = i;
              foundLinkedHistoryItem = false; // to stop this check
            }
          } else if (foundLinkedItem) {
            if (existingItem.linkedJobItemId === linkedItemId) {
              if (!existingItem.hasLinkedItems) {
                foundItem = i;
                foundLinkedItem = false; // to stop this check
              } else {
                linkedItemId = existingItem.id;
              }
            }
          } else if (foundItem === 0) {
            if (existingItem.originalItemTypeId === addItem.originalItemTypeId
              && existingItem.id === addItem.id) {

              if (existingItem.hasLinkedItems) {
                // we need to find the bottom to add there
                foundLinkedItem = true;
                linkedItemId = existingItem.id;
              } else {
                foundItem = i;
                // console.log('foundItem = ' + foundItem);
              }
            } else if (existingItem.id === addItem.linkedJobItemId && existingItem.isHistoryRecord) {
              // we've found a set - if this item is a history item then we need to find the non-history item
              // to place this item after that
              // if this is not a history item we can ignore so we can find the original item
              foundLinkedHistoryItem = true;
              linkedItemId = existingItem.id;
            }
          }
          i++;
        });

        // insert this item
        // console.log('foundItem = ' + foundItem);
        // this.currentJobItemsUnfiltered.splice(foundItem, 0, addItem);
        this.currentJobItemsUnfiltered = this.currentJobItemsUnfiltered.slice(0, foundItem)
          .concat(addItem)
          .concat(this.currentJobItemsUnfiltered.slice(foundItem));
      });

    }

    this.showSetupLinesFilter();
  }

  createAddedItem(item: IJobItem, changedElement: IJobVarItem, variationNumber: number, variationId: number) {
    // const addedItem = item;
    const addedItem = Object.assign(Object.create(Object.getPrototypeOf(item)), item);
    // item.isDeleted = true;


    // console.log('found matching item to added one - item: ' + JSON.stringify(item));
    // console.log('found matching item to added one - changedElement: ' + JSON.stringify(changedElement));

    addedItem.id = changedElement.originalItemId;
    addedItem.jobItemAboveId = changedElement.jobItemAboveId;
    addedItem.orderNo = changedElement.orderNo;
    addedItem.itemTypeId = changedElement.itemTypeId;
    addedItem.itemDescription = changedElement.itemDescription;
    addedItem.selectionTypeId = changedElement.selectionTypeId;
    addedItem.linkedJobItemId = changedElement.linkedJobItemId;
    addedItem.selectedOptionId = changedElement.selectedOptionId;
    addedItem.selection = changedElement.selection;
    addedItem.costTypeId = changedElement.costTypeId;
    addedItem.price = changedElement.price;
    addedItem.isDeleted = changedElement.isDeleted;
    addedItem.changedByVO = variationNumber;
    addedItem.changedByVOId = variationId;
    addedItem.changedByJobVarId = changedElement.id;
    addedItem.changeTypeId = changedElement.changeTypeId;
    addedItem.jobItemAboveTypeId = changedElement.jobItemAboveTypeId;
    addedItem.jobVarTypeId = changedElement.jobVarTypeId;
    addedItem.itemChangedId = changedElement.itemChangedId;
    addedItem.originalItemTypeId = changedElement.originalItemTypeId;
    addedItem.isHistoryRecord = false;
    addedItem.itemNotTaken = changedElement.itemNotTaken;
    addedItem.deleteComment = changedElement.deleteComment;
    addedItem.attachmentName = changedElement.attachmentName;
    addedItem.attachmentId = changedElement.attachmentId;
    addedItem.attachmentTypeId = changedElement.attachmentTypeId;
    addedItem.isBoldNote = changedElement.isBoldNote;
    addedItem.isItalicNote = changedElement.isItalicNote;
    addedItem.noteColour = changedElement.noteColour;
    addedItem.optionListAttachmentId = changedElement.optionListAttachmentId;
    addedItem.isBoldText = changedElement.isBoldNote;
    addedItem.optionColour = changedElement.noteColour;
    addedItem.isHiddenFromMaster = changedElement.isHiddenFromMaster;
    addedItem.isChecked = changedElement.isChecked;
    addedItem.isConfirmedbyCustomer = changedElement.isConfirmedbyCustomer;
    addedItem.isDoNotPrint = changedElement.isDoNotPrint;
    addedItem.connectedItemId = changedElement.connectedItemId;
    addedItem.previousItemDescription = item.itemDescription;
    addedItem.previousSelection = item.selection;
    addedItem.previousSelectedOptionId = item.selectedOptionId;
    addedItem.previousIsDeleted = false;
    addedItem.previousOptionListId = item.optionListId;
    addedItem.previousQuantity = item.quantity;
    addedItem.hasLinkedItems = changedElement.hasLinkedItems;
    addedItem.masterItemId = changedElement.masterItemId;
    addedItem.optionNumber = 0;
    addedItem.varOptionId = changedElement.varOptionId;
    addedItem.hideFixedPSum = changedElement.hideFixedPSum;
    addedItem.optionImageId = changedElement.optionImageId;
    addedItem.commentTypeId = changedElement.commentTypeId;
    addedItem.jobItemCommentId = changedElement.jobItemCommentId;
    addedItem.quantity = changedElement.quantity;
    addedItem.isSetUpLine = false;

    if (changedElement.optionListId === null) {
      addedItem.optionListId = null;
      addedItem.isQtyRequired = false;
      addedItem.optionImageId = null;
    } else {
      addedItem.optionListId = changedElement.optionListId;
      addedItem.isQtyRequired = this.checkIsQtyReuired(+changedElement.optionListId);
      // console.log('createAddedItem - addedItem.isQtyRequired=' + addedItem.isQtyRequired
      //     + ' for optionListId= ' + changedElement.optionListId);
    }

    // get the printed description
    if (changedElement.selectedOptionId) {
      const option = this.getOptionListItemById(changedElement.selectedOptionId);
      if (option && option.printedDescription) {
        addedItem.printedDescription = option.printedDescription;
      } else {
        addedItem.printedDescription = null;
      }
    } else {
      addedItem.printedDescription = null;
    }

    this.addedItems = this.addedItems.concat(addedItem);
  }

  private filterHeading(variationId: number, jobItemAboveTypeId: number, jobItemAboveId: number): boolean {

    let testSelection = '';
    let testItemDescription = '';
    let result = false;

    if (!jobItemAboveTypeId || jobItemAboveTypeId === 0) {
      jobItemAboveTypeId = 1;
    }

    this.currentJobItemsThisVO.forEach(item => {
      if (((item.jobItemAboveTypeId === null && jobItemAboveTypeId === 1) || item.jobItemAboveTypeId === jobItemAboveTypeId)
        && item.jobItemAboveId === jobItemAboveId) {
        if ((this.showSetupLines || !item.isSetUpLine) &&
          (this.showHiddenLines || !item.isDeleted)) {

          if (!item.selection
            || (item.itemTypeId === this.itemTypeEnum.Detail && item.selectionTypeId === this.selectionTypeEnum.Checkbox)) {
            testSelection = '';
          } else {
            testSelection = item.selection.toLowerCase().trim();
          }

          if (!this.isLocked
            || (this.selectionsMode === 'Master'
              && ((this.showHistoryOfChanges && item.changedByVOId
                && ((!this._authService.isClient() && !this._authService.isAssociate()) || item.isHistoryRecord))
                || (this.showNotApplicableItems || testSelection !== 'not applicable')))
            || (this.selectionsMode === 'Variations'
              && (item.changedByVOId === variationId || (this.showNotApplicableItems || testSelection !== 'not applicable')))) {

            if (!(this._authService.isClient() || this._authService.isAssociate())
              || !(item.itemTypeId === this.ItemType.Detail
                && item.selectionTypeId === this.selectionTypeEnum.Dropdown
                && item.isHistoryRecord
                && (!item.selection || item.selection === ''))) {

              if (this.filterByNotSelected) {
                if (item.itemTypeId === this.ItemType.Detail
                  && item.selectionTypeId === this.selectionTypeEnum.Dropdown
                  && !item.isHistoryRecord
                  && (!item.selection || item.selection === '' || this.checkNotSelected(item))) {
                  result = true;
                }
              } else if (this.filterByPSItems) {
                if ((item.provisionalSum && item.provisionalSum !== 0)
                  || item.costTypeId === this.costTypeEnum.ProvisionalSum
                  || item.costTypeId === this.costTypeEnum.PSFinalised) {
                  result = true;
                }
              } else if (this.filterByUnChecked) {
                if (!item.isChecked && item.changedByVOId === variationId && !item.itemNotTaken && !item.hasLinkedItems
                  && !(item.itemTypeId === this.ItemType.Heading)
                  && (!item.isHistoryRecord || item.changeTypeId === ChangeTypeEnum.Delete)) {
                  result = true;
                }
              } else if (this.filterByClientUpdatable) {
                if (this.isItemClientUpdatable(item, variationId)) {
                  // show any that have been changed by an office user or that a client can change
                  result = true;
                }
              } else if (this.filterByNotSelectedByRep) {
                if (item.itemTypeId === this.ItemType.Detail
                  && item.selectionTypeId === this.selectionTypeEnum.Dropdown
                  && !item.isHistoryRecord
                  && (!item.selection || item.selection === '')
                  && this.checkNotSelectedByRep(item.optionListId)) {
                  result = true;
                }
              } else {
                if (!item.itemDescription) {
                  testItemDescription = '';
                } else {
                  testItemDescription = item.itemDescription.toLowerCase();
                }

                if (testItemDescription.search(this.filterText) >= 0 || testSelection.search(this.filterText) >= 0) {
                  result = true;
                }
              }

              if (!result && item.itemTypeId === this.ItemType.Heading) {
                if (this.filterHeading(variationId, item.originalItemTypeId, item.id)) {
                  result = true;
                }
              }
            }
          }
        }
      }
    });

    return result;
  }

  checkForAttachment(jobitemId, variationId) {
    // we check if a linked item has an attachment and warn that it will need to be changed/deleted manually
    this.currentJobItems.forEach(element => {
      if (!element.isHistoryRecord && element.linkedJobItemId === jobitemId) {
        if (element.attachmentId && element.attachmentVariationId === variationId) {
          this.notiService.showInfo('A linked item has a manual image which will need to be changed or deleted manually if required');
        } else if (element.hasLinkedItems) {
          this.checkForAttachment(element.id, variationId);
        }
      }
    });
  }

  sortJobItems() {
    // quick sort is all that is needed
    this.currentJobItemsUnfiltered = this.currentJobItemsUnfiltered.sort(this.utils.sortBy('jobItemAboveTypeId',
      this.utils.sortBy('JobItemAboveId', this.utils.sortBy('orderNo', false))));
  }

  getThisItemOptionListId(originalItemTypeId: number, id: number, optionNumber: number): number {
    // get the latest option list id for this item - may have been changed by another linked item
    let optionListId = null;

    this.currentJobItemsUnfiltered.forEach(element => {
      if (element.originalItemTypeId === originalItemTypeId && element.id === id && element.optionNumber === optionNumber) {
        optionListId = element.optionListId;
      }
    });

    return optionListId;
  }

  updateComment(jobVarItemTypeId: number, id: number, commentTypeId: number, jobItemCommentId: number) {
    this.currentJobItemsUnfiltered.forEach(item => {
      if (item.originalItemTypeId === jobVarItemTypeId && item.id === id) {
        item.commentTypeId = commentTypeId;
        item.jobItemCommentId = jobItemCommentId;
      }
    });

    this.currentJobItems.forEach(item => {
      if (item.originalItemTypeId === jobVarItemTypeId && item.id === id) {
        item.commentTypeId = commentTypeId;
        item.jobItemCommentId = jobItemCommentId;
      }
    });
  }

  checkIsQtyReuired(id: number): boolean {
    this.treeOptionNodes = this.optionService.getCurrentOptionNodes();
    let isQtyRequired = false;
    this.optionsListFound = false;

    if (this.treeOptionNodes && this.treeOptionNodes.length) {
      this.treeOptionNodes.forEach(element => {
        if (element.id === id) {
          isQtyRequired = element.isQtyRequired;
          this.optionsListFound = true;
        } else if (!this.optionsListFound) {
          const isQtyFromChild = this.checkChildrenForIsQty(element, id);
          if (isQtyFromChild) {
            isQtyRequired = true;
            this.optionsListFound = true;
          }
        }
      });
    }

    if (!this.optionsListFound) {
      // we may have house options
      this.getHouseOptionListItems(id);
      isQtyRequired = this.optionListParentNode.isQtyRequired;
    }

    return isQtyRequired;
  }

  checkChildrenForIsQty(node: IOptionListHeader, id: number): boolean {
    let isQtyRequired = false;
    node.children.forEach(element => {
      if (element.id === id) {
        isQtyRequired = element.isQtyRequired;
        this.optionsListFound = true;
      } else if (!this.optionsListFound) {
        const isQtyFromChild = this.checkChildrenForIsQty(element, id);
        if (isQtyFromChild) {
          isQtyRequired = true;
          this.optionsListFound = true;
        }
      }
    });
    return isQtyRequired;
  }

  setupJobItemNodesChildren(originalItemTypeId: number, id: number,
    treeJobItemNodes: IJobItem[], variationId: number): IJobItemWithChildren[] {
    // recursively check the children

    const childrenNodes: IJobItemWithChildren[] = [];
    const childNode: IJobItemWithChildren = new IJobItemWithChildren;
    treeJobItemNodes.forEach(element => {

      // we skip deleted items
      if (((id === null && element.jobItemAboveId === null)
        || (id !== null && element.jobItemAboveTypeId === originalItemTypeId && element.jobItemAboveId === id))
        && (!element.isHistoryRecord || element.changeTypeId === this.changeTypeEnum.Delete)
        && !element.isDeleted && !element.isSetUpLine) {

        if (element.itemTypeId === this.itemTypeEnum.Heading) {
          childNode.children = this.setupJobItemNodesChildren(element.originalItemTypeId,
            element.id, treeJobItemNodes, variationId);

          // now set the checked & indeterminate states
          if (childNode.children.length) {
            let allChildrenChecked = true;
            let noChildChecked = true;

            for (const child of childNode.children) {
              if (!child.checked || child.indeterminate) {
                allChildrenChecked = false;
              }
              if (child.checked) {
                noChildChecked = false;
              }
            }

            if (allChildrenChecked) {
              childNode.checked = true;
              childNode.indeterminate = false;
            } else if (noChildChecked) {
              childNode.checked = false;
              childNode.indeterminate = false;
            } else {
              childNode.checked = true;
              childNode.indeterminate = true;
            }
          } else {
            childNode.checked = false;
            childNode.indeterminate = false;
          }
        } else {
          childNode.children = [];
          childNode.checked = false;
          childNode.indeterminate = false;
        }

        if ((element.itemTypeId === this.itemTypeEnum.Heading
          && (!variationId || (childNode.children && childNode.children.length)))
          || (element.itemTypeId !== this.itemTypeEnum.Heading && (!variationId || element.changedByVOId === variationId))) {

          if (!element.itemDescription || element.itemDescription === '') {
            childNode.description = element.selection;
          } else if (element.selection) {
            childNode.description = element.itemDescription + ' - ' + element.selection;
          } else {
            childNode.description = element.itemDescription;
          }

          // if the item has not been changed use the original id
          if (element.changedByJobVarId && element.changedByJobVarId !== 0) {
            this.id = element.changedByJobVarId;
          } else {
            this.id = element.id;
          }

          // add manually as the point will not work.
          childrenNodes.push({
            // childrenNodes.push({
            id: this.id,
            itemTypeId: element.itemTypeId,
            changeTypeId: element.changeTypeId,
            jobVarTypeId: element.jobVarTypeId,
            itemChangedId: element.itemChangedId,
            description: childNode.description,
            originalItemTypeId: element.originalItemTypeId,
            originalItemId: element.id,
            connectedItemId: element.connectedItemId,
            children: childNode.children,
            parentId: id,
            changedByVOId: element.changedByVOId,
            changedByJobVarId: element.changedByJobVarId,
            masterItemId: element.masterItemId,
            hasLinkedItems: element.hasLinkedItems,
            optionNumber: element.optionNumber,
            checked: false,
            indeterminate: childNode.indeterminate,
            isChecked: element.isChecked,
            variationItemNumber: element.variationItemNumber,
          });
        }
      }
    });
    return childrenNodes;
  }

  setVariationNumber(varNum: number, searchString: string) {
    this.currentVariationNumber = varNum;
    this.filterTextFromHistoryLink = searchString.toLowerCase();
    this.currentVariationChanged.emit(this.currentVariationNumber);
  }

  isSetUpItemPending(headingItemId): boolean {
    let result = false;
    this.currentJobItemsUnfiltered.forEach(item => {
      if (item.jobItemAboveTypeId !== JobVarTypeEnum.JobVarItem && item.jobItemAboveId === headingItemId
        && item.itemTypeId === ItemTypeEnum.Detail && item.selectionTypeId === SelectionTypeEnum.Dropdown
        && (!item.selection || item.selection.trim() === '')) {
        result = true;
      } else if (item.jobItemAboveId === headingItemId && item.itemTypeId === ItemTypeEnum.Heading) {
        if (this.isSetUpItemPending(item.id)) {
          result = true;
        }
      }
    });
    return result;
  }

  isInNotPrintedSection(jobItemAboveTypeId: number, itemId: number): boolean {
    let result = false;

    if (itemId) {
      const parentItem = this.currentJobItemsUnfiltered
        .find(i => i.originalItemTypeId === jobItemAboveTypeId && i.id === itemId);

      if (parentItem) {
        if (parentItem.isDoNotPrint) {
          result = true;
        } else {
          if (parentItem.jobItemAboveId !== null) {
            result = this.isInNotPrintedSection(parentItem.jobItemAboveTypeId, parentItem.jobItemAboveId);
          }
        }
      }
    }
    return result;
  }

  parentLinkedItemChanged(linkedJobItemId: number): boolean {
    // find parent linked item. if changed we need to set this item to TBA
    if (linkedJobItemId) {
      const parentItem = this.currentJobItems
        .find(i => i.id === linkedJobItemId && i.changedByVOId === this.currentVariation.id);
      if (parentItem) {
        return true;
      }
    }
    return false;
  }

  updateJobVarItemExtraStore(jobVarItemId: number, variationOnlyComment: string) {
    // if we have a variation comment we add to the jobvaritemextra
    const jobVarItemExtra = this.currentJobVarItemExtras.find(i => i.jobVarItemId === jobVarItemId);
    if (jobVarItemExtra) {
      jobVarItemExtra.variationOnlyComment = variationOnlyComment;
    } else if (variationOnlyComment && variationOnlyComment !== '') {
      // add a record
      const newJobVarItemExtra: JobVarItemExtra = {
        id: 0,
        jobVarItemId: jobVarItemId,
        colourId: 0,
        variationOnlyComment: variationOnlyComment
      };
      this.currentJobVarItemExtras.push(newJobVarItemExtra);
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
