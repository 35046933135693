export class IJobItem {
    id: number;
    jobId: number;
    jobVariationId: number;
    jobItemAboveId: number;
    orderNo: number;
    itemTypeId: number;
    itemDescription: string;
    selectionTypeId: number;
    canEnterManualSelection: boolean;
    optionListId: number;
    linkedJobItemId: number;
    connectedJobItemId: number;
    selectedOptionId: number;
    selection: string;
    costTypeId: number;
    provisionalSum: number;
    price: number;
    isSetUpLine: boolean;
    setUpTags: string;
    // imageUrl: string;
    isDeleted: boolean;
    changedByVO: number;
    changedByVOId: number;
    changedByJobVarId: number;
    changeTypeId: number;
    jobItemAboveTypeId: number;
    originalItemTypeId: number;
    isHistoryRecord: boolean;
    itemNotTaken: boolean;
    deleteComment: string;
    attachmentName: string;
    attachmentId: number;
    attachmentTypeId: number;
    noteColour: number;
    isBoldNote: boolean;
    isItalicNote: boolean;
    attachmentVariationId: number;
    optionListAttachmentId: number;
    isBoldText: boolean;
    optionColour: number;
    isHiddenFromMaster: boolean;
    isChecked: boolean;
    isConfirmedbyCustomer: boolean;
    isDoNotPrint: boolean;
    connectedItemId: number;
    previousItemDescription: string;
    previousSelection: string;
    previousSelectedOptionId: number;
    previousIsDeleted: boolean;
    previousOptionListId: number;
    previousQuantity: number;
    hasLinkedItems: boolean;
    masterItemId: number;
    optionNumber: number;
    varOptionId: number;
    hideFixedPSum: boolean;
    variationType: number;
    optionImageId: number; // global image id if used
    jobItemCommentId: number; // the id of the current comment
    commentTypeId: number; // if there's a comment this is the type e.g. important
    isAcceptedIntoContract: boolean | null;
    quantity: number;
    unitOfMeasure: string;
    isQtyRequired: boolean;
    jobVarTypeId: number;
    itemChangedId: number;
    approvedDate: Date;
    printedDescription: string;
    highlightColourId: number;

    checked: boolean; // used by trees
    variationItemNumber: number;
    hasConnectedItems: boolean;
}
