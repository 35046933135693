import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobItemExtraService } from '../../../services/felixApi/job-item-extra.service';
import { IJobItem } from '../../../dtos/job-item';
import { OptionListService } from '../../../services/felixApi/option-list.service';
import { IOptionListHeader } from '../../../dtos/option-list-header';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../../services/notification.service';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import { ItemTypeEnum } from '../../../dtos/item-type.enum';
import { SelectionTypeEnum } from '../../../dtos/selection-type.enum';
import { TreeComponent } from '@circlon/angular-tree-component';
import { GlobalService } from '../../../services/global.service';
import { JobItemExtra } from '../../../dtos/job-item-extra';

@Component({
  selector: 'js-item-setup-modal',
  templateUrl: './item-setup-modal.component.html',
  styleUrls: ['./item-setup-modal.component.scss']
})
export class ItemSetupModalComponent implements OnInit, OnDestroy {
  @Input() jobItem: IJobItem;
  @Input() jobLineSetupAdmin: boolean;

  loading = true;
  subscriptions: Subscription[] = [];
  ItemType = ItemTypeEnum;
  treeOptionNodesSetup: boolean;
  treeOptionOptions: any;
  treeOptionNodes: IOptionListHeader[] = [];
  treeJobItemNodesSetup: boolean;
  treeJobItemOptions: any;
  treeJobItemNodes: any[] = [];
  detailOptionType: string;
  isSetupLine: boolean;
  canEnterManualSelection: boolean;
  optionListId: number;
  linkedJobItemId: number;
  addendumCode: string;
  // purchaseOrdersActive: boolean;
  jobItemExtra: JobItemExtra;

  constructor(
    private _activeModal: NgbActiveModal,
    private _optionListService: OptionListService,
    private notiService: NotificationService,
    private _jobItemService: JobItemService,
    private _globalService: GlobalService,
    private _jobItemExtraService: JobItemExtraService) { }

  ngOnInit() {
    // const purchaseOrdersActive = this._globalService.getCompanyConfigValue(ConfigurationEnum.PurchaseOrderSystemActive);
    // if (purchaseOrdersActive && purchaseOrdersActive === 1) {
    //   this.purchaseOrdersActive = true;
    // }

    this.isSetupLine = this.jobItem.isSetUpLine;
    this.canEnterManualSelection = this.jobItem.canEnterManualSelection;
    this.detailOptionType = '';
    this.optionListId = this.jobItem.optionListId;
    this.linkedJobItemId = this.jobItem.linkedJobItemId;

    if (this.jobItem.itemTypeId === this.ItemType.Detail) {
      if (this.jobItem.selectionTypeId === SelectionTypeEnum.Dropdown) {
        if (this.jobItem.linkedJobItemId) {
          this.detailOptionType = 'Link to another line';
        } else {
          this.detailOptionType = 'Use an Option List';
        }
      } else {
        this.detailOptionType = 'Checkbox';
      }
    }

    this.setupOptionNodes();
    this.setupJobItemNodes();
    this.loadJobItemExtra();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  loadJobItemExtra() {
    // get the purchase order addendum code
    this.subscriptions = this.subscriptions.concat(
      this._jobItemExtraService.getJobItemExtra(this.jobItem.id)
        .subscribe({
          next: (jobItemExtra) => {
            this.jobItemExtra = jobItemExtra;
            this.addendumCode = this.jobItemExtra?.addendumCode;
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  // set up the tree nodes of job items to allow linking
  setupOptionNodes() {
    this.treeOptionNodesSetup = false;
    this.treeOptionOptions = {
      idField: 'id',
      displayField: 'description',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22,
      scrollOnActivate: false
    };

    this.treeOptionNodes = this._optionListService.getCurrentOptionNodes();
    if (!this.treeOptionNodes || !this.treeOptionNodes.length) {
      this._optionListService.resetCurrentOptionNodes(); // ensure we get a new list of options if we need them
      this.subscriptions = this.subscriptions.concat(
        this._optionListService.getOptionListChildren(0, true, false)
          .subscribe({
            next: (treeOptionNodes) => {
              this.treeOptionNodes = treeOptionNodes;
              this._optionListService.setCurrentOptionNodes(treeOptionNodes);

              this.treeOptionNodesSetup = true;
            },
            error: (err) => {
              this.notiService.notify(err);
              this.treeOptionNodesSetup = true;
            }
          })
      );
    } else {
      this.treeOptionNodesSetup = true;
    }
  }

  // set up the tree nodes for the add/edit item
  setupJobItemNodes() {
    this.treeJobItemNodesSetup = false;
    this.treeJobItemOptions = {
      idField: 'id',
      displayField: 'itemDescription',
      childrenField: 'children',
      useVirtualScroll: true,
      nodeHeight: 22
    };

    this.treeJobItemNodes = [];
    this._jobItemService.currentJobItems.forEach(element => {
      if (element.jobItemAboveTypeId === this.jobItem.jobItemAboveTypeId
        && element.jobItemAboveId === this.jobItem.jobItemAboveId
        && (element.originalItemTypeId !== this.jobItem.originalItemTypeId
          || element.id !== this.jobItem.id)
        && element.orderNo <= this.jobItem.orderNo
        && element.selectionTypeId === SelectionTypeEnum.Dropdown) {
        this.treeJobItemNodes = this.treeJobItemNodes.concat(element);
      }
    });
    this.treeJobItemNodesSetup = true;
  }

  refreshList() {
    this._optionListService.setCurrentOptionNodes(null);
    this.treeOptionNodesSetup = false;
    this.setupOptionNodes();
  }

  // set the linked option list when clicked on from tree
  setOptionList(id: number) {
    if (id !== this.optionListId) {
      this.optionListId = id;
    }
  }

  // when the pption list tree is initialised set the focused node to the one already selected.
  onOptionTreeInit(tree: TreeComponent) {
    if (this.optionListId !== null && this.optionListId !== undefined && this.optionListId !== 0 && this.treeOptionNodesSetup) {
      tree.treeModel.getNodeById(this.optionListId)?.setActiveAndVisible();
    }
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  // when the job item tree is initialised set the focused node to the one already selected.
  onItemTreeInit(tree: TreeComponent) {
    if (this.linkedJobItemId !== null && this.linkedJobItemId !== undefined && this.linkedJobItemId !== 0 && this.treeJobItemNodesSetup) {
      tree.treeModel.getNodeById(this.linkedJobItemId)?.setActiveAndVisible();
    }
    tree.treeModel.virtualScroll.setViewport(tree.treeModel.virtualScroll.viewport);
  }

  // set the linked job item when clicked on from tree
  setLinkedItemId(id, tree) {
    if (this.jobItem.id === id) {
      this.notiService.showError('Cannot link an item to itself');
      this.onItemTreeInit(tree); // reset
    } else {
      this.linkedJobItemId = id;
    }
  }

  cancel() {
    this._activeModal.dismiss();
  }

  closeOk(closeToShowHide: boolean) {
    this.loading = true;

    if (this.addendumCode !== this.jobItemExtra?.addendumCode) {
      if (this.jobItemExtra) {
        // change
        this.subscriptions = this.subscriptions.concat(
          this._jobItemExtraService.updateJobItemExtra({ id: this.jobItemExtra.id, addendumCode: this.addendumCode })
            .subscribe({
              next: () => {
                this.modalClose(closeToShowHide);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      } else {
        // add
        this.subscriptions = this.subscriptions.concat(
          this._jobItemExtraService.addJobItemExtra({ jobItemId: this.jobItem.id, addendumCode: this.addendumCode })
            .subscribe({
              next: () => {
                this.modalClose(closeToShowHide);
              },
              error: (err) => {
                this.notiService.notify(err);
                this.loading = false;
              }
            })
        );
      }
    } else {
      this.modalClose(closeToShowHide);
    }
  }

  modalClose(closeToShowHide: boolean) {
    this._activeModal.close({
      isSetupLine: this.isSetupLine,
      canEnterManualSelection: this.canEnterManualSelection,
      detailOptionType: this.detailOptionType,
      optionListId: this.optionListId,
      linkedJobItemId: this.linkedJobItemId,
      setupTags: closeToShowHide
    });
  }
}
