import { VariationTypeEnum } from './../../dtos/variation-type.enum';
import { VariationService } from './../../services/felixApi/variation.service';
import { UtilsService } from './../../services/utils.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { JobService } from '../../services/felixApi/job.service';
import { NotificationService } from '../../services/notification.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { SelectCategoriesComponent } from './select-categories/select-categories.component';

@Component({
  selector: 'js-selection-report-modal',
  templateUrl: './selection-report-modal.component.html',
  styleUrls: ['./selection-report-modal.component.scss']
})
export class SelectionReportModalComponent implements OnInit, OnDestroy {
  @Input() variationNumber: number;
  @Input() exportToExcel: boolean;
  @Input() isSalesQuote: boolean;
  @Input() isContractQuote: boolean;
  // @Input() printSalesQuoteOnly: boolean;

  printImages = true;
  printPrices = true;
  printNotApplicable = false;
  printNonPrintItems = false;
  showPrintNonPrint = false;
  printConnectedTogether = true;
  printVONumber = true;
  printProvisionalSums = false; // as requested by Mike 12-2-19
  printWholeAddendum = false; // for sales quote we can print the whole addendum or just the variations
  returnData: any;
  modalHeading: string;
  isAdmin = false; // admins can print the included amounts
  isAssociate = false; // is this user an associate
  isClient = false; // is this user a client
  printChangeHistory = true;
  printItemsNotTaken = true;
  printIncludedAmounts = false; // admins can choose to print the report showing the 'included' amounts
  printPdfAttachments = false; // sales can print any attachments with the quote
  printFromSalesPriceOnwards = true; // when contract stage we can ignore the sales VOs and start at the Sales Price or print all
  printWithHighlighting = false; // variations will print with highlighting
  quoteDate: Date;
  quoteExpiryDate: Date;
  canChangeQuoteDate: boolean;
  permissionLevel: string;
  canSeeAmounts = false;
  canReadAmountsAdmin = false;
  configurationEnum = ConfigurationEnum;
  quoteXMLExport: boolean;
  outputFileName = '';
  generateXML: boolean;
  subscriptions: Subscription[] = [];
  loading = true;
  salesQuoteNumber: string;
  addendumName: string;
  blob: Blob;
  pdf: ArrayBufferLike;
  hasSeparateAttachments: boolean;
  isPriceGuide = false;
  isPriceGuideCheckBoxVisible = false;
  printQuantities = false;
  selectedCategories: number[] = null;
  showSignatureSection = false;
  showCheckBoxes = false;
  showShowCheckBoxesSelection = false;
  showQuoteExpiryDate: boolean;
  showSignatureCheckBox: boolean;
  printConfigAttachments = true;
  separateAttachmentsText = 'Generate Separate Attachments';

  constructor(private _activeModal: NgbActiveModal,
    private _globalService: GlobalService,
    private _variationService: VariationService,
    private _jobService: JobService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private _authService: AuthService,
    private utilsService: UtilsService) { }

  ngOnInit() {
    if (this._authService.isAdminOrSuperUser()) {
      this.isAdmin = true;
    }
    this.addendumName = this._globalService.getAddendumName();
    if (window.innerWidth < 450) {
      this.separateAttachmentsText = 'Separate Attachments';
    }
    this.checkCompanyAttachments();

    if (this._authService.isClient() || this.isAdmin || this._authService.canSeeAmountsPermission) {
      this.canSeeAmounts = true;
    } else {
      this.printPrices = false;
    }

    if (this.isContractQuote) {
      if (this._jobService.currentJob.contractQuoteDate) {
        this.quoteDate = this._jobService.currentJob.contractQuoteDate;
      } else if (this._jobService.currentJob.lockedDate) {
        this.quoteDate = this._jobService.currentJob.lockedDate; // for old jobs
      }

      this.quoteExpiryDate = this._jobService.currentJobExtra?.contractQuoteDueDate;
    } else {
      if (!this._jobService.currentJob.salesDate) {
        this.canChangeQuoteDate = true;
      }
      if (this._jobService.currentJob.quoteDate) {
        this.quoteDate = this._jobService.currentJob.quoteDate;
      }

      this.quoteExpiryDate = this._jobService.currentJobExtra?.salesQuoteDueDate;
      this.salesQuoteNumber = this._jobService.currentJobExtra?.salesQuoteNumber;
    }

    if (!this.quoteDate) {
      this.quoteDate = new Date();
    }

    if (this.isSalesQuote || this.isContractQuote) {
      this.printPdfAttachments = true; // default for quotes to print attachments

      this.permissionLevel = this._authService.getSelectionsPermissions('CanReadAmounts');

      if (this.isAdmin || this.permissionLevel === 'Admin') {
        this.canReadAmountsAdmin = true;
      }

      this.showQuoteExpiryDate = this._globalService.getCurrentCompany().salesQuoteTypeId >= 8; // only avaible on types >= 8
      this.showSignatureCheckBox = this._globalService.getCurrentCompany().salesQuoteTypeId >= 8; // only avaible on types >= 8
      this.showSignatureSection = true;


      if (this.isContractQuote) {
        this.modalHeading = 'Create Contract Quote PDF';

        if ((this.permissionLevel === 'Write' || this.permissionLevel === 'Admin'
          || this._authService.isAdminOrSuperUser())
          && !this._jobService.currentJob.contractSignedDate) {
          this.canChangeQuoteDate = true;
        }

        // get config
        if (this._globalService.getCompanyConfigValue(this.configurationEnum.ContractPrintFromSalesPriceOnwards) === 2) {
          this.printFromSalesPriceOnwards = false;
        }
      } else {
        this.modalHeading = 'Create Sales Proposal PDF';
        this.printFromSalesPriceOnwards = false;

        if ((this.permissionLevel === 'Write' || this.permissionLevel === 'Admin'
          || this._authService.isAdminOrSuperUser())
          && !this._jobService.currentJob.salesDate) {
          this.canChangeQuoteDate = true;
        }

        if (this._globalService.getCurrentCompany().salesQuoteTypeId === 1) {
          this.isPriceGuideCheckBoxVisible = true;
        }
      }

      this.printProvisionalSums = true;
      this.printChangeHistory = false;

      // get config for printing whole addendum
      if (this._globalService.getCompanyConfigValue(this.configurationEnum.SalesQuotePrintWholeAddendum) === 1) {
        this.printWholeAddendum = true;
      }
      // get config for XML export
      if (this._globalService.getCompanyConfigValue(this.configurationEnum.QuoteXMLExport) === 1) {
        this.quoteXMLExport = true;
      }

      this.loading = false;
    } else {
      this.printProvisionalSums = this._globalService.getCompanyConfigValue(this.configurationEnum.AddendaPDFDefaultShowPSAsTrue) === 1;
      if (this._authService.isAssociate()) {
        this.isAssociate = true;
        this.printPrices = false;
        this.printProvisionalSums = false;
      } else if (this._authService.isClient()) {
        this.isClient = true;
        this.printProvisionalSums = true;
      }

      if (!this.exportToExcel) {
        if (this.variationNumber) {
          this.showShowCheckBoxesSelection = this._globalService.getCurrentCompany().variationTypeId === 3; // only avaible on type 3
          const variation = this._variationService.currentVariations.find(v => v.variationNumber === this.variationNumber);
          this.showSignatureCheckBox = variation?.variationType === VariationTypeEnum.Office || variation?.variationType === VariationTypeEnum.PreContractOffice;
        } else if (!this.isAssociate && !this.isClient) {
          this.showSignatureCheckBox = true;
        }
      }

      if (!this.variationNumber) {
        this.showPrintNonPrint = true;
      } else {
        this.printNotApplicable = true;
        this.printPdfAttachments = true; // default to print attachments
      }

      if (this.exportToExcel) {
        this.modalHeading = 'Export to Excel';
        this.printConnectedTogether = false;
      } else {
        this.modalHeading = 'Create PDF Report';
      }
      this.loading = false;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  close() {
    this.returnData = { runReport: false };
    this._activeModal.close(this.returnData);
  }

  generateXMLfile() {
    this.generateXML = true;
  }

  runReport(exportToXML: boolean) {
    if (this.isSalesQuote && !this.quoteDate) {
      this.notiService.showInfo('Must specify a quote date');
    } else {
      if (this.canChangeQuoteDate && (this.isSalesQuote || this.isContractQuote)) {
        // we need to update the quote number
        this.loading = true;

        if (this._jobService.currentJobExtra) {
          // patch
          const updateData: any = {};
          let dataToUpdate = false;

          if (this.isSalesQuote) {
            if (this._jobService.currentJobExtra.salesQuoteNumber !== this.salesQuoteNumber) {
              updateData.salesQuoteNumber = this.salesQuoteNumber;
              dataToUpdate = true;
            }
            if (this._jobService.currentJobExtra.salesQuoteDueDate !== this.quoteExpiryDate) {
              updateData.salesQuoteDueDate = this.utilsService.convertDateToString(this.quoteExpiryDate, null);
              dataToUpdate = true;
            }
          } else {
            if (this._jobService.currentJobExtra.contractQuoteDueDate !== this.quoteExpiryDate) {
              updateData.contractQuoteDueDate = this.utilsService.convertDateToString(this.quoteExpiryDate, null);
              dataToUpdate = true;
            }
          }

          if (dataToUpdate) {
            this.subscriptions = this.subscriptions.concat(
              this._jobService.updateJobExtra(this._jobService.currentJobExtra.id, updateData)
                .subscribe({
                  next: (jobExtra) => {
                    this._jobService.currentJobExtra = jobExtra;
                    this.runReportGo(exportToXML);
                  },
                  error: (err) => {
                    this.notiService.notify(err);
                    this.loading = false;
                  }
                })
            );
          } else {
            this.runReportGo(exportToXML);
          }
        } else {
          // add
          const updateData: any = {};
          let dataToUpdate = false;

          if (this.isSalesQuote) {
            if (this.quoteExpiryDate || this.salesQuoteNumber) {
              updateData.salesQuoteNumber = this.salesQuoteNumber;
              updateData.salesQuoteDueDate = this.quoteExpiryDate;
              dataToUpdate = true;
            }
          } else {
            if (this.quoteExpiryDate) {
              updateData.contractQuoteDueDate = this.quoteExpiryDate;
              dataToUpdate = true;
            }
          }

          if (dataToUpdate) {
            updateData.jobId = this._jobService.currentJob.id;

            this.subscriptions = this.subscriptions.concat(
              this._jobService.addJobExtra(updateData)
                .subscribe({
                  next: (jobExtra) => {
                    this._jobService.currentJobExtra = jobExtra;
                    this.runReportGo(exportToXML);
                  },
                  error: (err) => {
                    this.notiService.notify(err);
                    this.loading = false;
                  }
                })
            );
          } else {
            this.runReportGo(exportToXML);
          }
        }
      } else {
        this.runReportGo(exportToXML);
      }
    }
  }

  runReportGo(exportToXML: boolean) {
    this.returnData = {
      runReport: true,
      printImages: this.printImages,
      printPrices: this.printPrices,
      printNotApplicable: this.printNotApplicable,
      printNonPrintItems: this.printNonPrintItems,
      printConnectedTogether: this.printConnectedTogether,
      printVONumber: this.printVONumber,
      printProvisionalSums: this.printProvisionalSums,
      printWholeAddendum: this.printWholeAddendum,
      printItemsNotTaken: this.printItemsNotTaken,
      printChangeHistory: this.printChangeHistory,
      printIncludedAmounts: this.printIncludedAmounts,
      printPdfAttachments: this.printPdfAttachments,
      printConfigAttachments: this.printConfigAttachments,
      printFromSalesPriceOnwards: this.printFromSalesPriceOnwards,
      printSalesQuoteOnly: this.isSalesQuote ? true : false,
      quoteDate: this.utilsService.convertDateToString(this.quoteDate, null),
      quoteExpiryDate: this.utilsService.convertDateToString(this.quoteExpiryDate, null),
      exportToXML: exportToXML,
      outputFileName: this.outputFileName,
      isPriceGuide: this.isPriceGuide,
      printQuantities: this.printQuantities,
      selectedCategories: this.selectedCategories,
      showSignatureSection: this.showSignatureSection,
      showCheckBoxes: this.showCheckBoxes,
      printWithHighlighting: this.printWithHighlighting
    };
    this._activeModal.close(this.returnData);
  }

  getCompanyAttachments() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getCompanyAttachments(this._jobService.currentJob.jobNumber, this.isSalesQuote)
        .subscribe({
          next: (pdfList) => {
            pdfList.forEach(pdfFle => {
              this.pdf = this.base64ToArrayBuffer(pdfFle.pdfReport);
              const fileExtn = pdfFle.attachmentTypeId === 5 ? '.xls' : pdfFle.attachmentTypeId === 6 ? '.xlsx' : pdfFle.attachmentTypeId === 7 ? '.xlsx' : '.pdf';
              saveAs(this.blob, this._jobService.currentJob.jobNumber + ' - ' + pdfFle.pdfReportName + fileExtn);
            });
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    if (this.exportToExcel) {
      this.blob = new Blob([bytes], { type: 'application/vnd.ms-excel' });
    } else {
      this.blob = new Blob([bytes], { type: 'application/pdf' });
    }
    return bytes.buffer;
  }

  checkCompanyAttachments() {
    this.loading = true;
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getSeparateAttachmentSettings()
        .subscribe({
          next: (pdfList) => {
            if (pdfList && pdfList.length) {
              const pdfFiles = pdfList
                .find(i => (i.printWithSalesQuote && this.isSalesQuote) || (i.printWithContractQuote && this.isContractQuote));

              if (pdfFiles) {
                this.hasSeparateAttachments = true;
              }
            }
            this.loading = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  selectCategories() {
    // here we select any categories we want to filter on
    const modalRef = this.modalService.open(SelectCategoriesComponent);

    modalRef.result.then(selectedCategories => {
      this.selectedCategories = selectedCategories;
    });
  }
}
