import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IJob } from '../../../dtos/job';
import { JobItemService } from '../../../services/felixApi/job-item.service';
import { JobService } from '../../../services/felixApi/job.service';
import { JobSearchModalComponent } from '../../../jobs/job-search-modal.component';
import { NotificationService } from '../../../services/notification.service';
import { Subscription } from 'rxjs';
import { HouseType } from '../../../dtos/house-type';
import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'js-copy-from-final-job',
  templateUrl: './copy-from-final-job.component.html',
  styleUrls: ['./copy-from-final-job.component.scss']
})
export class CopyFromFinalJobComponent implements OnInit, OnDestroy {

  // jobToCopy: IJob;
  // jobToCopyName = ''; // customer name from the job to copy
  // jobToCopyFrom = '';
  // jobNumberToCopyForm: UntypedFormGroup;
  loading = false;
  subscriptions: Subscription[] = [];
  houseType: HouseType;
  jobNumber: string;

  constructor(private _jobItemService: JobItemService,
    private _globalService: GlobalService,
    private _activeModal: NgbActiveModal,
    private _jobService: JobService,
    private modalService: NgbModal,
    private notiService: NotificationService) { }

  ngOnInit() {
    // this.jobNumberToCopyForm = new UntypedFormGroup({
    //   jobNumber: new UntypedFormControl('')
    // });

    // this.jobToCopy = null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  copyJob() {
    this.loading = true;

    this.subscriptions = this.subscriptions.concat(
      this._jobItemService.copyJobItemsFromFinalJob(this.jobNumber).subscribe({
        next: () => {
          this._activeModal.close(); // need to tell calling code to not create VO
        },
        error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  // getJobToCopy() {
  //   this.jobToCopyName = '';
  //   this.jobToCopy = null;

  //   if (this.jobNumberToCopyForm.value.jobNumber === this._globalService.getCurrentJob()) {
  //     this.notiService.showInfo('Cannot copy from itself');
  //   } else {
  //     this.loading = true;

  //     this.subscriptions = this.subscriptions.concat(
  //       this._jobService.getJob(this.jobNumberToCopyForm.value.jobNumber)
  //         .subscribe({
  //           next: (jobToCopy) => {
  //             this.jobToCopy = jobToCopy;
  //             if (this.jobToCopy) {
  //               this.jobToCopyName = this.jobToCopy.contractName;
  //               this.jobToCopyFrom = jobToCopy.jobNumber;
  //             } else {
  //               this.jobToCopyName = 'Job Not Found';
  //               this.jobToCopyFrom = '';
  //             }
  //             this.loading = false;
  //           },
  //           error: (err) => {
  //             this.jobToCopyName = 'Job Not Found';
  //             this.jobToCopyFrom = '';
  //             this.notiService.notify(err);
  //             this.loading = false;
  //           }
  //         })
  //     );
  //   }
  // }

  // changeJobToCopyNum() {
  //   // key pressed so clear to force GO
  //   this.jobToCopyName = '';
  //   this.jobToCopy = null;
  //   this.jobToCopyFrom = '';
  // }

  // jobSearchforCopy(jobSearchType: number) {
  //   // this.router.navigate(['jobsearch/selections']);
  //   const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-search' });
  //   modalRef.componentInstance.searchType = jobSearchType; // was this.jobSearchTypeEnum.Templates;

  //   modalRef.result.then((jobNumber) => {
  //     if (jobNumber) {
  //       this.jobNumberToCopyForm.patchValue({
  //         jobNumber: jobNumber
  //       });
  //       this.getJobToCopy();
  //     }
  //   }, () => {
  //   });
  // }

  refreshJobNumber(jobNumber: string) {
    this.jobNumber = jobNumber;

    // if (jobNumber && jobNumber !== '') {
    //   const currentJob = this._jobService.jobs?.find(i => i.jobNumber === jobNumber);
    //   this.setupCopyItemNodes(currentJob.id);
    // } else {
    //   this.selectedJobDocs = [];
    //   this.jobDocumentsForCopy = [];
    // }
  }

  cancel() {
    this._activeModal.dismiss();
  }
}
