<nav #ParentDiv
  *ngIf="!currComp || (currComp.userTypeId !== this.userTypeEnum.Associate && currComp.userTypeId !== this.userTypeEnum.Client)"
  class="navbar navbar-expand-sm navbar-light navbarclass">

  <dx-drop-down-button #dropdownButton *ngIf="!phoneSize" [items]="dropdownItems" [useSelectMode]="false" [dropDownOptions]="{ width: 140}"
    icon="assets/menuBar.png" class="menuDropDown">

    <div *dxTemplate="let item of 'item'">
      <img [src]="item.icon" class="dropDownLogo">
      <span class="dropdownOptionText">{{ item.text }}</span>
    </div>
  </dx-drop-down-button>

  <img class="navbar-brand logo" src="../assets/logotransparentlowercase.png" [routerLink]="['/welcome']" />


  <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div *ngIf="areaSelected !== ''" class="collapse navbar-collapse" id="navbarsExampleDefault"
    [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav me-auto mt-2 mt-lg-0" style="margin-top: 0!important;">
      <a class="nav-item active nav-link tab" [hidden]="hiddenJobs" [routerLink]="['/jobs']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'jobs'"
        (click)="isCollapsed = true; menuPress('jobs')">
        {{jobsHeading}}
      </a>

      <a class="nav-item active nav-link tab" [hidden]="hiddenSelections" [routerLink]="['/selections']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'selections'"
        (click)="isCollapsed = true; menuPress('selections')">
        {{addendumName}}
      </a>

      <a class="nav-item active nav-link tab" [hidden]="hiddenVariations" [routerLink]="['/variations']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'variations'"
        (click)="isCollapsed = true; menuPress('variations')">
        Variations
      </a>

      <a class="nav-item active nav-link tab" [hidden]="hiddenInfo" [routerLink]="['/documents']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'documents'"
        (click)="isCollapsed = true; menuPress('documents')">
        Info
      </a>

      <!-- <a *ngIf="isManualOrdersOn" class="nav-item active nav-link tab" [routerLink]="['/invoicelist']"
        [class.selected]="areaSelected === 'invoicelist'" [class.disabled]="disabledMenu"
        (click)="isCollapsed = true; menuPress('invoicelist')">
        Invoices
      </a> -->

      <a class="nav-item active nav-link tab" [hidden]="hiddenReports" [routerLink]="['/reports']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'reports'"
        (click)="isCollapsed = true; menuPress('reports')">
        Reports
      </a>

      <a class="nav-item active nav-link tab" [hidden]="hiddenSalesTasks || !isTrackingActive"
        [routerLink]="['/salestasks']" [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'salestasks'"
        (click)="isCollapsed = true; menuPress('salestasks')">
        {{ phoneSize ? 'Tasks' : 'Sales-Tasks' }}
      </a>

      <!-- <a class="nav-item active nav-link tab" [hidden]="!signedIn || innerWidth > 840" [routerLink]="['/welcome']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'welcome'"
        (click)="isCollapsed = true; menuPress('jobs')">
        <i _ngcontent-rqk-c623="" class="material-icons header-cog"> apps </i>
      </a> -->
    </ul>
  </div>

  <div *ngIf="signedIn && currComp && innerWidth > 740">
    <a class="currComp" [routerLink]="['/welcome']">
      <span style="vertical-align: top;">{{innerWidth > 1000 ? currUser + ' - ' + currComp.companyShortName :
        currComp.companyShortName}}</span>
      <i _ngcontent-rqk-c623="" class="material-icons btn-cog"> expand_more </i>
    </a>
  </div>
</nav>

<!-- Require seperate menu for clients and associates as the hamburger menu wont expand on the phone with the 3 options -->
<nav #ParentDiv
  *ngIf="signedIn && currComp && (currComp.userTypeId === this.userTypeEnum.Associate || currComp.userTypeId === this.userTypeEnum.Client)"
  class="navbar navbar-light navbarclass">

  <img *ngIf="currComp.userTypeId === this.userTypeEnum.Associate || !isClientDashboardVisible"
    class="navbar-brand logo" src="../assets/logotransparentlowercase.png" [routerLink]="['/welcome']" />

  <ul class="nav mt-2 mt-lg-0" style="margin-top: 0!important; margin-right: auto;">
    <a *ngIf="currComp.userTypeId === this.userTypeEnum.Client && isClientDashboardVisible"
      class="nav-item active nav-link t ab px-1 pb-0" [routerLink]="['/dashboard']" [class.disabled]="disabledMenu"
      [class.selected]="areaSelected === 'dashboard'" (click)="isCollapsed = true; menuPress('dashboard')">
      <i class="material-icons">home</i>
    </a>
    <a class="nav-item active nav-link tab px-2" [hidden]="hiddenSelections" [routerLink]="['/selections']"
      [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'selections'"
      (click)="isCollapsed = true; menuPress('selections')">
      {{addendumName}}
    </a>
    <a class="nav-item active nav-link tab px-2" [hidden]="hiddenVariations" [routerLink]="['/variations']"
      [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'variations'"
      (click)="isCollapsed = true; menuPress('variations')">
      Variations
    </a>
    <a class="nav-item active nav-link tab px-2" [hidden]="hiddenInfo" [routerLink]="['/documents']"
      [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'documents'"
      (click)="isCollapsed = true; menuPress('documents')">
      Info
    </a>
    <a *ngIf="isClient" class="nav-item active nav-link tab px-2" [routerLink]="['/documents-client']"
      [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'documents-client'"
      (click)="isCollapsed = true; menuPress('documents-client')">
      Info
    </a>

    <a class="nav-item active nav-link tab" [hidden]="!isClient" [routerLink]="['/job-progress']"
      [class.selected]="areaSelected === 'job-progress'" (click)="isCollapsed = true; menuPress('job-progress')">
      Progress
    </a>

    <!-- <a class="nav-item active nav-link tab" [hidden]="hiddenInfo" [routerLink]="['/callups']"
      [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'callups'"
      (click)="isCollapsed = true; menuPress('callups')">
      Call-Ups
    </a> -->
  </ul>

  <div *ngIf="signedIn && currComp && !phoneSize" class="ml-auto">
    <a class="currComp" [routerLink]="['/welcome']">
      {{currComp.companyShortName}}
      <i _ngcontent-rqk-c623="" class="material-icons header-cog"> expand_more </i>
    </a>
  </div>

  <div *ngIf="signedIn && currComp && phoneSize" class="ml-auto">
    <a class="welcomeButton" [routerLink]="['/welcome']">
      <i _ngcontent-rqk-c623="" class="material-icons header-cog"> more_vert </i>
    </a>
  </div>

</nav>

<!-- for iPhone rotation we add the calc 100vh -->
<div class="container" [style.height.px]="containerHeight">

  <div *ngIf="authService.versionIncorrect" class="refresh">

    <h4 class="mt-2 mb-4">Please ensure cookies are enabled in your browser settings...</h4>

    <div *ngIf="authService.correctVersion">
      <h4>Your Truth Engine version ({{authService.currentVersion}}) is out of date.</h4>
      <h4>{{authService.correctVersion}} is now available.</h4>
      <br>
    </div>

    <h5>Please refresh the browser below to update.</h5>
    <h5>If the problem persists then try clearing the cache by holding down Ctrl and Shift and hitting Delete</h5>
    <h5>Clear "Cached images and files" only and then "Cookies and other site data" if needed</h5>
    <br>
    <button class="btn btn-primary" (click)="refresh()">Refresh Browser</button>
    <br>
    <br>
    <button class="btn btn-primary" (click)="signOut()">Sign Out</button>
    <br>

    <!-- <div *ngIf="authService.cannotGetCurrentVersion">
      <br>
      <br>
      <h5>
        Please ensure that the <span *ngIf="isSafari">Safari</span><span *ngIf="!isSafari">Browser</span> setting:
        <span *ngIf="isMobile">(Preferences -> Privacy)</span>
        "Prevent cross-site tracking" option is off.
      </h5>
    </div> -->
  </div>

  <router-outlet *ngIf="!isIframe && !authService.versionIncorrect"></router-outlet>
</div>

<js-loading-spinner *ngIf="waitingAPI" [usePopUp]="true"></js-loading-spinner>