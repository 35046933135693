<div class="modal-header">
  <h4 class="modal-title">Check Show/Hide Rules</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()" tabindex="-1">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid #dataGrid [dataSource]="dataSource" [remoteOperations]="false" [showBorders]="true"
    [showRowLines]="true" [hoverStateEnabled]="true" [wordWrapEnabled]="true" [allowColumnResizing]="true"
    [rowAlternationEnabled]="false" [ngStyle]="{'max-height': 'calc(' + maxGridHeight + 'px)'}"
    (onToolbarPreparing)="onToolbarPreparing($event)" [selectedRowKeys]="updateRecords">

    <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-selection mode="multiple"></dxo-selection>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-export enabled="true"></dxo-export>

    <!-- <dxi-column dataField="jobNumber" [width]="130" alignment="center" sortOrder="asc"></dxi-column> -->

    <dxi-column dataField="itemDescription"></dxi-column>
    <dxi-column dataField="selection"> </dxi-column>
    <dxi-column dataField="errorString"> </dxi-column>
  </dx-data-grid>
</div>