<!-- if we have more than one option we show a header line -->
<div *ngIf="jobitem.masterItemId === null && jobitem.optionNumber === 1 && jobitem.changeTypeId === changeTypeEnum.Add"
  [ngStyle]="{'margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}" class="optionClass">Added
  Item - Option: {{jobitem.optionNumber}}</div>
<div
  *ngIf="jobitem.masterItemId === null && jobitem.optionNumber > 0 && !(jobitem.optionNumber === 1 && jobitem.changeTypeId === changeTypeEnum.Add)"
  [ngStyle]="{'margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}" class="optionClass">Option:
  {{jobitem.optionNumber}}</div>

<div class="lineClass" [class.yellowBackground]="backgroundColourId === backgroundColourEnum.Yellow"
  [class.pinkBackground]="backgroundColourId === backgroundColourEnum.Pink"
  [class.blueBackground]="backgroundColourId === backgroundColourEnum.Blue">

  <span *ngIf="showNumbering" class="variationItemNumberClass"
    [class.red]="jobitem.hasConnectedItems">{{jobitem.variationItemNumber}}
  </span>

  <!-- Item Description -->
  <div *ngIf="jobitem.itemDescription && jobitem.itemDescription !== ''"
    [ngStyle]="{'width': 'calc((((' + innerWidth + 'px - ' + selectionWidthDeduction + 'px) * ' + itemDescWidthPerc + ') - ' + descWidthDeduct + 'px - ' + numberingDeduction + 'px)', 'margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}"
    class="descriptionClass">
    <div *ngIf='jobitem.isHistoryRecord'>
      <div *ngIf="jobitem.itemNotTaken">{{jobitem.itemDescription}}</div>
      <div *ngIf="!jobitem.itemNotTaken" class="strikeclass">{{jobitem.itemDescription}}</div>
    </div>

    <div *ngIf='!jobitem.isHistoryRecord'>
      <div *ngIf="!jobitem.itemNotTaken" [class.bold]="jobitem.isBoldNote" [class.italic]="jobitem.isItalicNote"
        [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
        [class.greenNote]="jobitem.noteColour === optionColourEnum.Green">{{jobitem.itemDescription}}</div>
      <div *ngIf="jobitem.itemNotTaken" class="strikeclass">{{jobitem.itemDescription}}</div>
    </div>
  </div>

  <div *ngIf="!jobitem.itemDescription || jobitem.itemDescription === ''"
    [ngStyle]="{'width': 'calc(' + innerWidth + 'px - ' + selectionWidthDeduction + 'px - ' + descWidthDeduct + 'px + ' + noQtyWidthAddition + 'px - ' + numberingDeduction + 'px)', 'margin-left': 'calc(' + descWidthDeduct + 'px' + ')'}"
    class="descriptionClass">
    <p *ngIf="jobitem.isHistoryRecord && jobitem.itemNotTaken" [class.bold]="jobitem.isBoldNote"
      [class.italic]="jobitem.isItalicNote" [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
      [class.greenNote]="jobitem.noteColour === optionColourEnum.Green"
      class="strikeclass noDescClass                      ">{{jobitem.selection}} <span
        *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO
        Standard)</span></p>

    <p *ngIf="jobitem.isHistoryRecord && !jobitem.itemNotTaken" class="strikeclass noDescClass">{{jobitem.selection}}
      <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO
        Standard)</span>
    </p>

    <p *ngIf="!jobitem.isHistoryRecord && !jobitem.itemNotTaken" [class.bold]="jobitem.isBoldNote"
      [class.italic]="jobitem.isItalicNote" [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
      [class.greenNote]="jobitem.noteColour === optionColourEnum.Green" class="noDescClass editHover"
      (click)='editItem(jobitem, false)'>{{jobitem.selection}} <span
        *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span></p>

    <p *ngIf="!jobitem.isHistoryRecord && jobitem.itemNotTaken" class="strikeclass noDescClass"
      (click)='editItem(jobitem, false)'>{{jobitem.selection}}
      <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO
        Standard)</span>
    </p>

    <span
      *ngIf="jobitem.isHistoryRecord && jobitem.changedByVO === variationNumber && jobitem.changeTypeId === changeTypeEnum.Delete">
      (Deleted)
    </span>

    <div *ngIf="!jobitem.isHistoryRecord && jobitem.changedByVO === variationNumber && variationOnlyComment"
      class="noDescClass editHover" [class.strikeclass]="jobitem.itemNotTaken" (click)='editItem(jobitem, false)'>
      {{variationOnlyComment}}
    </div>

    <div *ngIf="!phoneSize && !jobitem.isHistoryRecord && showImages"
      [ngStyle]="{'margin-left': 'calc(((' + innerWidth + 'px - ' + selectionWidthDeduction + 'px) * ' + ITEM_DESC_PERC + ' + 6px)'}">
      <js-show-image
        *ngIf="jobitem.optionListAttachmentId && !(jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF)"
        [showPointer]="true" [imageId]=jobitem.selectedOptionId [manualItem]=false [refreshImage]="refreshImage"
        (emitImageClick)="selectionDropDownOpen();">
      </js-show-image>
      <js-show-image
        *ngIf="!jobitem.optionListAttachmentId && !(jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF) && jobitem.optionImageId"
        [imageId]=jobitem.optionImageId [manualItem]=false [imageLibrary]=true [showPointer]="true"
        [refreshImage]="refreshImage" (emitImageClick)="selectionDropDownOpen();">
      </js-show-image>
      <js-show-image *ngIf="jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF"
        [imageId]=jobitem.attachmentId [manualItem]=true [attachmentVariationId]=jobitem.attachmentVariationId
        [showPointer]="true" [refreshImage]="refreshImage" (emitImageClick)="selectionDropDownOpen();">
      </js-show-image>
    </div>
  </div>

  <!-- Selection -->
  <div *ngIf="jobitem.itemDescription && jobitem.itemDescription !== ''"
    [ngStyle]="{'width': 'calc(((' + innerWidth + 'px - ' + selectionWidthDeduction + 'px) * ' + selectionWidthPerc + ') + ' + noQtyWidthAddition + 'px)'}"
    class="selectionClass">

    <!-- If Detail Dropdown -->
    <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Dropdown)'>
      <div *ngIf='jobitem.isHistoryRecord' class="paddingleft2">
        <div *ngIf="jobitem.itemNotTaken && (jobitem.selection && jobitem.selection !== '')">
          <div [class.bold]="jobitem.isBoldText" [class.red]="jobitem.optionColour === optionColourEnum.Red"
            [class.green]="jobitem.optionColour === optionColourEnum.Green">{{jobitem.selection}}
            <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
          </div>
        </div>
        <div *ngIf="jobitem.itemNotTaken && !(jobitem.selection && jobitem.selection !== '')">Please Select...</div>
        <div *ngIf="!jobitem.itemNotTaken && (jobitem.selection && jobitem.selection !== '')">
          <span class="strikeclass">{{jobitem.selection}}
            <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span></span>
          <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Delete">&nbsp;(Deleted)</span>
          <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Change">&nbsp;(Changed)</span>
        </div>
        <div *ngIf="!jobitem.itemNotTaken && !(jobitem.selection && jobitem.selection !== '')"
          class="strikeclass              ">Please Select...</div>
      </div>
      <div *ngIf="!jobitem.isHistoryRecord">
        <div
          *ngIf="(showAddEditButtonsTmp || isClientAndSelectable) && !jobitem.itemNotTaken && !(jobitem.provisionalSum && jobitem.changedByVOId !== variationId)">
          <!-- next is the selection item linked to the option list -->
          <div ngbDropdown (openChange)="selectionOpened($event)" #selectionDrop="ngbDropdown" autoClose="outside"
            placement="bottom-left">
            <button *ngIf="(jobitem.selection && jobitem.selection !== 'Please Select...')"
              class="btn-sm btn-outline-primary dropdownclass wrapText" id="dropdownBasic1" ngbDropdownToggle>
              <span [class.bold]="jobitem.isBoldText" [class.red]="jobitem.optionColour === optionColourEnum.Red"
                [class.green]="jobitem.optionColour === optionColourEnum.Green" class="wrapText">{{jobitem.selection}}
                <span *ngIf="isPrintedDescDifferent" class="red">**</span>
                <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span></span>
            </button>
            <button *ngIf="(!jobitem.selection || jobitem.selection === 'Please Select...')"
              class="btn-sm btn-outline-primary dropdownclass2 red" id="dropdownBasic1" ngbDropdownToggle>Please
              Select...</button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdownmenuclass"
              [ngStyle]="{'max-width': maxSelectionDropDownWidth + 'px', 'min-width': minSelectionDropDownWidth + 'px'}">
              <div *ngIf="!waitingAPI">
                <div *ngFor="let opt of optionList">
                  <button *ngIf="opt.description !== 'Other - Click here' && !opt.notSelectable" class="dropdown-item"
                    (click)='setSelection(jobitem, opt); selectionDrop.close();'>
                    <span [class.bold]="opt.isBoldText" [class.red]="opt.optionColour === optionColourEnum.Red"
                      [class.green]="opt.optionColour === optionColourEnum.Green"
                      class="wrapText">{{opt.description}}</span>
                  </button>
                  <div *ngIf="opt.description !== 'Other - Click here' && opt.notSelectable" class="dropdown-item">
                    <span [class.bold]="opt.isBoldText" [class.red]="opt.optionColour === optionColourEnum.Red"
                      [class.green]="opt.optionColour === optionColourEnum.Green"
                      class="wrapText">{{opt.description}}</span>
                  </div>
                  <button *ngIf="opt.description === 'Other - Click here'" class="dropdown-item"
                    (click)="editItem(jobitem, true); selectionDrop.close();">
                    ** Other - Click here **
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <js-select-dropdown [jobitem]=jobitem [maxSelectionDropDownWidth]="maxSelectionDropDownWidth"
            [minSelectionDropDownWidth]="minSelectionDropDownWidth" [showImages]="showImages" (selectedOption)="setSelectionFromDropdown($event)">
          </js-select-dropdown> -->
        </div>
        <div
          *ngIf="!(showAddEditButtonsTmp || isClientAndSelectable) || jobitem.itemNotTaken || (jobitem.provisionalSum && jobitem.changedByVOId !== variationId)"
          class="paddingleft2 paddingFix">
          <div [class.bold]="jobitem.isBoldText" [class.redNote]="jobitem.optionColour === optionColourEnum.Red"
            [class.greenNote]="jobitem.optionColour === optionColourEnum.Green"
            [class.strikeclass]="jobitem.itemNotTaken">
            <span *ngIf="jobitem.selection && jobitem.selection !== ''">{{jobitem.selection}}</span>
            <span *ngIf="isPrintedDescDifferent" class="red">**</span>
            <span *ngIf="!jobitem.selection || jobitem.selection === ''">Please Select...</span>
            <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard"> (EO Standard)</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Check Box -->
    <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (jobitem.selectionTypeId === SelectionType.Checkbox)'>
      <div *ngIf="jobitem.isHistoryRecord">
        <div *ngIf="jobitem.itemNotTaken">
          <div *ngIf="jobitem.selection === 'Yes'" class="checkboxclass">
            {{jobitem.selection}} <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
          </div>
          <div *ngIf="jobitem.selection === ''" class="checkboxclass">
            No <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
          </div>
        </div>
        <div *ngIf="!jobitem.itemNotTaken">
          <div *ngIf="jobitem.selection === 'Yes'" class="checkboxclass">
            <span class="strikeclass">
              {{jobitem.selection}} <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
            </span>
            <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Delete">&nbsp;(Deleted)</span>
            <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Change">&nbsp;(Changed)</span>
          </div>
          <div *ngIf="jobitem.selection === ''" class="checkboxclass"><span class="strikeclass">
              No <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span></span>
            <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Delete">&nbsp;(Deleted)</span>
            <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Change">&nbsp;(Changed)</span>
          </div>
        </div>
      </div>
      <div *ngIf="!jobitem.isHistoryRecord">
        <div *ngIf="showAddEditButtonsTmp && !waitingAPI && !jobitem.itemNotTaken">
          <div class="checkbox checkboxclass">
            <label class="checkboxclass">
              <input name="selected" type="checkbox" [(ngModel)]="jobitem.selection"
                (change)="setCheckBox(jobitem, jobitem.selection)" class="checkboxclass"> <span
                *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
            </label>
          </div>
        </div>
        <div *ngIf="!showAddEditButtonsTmp || waitingAPI || jobitem.itemNotTaken">
          <div *ngIf="jobitem.itemNotTaken" class="strikeclass">
            <div *ngIf="jobitem.selection === 'Yes'">{{jobitem.selection}} <span
                *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO
                Standard)</span>
            </div>
            <div *ngIf="jobitem.selection === ''">No <span *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO
                Standard)</span>
            </div>
          </div>
          <div *ngIf="!jobitem.itemNotTaken">
            <div class="checkbox checkboxclass">
              <label class="checkboxclass">
                <input name="selected" type="checkbox" [(ngModel)]="jobitem.selection"
                  (change)="setCheckBox(jobitem, jobitem.selection)" class="checkboxclass" disabled> <span
                  *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf='(jobitem.itemTypeId === ItemType.Detail) && (!jobitem.selectionTypeId)'>
      <div *ngIf='jobitem.isHistoryRecord && jobitem.changedByVO === variationNumber'>
        <p *ngIf="jobitem.itemNotTaken" class="noteClass">{{jobitem.selection}} <span
            *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO
            Standard)</span>
        </p>
        <p *ngIf="!jobitem.itemNotTaken" class="noteClassStrike">{{jobitem.selection}} <span
            *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
          <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Delete">&nbsp;(Deleted)</span>
          <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Change">&nbsp;(Changed)</span>
        </p>
      </div>
      <div *ngIf='!jobitem.isHistoryRecord'>
        <p *ngIf="!jobitem.itemNotTaken" class="noteClass">{{jobitem.selection}}
          <span *ngIf="isPrintedDescDifferent" class="red">**</span> <span
            *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO
            Standard)</span>
        </p>
        <p *ngIf="jobitem.itemNotTaken" class="noteClassStrike">{{jobitem.selection}} <span
            *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
        </p>
      </div>
    </div>

    <!-- if Note line -->
    <div *ngIf='(jobitem.itemTypeId === ItemType.Note)'>
      <div *ngIf='jobitem.isHistoryRecord && jobitem.changedByVO === variationNumber'>
        <p *ngIf="jobitem.itemNotTaken" class="noteClass">
          <span [class.bold]="jobitem.isBoldNote" [class.italic]="jobitem.isItalicNote"
            [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
            [class.greenNote]="jobitem.noteColour === optionColourEnum.Green">{{jobitem.selection}} <span
              *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span></span>
        </p>
        <p *ngIf="!jobitem.itemNotTaken" class="noteClassStrike">{{jobitem.selection}} <span
            *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
          <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Delete">&nbsp;(Deleted)</span>
          <span *ngIf="jobitem.changeTypeId === changeTypeEnum.Change">&nbsp;(Changed)</span>
        </p>
      </div>
      <div *ngIf='!jobitem.isHistoryRecord'>
        <p *ngIf="!jobitem.itemNotTaken" class="noteClass editHover">
          <span [class.bold]="jobitem.isBoldNote" [class.italic]="jobitem.isItalicNote"
            [class.redNote]="jobitem.noteColour === optionColourEnum.Red"
            [class.greenNote]="jobitem.noteColour === optionColourEnum.Green"
            (click)='editItem(jobitem, false)'>{{jobitem.selection}} <span
              *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span></span>
        </p>
        <p *ngIf="jobitem.itemNotTaken" class="noteClassStrike">{{jobitem.selection}} <span
            *ngIf="jobitem.costTypeId === costTypeEnum.EOStandard">(EO Standard)</span>
        </p>
      </div>
    </div>

    <div *ngIf="!jobitem.isHistoryRecord && jobitem.changedByVO === variationNumber && variationOnlyComment"
      class="noteClass editHover" [class.strikeclass]="jobitem.itemNotTaken" (click)='editItem(jobitem, false)'>
      {{variationOnlyComment}}
    </div>

    <span *ngIf="!phoneSize">
      <js-show-image
        *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.optionListAttachmentId && !(jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF)"
        [imageId]=jobitem.selectedOptionId [manualItem]=false [showPointer]="true" [refreshImage]="refreshImage"
        (emitImageClick)="selectionDropDownOpen();">
      </js-show-image>
      <js-show-image
        *ngIf="!jobitem.isHistoryRecord && showImages && !jobitem.optionListAttachmentId && !(jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF) && jobitem.optionImageId"
        [imageId]=jobitem.optionImageId [manualItem]=false [imageLibrary]=true [showPointer]="true"
        [refreshImage]="refreshImage" (emitImageClick)="selectionDropDownOpen();">
      </js-show-image>
      <js-show-image
        *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF"
        [imageId]=jobitem.attachmentId [manualItem]=true [attachmentVariationId]=jobitem.attachmentVariationId
        [showPointer]="true" [refreshImage]="refreshImage" (emitImageClick)="selectionDropDownOpen();">
      </js-show-image>
    </span>
  </div>

  <!-- qty if required -->
  <div *ngIf="showQtyField" [ngStyle]="{'width': 'calc('+ quantityWidth + 'px' + ')'}" class="showQtyClass">
    <div *ngIf="!jobitem.isHistoryRecord && !jobitem.hasLinkedItems && (jobitem.itemNotTaken || !showAddEditButtonsTmp
      || jobitem.costTypeId === costTypeEnum.PSFinalised || jobitem.costTypeId === costTypeEnum.ProvisionalSum)">
      {{jobitem.quantity | number : '1.0-4'}}
    </div>
    <div *ngIf="!jobitem.isHistoryRecord && !jobitem.hasLinkedItems && !(jobitem.itemNotTaken || !showAddEditButtonsTmp
      || jobitem.costTypeId === costTypeEnum.PSFinalised || jobitem.costTypeId === costTypeEnum.ProvisionalSum)">
      <div ngbDropdown #myDrop="ngbDropdown" (openChange)="saveQty($event)">
        <button class="btn-sm btn-outline-primary dropdownclass2" id="dropdownQty" ngbDropdownToggle>
          {{jobitem.quantity | number : '1.0-4'}}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownQty" class="qtyDropdown">
          <!-- until service returns only the ones required test for Ids equal -->
          <div *ngIf="!waitingAPI">
            <form class="form-horizontal">
              <div class="form-group row">
                <label for="quantityEnteredId" class="control-label"></label>
                <input name="quantityEnteredId" class="form-control-sm qtyDropdownField" type="number"
                  [(ngModel)]="quantityEntered" (click)='$event.stopPropagation()' autocomplete="off"
                  (keydown.enter)="myDrop.close();" (keydown.tab)="myDrop.close();">
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Price -->
  <div *ngIf="priceWidth" [ngStyle]="{'width': 'calc('+ priceWidth + 'px' + ')'}" class="priceclass">

    <div *ngIf="!jobitem.hasLinkedItems && !isAssociate" class="noPriceClass">

      <div
        *ngIf="variationType !== variationTypeEnum.Office && variationType !== variationTypeEnum.PreContractExFromContract
        && isClient && !jobitem.isChecked && !jobitem.itemNotTaken
        && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete) && jobitem.changedByVO === variationNumber"
        class="amountClass">
        TBA
      </div>

      <div *ngIf="variationType !== variationTypeEnum.Office && variationType !== variationTypeEnum.PreContractExFromContract
      && !jobitem.isDoNotPrint && (!isClient || jobitem.isChecked || jobitem.itemNotTaken)" class="amountClass"
        (click)="editItem(jobitem, false)"
        [class.strikeclass]="(jobitem.isHistoryRecord && jobitem.changeTypeId !== changeTypeEnum.Delete && !jobitem.itemNotTaken)">

        <!-- if we have a provisionalSum it has been set in the job item or previous VO - if not costType tells us the price is a PS -->
        <span *ngIf="jobitem.isHistoryRecord || !jobitem.itemNotTaken">
          <span
            *ngIf='jobitem.provisionalSum || jobitem.costTypeId === costTypeEnum.ProvisionalSum || jobitem.costTypeId === costTypeEnum.PSFinalised'
            class="boldText">
            <span
              *ngIf='!phoneSize && jobitem.changedByVO !== variationNumber && jobitem.costTypeId === costTypeEnum.PSFinalised; else elseBlock'>
              Fixed
            </span>
            <ng-template #elseBlock>
              <span
                *ngIf='!phoneSize && jobitem.costTypeId !== costTypeEnum.PSFinalised && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)'>
                PS
              </span>
              <span *ngIf='!phoneSize && jobitem.costTypeId === costTypeEnum.PSFinalised
              && jobitem.changeTypeId !== changeTypeEnum.Delete && (jobitem.price + jobitem.provisionalSum)'>
                {{(jobitem.price + jobitem.provisionalSum) | number}}
              </span>
            </ng-template>
          </span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.changedByVO === variationNumber && jobitem.costTypeId === costTypeEnum.NoCharge
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)
          && !(jobitem.provisionalSum && jobitem.changeTypeId === changeTypeEnum.Delete)'>N/C</span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.costTypeId === costTypeEnum.ByOwner'>
            <span *ngIf="jobitem.price">*</span>
            <span *ngIf="!jobitem.price">By Owner</span>
          </span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.changedByVO === variationNumber && jobitem.costTypeId === costTypeEnum.Note
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)'>
            <span *ngIf="!jobitem.price">Note</span>
          </span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.costTypeId === costTypeEnum.Excluded'>
            <span *ngIf="jobitem.price">*</span>
            <span *ngIf="!jobitem.price">Excluded</span>
          </span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.changedByVO === variationNumber && (jobitem.costTypeId === costTypeEnum.Included)
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)'>
            <span *ngIf="jobitem.price && !isClient">*</span>
            <span *ngIf="!jobitem.price || isClient">Included</span>
          </span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.changedByVO === variationNumber && (jobitem.costTypeId === costTypeEnum.Promotion)
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)'>
            <span *ngIf="jobitem.price && !isClient">*</span>
            <span *ngIf="!jobitem.price || isClient">Promotion</span>
          </span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.changedByVO === variationNumber && (jobitem.costTypeId === costTypeEnum.BonusItem)
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)'>
            <span *ngIf="jobitem.price && !isClient">*</span>
            <span *ngIf="!jobitem.price || isClient">Bonus</span>
          </span>

          <span *ngIf='!jobitem.itemNotTaken && jobitem.changedByVO === variationNumber
          && !(jobitem.costTypeId > costTypeEnum.Priced && jobitem.costTypeId !== costTypeEnum.EOStandard)
          && !jobitem.provisionalSum
          && (jobitem.price ===  null || jobitem.price === 0 || (isQtyRequired && (jobitem.quantity === 0)))
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)'>TBA</span>

          <span
            *ngIf="!jobitem.itemNotTaken && (jobitem.price || jobitem.provisionalSum)
          && (jobitem.costTypeId !== costTypeEnum.PSFinalised || jobitem.changeTypeId === changeTypeEnum.Delete)
          && (!isClient || jobitem.costTypeId !== costTypeEnum.Included)
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete || jobitem.costTypeId === costTypeEnum.ProvisionalSum)">
            <span
              *ngIf="jobitem.costTypeId === costTypeEnum.PSFinalised && jobitem.changeTypeId === changeTypeEnum.Delete"
              [class.redNote]='(jobitem.price < 0)'>
              {{(jobitem.price) | number}}
            </span>
            <span
              *ngIf="jobitem.costTypeId !== costTypeEnum.PSFinalised || jobitem.changeTypeId !== changeTypeEnum.Delete"
              [class.redNote]='((jobitem.price + jobitem.provisionalSum) < 0)'>
              {{(jobitem.price + jobitem.provisionalSum) | number}}
            </span>
          </span>
        </span>

        <span *ngIf='!jobitem.isHistoryRecord && jobitem.itemNotTaken' class="redNote">
          Not Taken
        </span>

        <span *ngIf='jobitem.isHistoryRecord && jobitem.itemNotTaken
        && jobitem.changeTypeId === changeTypeEnum.Delete' class="redNote">
          Delete Not Taken
        </span>

      </div>

      <div *ngIf="isCheckedWidth !== 0" [ngStyle]="{'width': 'calc(' + isCheckedWidth + 'px)'}" class="isCheckedClass">
        <!-- If Admin the we show the isChecked box -->
        <span *ngIf="!waitingAPI && !jobitem.itemNotTaken && jobitem.changedByVO === variationNumber
          && (!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)
          && ((jobitem.costTypeId && jobitem.costTypeId > costTypeEnum.Priced
           && jobitem.costTypeId !== costTypeEnum.EOStandard && jobitem.costTypeId !== costTypeEnum.ProvisionalSum)
            || (jobitem.price && jobitem.price !== 0)
            || variationType === variationTypeEnum.Office
            || variationType === variationTypeEnum.PreContractExFromContract
            || jobitem.isDoNotPrint)">
          <label for="isChecked"></label>
          <input type="checkbox" name="isChecked" ngModel id="isChecked" [ngModel]="jobitem.isChecked"
            (click)="itemChecked(jobitem)">
        </span>
      </div>
    </div>
  </div>

  <div [ngStyle]="{'width': 'calc(' + buttonsWidth + 'px)'}" class="buttonsClass ms-auto">

    <div *ngIf="(showAddEditButtonsTmp)">
      <!-- add a var item line after this one -->
      <button *ngIf="(!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)"
        class='btn btn-sm btn-outline-primary btnline titleBtn'
        (click)="$event.preventDefault(); $event.stopPropagation(); addVarItem(jobitem)"><i
          class="material-icons">add</i></button>

      <button *ngIf="!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete"
        class='btn btn-sm btn-outline-primary btnline titleBtn'
        (click)='$event.preventDefault(); $event.stopPropagation(); editItem(jobitem, false)'>
        <i class="material-icons">create</i>
      </button>

      <button *ngIf="(!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)
      && (jobitem.commentTypeId || jobitem.changedByVOId === variationId)"
        class='btn btn-sm btn-outline-primary btnline titleBtn' (click)='showComment()'>
        <i class="material-icons" [class.orange]="jobitem.commentTypeId === jobItemCommentTypeEnum.Normal"
          [class.red]="jobitem.commentTypeId === jobItemCommentTypeEnum.Important">note</i>
      </button>

      <button *ngIf="(!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)
        && jobitem.changedByVOId === variationId && !isClient && !isAssociate"
        class='btn btn-sm btn-outline-primary btnline titleBtn'
        [class.yellowButton]="backgroundColourId === backgroundColourEnum.Yellow"
        [class.pinkButton]="backgroundColourId === backgroundColourEnum.Pink"
        [class.blueButton]="backgroundColourId === backgroundColourEnum.Blue"
        (click)='$event.preventDefault(); $event.stopPropagation(); changeBackground()'
        [disabled]="waitingForBackgroundColourChange">
        <i class="material-icons">flag</i>
      </button>

      <button *ngIf="!(jobitem.isHistoryRecord) && (jobitem.attachmentId)"
        class='btn btn-sm btn-outline-primary btnline titleBtn'
        (click)='$event.preventDefault(); $event.stopPropagation(); showAttachment(jobitem)'>
        <i class="material-icons">attach_file</i>
      </button>

      <button *ngIf="!(jobitem.isHistoryRecord) && isEstimatingAttachmentsExist"
        class='btn btn-sm btn-outline-primary btnline titleBtn' (click)='editItem(jobitem, false)'
        matTooltip="Estimating Attachments Exist" matTooltipPosition="above" [matTooltipShowDelay]="500">
        <i class="material-icons orange">attach_file</i>
      </button>
    </div>

    <div *ngIf="!showAddEditButtonsTmp">
      <button *ngIf="!jobitem.isHistoryRecord && jobitem.commentTypeId
     && jobitem.changedByVOId === variationId && !isClient && !isAssociate"
        class='btn btn-sm btn-outline-primary btnline titleBtn' (click)='showComment()'>
        <i class="material-icons" [class.orange]="jobitem.commentTypeId === jobItemCommentTypeEnum.Normal"
          [class.red]="jobitem.commentTypeId === jobItemCommentTypeEnum.Important">note</i>
      </button>

      <button *ngIf="(!jobitem.isHistoryRecord || jobitem.changeTypeId === changeTypeEnum.Delete)
        && jobitem.changedByVOId === variationId && !isClient && !isAssociate && !phoneSize"
        class='btn btn-sm btn-outline-primary btnline titleBtn'
        (click)='$event.preventDefault(); $event.stopPropagation(); changeBackground()'
        [disabled]="waitingForBackgroundColourChange">
        <i class="material-icons">flag</i>
      </button>

      <button *ngIf="!(jobitem.isHistoryRecord) && (jobitem.attachmentId) && !isClientAndSelectable"
        class='btn btn-sm btn-outline-primary btnline titleBtn' (click)='showAttachmentReadOnly(jobitem)'>
        <i class="material-icons">attach_file</i>
      </button>

      <button *ngIf="!(jobitem.isHistoryRecord) && (jobitem.attachmentId) && isClientAndSelectable"
        class='btn btn-sm btn-outline-primary btnline titleBtn'
        (click)='$event.preventDefault(); $event.stopPropagation(); showAttachment(jobitem)'>
        <i class="material-icons">attach_file</i>
      </button>

      <button *ngIf="!(jobitem.isHistoryRecord) && isEstimatingAttachmentsExist && !phoneSize"
        class='btn btn-sm btn-outline-primary btnline titleBtn' (click)='editItem(jobitem, false)'
        matTooltip="Estimating Attachments Exist" matTooltipPosition="above" [matTooltipShowDelay]="500">
        <i class="material-icons orange">attach_file</i>
      </button>
    </div>

  </div>
</div>

<div *ngIf="jobitem.deleteComment && jobitem.deleteComment !== ''" class="deleteCommentClass"
  [class.strikeclass]="jobitem.itemNotTaken">{{jobitem.deleteComment}}</div>

<div *ngIf="phoneSize" class="floatMiddle">
  <js-show-image
    *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.optionListAttachmentId && (!jobitem.attachmentId || jobitem.attachmentTypeId === attachmentTypeEnum.PDF)"
    [imageId]=jobitem.selectedOptionId [manualItem]=false [refreshImage]="refreshImage"
    (emitImageClick)="selectionDropDownOpen();">
  </js-show-image>
  <js-show-image
    *ngIf="!jobitem.isHistoryRecord && showImages && !jobitem.optionListAttachmentId && (!jobitem.attachmentId || jobitem.attachmentTypeId === attachmentTypeEnum.PDF) && jobitem.optionImageId"
    [imageId]=jobitem.optionImageId [manualItem]=false [imageLibrary]=true [refreshImage]="refreshImage"
    (emitImageClick)="selectionDropDownOpen();">
  </js-show-image>
  <js-show-image
    *ngIf="!jobitem.isHistoryRecord && showImages && jobitem.attachmentId && jobitem.attachmentTypeId !== attachmentTypeEnum.PDF"
    [imageId]=jobitem.attachmentId [manualItem]=true [attachmentVariationId]=jobitem.attachmentVariationId
    [refreshImage]="refreshImage" (emitImageClick)="selectionDropDownOpen();">
  </js-show-image>
</div>
