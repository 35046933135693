import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { GlobalService } from '../global.service';
import { IJobVarItem } from '../../dtos/job-var-item';
import { SelectedJobItems } from '../../dtos/selectedJobItemIds';
import { JobVarCalc } from '../../dtos/job-var-calc';
import { JobVarItemExtra } from '../../dtos/job-var-item-extra';
import { AuthService } from '../auth.service';
import { HttpService } from '../http.service';
import { ModifiedBy } from '../../dtos/modified-by';

@Injectable({
  providedIn: 'root'
})
export class JobVarItemService {

  allJobVarCalcs: JobVarCalc[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private _authService: AuthService,
    private userService: UserService,
    private globalService: GlobalService) { }

  getPreviousJobVarItem(jobVariationId, changedByJobVarId): Observable<IJobVarItem> {
    return this._http.get<IJobVarItem>(this.globalService.getApiUrl() +
      '/variation-items/' + changedByJobVarId + '/previous?jobVariationId=' + jobVariationId,
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  getJobVarItemChangedBy(id): Observable<ModifiedBy> {
    return forkJoin(
      [
        this.getJobVarItemChangedById(id),
        this.userService.getAllCurrCompUsers(true)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getJobVarItemChangedById(id): Observable<ModifiedBy> {
    return this._http.get<ModifiedBy>(this.globalService.getApiUrl() + '/variation-items/' + id + '/who',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addJobVarItem(varNum, itm: any): Observable<IJobVarItem[]> {

    let url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() + '/' + varNum + '/variation-items';

    if (this._authService.isClient()) {
      url += '-by-client';
    }

    return this._http.post<IJobVarItem[]>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateJobVarItem(itm: any): Observable<IJobVarItem[]> {
    let url = this.globalService.getApiUrl() + '/variation-items/' + itm.id;

    if (this._authService.isClient()) {
      url += '/by-client';
    }

    return this._http.patch<IJobVarItem[]>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      tap(() => {
        // delete the cached jobVarCalcRecords as we will read them again
        this.removeJobVarCalcs(itm.id);
      }),
      catchError(this.handleError));
  }

  updateJobVarItemIsChecked(itm: any) {
    const url = this.globalService.getApiUrl() + '/variation-items/' + itm.id + '/ischecked';
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateJobVarItemsAllChecked(jobVariationId: number, isChecked: boolean) {
    const url = this.globalService.getApiUrl() + '/variation-items/' + jobVariationId + '/set-all-ischecked?isChecked=' + isChecked;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateJobVarItemIsDoNotPrint(id: number, isDoNotPrint: boolean, itemDescription: string) {
    const url = this.globalService.getApiUrl() + '/variation-items/' + id + '/set-isdonotprint?isDoNotPrint=' + isDoNotPrint;
    return this._http.patch(url, JSON.stringify({ itemDescription: itemDescription }), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateJobVarItemIsAcceptedIntoContract(itmId: any, isAcceptedIntoContract: boolean) {
    const url = this.globalService.getApiUrl() + '/variation-items/' + itmId
      + '/set-isAcceptedIntoContract?isAcceptedIntoContract=' + isAcceptedIntoContract;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateJobVariationType(jobVariationId: number, variationTypeId: number) {
    const url = this.globalService.getApiUrl() + '/job-variations/' + jobVariationId + '/move-type?variationTypeId=' + variationTypeId;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // updateJobVarCalc(jobVarItemId: number, itm: any): Observable<JobVarCalc[]> {
  //   const url = this.globalService.getApiUrl() + '/jobs/' + jobVarItemId + '/variation-item-calc';
  //   return this._http.patch<JobVarCalc[]>(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
  //     tap(res => {
  //       this.allJobVarCalcs = res;
  //     }),
  //     catchError(this.handleError));
  // }

  deleteJobVarItem(itmId: number) {
    let url = this.globalService.getApiUrl() + '/variation-items/' + itmId;

    if (this._authService.isClient()) {
      url += '/by-client';
    }

    return this._http.delete(url, this.httpService.getHttpOptions()).pipe(
      tap(() => {
        // delete the cached jobVarCalcRecords as we will read them again
        this.removeJobVarCalcs(itmId);
      }),
      catchError(this.handleError));
  }

  createJobVarItemOption(varNum, id: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob() +
      '/' + varNum + '/variation-item-option/' + id;
    return this._http.post<IJobVarItem>(url, JSON.stringify(id), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateJobVarItemSetNotTaken(itmId: number, itemNotTaken: boolean) {
    const url = this.globalService.getApiUrl() + '/variation-items/' + itmId + '/set-not-taken?itemNotTaken=' + itemNotTaken;
    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  updateByBoard(varNum, boardId: number) {
    // update job items by calling the board update patch
    let url = this.globalService.getApiUrl() + '/variation-items/' + this.globalService.getCurrentJob() +
      '/variation/' + varNum;
    if (this._authService.isClient()) {
      url += '/board-items-by-client/' + boardId;
    } else {
      url += '/board-items/' + boardId;
    }
    const itm = {};
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions()).pipe(
      tap(() => {
        // delete the cached jobVarCalcRecords as we will read them again
        this.allJobVarCalcs = [];
      }),
      catchError(this.handleError));
  }

  setConnectedItems(variationId, itemId: number, itemsToSet: SelectedJobItems) {
    const url = this.globalService.getApiUrl() + '/connected-items/' + itemId + '?jobVariationId=' + variationId;
    return this._http.patch(url, JSON.stringify(itemsToSet), this.httpService.getHttpOptions());
  }

  setOtherOptionsNotTaken(itemId: number) {
    // update job items by calling the board update patch
    const url = this.globalService.getApiUrl() + '/variation-items/' + this.globalService.getCurrentJob() + '/' + itemId + '/selected';
    const itm = {};
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  moveItemsToNewVO(variationFromId: number, variationToId: number, itemsToSet: SelectedJobItems) {
    const url = this.globalService.getApiUrl() + '/variation-items/' + this.globalService.getCurrentJob()
      + '/variation/' + variationFromId + '/move-items/' + variationToId;
    return this._http.patch(url, JSON.stringify(itemsToSet), this.httpService.getHttpOptions());
  }

  getAllJobVarCalcs(jobVariationId: number): Observable<JobVarCalc[]> {
    this.allJobVarCalcs = [];
    // return of(this.allJobVarCalcs);
    return this._http.get<JobVarCalc[]>(this.globalService.getApiUrl() + '/job-variations/' + jobVariationId + '/variation-item-calcs',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.allJobVarCalcs = res;
        }),
        catchError(this.handleError));
  }

  getJobVarCalcs(jobVarItemId: number): Observable<JobVarCalc[]> {
    if (this.allJobVarCalcs && this.allJobVarCalcs.length && this.allJobVarCalcs.find(i => i.jobVarItemId === jobVarItemId)) {
      return of(this.allJobVarCalcs.filter(i => i.jobVarItemId === jobVarItemId));
    } else {
      return this._http.get<JobVarCalc[]>(this.globalService.getApiUrl() + '/jobs/' + jobVarItemId + '/variation-item-calc',
        this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.allJobVarCalcs = this.allJobVarCalcs.concat(res);
          }),
          catchError(this.handleError));
    }
  }

  removeJobVarCalcs(jobVarItemId: number) {
    if (this.allJobVarCalcs && this.allJobVarCalcs.length) {
      const foundRecords = this.allJobVarCalcs.filter(i => i.jobVarItemId === jobVarItemId);
      if (foundRecords && foundRecords.length) {
        foundRecords.forEach(foundRecord => {
          const foundId = this.allJobVarCalcs.findIndex(i => i.id === foundRecord.id);
          if (foundId >= 0) {
            this.allJobVarCalcs.splice(foundId, 1);
          }
        });
      }
    }
  }

  copyJobVarItems(jobNumberFrom: string, variationFrom: number, variationTo: number, items: any) {
    const url = this.globalService.getApiUrl() + '/jobs/' + this.globalService.getCurrentJob()
      + '/copy-variation-items?variationId=' + variationTo + '&jobNumberFrom=' + jobNumberFrom + '&variationFromId=' + variationFrom;
    return this._http.post(url, JSON.stringify(items), this.httpService.getHttpOptions());
  }

  getJobVarItemExtras(jobNumber: string, variationNumber: number): Observable<JobVarItemExtra[]> {
    return this._http.get<JobVarItemExtra[]>(this.globalService.getApiUrl() + '/jobs/' + jobNumber + '/variation/' + variationNumber + '/variation-item-extras',
      this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  setJobVarItemExtras(jobVarItemId: number, colourId: number): Observable<JobVarItemExtra> {
    const url = this.globalService.getApiUrl() + '/variation-item-extras/' + jobVarItemId;
    return this._http.patch<JobVarItemExtra>(url, JSON.stringify({ colourId: colourId }), this.httpService.getHttpOptions());
  }

  addJobVarItemExtras(jobVarItemId: number, updateData: number): Observable<JobVarItemExtra> {
    const url = this.globalService.getApiUrl() + '/variation-item-extras/' + jobVarItemId;
    return this._http.patch<JobVarItemExtra>(url, JSON.stringify(updateData), this.httpService.getHttpOptions());
  }

  clearVariationHighlighting(jobVariationId: number) {
    const url = this.globalService.getApiUrl() + '/variation-item-extras/' + jobVariationId + '/clear-highlighting';
    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  moveJobVarItem(itemToMoveId: number, jobItemId: number, jobVarItemId: number, addAsChild: boolean) {
    const url = this.globalService.getApiUrl() + '/variation-items/' + itemToMoveId + '/reorder';
    const updateData = {
      afterJobItemId: jobItemId,
      afterJobVarItemId: jobVarItemId,
      asChild: addAsChild
    };
    return this._http.patch(url, JSON.stringify(updateData), this.httpService.getHttpOptions());
  }


  // reprice estimating items and the variation
  repriceVariation(jobVariationId: number, newDateString: string, jobVarItemId: number) {
    let url = this.globalService.getApiUrl() + '/jobvarcostitems/revalue?jobVariationId=' + jobVariationId
      + '&newDateString=' + newDateString;

    if (jobVarItemId) {
      url += '&jobVarItemId=' + jobVarItemId;
    }
    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }


  postUploadtemplate(jobNumber: string, variationId: number, headingTypeId: number, headingItemId: number,
    jobVarTypeId: number, itemChangedId: number, xlFile) {

    const url = this.globalService.getApiUrl() + '/variation-items/' + jobNumber + '/excel-upload' +
      '?variationId=' + variationId + '&headingTypeId=' + headingTypeId + '&headingItemId=' + headingItemId +
      '&jobVarTypeId=' + jobVarTypeId + '&itemChangedId=' + itemChangedId;

    const options = this.httpService.getHttpFileOptions();
    return this._http.post(url, xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}

