import { IAddress } from './address';

export class IJob {
    id: number;
    jobNumber: string;
    jobAddressId: number;
    contractName: string;
    contractAddressId: number;
    isActive: boolean;
    jobAddress: IAddress;
    contractAddress: IAddress;
    isLocked: boolean;
    lockedDate: Date;
    generalNotes: string;
    warningNote: string;
    contractPrice: number;
    clientEmail: string;
    phoneNumber1: string;
    phoneNotes1: string;
    phoneNumber2: string;
    phoneNotes2: string;
    salesDate: Date;
    contractSignedDate: Date;
    houseTypeId: number;
    depositAmount: number;
    basePrice: number;
    budgetAmount: number;
    volume: string;
    folio: string;
    council: string;
    jobTypeId: number;
    isCustomerNotificationEnabled: boolean;
    houseModificationDescription: string;
    jobMargin: number;
    variationMargin: number;
    isGSTFree: boolean;
    gstRate: number;
    quoteDate: Date;
    totalReceipts: number;
    estate: string;
    landZoningId: number;
    landTitleTypeId: number;
    landTypeId: number;
    titleDueDate: Date;
    depositPaid: number;
    depositBalanceDue: Date;
    feesMargin: number;
    planNumber: string;
    salutation: string;
    divisionId: number;
    salesQuoteSignedDate: Date;
    contractQuoteDate: Date;
    firstName1: string;
    lastName1: string;
    firstName2: string;
    lastName2: string;
    masterJobId: number;
    depositAmount2: number; // for Oakford's second deposit
    isMasterJob: boolean;
    estateMasterId: number;
    isEmailClientUpdates: boolean;
    useMasterJobAddenda: boolean;
    useUploadedAddenda: boolean;
    contractsRequiredByDate: Date;
    useMasterJobDocuments: boolean;

    jobAddressString: string;
    isOnHold: boolean;
    currentActivityCode: string;
    currentActivityDesc: string;

    landPrice: number;
    landId: number;
}


export class JobForm {
    jobNumber: string;
    divisionId: number;
    contractName: string;
    houseTypeId: number;
    salesDate: Date;
    contractSignedDate: Date;
    completionDate: Date;
    handoverDate: Date;
    maintenanceCompleteDate: Date;
    clientEmail: string;
    phoneNumber1: string;
    phoneNotes1: string;
    phoneNumber2: string;
    phoneNotes2: string;
    firstName1: string;
    lastName1: string;
    firstName2: string;
    lastName2: string;
    jobAddress: IAddress;
}
