import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PDFReports } from '../dtos/pdf-report';

import { VariationService } from '../services/felixApi/variation.service';
import { JobItemService } from '../services/felixApi/job-item.service';
import { GlobalService } from '../services/global.service';
import { JobService } from '../services/felixApi/job.service';
import { JobItemLinkService } from '../services/felixApi/job-item-link.service';
import { JobSearchTypeEnum } from '../dtos/job-search-type.enum';
import { ShowPdfComponent } from '../shared/show-pdf.component';
import { User } from '../dtos/user';
import { UserService } from '../services/felixApi/user.service';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { SelectOptionModalComponent } from '../option-lists/select-option/select-option-modal.component';
import { SelectedIDs } from '../dtos/selectedIDs';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { JobMarginComponent } from './job-margin/job-margin.component';
import { IJob } from '../dtos/job';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { ReportActiveCampaignComponent } from './report-active-campaign/report-active-campaign.component';
import { ReportHubSpotComponent } from './report-hubspot/report-hubspot.component';
import { LoanShortfallComponent } from './loan-shortfall/loan-shortfall.component';
import { JobDocumentsChangedComponent } from './job-documents-changed/job-documents-changed.component';
import { VariationReportComponent } from './variation-report/variation-report.component';

@Component({
  selector: 'js-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'reports';
  subscriptions: Subscription[] = [];

  loading = false;
  successMessage = 'Success. Check your downloads folder.';
  success = false;
  errorMessage = '';
  error = false;
  pdfReport: PDFReports;
  blob: any;
  pdf: any;
  reportHeading: string;
  reportDescription: string;
  closeResult = '';
  askForJobNumber: boolean;
  jobNumber: string;
  jobNumberForm: UntypedFormGroup;
  okToRunReport: boolean;
  canRunForAllJobs = false;
  jobSearchTypeEnum = JobSearchTypeEnum; // when we do a job search we can specify if we want to get the templates or all jobs initially
  userTypeEnum = UserTypeEnum;

  users: User[] = [];
  usersTemp: User[] = [];
  useAssignedTo = false;
  selectUser = false;
  permissionLevel: string;
  jobsWritePermission = false;
  jobLineSetupAdmin = false;

  // for variation report
  variationStatus = [
    { text: 'Open', id: 1 },
    { text: 'Estimating', id: 2 },
    { text: 'Pending Approval', id: 5 },
    { text: 'Cancelled', id: 6 },
    { text: 'Approved', id: 7 },
    { text: 'Actioned', id: 8 },
    { text: 'Invoiced', id: 9 }
  ];

  variationTypes = [
    { text: 'Sales', id: 1 },
    { text: 'Pre-Contract', id: 2 },
    { text: 'Post-Contract', id: 3 }
  ];

  exportToExcel = false;
  isSuperUser = false;

  variationStatusStore: DataSource;
  variationTypesStore: DataSource;
  selectAllModeVlaue: 'page';
  selectionModeValue: 'all';
  includeInActiveJobs = false;
  includeTestJobs = false;
  selectedIDs: number[] = [];
  selectedVariationTypes: number[] = [];
  selectedVariationIDs: SelectedIDs;
  salesVariationsActive: boolean;
  showIncludeTestJobsCheckBox = false;
  estimatingRead: boolean;
  officerId: number;
  currentJob: IJob;
  isActiveCampaignActive: boolean;
  isHubSpotActive: boolean;
  claimScheduleEnabled: boolean;
  includeZeroValueVOs: boolean;

  constructor(private _variationService: VariationService,
    private _jobService: JobService,
    private _userService: UserService,
    private _jobItemService: JobItemService,
    private _jobItemLinkService: JobItemLinkService,
    private _authService: AuthService,
    private _globalService: GlobalService,
    private notiService: NotificationService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.isSuperUser = this._authService.isSuperUser();

    this.permissionLevel = this._authService.getSelectionsPermissions('Jobs');
    // console.log('jobs perm=' + this.permissionLevel);
    if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Write'
      || this._authService.isAdminOrSuperUser()) {
      this.jobsWritePermission = true;
    }

    this.permissionLevel = this._authService.getSelectionsPermissions('JobLineSetup');
    // console.log('JobLineSetup perm=' + this.permissionLevel);
    if (this.permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.jobLineSetupAdmin = true;
    }

    this.permissionLevel = this._authService.getSelectionsPermissions('Estimating');
    if (this.permissionLevel === 'Read' || this.permissionLevel === 'Write' || this.permissionLevel === 'Admin' || this._authService.isAdminOrSuperUser()) {
      this.estimatingRead = true;
    }

    if (this._globalService.getCompanyConfigValue(ConfigurationEnum.ClaimSchedule) === 1) {
      this.claimScheduleEnabled = true;
    }

    this.getUsers();
    this.setLeadManagementPerms();

    // get config
    if (this._globalService.getCurrentCompany().salesVariationsActive) {
      this.salesVariationsActive = true;
    } else {
      this.selectedVariationTypes = [1];
    }

    this.variationStatusStore = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: this.variationStatus
      })
    });

    this.variationTypesStore = new DataSource({
      store: new ArrayStore({
        key: 'id',
        data: this.variationTypes
      })
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  outstandingVOsReport(content) {
    // create PDF report of outstanding VOs
    this.reportHeading = 'Variations Report';
    this.reportDescription = 'This report lists all variations for the selected status and type. Excludes inactive jobs.';
    this.askForJobNumber = false;
    this.okToRunReport = true;
    this.useAssignedTo = false;
    this.includeZeroValueVOs = true;

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.success = false;
      this.error = false;
      this.loading = true;

      this.selectedVariationIDs = { ids: this.selectedIDs, variationTypes: this.selectedVariationTypes };

      this.subscriptions = this.subscriptions.concat(
        this._variationService.getVariationsReport(this.selectedVariationIDs, this.includeTestJobs, this.includeZeroValueVOs)
          .subscribe({
            next: (variations) => {
              // open grid for result
              this.loading = false;
              const modalRef1 = this.modalService.open(VariationReportComponent, { windowClass: 'modal-est2' });
              modalRef1.componentInstance.variations = variations;
              modalRef1.result.then(() => { }, () => { });
            },
            error: (err) => {
              this.errorMessage = 'No variations outstanding.';
              this.notiService.notify(err);
              this.loading = false;
              this.error = true;
            }
          })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  itemsWaitingSelection(content) {
    // create PDF report of outstanding VOs
    this.reportHeading = 'Items Waiting Selection';
    this.reportDescription =
      'If the job number is left blank, this report lists all locked Jobs with items that are still waiting to be selected.';
    this.askForJobNumber = true;
    this.okToRunReport = true;
    this.canRunForAllJobs = true;
    this.useAssignedTo = true;
    this.showIncludeTestJobsCheckBox = true;
    this.selectUser = false;
    this.setupjobNumberForm();

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.success = false;
      this.error = false;
      this.loading = true;

      this.subscriptions = this.subscriptions.concat(
        this._jobItemService
          .getItemsNotSelectedReport(this.jobNumber, this.officerId, this.includeTestJobs)
          .subscribe({
            next: (pdfReport) => {
              this.pdfReport = pdfReport;
              this.runReport();
            },
            error: (err) => {
              this.errorMessage = 'No items waiting selection.';
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  contractSummaryReport(content) {
    this.reportHeading = 'Total Contract Summary';
    this.reportDescription = 'This report lists the variations for a specific job to arrive at a total contract summary.';
    this.askForJobNumber = true;
    this.selectUser = false;
    this.canRunForAllJobs = false;
    this.okToRunReport = false;
    this.useAssignedTo = false;
    this.showIncludeTestJobsCheckBox = false;

    this.setupjobNumberForm();

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.success = false;
      this.error = false;
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._variationService.getContractSummaryReport(this.jobNumberForm.value.printVONumbers, this.jobNumberForm.value.printFromContract)
          .subscribe({
            next: (pdfReport) => {
              this.pdfReport = pdfReport;
              this.runReport();
            },
            error: (err) => {
              this.errorMessage = 'Error reading report';
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  jobShowHideSetupReport(content) {
    this.reportHeading = 'Job Setup for Show/Hide rules';
    this.reportDescription = 'This report lists the rules set up for this job that show/hide specific items.';
    this.askForJobNumber = true;
    this.selectUser = false;
    this.canRunForAllJobs = false;
    if (this._globalService.getCurrentJob() !== '') {
      this.okToRunReport = true;
    } else {
      this.okToRunReport = false;
    }
    this.useAssignedTo = false;
    this.showIncludeTestJobsCheckBox = false;

    this.setupjobNumberForm();

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.success = false;
      this.error = false;
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobItemLinkService.getJobItemLinksReport()
          .subscribe({
            next: (pdfReport) => {
              this.pdfReport = pdfReport;
              this.runReport();
            },
            error: (err) => {
              this.errorMessage = 'Error reading report';
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  outstandingPSReport(content) {
    // create PDF report of outstanding VOs
    this.reportHeading = 'Outstanding Provisional Sums';
    this.reportDescription =
      'This report lists all provisional sum items waiting to be fixed. If no job number is specified all locked jobs will be reported on.';
    this.askForJobNumber = true;
    this.selectUser = false;
    this.okToRunReport = true;
    this.canRunForAllJobs = true;
    this.setupjobNumberForm();
    this.useAssignedTo = true;
    this.showIncludeTestJobsCheckBox = true;

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.success = false;
      this.error = false;
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.getOutstandingPSReport(this.jobNumber, this.includeTestJobs, this.officerId)
          .subscribe({
            next: (pdfReport) => {
              this.pdfReport = pdfReport;
              this.runReport();
            },
            error: (err) => {
              this.errorMessage = 'No PS items waiting to be fixed.';
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  jobItemsChanged(content) {
    // create PDF report of Job Items Changed
    this.reportHeading = 'Job Items Changed';
    this.reportDescription =
      'This report lists all master job items that have been changed.';
    this.askForJobNumber = true;
    this.okToRunReport = false;
    this.canRunForAllJobs = false;
    this.setupjobNumberForm();
    this.useAssignedTo = false;
    this.selectUser = true;
    this.showIncludeTestJobsCheckBox = false;

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.success = false;
      this.error = false;
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobItemService.getjobItemsChangedReport(this.jobNumber, this.officerId)
          .subscribe({
            next: (pdfReport) => {
              this.pdfReport = pdfReport;
              this.runReport();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  findItem() {
    // find an item across jobs by option id or string
    const modalRef = this.modalService.open(SelectOptionModalComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(() => {
    }, () => {
    });
  }

  jobRolesReport(content) {
    // report of jobs showing job roles.
    this.reportHeading = 'Job List with Roles';
    this.reportDescription =
      'This report is an Excel report of all jobs having users assigned to job roles - multiple rows will be shown if multiple roles are assigned';
    this.askForJobNumber = false;
    this.okToRunReport = true;
    this.canRunForAllJobs = true;
    this.useAssignedTo = false;
    this.showIncludeTestJobsCheckBox = true;

    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;

      this.success = false;
      this.error = false;
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._jobService.getJobRolesReport(!this.includeInActiveJobs, this.includeTestJobs)
          .subscribe({
            next: (pdfReport) => {
              this.pdfReport = pdfReport;
              this.exportToExcel = true;
              this.runReport();
              this.exportToExcel = false; // reset
            },
            error: (err) => {
              this.errorMessage = 'No jobs found.';
              this.notiService.notify(err);
              this.loading = false;
            }
          })
      );
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  setupjobNumberForm() {
    this.jobNumberForm = new UntypedFormGroup({
      printVONumbers: new UntypedFormControl(true),
      printFromContract: new UntypedFormControl(true)
    });
    this.officerId = null;
    this.refreshJobNumber(this._globalService.getCurrentJob());
  }

  refreshJobNumber(jobNumber: string) {
    this.jobNumber = jobNumber;

    if (this.jobNumber && this.jobNumber !== '') {
      this.okToRunReport = true;
    } else if (this.canRunForAllJobs) {
      this.okToRunReport = true;
    } else {
      this.okToRunReport = false;
    }

    this.currentJob = this._jobService.currentJob;
  }

  runReport() {
    if (!this.pdfReport.pdfReport) {
      // No variations found for given job
      this.errorMessage = 'No data to display';
      this.success = false;
      this.error = true;
      this.loading = false;
    } else {
      this.errorMessage = '';
      this.success = true;
      this.error = false;
      this.loading = false;

      const modalRef1 = this.modalService.open(ShowPdfComponent, { windowClass: 'modal-pdf' });
      modalRef1.componentInstance.pdfReport = this.pdfReport;
      modalRef1.componentInstance.exportToExcel = this.exportToExcel;

      modalRef1.result.then(() => {
        this.closeResult = 'Closed';
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getUsers() {
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJobsByAddressWithExtras(true)
        .subscribe({
          next: () => {
            this.filterUsers();
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  filterUsers() {
    this._userService.users.forEach(user => {
      if ((user.isActive) && (user.userTypeId === this.userTypeEnum.Admin || user.userTypeId === this.userTypeEnum.Office)) {
        this.users = this.users.concat(user);
      }
    });
  }

  setToMe() {
    this.officerId = this._authService.getCurrentUserId();
  }

  jobMarginReport() {
    // here we use the modal to check all items have a cost so margins work
    const modalRef = this.modalService.open(JobMarginComponent,
      { windowClass: 'modal-est', scrollable: true });

    modalRef.result.then(() => {
    }, () => {
    });
  }

  setLeadManagementPerms() {
    // setting 1 for normal and 2 for admins only - for testing, 3 = HubSpot
    this.isActiveCampaignActive = false;
    this.isHubSpotActive = false;
    const config = this._globalService.getCompanyConfigValue(ConfigurationEnum.ActiveCampaignOrHubSpotIntegrationEnabled);
    if (config && config < 4) {
      if (this._authService.isAdminOrSuperUser()) {
        if (config === 3) {
          this.isHubSpotActive = true;
        }
        else {
          this.isActiveCampaignActive = true;
        }
      } else {
        // users need jobs write and job cannot be marked sold
        const permissionLevel = this._authService.getSelectionsPermissions('Jobs');
        if (permissionLevel === 'Admin' || permissionLevel === 'Write') {
          if (config === 3) {
            this.isHubSpotActive = true;
          }
          else {
            this.isActiveCampaignActive = true;
          }
        }
      }
    }
  }

  jobsIntegratedToActiveCampaign() {
    const modalRef = this.modalService.open(ReportActiveCampaignComponent,
      { windowClass: 'modal-edit', scrollable: true });

    modalRef.result.then(() => {
    }, () => {
    });
  }

  jobsIntegratedToHubSpot() {
    const modalRef = this.modalService.open(ReportHubSpotComponent,
      { windowClass: 'modal-edit', scrollable: true });

    modalRef.result.then(() => {
    }, () => {
    });
  }

  loanShortfall() {
    const modalRef = this.modalService.open(LoanShortfallComponent,
      { windowClass: 'modal-splits', scrollable: true });

    modalRef.result.then(() => {
    }, () => {
    });
  }

  jobDocumentsChanged() {
    const modalRef = this.modalService.open(JobDocumentsChangedComponent,
      { windowClass: 'modal-edit', scrollable: true });

    modalRef.result.then(() => {
    }, () => {
    });
  }
}
