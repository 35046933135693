import { JobItemService } from './../services/felixApi/job-item.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { JobService } from '../services/felixApi/job.service';
import { GlobalService } from '../services/global.service';
import { VariationService } from '../services/felixApi/variation.service';
import { IJob } from '../dtos/job';
import { Variation } from '../dtos/variation';
import { VariationStatusEnum } from '../dtos/variation-status.enum';
import { VariationTypeEnum } from '../dtos/variation-type.enum';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { EmailService } from '../services/felixApi/email.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';
import { CompanyService } from '../services/felixApi/company.service';
import { CompanyActivity } from '../dtos/company-activity';
import { UserService } from '../services/felixApi/user.service';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { RoleTypeEnum } from '../dtos/role-type.enum';
import { CompanyRoleUser } from '../dtos/companyRoleUser';
import { AdminOverrideModalComponent } from './admin-override-modal/admin-override-modal.component';
import { CreateTaskModalComponent } from './create-task-modal/create-task-modal.component';
import { VariationSplitComponent } from './variation-split/variation-split.component';

@Component({
  templateUrl: './variation-detail.component.html',
  styleUrls: ['./variation-detail.component.scss']
})
export class VariationDetailComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'Variation-Detail';
  subscriptions: Subscription[] = [];
  variationNum: number;
  currentJobNum: string;
  pageTitle: string;
  job: IJob = new IJob();
  variations: Variation[];
  jobString = '';
  varnForm: UntypedFormGroup;
  deleteForm: UntypedFormGroup;
  updaterecord: any;
  updateSuccessful: boolean;
  updateRun = false;
  tmpDueDate = '';
  variationStatus = VariationStatusEnum;
  userTypeEnum = UserTypeEnum;
  roleTypeEnum = RoleTypeEnum;
  tmpStatus = '';
  selectedStatus = '';
  todaysDate = new Date();
  variationType = 0;
  variationTypeEnum = VariationTypeEnum;
  permissionLevel = '';
  permissionWrite = false;
  loading = true;
  configurationEnum = ConfigurationEnum;
  customerUpdateAllowed = false;
  isConstructionActivitiesUsed: boolean;
  activities: CompanyActivity[];
  compActId: number;
  assignedUserId: any;
  estimators: CompanyRoleUser[];
  estimatorRequired: boolean;
  postContractPendingApprovalDueDateBlankWarning: boolean;
  isTrackingActive = false;
  variationSplitsEnabled: boolean;
  variationTypes: { id: number; description: string; }[] = [
    { id: VariationTypeEnum.PreConstruction, description: 'PreConstruction' },
    { id: VariationTypeEnum.Construction, description: 'Construction' },
    { id: VariationTypeEnum.Office, description: 'Office/No Charge' },
    { id: VariationTypeEnum.FinalAccount, description: 'Final Account' },
    { id: VariationTypeEnum.PreContractVariation, description: 'Pre-Contract Variation' },
    { id: VariationTypeEnum.PreContractProvisionalSum, description: 'Siteworks & Preliminaries' },
    { id: VariationTypeEnum.PreContractOffice, description: 'Office/Discount' },
    { id: VariationTypeEnum.PreContractExFromContract, description: 'Excluded from Contract' },
    { id: VariationTypeEnum.SalesVariation, description: 'Sales Variation' },
    { id: VariationTypeEnum.SalesProvisionalSum, description: 'Siteworks & Preliminaries' },
    { id: VariationTypeEnum.SalesOffice, description: 'Office/Discount' },
  ];
  variationTypesFiltered: { id: number; description: string; }[];
  variationTypeId: number;
  deleteLines = false;
  isAdmin: boolean;

  constructor(
    private _jobService: JobService,
    private jobItemService: JobItemService,
    private _userService: UserService,
    private _variationService: VariationService,
    private _globalService: GlobalService,
    private _authService: AuthService,
    private router: Router,
    private _fb: UntypedFormBuilder,
    private notiService: NotificationService,
    private _emailService: EmailService,
    private _companyService: CompanyService,
    private modalService: NgbModal) {
    this.onTypeValueChanged = this.onTypeValueChanged.bind(this);
  }

  ngOnInit() {
    this.isAdmin = this._authService.isAdminOrSuperUser();

    this.variationNum = this._jobService.currentVariationId;
    this._jobService.currentVariationId = null;
    // this.variationNum = +this._route.snapshot.paramMap.get('id');

    if (this.variationNum === 0) {
      this.pageTitle = 'Add variation to job';
    } else {
      this.pageTitle = 'Edit variation for job';
    }

    // get config
    if (this._globalService.getCompanyConfigValue(this.configurationEnum.CustomerUpdateAllowed) === 1) {
      this.customerUpdateAllowed = true;
    }
    if (this._globalService.getCompanyConfigValue(this.configurationEnum.EstimatorRequired) === 1) {
      this.estimatorRequired = true;
    }
    if (this._globalService.getCompanyConfigValue(this.configurationEnum.PostContractPendingApprovalDueDateBlankWarning) === 1) {
      this.postContractPendingApprovalDueDateBlankWarning = true;
    }
    if (this._globalService.getCompanyConfigValue(this.configurationEnum.TrackingSystemActive) === 1) {
      this.isTrackingActive = true;

      // check we have a task for post contract variations
      this.checkTaskForPostContractVariation();
    }
    if (this._globalService.getCompanyConfigValue(this.configurationEnum.ClaimSchedule) === 1) {
      if (this._globalService.getCompanyConfigValue(this.configurationEnum.TrackingSystemActive) === 1) {
        this.variationSplitsEnabled = true;
      }
    }

    // set up form for delete modal
    this.deleteForm = this._fb.group({
      varnTitle: ''
    });

    // set up form
    this.varnForm = this._fb.group({
      varnTitle: '',
      extraDescription: '',
      variationDate: this.dateOnly(null),
      dueDate: this.dateOnly(null),
      adminFee: 0,
      status: '',
      statusComment: '',
      canBeModifiedByClient: false,
      author: '',
      additionalDays: 0,
      companyActivityId: 0,
      assignedUserId: 0
    });

    // re-read job information
    this.getJob();
    this.getUsers();
  }

  checkTaskForPostContractVariation() {
    this.subscriptions = this.subscriptions.concat(
      this._variationService.getVariationTasks()
        .subscribe({
          next: () => {
          },
          error: () => {
            // may nave no permission
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getJob() {
    this.currentJobNum = this._globalService.getCurrentJob();
    this.subscriptions = this.subscriptions.concat(
      this._jobService.getJob(this.currentJobNum)
        .subscribe({
          next: (job) => {
            this.job = job;
            this._jobService.currentJob = job;
            this.jobString = this._globalService.getJobString(this.job, true);
            if (this.job) {
              this.getConstructionActivities();
            } else {
              this.loading = false;
            }
          },
          error: () => {
            this.loading = false;
          }
        })
    );
  }

  getConstructionActivities() {
    // get config
    this.isConstructionActivitiesUsed = false;
    if (this.job.isLocked && this._globalService.getCompanyConfigValue(this.configurationEnum.ConstructionActivitiesUsed) === 1) {
      this.isConstructionActivitiesUsed = true;

      // get the list
      this.subscriptions = this.subscriptions.concat(
        this._companyService.getCompanyActivities()
          .subscribe({
            next: (activities) => {
              this.activities = activities;
              this.setupVariationform();
            },
            error: (err) => {
              this.notiService.notify(err);
              this.setupVariationform();
            }
          })
      );
    } else {
      this.setupVariationform();
    }
  }

  getUsers() {
    this.subscriptions = this.subscriptions.concat(
      this._userService.getCompanyRoleUsersByRoleID(this.roleTypeEnum.SalesEstimator)
        .subscribe({
          next: (estimators) => {
            this.estimators = estimators.filter(i => i.user.isActive);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
    );
  }

  calcPermissionNew() {
    this.permissionLevel = ''; // reset

    if (this._authService.isAdminOrSuperUser()) {
      this.permissionWrite = true;
      this.permissionLevel = 'Admin';
    } else if (!this._jobService.currentJob.salesDate) {
      this.permissionLevel = this._authService.getSelectionsPermissions('SalesVariations');
      if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Write') {
        this.permissionWrite = true;
      }
    } else if (!this._jobService.currentJob.isLocked) {
      this.permissionLevel = this._authService.getSelectionsPermissions('PreContractVariations');
      if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Write') {
        this.permissionWrite = true;
      }
    } else if (this._jobService.currentJob.isLocked) {
      this.permissionLevel = this._authService.getSelectionsPermissions('Variations');
      if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Write') {
        this.permissionWrite = true;
      }
    }
  }

  calcPermissionEdit() {
    this.permissionLevel = ''; // reset

    if (this._authService.isAdminOrSuperUser()) {
      this.permissionWrite = true;
      this.permissionLevel = 'Admin';
    } else {
      if (this.variations[0].variationType > 10 && this.variations[0].variationType < 20) {
        if (!this._jobService.currentJob.salesDate || this.variations[0].statusId < VariationStatusEnum.Cancelled) {
          this.permissionLevel = this._authService.getSelectionsPermissions('SalesVariations');
          if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Write') {
            this.permissionWrite = true;
          }
        }
      } else if (this.variations[0].variationType > 20) {
        if (!this._jobService.currentJob.isLocked) {
          this.permissionLevel = this._authService.getSelectionsPermissions('PreContractVariations');
          if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Write') {
            this.permissionWrite = true;
          }
        }
      } else {
        if (this._jobService.currentJob.isLocked) {
          this.permissionLevel = this._authService.getSelectionsPermissions('Variations');
          if (this.permissionLevel === 'Admin' || this.permissionLevel === 'Write') {
            this.permissionWrite = true;
          }
        }
      }
    }
  }

  setupVariationform() {
    if (this.variationNum === 0) {
      // adding a variation

      // set todays date as default VO date
      this.varnForm.patchValue({
        variationDate: JSON.stringify(this.todaysDate).substr(1, 10),
        author: this._authService.getCurrentUser().fullName,
        additionalDays: 0
      });

      // check if we already have construction variations and default the tick box
      if (this.job.isLocked) {
        this.checkForConstructionVariations();
        this.varnForm.patchValue({
          variationDate: JSON.stringify(this.todaysDate).substr(1, 10),
          companyActivityId: 0 // this.activities?.find(i => i.isDefaultForVariations)?.id - NOTE this is set when the VO is approved by the backend
        });

        this.variationTypesFiltered = this.variationTypes.filter(i => i.id < 10);
      } else if (this.job.salesDate) {
        this.variationTypesFiltered = this.variationTypes.filter(i => i.id > 20);
      } else {
        this.variationTypesFiltered = this.variationTypes.filter(i => i.id < 20 && i.id > 10);
      }
      this.variationTypeId = null;
      this.calcPermissionNew();
      this.loading = false;

    } else {
      // editing a variation so get variation
      this.variationTypesFiltered = [];
      this.subscriptions = this.subscriptions.concat(
        this._variationService.getVariation(this.variationNum).subscribe({
          next: (variation) => {
            this.variations = variation;
            this.pageTitle = 'Edit variation ' + this.variations[0].displayedVariationNumber + ' for job';
            if (this.variations[0].dueDate) {
              this.tmpDueDate = JSON.stringify(this.variations[0].dueDate).substr(1, 10);
            } else {
              this.tmpDueDate = '';
            }

            if (this.variations[0].statusId === this.variationStatus.Open) {
              this.tmpStatus = 'Open';
            } else if (this.variations[0].statusId === this.variationStatus.Estimating) {
              this.tmpStatus = 'Estimating';
            } else if (this.variations[0].statusId === this.variationStatus.PendingApproval) {
              this.tmpStatus = 'Pending Approval';
            } else if (this.variations[0].statusId === this.variationStatus.Cancelled) {
              this.tmpStatus = 'Cancelled';
            } else if (this.variations[0].statusId === this.variationStatus.Approved) {
              this.tmpStatus = 'Approved';
            } else if (this.variations[0].statusId === this.variationStatus.Actioned) {
              this.tmpStatus = 'Actioned';
            } else if (this.variations[0].statusId === this.variationStatus.Invoiced) {
              this.tmpStatus = 'Invoiced';
            }
            // this.selectedStatus can be changed by a dropdown and need to check it later
            this.selectedStatus = this.tmpStatus;

            this.varnForm.setValue({
              varnTitle: this.variations[0].title,
              extraDescription: this.variations[0].extraDescription,
              variationDate: this.dateOnly(this.variations[0].variationDate),
              dueDate: this.dateOnly(this.variations[0].dueDate),
              adminFee: this.variations[0].adminFee,
              status: this.tmpStatus,
              statusComment: this.variations[0].statusComment,
              canBeModifiedByClient: this.variations[0].canBeModifiedByClient,
              author: this.variations[0].author,
              additionalDays: this.variations[0].additionalDays,
              companyActivityId: this.variations[0].companyActivityId,
              assignedUserId: this.variations[0].assignedUserId
            });
            this.calcPermissionEdit();

            this.variationTypeId = this.variations[0].variationType;
            if (this.variations[0].variationType < 10) {
              this.variationTypesFiltered = this.variationTypes.filter(i => i.id < 10);
            } else if (this.variations[0].variationType > 20) {
              this.variationTypesFiltered = this.variationTypes.filter(i => i.id > 20);
            } else {
              this.variationTypesFiltered = this.variationTypes.filter(i => i.id < 20 && i.id > 10);
            }
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        })
      );
    }
  }

  // if we have existing construciton variations we default to that
  checkForConstructionVariations() {
    if (!this.isTrackingActive) {
      this.variationType = this.variationTypeEnum.PreConstruction;
    }
    this._variationService.currentVariations.forEach(element => {
      if (element.variationType === this.variationTypeEnum.Construction) {
        this.variationType = this.variationTypeEnum.Construction;
        // this.setDefaultConstructionAdminFee();
      }
    });
  }

  save(gotoVO: boolean = false) {
    if (!this.variationTypeId) {
      this.notiService.showWarning('Please select a variation type');
    } else if (this.isTrackingActive && this.variationNum === 0
      && ((this._variationService.taskForPostContractVariation && this.variationTypeId < 10)
        || (this._variationService.taskForSalesVariation && this.variationTypeId > 10 && this.variationTypeId < 20)
        || (this._variationService.taskForPreContractVariation && this.variationTypeId > 20)
        || this.weHaveTaskToCreate())) {
      if (this._globalService.getCompanyConfigValue(this.configurationEnum.DoNotAskToCreateTaskForVariation)) {
        this.saveNext(gotoVO, true);
      } else {
        // ask if we need to create a task
        const modalRef = this.modalService.open(CreateTaskModalComponent, { backdrop: 'static' });
        modalRef.result.then(() => {
          this.saveNext(gotoVO, true);
        }, () => {
          this.saveNext(gotoVO, false);
        });
      }
    } else {
      this.saveNext(gotoVO, false);
    }
  }

  weHaveTaskToCreate() {
    const variationWorkFlows = this._variationService.variationWorkFlows
      .filter(i => i.variationTypeId === this.variationTypeId
        && (!i.divisionId || i.divisionId === this.job.divisionId)
        && i.variationStatusId === this.variationStatus.Open
        && i.askUserToCreate);

    if (variationWorkFlows.length > 0) {
      return true;
    }
    return false;
  }

  saveNext(gotoVO: boolean, createTask: boolean) {
    // check if we are allowed to modify or if admin
    if (this.variationNum !== 0 && (this._authService.isAdminOrSuperUser())) {

      // check configs for allowing sold date to be entered before variations are marked approved
      let canMarkSoldWhenVariationsPending = false;
      if (this._globalService.getCompanyConfigValue(this.configurationEnum.CanMarkSoldWhenVariationsPending) === 1) {
        canMarkSoldWhenVariationsPending = true;
      }

      if ((this.job.isLocked && this.variations[0].variationType > 20)
        || (this.job.salesDate && this.variations[0].variationType > 10 && this.variations[0].variationType < 20)) {

        // marking approved does not need the warning in this case
        if (this.job.salesDate && this.variations[0].variationType > 10 && this.variations[0].variationType < 20
          && canMarkSoldWhenVariationsPending && this.varnForm.get('status').value === 'Approved') {
          this.saveGo(true, gotoVO, createTask);
        } else {
          const modalRef = this.modalService.open(AdminOverrideModalComponent,
            { windowClass: 'modal-edit2', backdrop: 'static' });
          modalRef.componentInstance.variationType = this.variations[0].variationType;
          modalRef.result.then(() => {
            this.saveGo(true, gotoVO, createTask);
          });
        }
      } else {
        this.saveGo(false, gotoVO, createTask);
      }
    } else {
      this.saveGo(false, gotoVO, createTask);
    }
  }

  saveGo(adminOverride: boolean, gotoVO: boolean, createTask: boolean) {
    this.loading = true;

    // if office we can't allocate to a client
    if (this.varnForm.get('canBeModifiedByClient').value
      && (this.variationTypeId === VariationTypeEnum.Office
        || this.variationTypeId === VariationTypeEnum.PreContractExFromContract)) {
      this.notiService.showInfo('Cannot allocate Office variations to a client. Set to false');
      this.varnForm.patchValue({
        canBeModifiedByClient: false
      });
    }

    if (this.variationNum === 0) {
      // add  -----------------------------------
      this.compActId = this.varnForm.get('companyActivityId').value === '0'
        || this.varnForm.get('companyActivityId').value === 0 ? null : this.varnForm.get('companyActivityId').value;

      this.assignedUserId = this.varnForm.get('assignedUserId').value === '0'
        || this.varnForm.get('assignedUserId').value === 0 ? null : this.varnForm.get('assignedUserId').value;

      this.updaterecord = {
        title: this.varnForm.get('varnTitle').value,
        variationType: this.variationTypeId,
        extraDescription: this.varnForm.get('extraDescription').value,
        canBeModifiedByClient: this.varnForm.get('canBeModifiedByClient').value,
        author: this.varnForm.get('author').value,
        additionalDays: this.varnForm.get('additionalDays').value,
        companyActivityId: this.compActId,
        adminFee: this.varnForm.get('adminFee').value,
        assignedUserId: this.assignedUserId,
        createTask: createTask,
        statusComment: this.varnForm.get('statusComment').value
      };

      if (this.varnForm.get('variationDate').value !== '') {
        this.updaterecord.variationDate = this.varnForm.get('variationDate').value;
      }
      if (this.varnForm.get('dueDate').value && this.varnForm.get('dueDate').value !== '') {
        this.updaterecord.dueDate = this.varnForm.get('dueDate').value;
      }

      this.subscriptions = this.subscriptions.concat(
        this._variationService.addVariation(this.updaterecord).subscribe({
          next: (res) => {
            this.updateSuccessful = true;
            this.updateRun = true;
            if (gotoVO) {
              this.variationNum = res.variationNumber;
              this.gotoSelections();
            } else {
              this.back();
            }
          },
          error: (err) => {
            this.notiService.notify(err, '', true);
            this.updateSuccessful = false;
            this.updateRun = true;
            this.loading = false;
          }
        })
      );

    } else {
      // edit a variation so save it.
      this.updaterecord = { id: this.variations[0].id };
      if (this.varnForm.get('varnTitle').value !== this.variations[0].title) {
        this.updaterecord.title = this.varnForm.get('varnTitle').value;
      }

      if (this.varnForm.get('variationDate').value !== this.variations[0].variationDate) {
        this.updaterecord.variationDate = this.varnForm.get('variationDate').value;
      }
      if (this.varnForm.get('extraDescription').value !== this.variations[0].extraDescription) {
        this.updaterecord.extraDescription = this.varnForm.get('extraDescription').value;
      }
      if (this.varnForm.get('dueDate').value !== this.variations[0].dueDate) {
        this.updaterecord.dueDate = this.varnForm.get('dueDate').value;
      }
      if (this.variations[0].dueDate && (!this.varnForm.get('dueDate').value || this.varnForm.get('dueDate').value === '')) {
        this.updaterecord.dueDate = null;
      }

      // if we have an invalid date
      if (this.varnForm.get('dueDate').value === 'ull') {
        this.updaterecord.dueDate = null;
      }

      if (this.varnForm.get('adminFee').value !== this.variations[0].adminFee) {
        this.updaterecord.adminFee = this.varnForm.get('adminFee').value;
      }
      if (this.variationTypeId !== this.variations[0].variationType) {
        this.updaterecord.variationType = this.variationTypeId;
      }

      if (this.varnForm.get('status').value !== this.tmpStatus) {
        if (this.varnForm.get('status').value === 'Open') {
          this.updaterecord.statusId = this.variationStatus.Open;
        } else if (this.varnForm.get('status').value === 'Estimating') {
          this.updaterecord.statusId = this.variationStatus.Estimating;
        } else if (this.varnForm.get('status').value === 'Pending Approval') {
          this.updaterecord.statusId = this.variationStatus.PendingApproval;
        } else if (this.varnForm.get('status').value === 'Cancelled') {
          this.updaterecord.statusId = this.variationStatus.Cancelled;
        } else if (this.varnForm.get('status').value === 'Approved') {
          this.updaterecord.statusId = this.variationStatus.Approved;
        } else if (this.varnForm.get('status').value === 'Actioned') {
          this.updaterecord.statusId = this.variationStatus.Actioned;
        } else if (this.varnForm.get('status').value === 'Invoiced') {
          this.updaterecord.statusId = this.variationStatus.Invoiced;
        }
      }
      if (this.varnForm.get('statusComment').value !== this.variations[0].statusComment) {
        this.updaterecord.statusComment = this.varnForm.get('statusComment').value;
      }
      if (this.varnForm.get('canBeModifiedByClient').value !== this.variations[0].canBeModifiedByClient) {
        this.updaterecord.canBeModifiedByClient = this.varnForm.get('canBeModifiedByClient').value;
      }
      if (this.varnForm.get('author').value !== this.variations[0].author) {
        this.updaterecord.author = this.varnForm.get('author').value;
      }
      if (this.varnForm.get('additionalDays').value !== this.variations[0].additionalDays) {
        this.updaterecord.additionalDays = this.varnForm.get('additionalDays').value;
      }

      this.compActId = this.varnForm.get('companyActivityId').value === '0'
        || this.varnForm.get('companyActivityId').value === 0 ? null : this.varnForm.get('companyActivityId').value;

      if (this.compActId !== this.variations[0].companyActivityId) {
        this.updaterecord.companyActivityId = this.compActId;
      }

      this.assignedUserId = this.varnForm.get('assignedUserId').value === '0'
        || this.varnForm.get('assignedUserId').value === 0 ? null : this.varnForm.get('assignedUserId').value;

      if (this.assignedUserId !== this.variations[0].assignedUserId) {
        this.updaterecord.assignedUserId = this.assignedUserId;
      }

      // console.log(JSON.stringify(this.updaterecord));

      this.subscriptions = this.subscriptions.concat(
        this._variationService.updateVariation(this.updaterecord, adminOverride).subscribe({
          next: () => {
            // now call email alerting
            if (this.varnForm.get('status').value !== this.tmpStatus) {
              this.sendEmailNotification(this.variations[0].id);
            } else {
              this.updateSuccessful = true;
              this.updateRun = true;
              this.back();
            }
          },
          error: (err) => {
            this.notiService.notify(err, '', true);
            this.updateSuccessful = false;
            this.updateRun = true;
            this.loading = false;
          }
        })
      );
    }
  }

  setStatus(statusString) {
    if (this.variationTypeId === VariationTypeEnum.Construction
      && !this.varnForm.get('additionalDays').value
      && statusString === 'Pending Approval') {
      this.notiService.showWarning('Note: Additional days have not been specified.');
    }
    if (this.estimatorRequired && statusString === 'Pending Approval') {
      if (!this.varnForm.get('assignedUserId').value) {
        this.notiService.showWarning('Note: Sales Estimator has not been specified.');
      }
    }
    if (this.postContractPendingApprovalDueDateBlankWarning && statusString === 'Pending Approval') {
      // not 'ull' is a check for an invalid date
      if (!this.varnForm.get('dueDate').value
        || this.varnForm.get('dueDate').value === 'ull' || this.varnForm.get('dueDate').value === '') {
        this.notiService.showWarning('Note: Due Date not specified.');
      }
    }
    this.selectedStatus = statusString;
    this.varnForm.patchValue({
      status: statusString
    });
  }

  sendEmailNotification(variationId) {
    this.subscriptions = this.subscriptions.concat(
      this._emailService.sendVariationStatusEmail(variationId).subscribe({
        next: () => {
          this.updateSuccessful = true;
          this.updateRun = true;
          this.back();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.updateSuccessful = false;
          this.updateRun = true;
          this.loading = false;
        }
      })
    );
  }

  back() {
    this.router.navigate(['variations/']);
  }

  delete(content) {
    this.deleteForm.setValue({
      varnTitle: this.variations[0].title
    });

    this.modalService.open(content).result.then(() => {
      this.loading = true;
      this.subscriptions = this.subscriptions.concat(
        this._variationService.deleteVariation(this.variations[0].id, this.deleteLines).subscribe({
          next: () => {
            this.updateSuccessful = true;
            this.jobItemService.currentVariation = null;
            this.jobItemService.currentVariationNumber = null;
            this.updateRun = true;
            this.back();
          },
          error: (err) => {
            this.notiService.notify(err, '', true);
            this.updateSuccessful = false;
            this.updateRun = true;
            this.loading = false;
          }
        })
      );
    });
  }

  setPriceZero() {
    this.varnForm.patchValue({
      adminFee: 0
    });
  }

  // setDefaultConstructionAdminFee() {
  //   this.varnForm.patchValue({
  //     adminFee: this._globalService.getCompanyConfigValue(this.configurationEnum.DefaultConstructionAdminFee)
  //   });
  // }

  onTypeValueChanged(e) {
    if (e.value === this.variationTypeEnum.Construction) {
      const defaultAdminFee = this._globalService.getCompanyConfigValue(this.configurationEnum.DefaultConstructionAdminFee);
      if (this.varnForm.get('adminFee').value !== defaultAdminFee) {
        this.varnForm.patchValue({
          adminFee: defaultAdminFee
        });
        this.notiService.showInfo('Admin fee adjusted');
      }
    } else {
      if (this.varnForm.get('adminFee').value !== 0) {
        this.varnForm.patchValue({
          adminFee: 0
        });
        this.notiService.showInfo('Admin fee reset');
      }
    }
  }

  gotoSelections() {
    // goto selections for this VO
    this._jobService.currentVariationId = this.variationNum;
    this.router.navigate(['selections']);
  }

  invoiceSplits() {
    const modalRef = this.modalService.open(VariationSplitComponent, { windowClass: 'modal-splits', backdrop: 'static' });
    modalRef.componentInstance.variationId = this.variations[0].id;
    modalRef.componentInstance.variationTotal = this.variations[0].variationTotal;
    modalRef.result.then(() => {
    }, () => {
    });
  }

  dateOnly(date: Date): Date {
    if (date) {
      date = new Date(date);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }
    else {
      var newDate = null;
      return newDate;
    }
  }
}
