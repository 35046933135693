import { JobService } from './../../services/felixApi/job.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobDocumentRecordTypeEnum } from '../../dtos/job-document-record-type.enum';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { JobDocumentService } from '../../services/felixApi/job-document.service';
import { IJobDocument } from '../../dtos/job-document';
import { JobDocumentAttachmentService } from '../../services/felixApi/job-document-attachment.service';

@Component({
  selector: 'js-save-doc-copy',
  templateUrl: './save-doc-copy.component.html',
  styleUrls: ['./save-doc-copy.component.css']
})
export class SaveDocCopyComponent implements OnInit, OnDestroy {
  @Input() saveDescription: string;
  @Input() fileBlob: Blob;

  subscriptions: Subscription[] = [];
  loadingDocs: boolean = false;
  popupWidth: number;
  popupHeight: number;
  selectedFolder: string[] = [];
  jobDocuments: IJobDocument[];
  saveFileName: string;
  isPDF: boolean = false;

  constructor(
    private globalService: GlobalService,
    private jobService: JobService,
    private jobDocumentService: JobDocumentService,
    private activeModal: NgbActiveModal,
    private notiService: NotificationService,
    private jobDocumentAttachmentService: JobDocumentAttachmentService
  ) { }

  ngOnInit() {
    this.popupHeight = this.globalService.innerHeight < 575 ? this.globalService.innerHeight : 575;
    this.popupWidth = this.globalService.innerWidth < 650 ? this.globalService.innerWidth : 650;
    if (this.saveDescription.endsWith('.pdf') || this.saveDescription.endsWith('.PDF')) {
      this.saveFileName = this.saveDescription.substring(0, this.saveDescription.length - 4);
      this.isPDF = true;
    } else {
      this.saveFileName = this.saveDescription;
    }

    this.loadDocumentFolders();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  loadDocumentFolders() {
    this.loadingDocs = true;
    this.subscriptions = this.subscriptions.concat(
      this.jobDocumentService.getJobDocumentForEmail(this.jobService.currentJob.id, false)
        .subscribe({
          next: (res) => {
            this.jobDocuments = res.filter(x => x.recordTypeId === JobDocumentRecordTypeEnum.Heading);
            this.loadingDocs = false;
          },
          error: (err) => {
            this.notiService.notify(err);
            this.jobDocuments = [];
            this.loadingDocs = false;
          }
        })
    );
  }

  save() {
    this.loadingDocs = true;
    this.loadFile();
  }

  onToolbarPreparing(e, template: string) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: template
    });
  }

  loadFile() {
    const formData: FormData = new FormData();
    formData.append('image', this.fileBlob, this.saveFileName + (this.isPDF ? '.pdf' : ''));

    this.subscriptions.push(
      this.jobDocumentAttachmentService.uploadDocument(this.selectedFolder[0], formData, this.jobService.currentJob.jobNumber)
        .subscribe({
          next: () => {
            this.activeModal.close();
          }, error: (err) => {
            this.notiService.notify(err);
            this.loadingDocs = false;
          }
        })
    );
  }

  close() {
    this.activeModal.dismiss();
  }
}
