import { CompanyService } from './../../services/felixApi/company.service';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { JobService } from '../../services/felixApi/job.service';
import { GlobalService } from '../../services/global.service';
import { NotificationService } from '../../services/notification.service';
import CustomStore from 'devextreme/data/custom_store';
import { IJob } from '../../dtos/job';
import { JobSearchModalComponent } from '../../jobs/job-search-modal.component';
import { JobSearchTypeEnum } from '../../dtos/job-search-type.enum';
import { VariationSplitsService } from '../../services/felixApi/variation-splits.service';
import { LoanShortfall } from '../../dtos/loan-shortfall';
import { ClaimsService } from '../../services/felixApi/claims.service';
import { VariationService } from '../../services/felixApi/variation.service';
import { JobBank } from '../../dtos/job-bank';
import { VariationSplitEnum } from '../../dtos/variation-split';
import { VariationStatusEnum } from '../../dtos/variation-status.enum';

@Component({
  selector: 'js-loan-shortfall',
  templateUrl: './loan-shortfall.component.html',
  styleUrls: ['./loan-shortfall.component.scss']
})
export class LoanShortfallComponent implements OnInit {

  subscriptions: Subscription[] = [];
  gridHeight: number;
  dataSource: CustomStore;
  jobs: IJob[];
  loading = true;
  jobNumber: string;
  jobId: number;
  invoiceRecords: LoanShortfall[] = [];
  jobBank: JobBank | null;
  loanShortfallAmount = 0;
  job: IJob;

  constructor(private _activeModal: NgbActiveModal,
    private companService: CompanyService,
    private globalService: GlobalService,
    private jobService: JobService,
    private claimsService: ClaimsService,
    private variationService: VariationService,
    private variationSplitsService: VariationSplitsService,
    private notiService: NotificationService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.subscriptions = this.subscriptions.concat(
      this.globalService.innerWidthChanged.subscribe(() => {
        setTimeout(() => {
          this.setHeight();
        }, 500); // wait for iPhone
      })
    );
    this.setHeight();

    this.jobNumber = this.globalService.getCurrentJob();
    if (this.jobNumber && this.jobNumber !== 'undefined') {
      this.getJob();
    } else {
      this.jobNumber = '';
    }
  }

  setHeight() {
    this.gridHeight = this.globalService.innerHeight - 400;
  }

  getJob() {
    this.loading = true;
    this.jobBank = null;
    this.job = null;
    this.variationService.currentVariations = [];
    this.variationSplitsService.variationSplitsForJob = [];
    this.claimsService.claimJobLines = [];
    this.invoiceRecords = [];

    this.subscriptions = this.subscriptions.concat(
      this.jobService.getJob(this.jobNumber)
        .subscribe({
          next: (job) => {
            if (job) {
              this.job = job;
              this.globalService.setCurrentJob(this.jobNumber);
              this.jobId = job.id;
              this.jobs = this.jobService.jobs;
              this.getJobBankDetails();
            } else {
              this.notiService.showInfo('Job not found');
              this.jobId = null;
              this.loading = false;
            }
          },
          error: () => {
            this.notiService.showInfo('Job not found');
            this.jobId = null;
            this.loading = false;
          }
        })
    );
  }

  getJobBankDetails() {
    this.jobBank = null;

    this.subscriptions = this.subscriptions.concat(
      this.claimsService.getJobBankDetails(this.jobId)
        .subscribe({
          next: (jobBank) => {
            this.jobBank = jobBank;
            this.getLoanShortfallData();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getLoanShortfallData() {
    this.loanShortfallAmount = -this.jobBank?.loanAmount;

    this.subscriptions = this.subscriptions.concat(
      this.variationSplitsService.getLoanShortfallData(this.jobId, this.jobNumber).subscribe({
        next: () => {
          // add the deposit
          this.invoiceRecords.push({
            recId: 'D',
            invoiceType: 'Deposit',
            claimNumber: '',
            description: 'Deposit',
            claimSplitType: '',
            companyActivity: '',
            amountToLoan: 0,
            amountToClient: this.job.depositAmount
          });

          // add the claim lines
          this.claimsService.claimJobLines.forEach(claimLine => {

            // add any split amounts
            let claimAmount = claimLine.amount;

            this.variationSplitsService.variationSplitsForJob
              .filter(i => i.jobLineId === claimLine.id)
              .forEach(split => {
                claimAmount += split.amount;
              });

            this.invoiceRecords.push({
              recId: 'C' + claimLine.id,
              invoiceType: 'Claim',
              claimNumber: claimLine.orderNo.toFixed(0),
              description: claimLine.description,
              claimSplitType: claimLine.claimTriggerId === 1 ? 'At Activity' : 'Raised Immediately',
              companyActivity: this.companService.activities.find(i => i.id === claimLine.companyActivityId)?.description,
              amountToLoan: claimLine.isInvoiceToClient ? 0 : claimAmount,
              amountToClient: claimLine.isInvoiceToClient ? claimAmount : 0
            });

            if (!claimLine.isInvoiceToClient) {
              this.loanShortfallAmount += claimAmount;
            }
          });

          // add the variations
          this.variationService.currentVariations.filter(i => i.variationType < 10 && i.statusId >= VariationStatusEnum.Approved).forEach(variation => {
            // get any splits for this variation
            const splits = this.variationSplitsService
              .variationSplitsForJob.filter(i => i.jobVariationId === variation.id);

            if (!splits || !splits.length) {
              this.invoiceRecords.push({
                recId: 'V' + variation.id,
                invoiceType: 'Variation',
                claimNumber: variation.displayedVariationNumber,
                description: variation.title,
                claimSplitType: 'By Activity',
                companyActivity: this.companService.activities.find(i => i.id === variation.companyActivityId)?.description,
                amountToLoan: 0,
                amountToClient: variation.variationTotal
              });
            } else {
              // add for each split
              splits.forEach(split => {
                let isInvoiceToClient = split.isInvoiceToClient;
                if (split.variationSplitTypeId === VariationSplitEnum.AddToClaim) {
                  isInvoiceToClient = this.claimsService.claimJobLines.find(i => i.id === split.jobLineId).isInvoiceToClient;
                }

                this.invoiceRecords.push({
                  recId: 'V' + variation.id,
                  invoiceType: 'Variation',
                  claimNumber: variation.displayedVariationNumber + '.' + split.orderNo,
                  description: variation.title,
                  claimSplitType: split.variationSplitTypeId === VariationSplitEnum.AddToClaim ? 'Add to claim'
                    : split.variationSplitTypeId === VariationSplitEnum.AtActivity ? 'At Activity' : 'At Date',
                  companyActivity: this.companService.activities.find(i => i.id === split.companyActivityId)?.description,
                  amountToLoan: split.variationSplitTypeId === VariationSplitEnum.AddToClaim ? 0 : isInvoiceToClient ? 0 : split.amount,
                  amountToClient: split.variationSplitTypeId === VariationSplitEnum.AddToClaim ? 0 : isInvoiceToClient ? split.amount : 0
                });

                if (!isInvoiceToClient && split.amount && split.variationSplitTypeId !== VariationSplitEnum.AddToClaim) {
                  this.loanShortfallAmount += split.amount;
                }
              });
            }
          });

          if (this.loanShortfallAmount < 0) {
            this.loanShortfallAmount = 0;
          }

          this.setUpDataSet();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'recId',
      load: () => this.invoiceRecords
    });
    this.loading = false;
  }

  close() {
    this._activeModal.close(null);
  }

  jobSearch() {
    const modalRef = this.modalService.open(JobSearchModalComponent, { windowClass: 'modal-search' });
    modalRef.componentInstance.searchType = JobSearchTypeEnum.AllJobs;

    modalRef.result.then((jobNumber) => {
      if (jobNumber) {
        this.jobNumber = jobNumber;
        this.getJob();
      }
    });
  }

  changeJobNum() {
    this.jobId = null;
  }

  calcLineTotal(data) {
    return data.amountToClient + data.amountToLoan;
  }
}
